<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">

      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button @click="showCreateModal = true" type="button" class="btn-primary inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm sm:w-auto"><span>Create New Bookmark</span></button>
      </div>
    </div>
    <div class="mt-8 flex flex-col" v-if="bookmarks.length">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Bookmark</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">URL</th>
                <th scope="col" class="py-3.5 pl-4 text-left text-sm font-semibold text-right text-gray-900 sm:pr-6">
                  Options
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <agency-bookmark-list-item
                v-for="(bookmark, i) in bookmarks"
                :bookmark="bookmark"
                :key="bookmark.id"
                :agencyId="agencyId"
                @bookmark-updated="syncData"
                @bookmarkDeleted="fetchBookmarks"
              ></agency-bookmark-list-item>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full text-center my-4">
      No bookmarks.
    </div>
  </div>

  <!-- Create New Bookmark Modal  -->
  <Modal :show="showCreateModal" @onClose="showCreateModal = false" :unmount="true" title="Create New Bookmark">
    <AgencyBookmarksForm :form-data="formData" @submit="createBookmark"></AgencyBookmarksForm>
  </Modal>
  <!-- End Create Bookmark Modal -->
</template>

<script>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import AgencyBookmarkListItem from "./AgencyBookmarkListItem.vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import Modal from "@/Components/Modal.vue";
import AgencyBookmarksForm from "@/Components/partials/AgencyBookmarksForm.vue";

export default {
  name: "AgencyManageBookmarks",

  mixins: [serverValidationErrorsMixin],

  components: {
    AgencyBookmarksForm,
    Modal,
    AgencyBookmarkListItem,
    LoadingSpinner,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },

  props: {
    agencyId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        name: null,
        url: null,
        errors: {},
      },
      bookmarks: [],
      loading: false,
      showCreateModal: false,
    }
  },

  mounted() {
    this.fetchBookmarks();
  },

  methods: {
    fetchBookmarks() {
      this.loading = true;
      axios
        .get(`/agencies/${this.agencyId}/bookmarks`)
        .then((response) => {
          this.bookmarks = response.data;
        })
        .catch((error) => {
          // Handle error
        })
        .finally(() => {
          this.loading = false;
        })
    },

    createBookmark() {
      this.loading = true;
      setTimeout(() => {
        axios
          .post(`/agencies/${this.agencyId}/bookmarks`, this.formData)
          .then((response) => {
            this.bookmarks.push(response.data);
            this.formData = {
              name: null,
              url: null,
            };
            this.showCreateModal = false;
          })
          .catch((error) => {
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    syncData(event) {
      let elementPos = this.bookmarks.map(function(x) {return x.id; }).indexOf(event.bookmark.id);
      this.bookmarks[elementPos] = event.bookmark;
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.formData.errors = error.response.data.errors;
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        })
      }
      console.log("AgencyManageBookmarks - handleErroredSubmission", error.response);
    },
  },
}
</script>

<style scoped>

</style>
