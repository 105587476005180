<script setup>
import {ref, onMounted} from 'vue';
import Selectable from "selectable.js";

const props = defineProps({
  days: Array,
  slots: Array,
  hours: Object,
  agencyId: {
    type: Number,
    required: true
  },
});

let dataIndex = 0;
const selectable = ref(null);
const selected_slots = ref(null);

function initSelectable(container) {
  if (!container) return;
  selectable.value = new Selectable({
    appendTo: container,
    toggle: true
  });
  selected_slots.value = getSelectedIndex();
}

function getSelectedIndex() {
  return selectable.value
  .getSelectedNodes()
  .map(item => Number(item.dataset.index));
}

function getSelectedSlots() {
  return selectable.value
  .getSelectedNodes()
  .map(item =>
    Object({[item.dataset.dayId]: Number(item.dataset.timeslotId)})
  )
  .reduce((result, item) => {
    let entry = Object.entries(item)[0];
    result[entry[0]] = result[entry[0]] || [];
    result[entry[0]].push(entry[1]);
    return result;
  }, []);
}

function updateSelectedSlots() {
  axios.post(route('agency_working_hours.store', props.agencyId),
    {
      selected_slots: getSelectedSlots(),
    }).then(() => {
    selected_slots.value = getSelectedIndex();
    Swal.fire(
      "",
      "<h2>Agency working hours have been updated!</h2>",
      "success"
    );
  }).catch(error => {
    console.log(error)
    Swal.fire("Oops...", "<h2>Something went wrong!</h2>", "error");
  })
}

function discardChanges() {
  selectable.value.clear();
  selectable.value.select(selected_slots.value);
}

onMounted(() => {
  let selectableContainer = document.getElementById('selectable_container')
  initSelectable(selectableContainer)
})
</script>

<template>
    <header
      class="md:flex md:flex-none items-center justify-between py-4 px-6">
      <h1 class="text-lg font-semibold text-gray-900">Agency working hours</h1>
      <div class="flex items-center">
        <div class="block md:ml-4 md:flex md:items-center">
          <button @click="updateSelectedSlots" type="button"
                  class="w-full md:w-auto md:ml-4 btn btn-primary btn-sm">
            Update
          </button>
          <button @click="discardChanges" type="button"
                  class="w-full md:w-auto md:ml-4 btn btn-primary btn-sm">
            Discard changes
          </button>
        </div>
      </div>
    </header>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div id="selectable_container"
               class="relative overflow-hidden shadow ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full table-fixed divide-y divide-gray-200 workhours">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" width="12.5%"
                    class="border border-gray-200 py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">Time slots
                </th>
                <th scope="col" v-for="day in days" width="12.5%"
                    class="relative py-3.5 pl-3 pr-4 sm:pr-6 font-semibold items-center justify-center py-3 border border-gray-200">
                  {{ day }}
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="(slot, slotKey) in slots" :key="`slot_${slotKey}`" class="py-3">
                <td
                  class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 text-sm bg-white text-right py-3">
                  {{ `${slot[0]} - ${slot[1]}` }}
                </td>
                <td v-for="(day, key) in days" :key="`day_${key}`"
                    class="py-3 ui-selectable border border-gray-200 whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900"
                    :class="hours[slotKey] && hours[slotKey][key] ? 'ui-selected' : ''" :data-day-id="key"
                    :data-timeslot-id="slotKey" :data-index="dataIndex++"></td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">
table.workhours > thead > tr > th,
table.workhours > tbody > tr > td:first-child {
  color: black;
  text-align: center;
}

table.workhours tbody > tr.ui-selected,
table.workhours tbody > tr > .ui-selected {
  background-color: rgb(var(--color-primary));
}

table.workhours > thead > tr:first-child {
  position: sticky;
  top: 0;
  z-index: 2;
}

#selectable_container {
  /*max-height: 64vh !important;*/
}
</style>
