<template>
  <!-- BEGIN: Pagination -->
  <div v-if="pager.pages && pager.pages.length">
    <div v-if="showInfoText" class="flex flex-wrap justify-between sm:flex-row sm:flex-nowrap items-center sm:space-y-2 space-y-2 sm:space-y-0 py-4">
      <div class="text-xs text-slate-600 text-right">Total pages: {{ paginationData.meta.last_page }}</div>
      <div class="text-xs text-slate-600 text-right">Total records: {{ paginationData.meta.total }}</div>
    </div>
    <div class="flex flex-wrap sm:flex-row sm:flex-nowrap items-center">

      <nav class="w-full sm:w-auto sm:mr-auto">
        <ul class="pagination">
          <!-- BEGIN: First  -->
          <li class="page-item" :class="{'disabled': pager.currentPage === 1}">
            <button class="page-link" :disabled="pager.currentPage === 1" @click.prevent="setPage(1)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevrons-left" class="lucide lucide-chevrons-left w-4 h-4" data-lucide="chevrons-left" data-v-353f12bb=""><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>
            </button>
          </li>
          <!-- END: First -->

          <!-- BEGIN: Previous  -->
          <li class="page-item" :class="{'disabled': pager.currentPage === 1}">
            <button class="page-link" :disabled="pager.currentPage === 1" @click="setPage(pager.currentPage - 1)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-left" class="lucide lucide-chevron-left w-4 h-4" data-lucide="chevron-left" data-v-353f12bb=""><polyline points="15 18 9 12 15 6"></polyline></svg>
            </button>
          </li>
          <!-- END: Previous -->

          <!-- BEGIN: Visible Buttons -->
          <li class="page-item" v-for="page in pager.pages" :key="page">
            <button class="page-link" :disabled="pager.currentPage === page" :class="pager.currentPage === page ? activeClasses : ''" @click.prevent="setPage(page)">{{ page }}</button>
          </li>
          <!-- END: Visible Buttons -->


          <!-- BEGIN: Next -->
          <li class="page-item" :class="{'disabled': pager.currentPage === pager.totalPages}">
            <button class="page-link" :disabled="pager.currentPage === pager.totalPages" @click="setPage(pager.currentPage + 1)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-right" class="lucide lucide-chevron-right w-4 h-4" data-lucide="chevron-right" data-v-353f12bb=""><polyline points="9 18 15 12 9 6"></polyline></svg>
            </button>
          </li>
          <!-- END: Next -->
          <!-- BEGIN: Last -->
          <li class="page-item" :class="{'disabled': pager.currentPage === pager.totalPages}">
            <button class="page-link" :disabled="pager.currentPage === pager.totalPages" @click="setPage(pager.totalPages)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevrons-right" class="lucide lucide-chevrons-right w-4 h-4" data-lucide="chevrons-right" data-v-353f12bb=""><polyline points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>
            </button>
          </li>
          <!-- END: Last -->
        </ul>
      </nav>

      <select @change="$emit('updatePerPage', $event.target.value)" class="w-20 input-field form-select box mt-3 sm:mt-0 bg-slate-100">
        <option value="6">6</option>
        <option value="12">12</option>
        <option selected value="24">24</option>
        <option value="48">48</option>
        <option value="96">96</option>
      </select>
    </div>
  </div>
  <!-- END: Pagination -->
</template>

<script>
export default {
  name: "AppPaginator",

  props: {
    paginationData: {
      type: Object,
      required: true
    },
    maxPages: {
      type: Number,
      default: 5
    },
    activeClasses: {
      type: String,
      default: 'bg-slate-100 pointer-events-none'
    },
    showInfoText: {
      type: Boolean,
      default: true
    },
    showPerPage: {
      type: Boolean,
      default: true
    },
  },

  emits: ['changePage', 'updatePerPage'],

  data() {
    return {
      perPage: 24,
      initialPage: 1,
      pager: {},
      page: 1,
      pages: '',
    }
  },

  created() {
    // set to initial page
    this.setPage(this.initialPage);
  },

  watch: {
    paginationData() {
      this.page = this.paginationData.meta.current_page;
      this.pages = this.paginationData.meta.last_page;
      this.perPage = this.paginationData.meta.per_page;

      // get new pager object for specified page
      this.pager = this.paginate(this.paginationData.meta.total, this.page, this.perPage, this.maxPages);
    },
  },
  methods: {
    setPage(page) {
      this.$emit('changePage', page);
    },

    paginate(totalItems, currentPage = 1, perPage = 1, maxPages = 1) {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / perPage);

      // ensure current page isn't out of range
      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > totalPages) {
        currentPage = totalPages;
      }

      let startPage, endPage;
      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

      // return object with all pager properties required by the view
      return {
        totalItems: totalItems,
        currentPage: currentPage,
        perPage: perPage,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        pages: pages
      };
    }
  },
}
</script>

<style scoped>
.page-item.disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
  opacity: 20% !important;
}

.disabled.page-link {
  pointer-events: none !important;
  cursor: not-allowed !important;

}
</style>
