<template>
  <table class="table table-report -mt-2 px-1 shadow-none">
    <thead>
      <tr>
<!--        <th class="text-center whitespace-nowrap">Actions</th>-->
        <th class="whitespace-nowrap">Lead name</th>
        <th class="whitespace-nowrap">Company</th>
        <th class="text-center whitespace-nowrap">Address</th>
        <th class="text-center whitespace-nowrap">Phone</th>
        <th class="text-center whitespace-nowrap">Email</th>
        <th class="text-center whitespace-nowrap">Status</th>
        <th class="text-center whitespace-nowrap">Lead date</th>
      </tr>
    </thead>
    <tbody>
      <lead-search-table-item
        v-for="(lead, index) in dataSet"
        :key="`lead_id_${lead.id}`"
        :index="index"
        :lead="lead"
        @leadUpdated="handleLeadUpdated"
        @leadDeleted="handleLeadDeleted"
        :states="states"></lead-search-table-item>
    </tbody>
  </table>
</template>

<script>
import _ from "lodash";
import LeadSearchTableItem from "./LeadSearchTableItem.vue";

export default {
  name: "LeadSearchTable",

  components: {
    LeadSearchTableItem,
  },

  props: {
    leads: {
      type: Array,
      default: () => [],
    },

    states: {
      type: Array,
      required: true,
    }
  },

  data() {
    return {
      dataSet: this.leads,
      loading: true
    }
  },

  mounted() {
    this.loading = false;
  },

  methods: {
    handleLeadDeleted(payload) {
      this.dataSet.splice(payload.index, 1);
    },

    handleLeadUpdated(payload) {
      return this.dataSet[payload.index] = payload.lead;
    },
  },
}
</script>

<style scoped>

</style>