<template>
  <tr class="outline outline-slate-300 rounded-lg shadow-none" v-if="editing">
    <td class="w-56 align-top shadow-none">
      <div class="flex justify-center items-center">
        <button :disabled="loading" class="btn btn-success btn-sm text-xs text-white p-1 mr-3" @click.prevent="updateLead">
          Save
        </button>
        <button :disabled="loading" class="btn btn-secondary btn-sm text-xs p-1" @click.prevent="editing = false;">
          Close
        </button>
      </div>
    </td>
    <td class="w-40 align-top shadow-none">
      <div class="form-inline w-40" :class="{'has-error': isInvalid('first_name')}">
        <label :for="`lead_first_name_${lead.id}`" class="form-label text-xs">First name</label>
        <input :id="`lead_first_name_${lead.id}`" type="text" class="form-control form-control-sm text-xs mt-0.5" v-model="first_name">
      </div>
      <div v-if="isInvalid('first_name')" class="pristine-error text-xs text-danger mt-1">{{ errorMessage('first_name') }}</div>

      <div class="form-inline w-40 mt-2 shadow-none" :class="{'has-error': isInvalid('last_name')}">
        <label :for="`lead_last_name_${lead.id}`" class="form-label text-xs">Last name</label>
        <input :id="`lead_last_name_${lead.id}`" type="text" class="form-control form-control-sm text-xs mt-0.5" v-model="last_name">
      </div>
      <div v-if="isInvalid('last_name')" class="pristine-error text-xs text-danger mt-1">{{ errorMessage('last_name') }}</div>
    </td>

    <td class="w-40 align-top shadow-none">
      <div class="input-form" :class="{'has-error': isInvalid('company')}">
        <input type="text" class="form-control form-control-sm text-xs mt-0.5 whitespace-nowrap w-40" placeholder="Company" v-model="company">
        <div v-if="isInvalid('company')" class="pristine-error text-xs text-danger mt-1">{{ errorMessage('company') }}</div>
      </div>
    </td>
    <td class="w-56 align-top shadow-none">
      <div class="form-inline w-56" :class="{'has-error': isInvalid('address_1')}">
        <label :for="`lead_address1_${lead.id}`" class="form-label text-xs sm:w-20">Address1</label>
        <input :id="`lead_address1_${lead.id}`" type="text" class="form-control form-control-sm text-xs mt-0.5" placeholder="Address1" v-model="address_1">
      </div>
      <div v-if="isInvalid('address_1')" class="pristine-error text-xs text-danger mt-1 text-right">{{ errorMessage('address_1') }}</div>

      <div class="form-inline w-56 mt-2 shadow-none" :class="{'has-error': isInvalid('address_2')}">
        <label :for="`lead_address2_${lead.id}`" class="form-label text-xs sm:w-20">Address2</label>
        <input :id="`lead_address2_${lead.id}`" type="text" class="form-control form-control-sm text-xs mt-0.5" placeholder="Address2" v-model="address_2">
      </div>
      <div v-if="isInvalid('address_2')" class="pristine-error text-xs text-danger mt-1 mr-auto text-right">{{ errorMessage('address_2') }}</div>

      <div class="form-inline w-56 mt-2 shadow-none" :class="{'has-error': isInvalid('city')}">
        <label :for="`lead_city_${lead.id}`" class="form-label text-xs sm:w-20">City</label>
        <input :id="`lead_city_${lead.id}`" type="text" class="form-control form-control-sm text-xs mt-0.5" placeholder="City" v-model="city">
      </div>
      <div v-if="isInvalid('city')" class="pristine-error text-xs text-danger mt-1 text-right">{{ errorMessage('city') }}</div>

      <div class="form-inline w-56 mt-2 shadow-none" :class="{'has-error': isInvalid('state_id')}">
        <label :for="`lead_state_${lead.id}`" class="form-label text-xs sm:w-20">State</label>
        <select :id="`lead_state_${lead.id}`" v-model="state_id" class="form-control form-control-sm w-full" id="state_id" name="state_id">
          <option v-for="(state, i) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
        </select>
      </div>
      <div v-if="isInvalid('state_id')" class="pristine-error text-xs text-danger mt-1 text-right">{{ errorMessage('state_id') }}</div>

      <div class="form-inline w-56 mt-2 shadow-none" :class="{'has-error': isInvalid('zip')}">
        <label :for="`lead_zip_${lead.id}`" class="form-label text-xs sm:w-20">Zip</label>
        <input :id="`lead_zip_${lead.id}`" type="text" class="form-control form-control-sm text-xs mt-0.5" placeholder="Zip" v-model="zip">
      </div>
      <div v-if="isInvalid('zip')" class="pristine-error text-xs text-danger mt-1 text-right">{{ errorMessage('zip') }}</div>

      <div class="form-inline w-56 mt-2 shadow-none" :class="{'has-error': isInvalid('county')}">
        <label :for="`lead_county_${lead.id}`" class="form-label text-xs sm:w-20">County</label>
        <input :id="`lead_county_${lead.id}`" type="text" class="form-control form-control-sm text-xs mt-0.5" placeholder="County" v-model="county">
      </div>
      <div v-if="isInvalid('county')" class="pristine-error text-xs text-danger mt-1 text-right">{{ errorMessage('county') }}</div>
    </td>
    <td class="w-40 align-top shadow-none">
      <div class="input-form w-40" :class="{'has-error': isInvalid('phone')}">
        <input type="text" class="form-control form-control-sm text-xs mt-0.5 whitespace-nowrap" placeholder="Phone" v-model="phone">
        <div v-if="isInvalid('phone')" class="pristine-error text-xs text-danger mt-1">{{ errorMessage('phone') }}</div>
      </div>
    </td>
    <td class="w-40 align-top shadow-none">
      <div class="input-form w-40" :class="{'has-error': isInvalid('email')}">
        <input type="text" class="form-control form-control-sm text-xs mt-0.5 whitespace-nowrap" placeholder="Email" v-model="email">
        <div v-if="isInvalid('email')" class="pristine-error text-xs text-danger mt-1">{{ errorMessage('email') }}</div>
      </div>
    </td>
    <td class="w-40 align-top shadow-none">
      <div class="w-40 input-form">
        <input id="lead_status" type="text" class="form-control form-control-sm w-full" readonly :value="lead.status_name">
      </div>
    </td>

    <td class="w-40 align-top shadow-none">
      <div class="input-form w-40" :class="{'has-error': isInvalid('lead_date')}">
        <el-date-picker
          id="lead-date-datepicker-edit-form"
          style="width: 100% !important;"
          v-model="lead.lead_date"
          type="date"
          placeholder="Pick a day"
          format="MMM DD, YYYY"
          value-format="YYYY-MM-DD"
        />
        <div v-if="isInvalid('lead_date')" class="pristine-error text-xs text-danger mt-1">{{ errorMessage('lead_date') }}</div>
      </div>
    </td>
  </tr>
  <tr class="shadow-none" v-else>
    <td>
      <a :href="lead.leads_show_route" class="text-primary font-medium text-xs whitespace-nowrap mt-0.5">{{ lead.full_name }}</a>
      <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">Lead ID: {{ lead.id }}</div>
      <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">Agent Name: {{ lead.agent.name }}</div>
    </td>
    <td>
      <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ lead.company }}</div>
    </td>
    <td class="text-center w-56">
      <div class="text-slate-500 text-xs mt-0.5 w-56">{{ lead.full_address }}</div>
    </td>
    <td class="w-40">
      <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ formatPhone(lead.phone) }}</div>
    </td>
    <td class="w-40">
      <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ lead.email }}</div>
    </td>
    <td class="w-40">
      <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ lead.status_name }}</div>
    </td>

    <td class="w-40">
      <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ lead.lead_date }}</div>
    </td>
  </tr>
</template>

<script>
import _ from "lodash";
import helpers from "../helper";
import serverValidationErrorsMixin from "./mixins/serverValidationErrorsMixin";
import {ElDatePicker} from "element-plus";

export default {
  name: "LeadSearchTableItem",

  mixins: [serverValidationErrorsMixin],

  components: {
    ElDatePicker,
  },

  props: {
    lead: {
      type: Object,
      required: true,
    },
    states: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    }
  },

  emits: ['lead-deleted', 'lead-updated'],

  data() {
    return {
      formData: _.cloneDeep(this.lead),
      editing: false,
      loading: false,
    }
  },

  computed: {
    first_name: {
      get() {
        return this.formData.first_name;
      },
      set(value) {
        this.formData.first_name = value;
      }
    },
    last_name: {
      get() {
        return this.formData.last_name;
      },
      set(value) {
        this.formData.last_name = value;
      }
    },
    company: {
      get() {
        return this.formData.company;
      },
      set(value) {
        this.formData.company = value;
      }
    },
    address_1: {
      get() {
        return this.formData.address_1;
      },
      set(value) {
        this.formData.address_1 = value;
      }
    },
    address_2: {
      get() {
        return this.formData.address_2;
      },
      set(value) {
        this.formData.address_2 = value;
      }
    },
    city: {
      get() {
        return this.formData.city;
      },
      set(value) {
        this.formData.city = value;
      }
    },
    state_id: {
      get() {
        return this.formData.state_id;
      },
      set(value) {
        this.formData.state_id = value;
      }
    },
    zip: {
      get() {
        return this.formData.zip;
      },
      set(value) {
        this.formData.zip = value;
      }
    },
    county: {
      get() {
        return this.formData.county;
      },
      set(value) {
        this.formData.county = value;
      }
    },
    lead_date: {
      get() {
        return this.formData.lead_date;
      }
    },
    email: {
      get() {
        return this.formData.email;
      },
      set(value) {
        this.formData.email = value;
      }
    },
    phone: {
      get() {
        return this.formData.phone;
      },
      set(value) {
        this.formData.phone = value;
      }
    },
  },
  methods: {
    updateLead() {
      if (this.loading) return;
      this.loading = true;
      // App\Http\Controllers\UpdateLeadSearchTableItemController::class, 'update'
      setTimeout(() => {
        axios
          .put(`/leads/${this.lead.id}/search-table-item-update`, this.formData)
          .then((response) => {
            this.handleSuccessfulSubmission(response);
          })
          .catch((error) => {
            console.log(error, 'AppLeadEditForm - submit()')
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    handleSuccessfulSubmission(response) {
      this.formData = response.data.lead;
      this.$emit('lead-updated', {
        index: this.index,
        lead: response.data.lead
      });

      Swal.fire({
        text: 'Lead updated.',
        icon: 'success'
      });

      this.errors = {};
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        })
      }
      console.log("AppLeadSearchTableItem - handleErroredSubmission", error.response);
    },

    confirmDelete() {
        this.loading = true;
        Swal.fire({
          title: 'Are you sure?',
          text: 'You wont not be able to undo this.',
          icon: 'warning',
          confirmButtonText: "Yes, I'm sure.",
          showCancelButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteLead();
          }
        })
    },

    deleteLead() {
      axios.delete(`/leads/${this.lead.id}`)
        .then((response) => {
          Swal.fire({
            title: 'Lead deleted',
          }).then(() => {
            this.$emit('lead-deleted', {
              index: this.index,
              lead: this.lead
            })
          })
        })
        .catch((error) => {
          Swal.fire({
            text: 'Unknown error occurred, please try again.',
            icon: 'error'
          });

          console.log("LeadSearchTableItem - handleErroredSubmission", error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateLeadDate(dateObj) {
      this.formData.lead_date = dateObj.dateInstance.toDateString();
    },
  },
}
</script>