<template>
  <div class="bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
    <div class="p-6 flex space-x-4 items-center justify-between">
      <div class="flex space-x-4 items-center justify-between">
        <h4 class="text-xl font-black">Lead Status & Assignment</h4>
      </div>
      <div class="text-black">
        <DragIcon class="menu-icon move-icon w-6 h-6 cursor-move -mr-2"></DragIcon>
      </div>
    </div>

    <div data-name="widget-body" class="min-h-[100px] relative">

      <!-- BEGIN: Lead types -->
      <div v-if="lead.data.lead_category === 'commercial'" class="px-6">
        <div>
          <div class="flex items-center justify-start space-x-2">
            <label class="input-label text-gray-600">Lead Types</label>
            <button @click.prevent="toggleForm(leadTypesForm)" type="button"
                    class="btn text-tb-blue p-0 rounded-full focus:ring-2 focus:ring-indigo-200">
              <PlusCircleIcon class="text-tb-blue w-6 h-6" :class="this.activeForm === leadTypesForm ? 'rotate-45' : 'rotate-0'"></PlusCircleIcon>
            </button>

          </div>
          <div class="flex flex-wrap justify-start items-center w-full mt-2">
            <div v-if="selectedTypes.length" v-for="(lead_type, index) in selectedTypes" :key="`lead_type-${lead_type.id}`"
                 class="bg-slate-300 mt-1 pl-3 pr-2 py-0.5 rounded-md text-sm flex justify-between items-center space-x-2 mr-1">
              <span class="text-sm py-1 font-medium">{{ lead_type.name }}</span>
              <button v-on:click.prevent="removeType(lead_type)" class="btn text-red-500 p-0 rounded-md border-none" :disabled="loading" :class="{'pointer-events-none': loading}">
                <XCircleIcon class="w-5 h-5"></XCircleIcon>
              </button>
            </div>
            <div v-else class="mt-1 h-4 min-h-[24px]">&mdash;</div>
          </div>
        </div>

        <!-- BEGIN: Lead Type Form - ADD -->
        <div v-show="activeForm === leadTypesForm">
          <div class="flex flex-wrap justify-start items-center w-full border-2 rounded mt-2 pt-1 pb-2 px-2 border-slate-100 border-dashed">
            <div v-if="availableTypes.length" v-for="availableType in availableTypes" :key="availableType.id"
                 class="bg-slate-100 mt-1 pl-3 pr-2 py-0.5 rounded-md text-sm flex justify-between items-center space-x-2 mr-1">
              <span class="text-sm py-1 font-medium">{{ availableType.name }}</span>
              <button v-on:click.prevent="addType(availableType)" type="button" class="btn text-tb-blue p-0 rounded-md border-none" :disabled="loading" :class="{'pointer-events-none': loading}">
                <PlusCircleIcon class="h-5 w-5"></PlusCircleIcon>
              </button>
            </div>
            <div v-else class="mt-1 h-4 min-h-[24px]">&mdash;</div>
          </div>
        </div>
        <!-- END: Lead Type Form -->
      </div>
      <!-- END: Lead types -->

      <!-- BEGIN: Lead Date / Time -->
      <div v-if="lead.data.lead_category === 'commercial'" class="w-full px-6 mt-4">
        <div class="mb-1">
          <label class="input-label text-gray-600">Lead Date / Time</label>
        </div>
        <div class="w-full">
          <input type="text" :value="leadDate" readonly disabled class="input-field font-medium text-sm" />
        </div>
      </div>
      <!-- END: Lead Date / Time -->

      <div class="flex justify-between w-full space-x-4 px-6 mt-4">
        <!-- BEGIN: Lead Agent -->
        <div class="w-full">
          <div class="mb-1">
            <label class="input-label text-gray-600">Agent</label>
          </div>
          <div class="w-full">
            <input type="text" :value="dataSet.agent ?? '—'" readonly disabled class="input-field font-medium text-sm" />
          </div>
        </div>
        <!-- END: Lead Agent -->

        <!-- BEGIN: Lead Status -->
        <div class="w-full">
          <div class="mb-1">
            <label class="input-label text-gray-600">Status</label>
          </div>
          <div class="w-full">
            <input type="text" :value="dataSet.status ?? '—'" readonly disabled class="input-field font-medium text-sm" />
          </div>
        </div>
        <!-- END: Lead Status -->
      </div>

      <div v-if="lead.data.lead_category === 'recruitment'" class="flex justify-between w-full space-x-4 px-6 mt-4">
        <!-- BEGIN: Lead Status -->
        <div class="w-full">
          <div class="mb-1">
            <label class="input-label text-gray-600">Recruiting Location</label>
          </div>
          <div class="w-full">
            <input type="text" :value="dataSet.recruiting_location ?? '—'" readonly disabled class="input-field font-medium text-sm" />
          </div>
        </div>
        <!-- END: Lead Status -->
      </div>

      <!-- BEGIN: Lead Sold Date -->
      <div v-if="dataSet.sold_date" class="flex justify-between py-3 px-4 border-b border-slate-100">
        <div>
          <span class="text-sm">Sold Date</span>
        </div>
        <div>
          <span class="font-medium text-sm">{{ dataSet.sold_date }}</span>
        </div>
      </div>
      <!-- END: Lead Sold Date -->

      <!-- BEGIN: Lead Categories (Folders) -->
      <div class="px-6 mt-4">
        <div>
            <div class="flex items-center justify-start space-x-2">
                <label class="input-label text-gray-600">Custom Tag Categories</label>
                <button @click.prevent="showLeadCategoriesForm" type="button"
                            class="btn text-tb-blue p-0 rounded-full border-none focus:ring-2 focus:ring-indigo-200">
                    <PlusCircleIcon class="text-tb-blue h-6 w-6 transform" :class="this.activeForm === leadCategoriesForm ? 'rotate-45' : 'rotate-0'"></PlusCircleIcon>
                </button>
            </div>
            <div class="flex flex-wrap justify-start items-center space-x-1 mt-2">

                <div v-if="dataSet.custom_tags.length" v-for="customTag in dataSet.custom_tags" :key="customTag.id"
                         class="bg-slate-300 mt-1 pl-3 pr-2 py-0.5 rounded-md text-sm flex justify-between items-center space-x-2 mr-1">
                    <span class="text-sm py-1 font-medium">{{ customTag.name }}</span>
                    <button @click.prevent="removeLeadFromFolder(customTag)" type="button" class="btn text-red-500 p-0 rounded-md border-none">
                        <XCircleIcon class="w-5 h-5"></XCircleIcon>
                    </button>
                </div>
                <div v-else class="mt-1 h-4 min-h-[24px]">&mdash;</div>

            </div>
        </div>

            <Modal :show="leadCategoriesFormVisible" @onClose="leadCategoriesFormVisible = false" title="Custom Tag Categories">
                <CustomTagAssignForm :lead-id="leadId" :custom-tags="customTags" @lead-folder-assigned-successfully="handleLeadFolderAssigned"></CustomTagAssignForm>
            </Modal>
      </div>
      <!-- END: Lead Categories (Folders) -->

      <!-- BEGIN: Share lead -->
      <div class="px-6 mt-4">
        <div>
          <div class="flex items-center justify-start space-x-2">
            <label class="input-label text-gray-600">Share Lead</label>
            <button @click.prevent="showShareLeadForm" type="button"
                    class="btn text-tb-blue p-0 rounded-full border-none focus:ring-2 focus:ring-indigo-200">
              <PlusCircleIcon class="text-tb-blue h-6 w-6 transform" :class="this.activeForm === shareLeadForm ? 'rotate-45' : 'rotate-0'"></PlusCircleIcon>
            </button>
          </div>
          <div class="flex flex-wrap justify-start items-center space-x-1 mt-2">

            <div v-if="dataSet.shared_with.length" v-for="agent in dataSet.shared_with" :key="agent.id"
                 class="bg-slate-300 mt-1 pl-3 pr-2 py-0.5 rounded-md text-sm flex justify-between items-center space-x-2 mr-1">
              <span class="text-sm py-1 font-medium">{{ agent.name }}</span>
              <button @click.prevent="stopSharingLeadWithAgent(agent)" type="button" class="btn text-red-500 p-0 rounded-md border-none">
                <XCircleIcon class="w-5 h-5"></XCircleIcon>
              </button>
            </div>
            <div v-else class="mt-1 h-4 min-h-[24px]">&mdash;</div>

          </div>
        </div>

        <Modal :show="shareLeadFormModalVisible" @onClose="shareLeadFormModalVisible = false" title="Find agent(s) to share this lead with" :unmount="true">
          <AgentLeadShareCreateForm :lead-id="leadId" @lead-shared-successfully="handleLeadShared"></AgentLeadShareCreateForm>
        </Modal>
      </div>
      <!-- END: Share Lead -->

      <!-- BEGIN: Source -->
      <div class="px-6 mt-4 pb-6" :class="{'cursor-pointer': canEditSource}">
        <div class="mb-1">
          <label class="input-label text-gray-600">Source</label>
        </div>
        <div class="w-full flex space-x-4">
          <input v-if="!canEditSource" type="text" :value="dataSet.source" readonly disabled class="input-field font-medium text-sm" />
          <select class="input-field w-4/5 font-medium text-sm" v-model="selected_source_id" v-else>
            <option v-for="(source, index) in sources" :key="source.id" :label="`${source.vendor} - ${source.label}`" :value="source.id"></option>
          </select>
          <button type="button" @click.prevent="confirmSourceChange"
            v-if="canEditSource"
            :disabled="selected_source_id === dataSet.source_id || processing_source_change"
            class="btn btn-primary text-sm"
            :class="{'opacity-30': selected_source_id === dataSet.source_id || processing_source_change}"
          >Change</button>
        </div>
      </div>
      <!-- END: Source -->

      <!-- BEGIN: HST Lead ID -->
      <div v-if="lead.data.hst_lead_id" class="px-6 mt-4 pb-6">
        <div class="mb-1">
          <label class="input-label text-gray-600">HST Lead ID <a class="text-blue-700" target="_blank" :href="`https://hstleadmaster.com/lead_view.asp?leadid=${lead.data.hst_lead_id}`">(View)</a></label>
        </div>
        <div class="w-full">
          <input type="text" :value="lead.data.hst_lead_id" readonly disabled class="input-field font-medium text-sm" />
        </div>
      </div>
      <!-- END: HST Lead ID -->
    </div>


  </div>

</template>

<script>
import Modal from "@/Components/Modal.vue";
import {XCircleIcon, PlusCircleIcon, MenuIcon} from '@heroicons/vue/outline'
import AgentLeadShareCreateForm from '@/Components/partials/AgentLeadShareCreateForm.vue';
import {Inertia} from "@inertiajs/inertia";
import DragIcon from "@/Components/DragIcon.vue";
import CustomTagAssignForm from "@/Components/partials/CustomTagAssignForm.vue";
import moment from "moment";

export default {
  name: "AppLeadStatusAndAssignment",

  components: {
    DragIcon,
    MenuIcon,
    CustomTagAssignForm,
    Modal,
    PlusCircleIcon,
    XCircleIcon,
    AgentLeadShareCreateForm
  },

  props: {
    dataSet: {
      type: Object,
      required: true
    },

    types: {
      type: Array,
      required: true,
    },

    tags: {
      type: Array,
      default: () => [],
    },

    leadId: {
      type: Number,
      required: true
    },
    customTags: Array,
    timezone: {
      type: String,
      default: 'America/New_York'
    },
    lead: {
      type: Object,
    },
    user: {},
    sources: {},
    role: String,
  },

  data() {
    return {
      activeForm: null,
      forms: {
        leadType: {
          name: 'leadType',
          indexUrl: `/leads/${this.leadId}/types`,
          storeUrl: `/leads/${this.leadId}/types`,
          formData: {},
        },
        shareLead: {
          name: 'shareLead',
        },
        leadCategories: {
          name: 'leadCategories',
        },
        tags: {
          name: 'tags',
          indexUrl: `/leads/${this.leadId}/tags`, // TODO
          storeUrl: `/leads/${this.leadId}/tags`, // TODO
          formData: {},
        }
      },
      loadingTimeout: 350,
      loading: false,
      shareLeadFormModalVisible: false,
      leadCategoriesFormVisible: false,
      selected_source_id: this.dataSet.source_id,
      processing_source_change: false,
    }
  },

  created() {
    this.forms.leadType.formData = this.dataSet.lead_types;
  },

  computed: {
    canEditSource() {
      // If the lead has an HST Lead ID, it cannot be changed
      if (this.lead.data.hst_lead_id) {
          return false;
      }
      // Only the user who originally owns the source can change it
      return this.lead.data.user_id === this.user.id && this.user.id === this.dataSet.source_user_id; //|| ['Agency Admin', 'Super Admin'].includes(this.role);
    },
    leadTypesForm() {
      return this.forms.leadType.name;
    },
    shareLeadForm() {
      return this.forms.shareLead.name;
    },
    tagsForm() {
      return this.forms.tags.name;
    },
    leadCategoriesForm() {
      return this.forms.tags.name;
    },

    selectedTypes() {
      return this.forms.leadType.formData;
    },

    selectedTags() {
      return this.dataSet.tags;
    },

    availableTypes() {
      let leadTypes = this.selectedTypes.length ? this.selectedTypes.map(i => i.id) : [];
      if (leadTypes.length) {
        return this.types.filter(type => !leadTypes.includes(type.id));
      }

      return this.types;
    },

    availableTags() {
      let leadTags = this.selectedTags.length ? this.selectedTags.map(i => i.id) : [];
      if (leadTags.length) {
        return this.tags.filter(type => !leadTags.includes(type.id));
      }

      return this.tags;
    },
    leadDate() {
      if (this.dataSet.lead_date_time && this.timezone) {
        return moment(this.dataSet.lead_date_time).tz(this.timezone).format('MM/DD/YYYY h:mm A');
      }
      return this.dataSet.lead_date_timezoneless;
    }
  },

  methods: {
    toggleForm(form) {
      if (form === this.activeForm) {
        return this.closeForm();
      }

      this.activeForm = form;
    },

    closeForm() {
      this.activeForm = null;
    },

    async removeType(lead_type) {
      this.loading = true;
      await new Promise((resolve, reject) => {
          let index = this.selectedTypes.findIndex(object => {
            return object.lead_type_id === lead_type.lead_type_id;
          });

          setTimeout(() => {
            resolve(this.forms.leadType.formData.splice(index, 1));
          }, this.loadingTimeout);
      }).then(() => {
        this.submitForm(this.forms.leadType.name)
      });
    },

    async addType(lead_type) {
      this.loading = true;
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve( this.forms.leadType.formData.push(lead_type));
        }, this.loadingTimeout);
      }).then(() => {
        this.submitForm(this.forms.leadType.name)
      });
    },

    submitForm(form) {
      axios
        .post(this.forms[form].storeUrl, {
          data: this.forms[form].formData
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showShareLeadForm() {
      this.shareLeadFormModalVisible = true;
    },

    showLeadCategoriesForm() {
      this.leadCategoriesFormVisible = true;
    },

    handleLeadShared() {
      this.shareLeadFormModalVisible = false;
    },

    handleLeadFolderAssigned() {
      this.leadCategoriesFormVisible = false;
    },

    addTagToLead(tag) {
      Inertia.post(route('lead.tags.store', this.leadId),
        {
          agency_lead_tag_id: tag.id
        }, {
          preserveScroll: true,
          only: ['statusAndAssignmentData'],
          onSuccess: () => {

          },
          onError: () => {
            console.log('error')
          },
          onFinish: () => {

          },
        }
      )
    },

    removeTagFromLead(tag) {
      let agencyLeadTagId = tag.id;
      Inertia.delete(route('lead.tags.delete', [this.leadId, agencyLeadTagId]),
        {
          preserveScroll: true,
          only: ['statusAndAssignmentData'],
          onSuccess: () => {

          },
          onError: () => {
            console.log('error')
          },
          onFinish: () => {

          },
        }
      )
    },

    stopSharingLeadWithAgent(agent) {
      Inertia.delete(route('agent-lead-share.delete', [this.leadId, agent.id]), {
        preserveScroll: true,
        only: ['statusAndAssignmentData'],
        onSuccess: () => {

        },
        onError: () => {
          console.log('error')
        },
        onFinish: () => {

        },
      })
    },
    removeLeadFromFolder(customTag) {
        Inertia.delete(route('lead-custom-tags.lead.destroy', [customTag.id, this.leadId]), {
            preserveScroll: true,
            only: ['statusAndAssignmentData'],
            onSuccess: () => {

            },
            onError: () => {
                console.log('error')
            },
            onFinish: () => {

            },
        })
    },
    confirmSourceChange() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to undo this.',
        icon: 'warning',
        confirmButtonText: "Yes, I'm sure.",
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeSource();
        }
      })
    },
    changeSource() {
      this.processing_source_change = true;
      axios.post(route('leads.change-source', this.lead.data.id), {
        new_source_id: this.selected_source_id,
      })
        .then(r => {
          $notify.success("Source changed, reloading");
          window.location.reload();
        })
        .catch(e => {
          $notify.error("Error: " + e.response?.status);
          throw e;
        })
        .finally(() => {
          this.processing_source_change = false;
        })
    }
  },
}
</script>

<style scoped>

</style>
