<template>
  <form @submit.prevent="submitBillingProfile" class="intro-y box p-5" method="POST">
    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: First name -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('first_name')}">
        <label for="first_name" class="form-label text-sm text-gray-600">First name</label>
        <input v-model="billingProfile.first_name" id="first_name" type="text" class="input-field w-full" name="first_name">
        <div v-if="isInvalid('first_name')" class="error-message mt-1">{{ errorMessage('first_name') }}</div>
      </div>
      <!-- END: First name -->

      <!-- BEGIN: Last name -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('last_name')}">
        <label for="last_name" class="form-label text-sm text-gray-600">Last name</label>
        <input v-model="billingProfile.last_name" id="last_name" type="text" class="input-field w-full" name="last_name">
        <div v-if="isInvalid('last_name')" class="error-message mt-1">{{ errorMessage('last_name') }}</div>
      </div>
      <!-- END: Last name -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Address 1 -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('address')}">
        <label for="address" class="form-label text-sm text-gray-600">Address</label>
        <input v-model="billingProfile.address" id="address" type="text" class="input-field w-full" name="address">
        <div v-if="isInvalid('address')" class="error-message mt-1">{{ errorMessage('address') }}</div>
      </div>
      <!-- END: Address 1 -->

      <!-- BEGIN: City -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('city')}">
        <label for="city" class="form-label text-sm text-gray-600">City</label>
        <input v-model="billingProfile.city" id="city" type="text" class="input-field w-full" name="city">
        <div v-if="isInvalid('city')" class="error-message mt-1">{{ errorMessage('city') }}</div>
      </div>
      <!-- END: City -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: State Id -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('state_id')}">
        <label for="state_id" class="form-label text-sm text-gray-600">State</label>
        <select v-model="billingProfile.state_id" data-placeholder="Select state" class="input-field w-full" id="state_id" name="state_id">
          <option v-for="(state, i) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
        </select>
        <div v-if="isInvalid('state_id')" class="error-message mt-1">{{ errorMessage('state_id') }}</div>
      </div>
      <!-- END: State Id -->

      <!-- BEGIN: Zip -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('zip')}">
        <label for="zip" class="form-label text-sm text-gray-600">Zip</label>
        <input v-maska="'######'" v-model="billingProfile.zip" id="zip" type="text" name="zip" class="input-field w-full">
        <div v-if="isInvalid('zip')" class="error-message mt-1">{{ errorMessage('zip') }}</div>
      </div>
      <!-- END: Zip -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Card Number -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('card_number')}">
        <div class="form-label text-gray-600 opacity-0">&nbsp;</div>
        <label for="card_number" class="form-label text-sm text-gray-600">Card Number</label>
        <input v-maska="`#### #### #### ####`" v-model="billingProfile.card_number" id="card_number" type="text" name="card_number" class="input-field w-full">
        <div v-if="isInvalid('card_number')" class="error-message mt-1">{{ errorMessage('card_number') }}</div>
      </div>
      <!-- END: Card Number -->

      <!-- BEGIN: Exp Month / Year -->
      <div class="col-span-12 lg:col-span-6 input-form">
        <label for="card_exp_month" class="form-label text-sm text-gray-600">Exp Month / Year</label>
        <div class="sm:grid grid-cols-2 gap-2" :class="{'has-error': isInvalid('card_exp_month')}">
          <div class="col-span-1 w-full">
            <div class="input-group">
              <div id="input-group-3" class="input-group-text input-group-sm">Month</div>
              <select v-model="billingProfile.card_exp_month" data-placeholder="Select month" class="input-field w-full" id="card_exp_month"
                      name="card_exp_month">
                <option value="">—</option>
                <option v-for="(month, i) in months" :key="i" :value="month">{{ month }}</option>
              </select>
            </div>
            <div v-if="isInvalid('card_exp_month')" class="error-message mt-1">{{ errorMessage('card_exp_month') }}</div>
          </div>

          <div class="col-span-1 w-full mt-2 sm:mt-0">
            <div class="input-group sm:mt-0" :class="{'has-error': isInvalid('card_exp_year')}">
              <div id="input-group-4" class="input-group-text input-group-sm">Year</div>
              <select v-model="billingProfile.card_exp_year" class="input-field w-full" id="card_exp_year"
                      name="card_exp_year">
                <option value="">—</option>
                <option v-for="(year, i) in years" :key="i" :value="year">{{ year }}</option>
              </select>
            </div>
            <div v-if="isInvalid('card_exp_year')" class="error-message mt-1">{{ errorMessage('card_exp_year') }}</div>
          </div>
        </div>
      </div>
      <!-- END: Exp Month / Year -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Card Cvv -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('card_cvv')}">
        <label for="card_cvv" class="form-label text-sm text-gray-600">Card Cvv</label>
        <input v-maska="`###`" v-model="billingProfile.card_cvv" id="card_cvv" type="text" name="card_cvv" class="input-field w-full">
        <div v-if="isInvalid('card_cvv')" class="error-message mt-1">{{ errorMessage('card_cvv') }}</div>
      </div>
      <!-- END: Card Cvv -->

      <!-- BEGIN: Email -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('email')}">
        <label for="email" class="form-label text-sm text-gray-600">Email</label>
        <input v-model="billingProfile.email" id="email" type="email" name="email" class="input-field w-full">
        <div v-if="isInvalid('email')" class="error-message mt-1">{{ errorMessage('email') }}</div>
      </div>
      <!-- END: Email -->
    </div>

    <div class="text-right mt-5">
      <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <span v-else>Submit</span>
      </button>
    </div>
  </form>
</template>

<script>
import serverValidationErrorsMixin from "./mixins/serverValidationErrorsMixin";
import helpers from "../helper";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";

export default {
  name: "AppBillingProfileCreateForm.vue",

  mixins: [serverValidationErrorsMixin],

  components: {
    LoadingSpinner,
  },

  props: {
    agencyId: {
      type: Number,
      required: true
    },
    states: {
      type: Array,
      default: () => [],
    },
    months: {
      type: Array,
      default: () => []
    },
    years: {
      type: Array,
      default: () => []
    },
  },

  emits: ['billing-profile-added'],

  data() {
    return {
      billingProfile: {
        first_name: null,
        last_name: null,
        title: null,
        address: null,
        city: null,
        state_id: null,
        zip: null,
        card_number: null,
        card_exp_month: null,
        card_exp_year: null,
        card_cvv: null,
        email: null,
      },
      loading: false,
    }
  },

  methods: {
    submitBillingProfile(e) {
      if (this.loading) return;
      this.loading = true;

      setTimeout(() => {
        axios
          .post(`/agencies/${this.agencyId}/billing-profiles`, this.billingProfile)
          .then((response) => {
            this.$emit('billing-profile-added', response.data);
            this.billingProfile = {
              first_name: null,
              last_name: null,
              title: null,
              address: null,
              city: null,
              state_id: null,
              zip: null,
              card_number: null,
              card_exp_month: null,
              card_exp_year: null,
              card_cvv: null,
              email: null,
            };
            this.hideModal();
          })
          .catch((error) => {
            console.log(error, 'AppAgentCreateForm - submit()')
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }, 1000)
    },

    handleSuccessfulSubmission(response) {
      setTimeout(() => {
        if (response.data && response.data.redirect_url) {
          window.location = response.data.redirect_url;
        } else {
          window.location = '/agents';
        }
      }, 200);
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        });
      }
      console.log("AppLeadCreateForm - handleErroredSubmission", error.response);
    },

    hideModal() {
      this.$emit('hide-modal');
    },
  },
}
</script>

<style scoped>

</style>