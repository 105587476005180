import helpers from "@/helper";
import {nextTick, ref} from "vue";

export function useErrorHandlerAxios() {
  const errors = ref({})

  function handleErrors(error) {
    if (error.response && error.response.data && error.response.status === 422) {
      errors ? errors.value = error.response.data.errors : null;

      return nextTick(() => {
        helpers.scrollToClass('has-error');
      })
    } else if (error.response && error.response.data && error.response.status === 403) {
      $notify.error({
        title: 'Denied',
        message: error.response?.data?.message ?? 'You are not allowed to do this.',
        showClose: true,
      })
    } else {
      $notify.error({
        title: 'Error',
        message: error.response?.data?.error_message ?? 'Something went wrong.',
        showClose: true,
      })
    }
  }

  function isInvalid(attribute) {
    return errors.value.hasOwnProperty(attribute);
  }

  function errorMessage(attribute) {
    if (errors.value.hasOwnProperty(attribute)) {
      return errors.value[attribute][0];
    }
  }

  function clearErrors() {
    errors.value = {};
  }

  return { handleErrors, isInvalid, errorMessage, clearErrors, errors };
}