<template>
  <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
      <thead class="bg-slate-200">
      <tr>
          <th scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Name
          </th>
          <th scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Balance
          </th>
          <th scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Direct Upline
          </th>
          <th scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Active
          </th>
          <th scope="col"
              class="relative py-3.5 pl-3 pr-4 sm:pr-6 text-sm text-right font-semibold text-gray-900">
              Options
          </th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <app-agents-table-list-item
        v-for="(agent, index) in agents"
        :key="`agent_id_${agent.id}`"
        :index="index"
        :agent="agent"
        @agent-status-updated="handleAgentStatusUpdated"
        @show-balance-modal="showBalanceDetails"
        ></app-agents-table-list-item>
      </tbody>
  </table>

    <Modal :show="showBalanceModal" :title="`${selectedAgent?.name} Balance`" @onClose="closeBalanceDetails">
        <p v-for="(balance, i) in selectedAgent?.balance" :key="balance" :class="{'font-bold': i === 'Total'}">
            {{ i }}: {{ balance }}
        </p>
    </Modal>
</template>

<script>
import AppAgentsTableListItem from "./AppAgentsTableListItem.vue";
import Modal from "@/Components/Modal.vue";

export default {
  name: "AppAgentsTable",

  components: {
    Modal,
    AppAgentsTableListItem,
  },

  emits: ['applyFilters'],

  props: {
    agents: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: true,
      showBalanceModal: false,
      selectedAgent: null,
    }
  },

  mounted() {
    this.loading = false;
  },

  methods: {
    handleAgentStatusUpdated(payload) {
      return this.$emit('applyFilters');
    },
    showBalanceDetails(payload) {
        this.selectedAgent = payload.agent;
        this.showBalanceModal = true;
    },
    closeBalanceDetails() {
        this.showBalanceModal = false;
        setTimeout(() => {
            this.selectedAgent = null;
        }, 300);
    }
  },
}
</script>

<style scoped>

</style>
