<template>
  <!-- BEGIN: Dropzone -->
  <div class="dropzone rounded-lg border-slate-200" data-custom-implementation="true" id="lead-view-files-dropzone">
    <div class="fallback">
      <input name="file" type="file" multiple/>
    </div>
    <div class="dz-message">
      <div class="text-lg font-medium">Drop files here or click to upload.</div>
      <div class="text-slate-500">
        Files will be automatically uploaded. You can add multiple files.
      </div>
    </div>
  </div>
  <!-- END: Dropzone -->

  <!-- BEGIN: Files -->
  <div class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-8">
    <div v-if="files.length" v-for="file in files" class="col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
      <div class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-gray-100">
        <div class="absolute left-0 top-0 mt-3 ml-3">
          <input v-if="false" class="form-check-input border border-slate-500" type="checkbox" :checked="false">
        </div>

        <a v-if="['image/jpeg', 'image/png', 'image/gif', 'image/jpg'].includes(file.mime_type)" :href="file.full_path" target="_blank"
           class="w-3/5 file__icon file__icon--image mx-auto">
          <div class="file__icon--image__preview image-fit">
            <img :src="file.full_path" alt="">
          </div>
        </a>

        <a v-else class="w-3/5 file__icon file__icon--file mx-auto" :href="file.full_path" target="_blank">
          <div class="file__icon__file-name">{{ file.extension }}</div>
        </a>

        <a class="block break-words text-sm font-medium mt-4 text-center" :href="file.full_path" target="_blank">{{ file.name }}</a>
        <div class="text-slate-500 break-words text-xs text-center mt-0.5">{{ file.size }}</div>
        <Menu as="div" class="absolute top-0 right-0">
          <div>
            <MenuButton class="bg-gray-100 mr-2 mt-3 ml-auto rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-tb-blue">
              <span class="sr-only">Open options</span>
              <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a :href="`/leads/${leadId}/files/${file.id}/download`" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Download</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a @click.prevent="openEditFileNameModal(file)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Rename</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a href="#" @click.prevent="deleteFile(file)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Delete</a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>

    <div v-else class="text-sm font-medium col-span-12 text-center">No files</div>

  </div>
  <!-- END: Files -->

  <!-- BEGIN: Pagination -->
  <app-paginator :paginationData="paginationDataSet" :maxPages="5" @changePage="fetchFiles"
                 @updatePerPage="changePaginationLength"></app-paginator>
  <!-- END: Pagination -->

  <!-- BEGIN: Edit File Name Modal -->
<TransitionRoot as="template" :show="showEditFileNameModal">
  <Dialog as="div" class="relative z-10" @close="showEditFileNameModal = false">
    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                     leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
    </TransitionChild>

    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300"
                         enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                         enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                         leave-from="opacity-100 translate-y-0 sm:scale-100"
                         leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <DialogPanel
            class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div class="mt-3 sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">Edit File Name</DialogTitle>
                <div>
                  <form @submit.prevent="submitEditFileName" class="intro-y box p-5">
                    <!-- BEGIN: File name  -->
                    <div class="col-span-12 input-form mt-4" :class="{'has-error': isInvalid('editedFileName')}">
                      <label for="editedFileName" class="form-label text-slate-600">Edited File Name</label>
                      <input type="text" v-model="editedFileName" name="editedFileName" id="editedFileName" class="input-field ">
                      <div v-if="isInvalid('editedFileName')" class="error-message mt-1">{{ errorMessage('editedFileName') }}</div>
                    </div>
                    <!-- END: File name  -->

                    <div class="text-right mt-5">
                      <button type="button" class="btn btn-default w-24" @click="showEditFileNameModal = false">
                        Cancel
                      </button>
                      <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
                        <LoadingSpinner v-if="loading"></LoadingSpinner>
                        <span v-else>Save</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </div>
  </Dialog>
</TransitionRoot>
<!-- END: Edit File Name Modal -->

</template>

<script>
import Dropzone from "dropzone";
import AppPaginator from "../AppPaginator.vue";
import { DotsVerticalIcon } from '@heroicons/vue/solid'
import LoadingSpinner from "../LoadingSpinner.vue";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild, TransitionRoot
} from "@headlessui/vue";

export default {
  name: "LeadViewFiles",
  mixins: [serverValidationErrorsMixin],

  components: {
    AppPaginator,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
    LoadingSpinner,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LoadingSpinner,
  },

  props: {
    leadId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      files: [],
      paginationDataSet: [],
      showEditFileNameModal: false, // Added: To control visibility of the modal
      editedFileName: "",
      dropzoneOptions: {
        maxFiles: 30,
        maxFilesize: 100, // mb
        parallelUploads: 2, // 1 was set
        paramName: "file",
        addRemoveLinks: true,
        uploadMultiple: false,
        autoProcessQueue: true,
        createImageThumbnails: false,
        url: `/leads/${this.leadId}/files`,
        headers: {
          "X-CSRF-TOKEN": this.$page.props.csrf_token,
        },
        accept: function(file, done) {
          const allowedMimeTypes = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
            "application/vnd.ms-excel",
            "application/vnd.ms-excel.sheet.macroEnabled.12",
            "text/csv",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "image/jpeg",
            "image/png",
            "image/bmp",
            "application/pdf",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          ];

          if (!allowedMimeTypes.includes(file.type)) {
            done("Error! Files of this type are not accepted");
          }
          else { done(); }
        }
      },
      currentPage: 1,
      paginationLength: 12
    }
  },

  mounted() {
    this.initializeComponent()
  },

  methods: {
    initializeComponent() {
      this.fetchFiles();
      this.initDropzone();
    },

    openEditFileNameModal(file) {
    // Set the selected file for editing
    this.selectedFile = file;
    // Optionally, set the edited file name to the current file name
    this.editedFileName = file.name;
    // Show the modal
    this.showEditFileNameModal = true;
  },

  submitEditFileName() {
    const editedFileName = this.editedFileName;

    // Ensure the edited file name is not empty
    if (!editedFileName.trim()) {
      return;
    }
    // Update the file name on the server
    axios
    .put(`/leads/${this.leadId}/files/${this.selectedFile.id}`, { name: editedFileName })
    .then(() => {
      this.fetchFiles();
      this.showEditFileNameModal = false;
      this.editedFileName = "";
    })
    .catch((error) => {
      console.log('LeadViewFiles - submitEditFileName() error', error);
    });
  },
    

    fetchFiles(page = null) {
      this.currentPage = page ? page : 1;

      // LeadFilesController@index
      axios
        .get(`/leads/${this.leadId}/files?page=${this.currentPage}&per_page=${this.paginationLength}`)
        .then(this.refresh)
        .catch((error) => {
          console.log('LeadViewFiles - fetchFiles() error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    initDropzone() {
      // The constructor of Dropzone accepts two arguments:
      // 1. The selector for the HTML element that you want to add Dropzone to
      // 2. An (optional) object with the configuration
      Dropzone.autoDiscover = false;

      const dropzone = new Dropzone("#lead-view-files-dropzone", this.dropzoneOptions);
      const vm = this;

      dropzone.on("queuecomplete", () => {
        vm.fetchFiles();
      });

      dropzone.on("error", (error) => {
        console.log(error);
      });
    },

    changePaginationLength(payload) {
      this.paginationLength = payload;
      this.fetchFiles();
    },

    handleDropzoneSuccess(file, response) {
      this.files.unshift(response.file);
    },

    deleteFile(file) {
      // LeadFilesController@destroy
      axios
        .delete(`/leads/${this.leadId}/files/${file.id}`)
        .then((response) => {
          this.fetchFiles();
        })
        .catch((error) => {
          console.log('LeadViewFiles - deleteFile() error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    refresh({data}) {
      this.paginationDataSet = data;
      this.files = data.data;
    },
  },
}
</script>

<style>
.dropzone {
  min-height: 200px;
}

.dropzone .dz-message {
  margin: 4em 0;
}

.pagination{
  margin-right: auto;
  align-items: center;
  display: flex;
}
.pagination .page-item.active .page-link {
  box-shadow: 0px 3px 20px #0000000b;
  position: relative;
  border-radius: 0.375rem;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.pagination .page-item.active .page-link{
  font-weight: 500;
}

.pagination .page-item .page-link {
  min-width: 40px;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-weight: 500;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}
.pagination .page-item .page-link:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(var(--color-primary) / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.2;
}

.pagination .page-item .page-link:hover:not(:disabled){
  --tw-border-opacity: 0.9;
  --tw-bg-opacity: 0.9;
}
.pagination .page-item .page-link:not(button){
  text-align: center;
}
.pagination .page-item .page-link:disabled{
  cursor: not-allowed;
  opacity: 0.7;
}
.pagination .page-item .page-link{
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: transparent;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(var(--color-slate-800) / var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.pagination .page-item .page-link.text-primary {
  color: #899CC1;
}
</style>
