<script setup>
import AppQuotesheetTableListItem from "@/Components/AppQuotesheetTableListItem.vue";
import {PlusCircleIcon} from "@heroicons/vue/outline";
import {Inertia} from "@inertiajs/inertia";
import {computed, ref} from "vue";

const props = defineProps({
  quotesheets: {
    type: Object,
    default: null,
  },
  lead_id: {
    type: Number,
    required: true
  }
});

const tabs = [
    {key: 'app-quotesheets',    label: 'Quotesheets'},
    {key: 'legacy-quotesheets', label: 'Legacy Quotesheets'},
];
const currentTab = ref('app-quotesheets');

const creating = ref(false);
function createQuotesheet() {
  if (creating.value) return;
  creating.value = true;
  Inertia.get(route('lead.quotesheets.create', props.lead_id), {}, {
    onFinish: () => {
      creating.value = false;
    }
  });
}

function setCurrentTab(tab) {
    currentTab.value = tab;
}

const appQuotesheets = computed(() => {
    return props.quotesheets.data.filter(i => !i.legacy_id);
});
const legacyQuotesheets = computed(() => {
    return props.quotesheets.data.filter(i => i.legacy_id);
});
</script>

<template>

    <!-- Tabs -->
    <div v-if="legacyQuotesheets.length > 0" class="mb-5">
      <div class="-mx-2">
        <div class="lg:hidden w-full">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select @change="setCurrentTab($event.target.value)" id="tabs" name="tabs"
                    class="block w-full rounded-md border-gray-300 focus:border-tb-blue focus:ring-tb-blue">
                <option v-for="tab in tabs" :key="tab.key" :value="tab.key" :selected="currentTab === tab.key">{{
                        tab.label
                    }}
                </option>
            </select>
        </div>
        <div class="hidden lg:block w-full">
            <nav class="flex space-x-1 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg p-2 bg-white" aria-label="Tabs">
                <a @click.prevent="setCurrentTab(tab.key)" v-for="tab in tabs" :key="tab.key" href="javascript:;"
                   :class="[currentTab === tab.key ? 'bg-tb-blue text-white shadow' : 'text-gray-500 hover:text-gray-700', 'px-6 py-2 font-semibold rounded-md']"
                   :aria-current="currentTab === tab.key ? 'page' : undefined">{{ tab.label }}</a>
            </nav>
        </div>
      </div>
    </div>

  <div class="flex flex-col">
    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6">
        <div v-show="currentTab === 'app-quotesheets'" class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <div class="md:flex items-center justify-between px-5 py-2 bg-white">
            <h1 class="text-xl font-semibold bg-white text-gray-900">
              Quotesheets
            </h1>
            <button @click.prevent="createQuotesheet" type="button" class="w-full md:w-auto btn-primary inline-flex items-center px-5 py-2 text-sm">
              <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              <span>Create Quotesheet</span>
            </button>
          </div>
          <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
            <thead class="bg-slate-200">
            <tr>
              <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Date</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Prepared For</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Application</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Options</th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
            <app-quotesheet-table-list-item v-for="(quotesheet, i) in appQuotesheets" :key="`quotesheet_${quotesheet.id}`" :quotesheet="quotesheet" :lead_id="props.lead_id"></app-quotesheet-table-list-item>
            </tbody>
          </table>
        </div>

        <div v-if="currentTab === 'legacy-quotesheets'" class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <div class="md:flex items-center justify-between px-5 py-2 bg-white">
                  <h1 class="text-xl font-semibold bg-white text-gray-900">
                      Legacy Quotesheets
                      <span class="mt-2 text-sm text-gray-600">(A table of legacy quotesheets prepared for this lead.)</span>
                  </h1>
          </div>
          <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
                  <thead class="bg-slate-200">
                  <tr>
                      <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Date</th>
                      <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Prepared For</th>
                      <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Link</th>
                  </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                  <app-quotesheet-table-list-item v-for="(quotesheet, i) in legacyQuotesheets" :key="`quotesheet_${quotesheet.id}`" :quotesheet="quotesheet" :lead_id="props.lead_id"
                                                  hide-application hide-options :legacy-id="quotesheet.legacy_id"></app-quotesheet-table-list-item>
                  </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
