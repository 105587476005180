<template>
  <tr v-if="editMode">
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" :class="{'has-error': isInvalid('name')}">
      {{ tag.id }}
      <div v-if="isInvalid('name')" class="error-message mt-1">&nbsp;</div>
    </td>
    <td class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6" :class="{'has-error': isInvalid('name')}">
      <input type="text" class="input-field   whitespace-nowrap" v-model="formData.name">
      <div v-if="isInvalid('name')" class="error-message mt-1">{{ errorMessage('name') }}</div>
    </td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
      <button class="btn-primary mr-2" @click="updateTag">Save</button>
      <button class="btn-secondary text-sm" @click="cancel">Cancel</button>
      <div v-if="isInvalid('name')" class="error-message mt-1">&nbsp;</div>
    </td>
  </tr>
  <tr v-else>
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ tag.id }}</td>
    <td class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">{{ tag.name }}</td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
      <button class="btn-secondary text-sm mr-2" @click="editMode = true">Edit</button>
      <button class="btn-danger text-sm" @click="confirmDelete">Delete</button>
    </td>
  </tr>


</template>

<script>
import _ from "lodash";
import helpers from "../../helper";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";

export default {
  name: "AgencyLeadTagListItem",

  mixins: [serverValidationErrorsMixin],

  props: {
    agencyId: {
      type: Number,
      required: true,
    },
    tag: {
      type: Object,
      required: true,
    }
  },

  emits: ['tag-updated', 'tag-deleted'],

  data() {
    return {
      formData: _.cloneDeep(this.tag),
      editMode: false,
    }
  },

  methods: {
    updateTag() {
      this.loading = false;
      // AgencyLeadTagController@update
      axios
        .put(`/agencies/${this.agencyId}/lead-tags/${this.tag.id}`, {
          name: this.formData.name,
        })
        .then((response) => {
          this.$emit('tag-updated', { tag: response.data });
          this.formData = response.data;
          this.editMode = false;
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.status === 422) {
            this.errors = error.response.data.errors;
            this.$nextTick(() => {
              helpers.scrollToClass('has-error');
            })
          } else {
            Swal.fire({
              text: 'Unknown error occurred, please try again.',
              icon: 'error'
            })
          }
          console.log("AgencyLeadTagListItem - handleErroredSubmission", error);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    confirmDelete() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to undo this.',
        icon: 'warning',
        confirmButtonText: "Yes, I'm sure.",
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTag();
        }
      })
    },

    deleteTag() {
      this.loading = true;
      // AgencyLeadTagController@destroy
      axios
        .delete(`/agencies/${this.agencyId}/lead-tags/${this.formData.id}`)
        .then((response) => {
          this.$emit('tag-deleted');
        })
        .catch((error) => {
          Swal.fire({
            text: 'Unknown error occurred, please try again.',
            icon: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    cancel() {
      this.editMode = false;
    },
  },
}
</script>

<style scoped>

</style>