export default {
  methods: {
    formatPhone(input) {
      if (!input) return input;
      let parts = /^(?:\+?(1))?([0-9]{3})([0-9]{3})([0-9]{4})$/.exec(input);
      if (!parts || !parts.length) return input;
      let formatted = `(${parts[2]}) ${parts[3]}-${parts[4]}`;
      // Attach country code if defined
      if (parts[1]) {
        formatted = "+1 " + formatted;
      }
      return formatted;
    }
  }
}