<template>
  <div class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4 border-b border-slate-100 pb-4">
    <!-- BEGIN: Agency Name -->
    <div @click="$emit('show-edit-mode', {fieldId: 'name'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">Agency Name</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.name }}</p>
    </div>
    <!-- END: Agency Name -->

    <!-- BEGIN: Designated Support Person -->
    <div @click="$emit('show-edit-mode', {fieldId: 'suppoer_person_name'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">Designated Support Person</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.support_person_name ?? '&mdash;' }}</p>
    </div>
    <!-- END: Designated Support Person -->
  </div>

  <div @click="$emit('show-edit-mode', {fieldId: 'default_schedule_name'})" class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4 border-b border-slate-100 pb-4 cursor-pointer">
    <!-- BEGIN: Default Action Map -->
    <div class="col-span-12 xl:col-span-6 flex justify-between items-center">
      <label class=" text-slate-600">Default Action Map</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.default_schedule_name ?? '&mdash;' }}</p>
    </div>
    <!-- END: Default Action Map -->

    <!-- BEGIN: Grace Pull Enabled -->
    <div class="col-span-12 xl:col-span-6 flex justify-between items-center">
      <label class=" text-slate-600">Grace Feature (Master Control)</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.grace_pulls_enabled ? 'Active' : 'Inactive' }}</p>
    </div>
    <!-- END: Grace Pull Enabled -->
  </div>

  <div @click="$emit('show-edit-mode', {fieldId: 'missed_appt_hours'})" class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4 border-b border-slate-100 pb-4 cursor-pointer">
    <!-- BEGIN: Missed Appointment - Change Status after -->
    <div class="col-span-12 xl:col-span-6 flex justify-between items-center">
      <label class=" text-slate-600">Missed Appointment - Change Status after</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.missed_appt_hours ?? 'Do Not Automatically Change' }}</p>
    </div>
    <!-- END: Missed Appointment - Change Status after -->

    <!-- BEGIN: Missed Appointment - Move To Bucket -->
    <div @click="$emit('show-edit-mode', {fieldId: 'missed_appt_bucket_name'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">Missed Appointment - Move To Bucket</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.missed_appt_bucket_name ?? 'Do Not Move' }}</p>
    </div>
    <!-- END: Missed Appointment - Move To Bucket -->
  </div>

  <div class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4 border-b border-slate-100 pb-4">
    <!-- BEGIN: Lead History -->
    <div @click="$emit('show-edit-mode', {fieldId: 'only_agent_history'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label for="first_name" class=" text-slate-600">Lead History</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.only_agent_history ? 'Show All History to the Owning Agent' : 'Only Show the agent their history for the lead' }}</p>
    </div>
    <!-- END: Lead History -->

    <!-- BEGIN: Require Notes On Dead Status -->
    <div @click="$emit('show-edit-mode', {fieldId: 'require_notes_on_dead'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label for="last_name" class=" text-slate-600">Require Notes On Dead Status</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.require_notes_on_dead ? 'Yes, require the agent to enter notes' : 'No' }}</p>
    </div>
    <!-- END: Require Notes On Dead Status -->
  </div>

  <div class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4 border-b border-slate-100 pb-4">
    <!-- BEGIN: Birthday Email Subject -->
    <div @click="$emit('show-edit-mode', {fieldId: 'birthday_email_subject'})" class="col-span-12 xl:col-span-6 cursor-pointer">
      <label class=" text-slate-600">Birthday Email Subject</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.birthday_email_subject ?? '&mdash;' }}</p>
    </div>
    <!-- END: Birthday Email Subject -->

    <!-- BEGIN: Grace Pulls On Weekends -->
    <div @click="$emit('show-edit-mode', {fieldId: 'grace_pulls_on_weekends'})" class="col-span-12 xl:col-span-6 cursor-pointer">
      <label class=" text-slate-600">Grace Feature - Weekends</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.grace_pulls_on_weekends ? 'Pull leads that are past due on the weekends' : 'Pull leads that are past due on the weekends' }}</p>
    </div>
    <!-- END: Grace Pulls On Weekends -->

    <!-- BEGIN: Birthday Email Body -->
    <div @click="$emit('show-edit-mode', {fieldId: 'birthday_email_body'})" class="col-span-12 xl:col-span-6 cursor-pointer">
      <label class=" text-slate-600">Birthday Email Body</label>
      <p class=" text-slate-700 text-right" v-html="agency_info.birthday_email_body"></p>
    </div>
    <!-- END: Birthday Email Body -->
  </div>

  <div class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4 border-b border-slate-100 pb-4">
    <!-- BEGIN: Quotesheet Subject -->
    <div @click="$emit('show-edit-mode', {fieldId: 'quotesheet_subject'})" class="col-span-12 xl:col-span-6 cursor-pointer">
      <label class=" text-slate-600">Quotesheet Subject</label>
      <p class=" font-medium text-slate-700">{{ agency_info.quotesheet_subject ?? '&mdash;' }}</p>
    </div>
    <!-- END: Quotesheet Subject -->

    <!-- BEGIN: Quotesheet Body -->
    <div @click="$emit('show-edit-mode', {fieldId: 'quotesheet_body'})" class="col-span-12 xl:col-span-6 cursor-pointer">
      <label class=" text-slate-600">Quotesheet Body</label>
      <p class=" text-slate-700" v-html="agency_info.quotesheet_body"></p>
    </div>
    <!-- END: Quotesheet Body -->
  </div>

  <div class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4 border-b border-slate-100 pb-4">
    <!-- BEGIN: Appointment Subject -->
    <div @click="$emit('show-edit-mode', {fieldId: 'appointment_subject'})" class="col-span-12 xl:col-span-6 cursor-pointer">
      <label class=" text-slate-600">Appointment Subject</label>
      <p class=" font-medium text-slate-700">{{ agency_info.appointment_subject ?? '&mdash;' }}</p>
    </div>
    <!-- END: Appointment Subject -->

    <!-- BEGIN: Appointment Body -->
    <div @click="$emit('show-edit-mode', {fieldId: 'appointment_body'})" class="col-span-12 xl:col-span-6 cursor-pointer">
      <label class=" text-slate-600">Appointment Body</label>
      <p class=" text-slate-700" v-html="agency_info.appointment_body"></p>
    </div>
    <!-- END: Appointment Body -->
  </div>

  <div class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4 border-b border-slate-100 pb-4">
    <!-- BEGIN: Agency Currency Setting -->
    <div @click="$emit('show-edit-mode', {fieldId: 'currency_label'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">Agency Currency Setting</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.currency_label ?? '—' }}</p>
    </div>
    <!-- END: Agency Currency Setting -->

    <!-- BEGIN: Allow agents to create questionnaires -->
    <div @click="$emit('show-edit-mode', {fieldId: 'allow_agent_questionnaire'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">Allow agents to create questionnaires</label>
      <p class=" font-medium text-slate-700 text-right">{{ agency_info.allow_agent_questionnaire ? 'Yes' : 'No' }}</p>
    </div>
    <!-- END: Allow agents to create questionnaires -->
  </div>

  <div class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4">
    <!-- BEGIN: Quotesheet Colors #1 -->
    <div @click="$emit('show-edit-mode', {fieldId: 'color_1'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">Quotesheet Colors #1</label>
      <p class=" font-medium text-slate-700 text-right flex items-center">
        <span :class="agency_info.color_1 ? 'mr-1' : ''">{{ agency_info.color_1 ?? '—' }}</span>
        <span v-if="agency_info.color_1" class="h-4 w-4 rounded" :style="`background-color: ${agency_info.color_1};`"></span>
      </p>
    </div>
    <!-- END: Quotesheet Colors #1 -->

    <!-- BEGIN: Quotesheet Colors #2 -->
    <div @click="$emit('show-edit-mode', {fieldId: 'color_2'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">Quotesheet Colors #2</label>
      <p class=" font-medium text-slate-700 text-right flex items-center">
        <span :class="agency_info.color_2 ? 'mr-1' : ''">{{ agency_info.color_2 ?? '—' }}</span>
        <span v-if="agency_info.color_2" class="h-4 w-4 rounded" :style="`background-color: ${agency_info.color_2};`"></span>
      </p>
    </div>
    <!-- END: Quotesheet Colors #2 -->
  </div>
  <div @click="$emit('show-edit-mode', {fieldId: 'color_3'})" class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4">
    <!-- BEGIN: Quotesheet Colors #3 -->
    <div class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">Quotesheet Colors #3</label>
      <p class=" font-medium text-slate-700 text-right flex items-center">
        <span :class="agency_info.color_3 ? 'mr-1' : ''">{{ agency_info.color_3 ?? '—' }}</span>
        <span v-if="agency_info.color_3" class="h-4 w-4 rounded" :style="`background-color: ${agency_info.color_3};`"></span>
      </p>
    </div>
    <!-- END: Quotesheet Colors #3 -->

    <!-- BEGIN: Quotesheet Colors #4 -->
    <div @click="$emit('show-edit-mode', {fieldId: 'color_4'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">Quotesheet Colors #4</label>
      <p class=" font-medium text-slate-700 text-right flex items-center">
        <span :class="agency_info.color_4 ? 'mr-1' : ''">{{ agency_info.color_4 ?? '—' }}</span>
        <span v-if="agency_info.color_4" class="h-4 w-4 rounded" :style="`background-color: ${agency_info.color_4};`"></span>
      </p>
    </div>
    <!-- END: Quotesheet Colors #4 -->
  </div>

  <div @click="$emit('show-edit-mode', {fieldId: 'ui_color_1'})" class="grid grid-cols-12 gap-3 md:gap-x-8 xl:gap-x-16 mt-4">
    <!-- BEGIN: UI Color #1 -->
    <div class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">UI Color #1</label>
      <p class=" font-medium text-slate-700 text-right flex items-center">
        <span :class="agency_info.ui_color_1 ? 'mr-1' : ''">{{ agency_info.ui_color_1 ?? '—' }}</span>
        <span v-if="agency_info.ui_color_1" class="h-4 w-4 rounded" :style="`background-color: ${agency_info.ui_color_1};`"></span>
      </p>
    </div>
    <!-- END: UI Color #1 -->

    <!-- BEGIN: UI Color #2 -->
    <div @click="$emit('show-edit-mode', {fieldId: 'color_4'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">UI Color #2</label>
      <p class=" font-medium text-slate-700 text-right flex items-center">
        <span :class="agency_info.ui_color_2 ? 'mr-1' : ''">{{ agency_info.ui_color_2 ?? '—' }}</span>
        <span v-if="agency_info.ui_color_2" class="h-4 w-4 rounded" :style="`background-color: ${agency_info.ui_color_2};`"></span>
      </p>
    </div>
    <!-- END: UI Color #2 -->

    <!-- BEGIN: UI Login Background Color -->
    <div @click="$emit('show-edit-mode', {fieldId: 'ui_login_background_color'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">UI Login Background Color</label>
      <p class=" font-medium text-slate-700 text-right flex items-center">
        <span :class="agency_info.ui_login_background_color ? 'mr-1' : ''">{{ agency_info.ui_login_background_color ?? '—' }}</span>
        <span v-if="agency_info.ui_login_background_color" class="h-4 w-4 rounded" :style="`background-color: ${agency_info.ui_login_background_color};`"></span>
      </p>
    </div>
    <!-- END: UI Login Background Color -->

    <!-- BEGIN: UI Color #2 -->
    <div @click="$emit('show-edit-mode', {fieldId: 'ui_topbar_background_color'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">UI Topbar Background Color</label>
      <p class=" font-medium text-slate-700 text-right flex items-center">
        <span :class="agency_info.ui_topbar_background_color ? 'mr-1' : ''">{{ agency_info.ui_topbar_background_color ?? '—' }}</span>
        <span v-if="agency_info.ui_topbar_background_color" class="h-4 w-4 rounded" :style="`background-color: ${agency_info.ui_topbar_background_color};`"></span>
      </p>
    </div>
    <!-- END: UI Color #2 -->

    <!-- BEGIN: UI Color #2 -->
    <div @click="$emit('show-edit-mode', {fieldId: 'ui_topbar_foreground_color'})" class="col-span-12 xl:col-span-6 flex justify-between items-center cursor-pointer">
      <label class=" text-slate-600">UI Topbar Text Color</label>
      <p class=" font-medium text-slate-700 text-right flex items-center">
        <span :class="agency_info.ui_topbar_foreground_color ? 'mr-1' : ''">{{ agency_info.ui_topbar_foreground_color ?? '—' }}</span>
        <span v-if="agency_info.ui_topbar_foreground_color" class="h-4 w-4 rounded" :style="`background-color: ${agency_info.ui_topbar_foreground_color};`"></span>
      </p>
    </div>
    <!-- END: UI Color #2 -->
  </div>
</template>

<script>
export default {
  name: "AgencyInfoReadOnly",

  props: {
    agency_info: {
      type: Object,
      required: true
    },
  },

  emits: ['show-edit-mode'],

}
</script>

<style scoped>

</style>
