<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">

      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button @click="showCreateModal = true" type="button" class="btn-primary inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm sm:w-auto">Add Bucket Access</button>
      </div>
    </div> -->

    <h3 class="font-medium">Buckets that Agent has access</h3>
    <div class="mt-2 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Bucket name</th>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Daily limit</th>
                <!-- <th class="py-3.5 pl-4 text-left text-sm font-semibold text-right text-gray-900 sm:pr-6">
                  Actions
                  <span class="sr-only">Actions</span>
                </th> -->
              </tr>
              </thead>
              <tbody v-if="agent_bucket_access.length" class="divide-y divide-gray-200 bg-white">
                <agent-bucket-list-item
                  v-for="(access) in agent_bucket_access"
                  :access="access"
                  :key="access.id"
                  :agent-id="agentId"
                  :limits="possible_daily_limits"
                  @bucket-limit-updated="syncData"
                  @bucket-access-deleted="fetchBucketsData"
                ></agent-bucket-list-item>
              </tbody>
              <tbody v-else class="divide-y divide-gray-200 bg-white">
                <tr>
                  <td colspan="6" class="whitespace-nowrap px-3 py-4 text-sm text-gray-600 text-center">
                    Agent has no access to any buckets. Give access from below table.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else class="w-full text-center my-4">
      Agent has no access to any buckets.
    </div> -->
    
    <h3 class="font-medium mt-8">Buckets that Agent has no access</h3>
    <div class="mt-2 flex flex-col mb-8">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Bucket name</th>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Daily limit</th>
                <!-- <th class="py-3.5 pl-4 text-left text-sm font-semibold text-right text-gray-900 sm:pr-6">
                  Actions
                  <span class="sr-only">Actions</span>
                </th> -->
              </tr>
              </thead>
              <tbody v-if="possibleBuckets.length" class="divide-y divide-gray-200 bg-white">
                <agent-bucket-no-access-list-item
                  v-for="(item) in possibleBuckets"
                  :access="item"
                  :key="item.id"
                  :agent-id="agentId"
                  :limits="possible_daily_limits"
                  @bucket-limit-updated="syncData"
                  @refetch-data="fetchBucketsData"
                ></agent-bucket-no-access-list-item>
              </tbody>
              <tbody v-else class="divide-y divide-gray-200 bg-white">
                <tr>
                  <td colspan="6" class="whitespace-nowrap px-3 py-4 text-sm text-gray-600 text-center">There are no more buckets available for this Agent.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else class="w-full text-center my-4">
      Agent has no access to any buckets.
    </div> -->
  </div>

  <!-- BEGIN: Create Bucket Access -->
  <TransitionRoot as="template" :show="showCreateModal">
    <Dialog as="div" class="relative z-10" @close="showCreateModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">Add Bucket Access</DialogTitle>
                  <div>
                    <form @submit.prevent="createBucketAccess" class="intro-y box p-5" method="POST">
                      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
                        <!-- BEGIN: Bucket -->
                        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('bucket_id')}">
                          <label for="bucket_id" class="form-label text-gray-600">Bucket</label>
                          <select class="input-field" name="bucket_id" id="bucket_id" v-model="bucket_id">
                            <option value="">—</option>
                            <option v-for="bucket in possibleBuckets" :key="bucket.id" :value="bucket.id">{{ bucket.name }}</option>
                          </select>
                          <div v-if="isInvalid('bucket_id')" class="error-message mt-1">{{ errorMessage('bucket_id') }}</div>
                        </div>
                        <!-- END: Bucket -->
                      </div>

                      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
                        <!-- BEGIN: Daily limit -->
                        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('daily_limit')}">
                          <label for="daily_limit" class="form-label text-gray-600">Daily limit</label>
                          <select class="input-field" name="daily_limit" id="daily_limit" v-model="daily_limit">
                            <option value="">—</option>
                            <option v-for="(limit, i) in possible_daily_limits" :key="i" :value="limit.value">{{ limit.label }}</option>
                          </select>
                          <div v-if="isInvalid('daily_limit')" class="error-message mt-1">{{ errorMessage('daily_limit') }}</div>
                        </div>
                        <!-- END: Daily limit -->
                      </div>

                      <div class="text-right mt-5">
                        <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
                          <LoadingSpinner v-if="loading"></LoadingSpinner>
                          <span v-else>Submit</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- END: Create Bucket Access -->
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {ElDatePicker} from "element-plus";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import AgentBucketListItem from "./AgentBucketListItem.vue";
import AgentBucketNoAccessListItem from "./AgentBucketNoAccessListItem.vue";

export default {
  name: "AgentBuckets",

  mixins: [serverValidationErrorsMixin],

  components: {
    AgentBucketNoAccessListItem,
    AgentBucketListItem,
    LoadingSpinner,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ElDatePicker
  },

  props: {
    agentId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      buckets: [],
      agent_bucket_access: [],
      possible_daily_limits: [],
      bucket_id: null,
      daily_limit: null,
      loading: false,
      showCreateModal: false,
    }
  },

  mounted() {
    this.fetchBucketsData();
  },

  computed: {
    agent_bucket_access_buckets_ids() {
      return this.agent_bucket_access.map(i => i.bucket_id);
    },
    possibleBuckets() {
      return this.buckets.filter(i => {
        i.daily_limit = 'no-access'; 
        return !this.agent_bucket_access_buckets_ids.includes(i.id)});
    }
  },

  methods: {
    fetchBucketsData() {
      this.loading = true;
      axios
        .get(`/agents/${this.agentId}/bucket-access`)
        .then((response) => {
          this.buckets = response.data.buckets;
          this.possible_daily_limits = response.data.possible_daily_limits;
          this.agent_bucket_access = response.data.agent_bucket_access;
        })
        .catch((error) => {
          // Handle error
        })
        .finally(() => {
          this.loading = false;
        })
    },

    createBucketAccess() {
      this.loading = true;
      setTimeout(() => {
        axios
          .post(`/agents/${this.agentId}/bucket-access`, {
            bucket_id: this.bucket_id,
            daily_limit: this.daily_limit
          })
          .then((response) => {
            this.agent_bucket_access.push(response.data);
            this.bucket_id = null;
            this.daily_limit = null;
            this.errors = {};
            this.showCreateModal = false;
          })
          .catch((error) => {
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    syncData(event) {
      let elementPos = this.agent_bucket_access.map(function(x) {return x.id; }).indexOf(event.access.id);
      this.agent_bucket_access[elementPos] = event.access;
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        })
      }
      console.log("AgentBuckets - handleErroredSubmission", error.response);
    },
  },
}
</script>

<style scoped>

</style>