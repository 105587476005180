<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">

      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button @click="showCreateModal = true" type="button" class="btn-primary inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm sm:w-auto"><span>Create New Dead Reason</span></button>
      </div>
    </div>
    <div class="mt-8 flex flex-col" v-if="deadReasons.length">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Dead Reason</th>
                <th scope="col" class="py-3.5 pl-4 text-left text-sm font-semibold text-right text-gray-900 sm:pr-6">
                  Options
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <agency-dead-reason-list-item
                v-for="(reason, i) in deadReasons"
                :reason="reason"
                :key="reason.id"
                :agencyId="agencyId"
                @reason-updated="syncData"
                @reasonDeleted="fetchDeadReasons"
              ></agency-dead-reason-list-item>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full text-center my-4">
      No dead reasons.
    </div>
  </div>


  <!-- Create New Dead Reason Modal  -->
  <TransitionRoot as="template" :show="showCreateModal">
    <Dialog as="div" class="relative z-10" @close="showCreateModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">Create Dead Reason</DialogTitle>
                  <div>
                    <form @submit.prevent="createDeadReason" class="intro-y box p-5" method="POST">
                      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
                        <!-- BEGIN: Dead Reason -->
                        <div class="col-span-12 input-form">
                          <label for="first_name" class="form-label text-slate-500">Dead Reason</label>
                          <input v-model="name" id="name" type="text" class="input-field w-full" name="name">
                          <div v-if="isInvalid('name')" class="error-message mt-1">{{ errorMessage('name') }}</div>
                        </div>
                        <!-- END: Dead Reason -->
                      </div>

                      <div class="text-right mt-5">
                        <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
                          <LoadingSpinner v-if="loading"></LoadingSpinner>
                          <span v-else>Submit</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- End Create Dead Reason Modal -->
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import AgencyDeadReasonListItem from "./AgencyDeadReasonListItem.vue";

export default {
  name: "AgencyManageDeadReasons",

  mixins: [serverValidationErrorsMixin],

  components: {
    AgencyDeadReasonListItem,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LoadingSpinner
  },

  props: {
    agencyId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      name: null,
      deadReasons: [],
      loading: false,
      showCreateModal: false,
    }
  },

  mounted() {
    this.fetchDeadReasons();
  },

  methods: {
    fetchDeadReasons() {
      this.loading = true;
      axios
        .get(`/agencies/${this.agencyId}/dead-reasons`)
        .then((response) => {
          this.deadReasons = response.data;
        })
        .catch((error) => {
          // Handle error
        })
        .finally(() => {
          this.loading = false;
        })
    },

    showCreateDeadReasonForm() {
      // TODO
    },

    createDeadReason() {
      this.loading = true;
      setTimeout(() => {
        axios
          .post(`/agencies/${this.agencyId}/dead-reasons`, {
            name: this.name
          })
          .then((response) => {
            this.deadReasons.push(response.data);
            this.name = null;
            this.showCreateModal = false;
          })
          .catch((error) => {
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    syncData(event) {
      let elementPos = this.deadReasons.map(function(x) {return x.id; }).indexOf(event.reason.id);
      this.deadReasons[elementPos] = event.reason;
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        })
      }
      console.log("AgencyManageDeadReasons - handleErroredSubmission", error.response);
    },
  },
}
</script>

<style scoped>

</style>