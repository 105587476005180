<script setup>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {Inertia} from "@inertiajs/inertia";
import {computed, ref, watch, nextTick, onBeforeMount, onDeactivated} from "vue";
import dayjs from "dayjs";
import {useForm} from "@inertiajs/inertia-vue3";
import MaskedDatePicker from "@/Components/MaskedDatePicker.vue";
import MaskedPhoneInput from "@/Components/MaskedPhoneInput.vue";
import {ElCheckbox, ElDatePicker, ElLoading} from "element-plus";
import BreezeInputError from "@/Components/InputError.vue";
import Modal from "@/Components/Modal.vue";
import BreezeButton from "@/Components/Button.vue";

const props = defineProps({
  lead: {type: Object, required: true},
  lead_id: {
   type: Number,
   required: true
  },
  leadLastName: {
    type: String,
    default: null
  },
  dependantRoles: {
    type: Array,
    default: () => [],
  },
  editMode: {
    type: Boolean,
    default: false,
  },
  dependantToEdit: {
    type: Object,
    default: null,
  },
  leadDependants: {
    type: Array,
    default: () => [],
  },
  dependantCompanyRoles: Object,
})

const emit = defineEmits(['dependantsUpdated']);

const formData = useForm({
  id: props.dependantToEdit?.id ?? null,
  role: props.dependantToEdit?.role ?? null,
  first_name: props.dependantToEdit?.first_name ?? null,
  last_name: props.dependantToEdit?.last_name ?? null,
  dob: props.dependantToEdit?.dob ?? null,
  age: props.dependantToEdit?.age ?? null,
  gender: props.dependantToEdit?.gender ?? null,
  height_feet: props.dependantToEdit?.height_feet ?? null,
  height_inches: props.dependantToEdit?.height_inches ?? null,
  weight: props.dependantToEdit?.weight ?? null,
  smoker: props.dependantToEdit?.smoker ?? null,
  nickname: props.dependantToEdit?.nickname ?? null,
  use_nickname_on_templates: props.dependantToEdit?.use_nickname_on_templates ?? false,
  company_role: props.dependantToEdit?.company_role ?? null,
  title: props.dependantToEdit?.title ?? null,
  phone: props.dependantToEdit?.phone ?? null,
  email: props.dependantToEdit?.email ?? null,
  notes: props.dependantToEdit?.notes ?? null,
  is_decision_maker: props.dependantToEdit?.is_decision_maker ?? null,
  medicare_id: props.dependantToEdit?.medicare_id ?? null,
  medicaid_id: props.dependantToEdit?.medicaid_id ?? null,
  part_a_effective_date: props.dependantToEdit?.part_a_effective_date ?? null,
  part_b_effective_date: props.dependantToEdit?.part_b_effective_date ?? null,
  part_d_effective_date: props.dependantToEdit?.part_d_effective_date ?? null,
  veteran: props.dependantToEdit?.veteran ?? null,
  social_security_benefits: props.dependantToEdit?.social_security_benefits ?? null,
})

const errors = ref(null);

const loading = ref(false);
const deleting = ref(false);

function submitForm() {
  if (loading.value) return;
  loading.value = true;

  setTimeout(() => {
    if (props.editMode && props.dependantToEdit) {
      return updateDependant();
    }

    createDependant()
  }, 1000)

}

function createDependant() {

  formData.transform((data) => ({
    ...data,
    ssn: ssnForm.ssn ?? null,
  })).post(route('lead_dependants.store', props.lead_id),  {
      preserveScroll: true,
      only: ['leadDependants', 'errors'],
    onSuccess: (response) => {
      emit('dependantsUpdated', response.data);
      resetFormData();
    },
    onError: (error) => {
    },
      onFinish: () => {
      loading.value = false;
    }
  })
}

function updateDependant() {
  formData.put(route('lead_dependants.update', [props.lead_id, props.dependantToEdit.id]),  {
      preserveScroll: true,
      only: ['lead', 'leadDependants', 'errors'],
      onSuccess: (response) => {
        emit('dependantsUpdated', response);
        resetFormData();
      },
      onError: (error) => {
      },
      onFinish: () => {
        loading.value = false;
      }
    }
  )
}

function deleteDependant() {
  if (deleting.value) return;
  deleting.value = true;
  setTimeout(() => {
    Inertia.delete(route('lead_dependants.delete', [props.lead_id, props.dependantToEdit.id]), {
        preserveScroll: true,
        only: ['leadDependants'],
        onSuccess: (response) => {
          emit('dependantsUpdated', response.data);
          resetFormData();
        },
        onError: (error) => {
        },
        onFinish: () => {
          deleting.value = false;
        }
      }
    )
  },500)
}

function resetFormData() {
  formData.role = null;
  formData.first_name = null;
  formData.last_name = null;
  formData.dob = null;
  formData.age = null;
  formData.gender = null;
  formData.height_feet = null;
  formData.height_inches = null;
  formData.weight = null;
  formData.smoker = 'No';
  formData.company_role = null;
  formData.title = null;
  formData.phone = null;
  formData.email = null;
  formData.notes = null;
  formData.is_decision_maker = null;
  if(!props.editMode) {
    formData.ssn = null
  }
}

const dob = computed(() => {
  return formData.dob;
});

const validDependantRoles = computed(() => {
  // Check if 'primary' role is already set among all dependants
  const isPrimaryAlreadySet = props.leadDependants.some(dependant => dependant.role === 'primary');

  // Include 'primary' if it's not set or if editing a 'primary' role
  return props.dependantRoles.filter(role => {
    if (!isPrimaryAlreadySet) {
      return true; // No primary set, include all roles
    }

    // If editing and the current dependant is 'primary', keep 'primary' in the options
    if (props.editMode && props.dependantToEdit?.role === 'primary') {
      return true;
    }

    // Exclude 'primary' if it's already set and we're not editing a 'primary' dependant
    return role !== 'primary';
  });
});

watch(dob, (newValue) => {
  nextTick(() => {
    let dob = dayjs(newValue, 'MM/DD/YYYY', true);
    formData.age = dob.isValid() && dayjs().diff(dob, 'year', false) >= 0 ? dayjs().diff(dob, 'year', false) : null;
  })
})


const usesGroupLeadFields = computed(() => {
  return props.lead.has_type_marked_as_group;
});

const ssnForm = useForm({
  ssn: null,
})

const credentialsForm = useForm({
  email: null,
  password: null,
  dependant_id: props.dependantToEdit?.id ?? null,
})

const ssnPasswordModalVisible = ref(false);
const showSSNPasswordForm = () => {
  ssnPasswordModalVisible.value = true;
}

const fetchingSSN = ref(false);
const submitCredentials = () => {
  if (fetchingSSN.value) return;
  fetchingSSN.value = true;
  credentialsForm.post(route('verify-credentials'), {
    preserveScroll: true,
    onSuccess: () => {
      fetchSSN();
    },
    onError: () => {
      fetchingSSN.value = false;
    }
  })
}

const decryptedSsn = ref(null);
const ssnVisible = ref(false);
async function fetchSSN() {
  axios.get(`/leads/${props.lead_id}/dependants/${props.dependantToEdit.id}/ssn`)
      .then((response) => {
        decryptedSsn.value = response.data.ssn;
        ssnForm.ssn = response.data.ssn;
        ssnPasswordModalVisible.value = false;
        ssnVisible.value = true;
        globalLoading.value = false;
      })
      .catch((error) => {
        console.log(error, 'LeadDependantForm - fetchSSN()')
      })
      .finally(() => {
        fetchingSSN.value = false;
      })
}

const hasSsnChanges = ref(false);
const submitSsn = () => {
  if (loading.value) return;
  loading.value = true;

  setTimeout(() => {
    axios
        .post(route('lead-dependants.ssn.update', [props.lead_id, props.dependantToEdit.id]), { ssn: ssnForm.ssn })
        .then((response) => {
          hasSsnChanges.value = false;
          decryptedSsn.value = ssnForm.ssn;
          $notify.success({
            title: 'Success',
            message: 'SSN updated successfully',
            showClose: true,
          })
        })
        .catch((error) => {
          console.log(error, 'LeadDependantForm - submit()')
        })
        .finally(() => {
          loading.value = false;
        })
  }, 500)
}

const checkIfSsnChanged = () => {
  if (ssnForm.ssn !== decryptedSsn.value) {
    hasSsnChanges.value = true;
    promptSaveSsnChanges();
  }
}

const promptSaveSsnChanges = () => {
  Swal.fire({
    title: "Save changes?",
    text: `Do you want to save changes to SSN before you leave`,
    icon: "warning",
    confirmButtonColor: '#EF4444',
    confirmButtonText: "Yes",
    showCancelButton: true,
    cancelButtonColor: '#899CC1',
  })
      .then((result) => {
        if (result.value) {  // Check result.value instead of just result
          submitSsn()
        } else {
          ssnForm.ssn = decryptedSsn.value;
        }
      });
}

const globalLoading = ref(true);
const credentialsCheckCachedLoading = ref(null);
const passwordChecked = ref(false);

onBeforeMount(() => {
  initModule();
})

async function passwordCheckedRecently() {
  return axios.get(route('dependant-form-password-checked-recently', props.dependantToEdit.id)).then(function(response) {
    return new Promise((resolve) => {
      if (response.data.checked) {
        resolve(true)
      } else {
        resolve(false)
      }
    });
  })
}


async function initModule() {
  if (!props.editMode) return;
  credentialsCheckCachedLoading.value = ElLoading.service({fullscreen: true, text: 'Loading', lock: true})
  let checkedRecently = await passwordCheckedRecently();
  if (checkedRecently) {
    let result1 = await fetchSSN()
    if (result1) {
      setTimeout(() => {
        passwordChecked.value = true;
        credentialsCheckCachedLoading.value.close()
        credentialsCheckCachedLoading.value = null;
      }, 500)
    } else {
      passwordChecked.value = false;
      credentialsCheckCachedLoading.value.close()
      credentialsCheckCachedLoading.value = null;
      setTimeout(() => {
        globalLoading.value = false;
      }, 500)
    }
  } else {
    passwordChecked.value = false;
    credentialsCheckCachedLoading.value.close()
    credentialsCheckCachedLoading.value = null;
    setTimeout(() => {
      globalLoading.value = false;
    }, 500)
  }
}

onDeactivated(() => {
  globalLoading.value = true;
})

const showMedicareFields = computed(() => {
    return formData.role !== 'primary' && props.lead.has_type_marked_as_medicare;
})
</script>

<template>
  <form class="intro-y box p-5">
    <div class="grid grid-cols-12 gap-3 lg:gap-4 mt-3">
      <!-- BEGIN: Roles -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.role}">
        <label for="dependant-role" class="block text-sm font-medium text-gray-700">Role</label>
        <select id="dependant-role" v-model="formData.role" class="input-field w-full truncate capitalize" name="roles">
          <option v-for="role in validDependantRoles" :value="role">{{ role }}</option>
        </select>
        <div v-if="formData.errors.role" class="error-message mt-1">{{ formData.errors.role }}</div>
      </div>
      <!-- END: Roles -->

      <!-- BEGIN: Dependant first name -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.first_name}">
        <label for="dependant-name" class="block text-sm font-medium text-gray-700">First Name</label>
        <input id="dependant-name" v-model="formData.first_name" type="text" name="name" class="input-field w-full">
        <div v-if="formData.errors.first_name" class="error-message mt-1">{{ formData.errors.first_name }}</div>
      </div>
      <!-- END: Dependant first name -->

      <!-- BEGIN: Dependant last name -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.last_name}">
        <label for="dependant-name" class="block text-sm font-medium text-gray-700">Last Name</label>
        <input id="dependant-name" v-model="formData.last_name" type="text" name="name" class="input-field w-full">
      <div v-if="formData.errors.last_name" class="error-message mt-1">{{ formData.errors.last_name }}</div>
      </div>
    <!-- END: Dependant last name -->

    <!-- BEGIN: Nickname -->
    <div class="col-span-12 input-form" :class="{'has-error': formData.errors.nickname}">
      <label for="dependant-nickname" class="block text-sm font-medium text-gray-700">Nickname</label>
      <input id="dependant-nickname" v-model="formData.nickname" type="text" name="nickname" class="input-field w-full" max="255">
      <div v-if="formData.errors.nickname" class="error-message mt-1">{{ formData.errors.nickname }}</div>
    </div>
    <!-- END: Nickname -->
    <!-- BEGIN: Use Nickname In Templates -->
    <div v-if="formData.role !== 'dependant'" class="col-span-12 input-form" :class="{'has-error': formData.errors.use_nickname_on_templates}">
        <label for="dependant-use_nickname_on_templates" class="block text-sm font-medium text-gray-700">Use Nickname On Templates Instead Of First Name</label>
        <input type="checkbox" id="dependant-use_nickname_on_templates" v-model="formData.use_nickname_on_templates" name="use_nickname_on_templates" class="input-checkbox">
        <div v-if="formData.errors.use_nickname_on_templates" class="error-message mt-1">{{ formData.errors.use_nickname_on_templates }}</div>
    </div>
    <!-- END: Use Nickname In Templates -->

    <!-- BEGIN: Dependant dob -->
    <div class="col-span-12 input-form" :class="{'has-error': formData.errors.dob}">
      <label for="dependant-form-dob" class="block text-sm font-medium text-gray-700">DOB</label>
      <masked-date-picker element-id="dependant-form-dob" v-model="formData.dob"></masked-date-picker>
      <div v-if="formData.errors.dob" class="error-message mt-1">{{ formData.errors.dob }}</div>
    </div>
    <!-- END: Dependant dob -->

    <!-- BEGIN: Dependant age -->
    <div class="col-span-12 input-form" :class="{'has-error': formData.errors.age}">
      <label for="dependant-age" class="block text-sm font-medium text-gray-700">Age</label>
      <input id="dependant-age" v-model="formData.age" type="number" name="main_contact" class="input-field w-full">
      <div v-if="formData.errors.age" class="error-message mt-1">{{ formData.errors.age }}</div>
    </div>
    <!-- END: Dependant age -->

    <!-- BEGIN: Social Security Number -->
    <div v-if="!editMode" class="col-span-12 input-form" :class="{'has-error': formData.errors.ssn }">
      <label for="dependant-ssn" class="block text-sm font-medium text-gray-700">Social Security Number</label>
      <input id="dependant-ssn" v-model="ssnForm.ssn" type="text" name="dependant-ssn" class="input-field w-full" maxlength="255">
      <div v-if="formData.errors.ssn" class="error-message mt-1">{{ formData.errors.ssn }}</div>
    </div>
    <div v-if="!globalLoading && editMode" class="col-span-12 input-form" :class="{'has-error': ssnForm.errors.ssn }">
      <label for="dependant-ssn" class="block text-sm font-medium text-gray-700">Social Security Number</label>
      <button v-if="!ssnVisible" type="button" @click.prevent="showSSNPasswordForm" class="btn btn-primary py-1 px-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-4 w-4"><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd"></path></svg></button>
      <input v-else @keyup.enter="submitSsn" @blur.prevent="checkIfSsnChanged" id="dependant-ssn" v-model="ssnForm.ssn" type="text" name="dependant-ssn" class="input-field w-full">
      <div v-if="ssnForm.errors.ssn" class="error-message mt-1">{{ ssnForm.errors.ssn }}</div>
    </div>
    <div v-if="globalLoading && editMode" class="col-span-12 input-form" :class="{'has-error': ssnForm.errors.ssn }">
      <label for="dependant-ssn" class="block text-sm font-medium text-gray-700">Social Security Number</label>
      <input type="text" name="dependant-ssn" class="input-field w-full opacity-0 py-1">
    </div>
    <!-- END: Social Security Number -->

      <!-- BEGIN: Dependant Gender -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.gender}">
        <label for="dependant-gender" class="block text-sm font-medium text-gray-700">Gender</label>
        <select id="dependant-gender" v-model="formData.gender" class="input-field w-full" name="gender">
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
        <div v-if="formData.errors.gender" class="error-message mt-1">{{ formData.errors.gender }}</div>
      </div>
      <!-- END: Dependant Gender -->

      <!--   Height ft   -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.height_feet}">
        <label for="dependant${i}_feet" class="block text-sm font-medium text-gray-700">Height (feet)</label>
        <input id="dependant${i}_feet" v-model="formData.height_feet" type="number" name="main_contact" class="input-field w-full" placeholder="Feet">
        <div v-if="formData.errors.height_feet" class="error-message mt-1">{{ formData.errors.height_feet }}</div>
      </div>
      <!--   Height in   -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.height_inches}">
        <label for="dependant${i}_in" class="block text-sm font-medium text-gray-700">Height (inches)</label>
        <input id="dependant${i}_inches" v-model="formData.height_inches" type="number" name="main_contact" class="input-field w-full" placeholder="Inches">
        <div v-if="formData.errors.height_inches" class="error-message mt-1">{{ formData.errors.height_inches }}</div>
      </div>

      <!-- BEGIN: Dependant Weight -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.weight}">
        <label for="dependant-weight" class="block text-sm font-medium text-gray-700">Weight (lbs)</label>
        <input id="dependant-weight" v-model="formData.weight" type="text" name="weight" class="input-field w-full">
        <div v-if="formData.errors.weight" class="error-message mt-1">{{ formData.errors.weight }}</div>
      </div>
      <!-- END: Dependant Weight -->

      <!-- BEGIN: Dependant Tobacco -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.smoker}">
        <label for="dependant-smoker" class="block text-sm font-medium text-gray-700">Tobacco</label>
        <select id="dependant-smoker" v-model="formData.smoker" class="input-field w-full" name="smoker">
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
        <div v-if="formData.errors.smoker" class="error-message mt-1">{{ formData.errors.smoker }}</div>
      </div>
      <!-- END: Dependant Tobacco -->

      <!-- MEDICARE / MEDICAID FIELDS -->

      <div v-if="showMedicareFields" class="col-span-12 input-form" :class="{'has-error': formData.errors.medicare_id}">
        <label for="dependant-medicare-id" class="block text-sm font-medium text-gray-700">Medicare ID</label>
        <input id="dependant-medicare-id" v-model="formData.medicare_id" type="text" name="medicare_id" class="input-field w-full">
        <div v-if="formData.errors.medicare_id" class="error-message mt-1">{{ formData.errors.medicare_id }}</div>
      </div>

      <div v-if="showMedicareFields" class="col-span-12 input-form" :class="{'has-error': formData.errors.part_a_effective_date}">
        <label for="part_a_effective_date-datepicker-dependant-form" class="block text-sm font-medium text-gray-700">Part A Effective Date</label>
        <el-date-picker
          id="part_a_effective_date-datepicker-dependant-form"
          style="width: 100% !important;"
          v-model="formData.part_a_effective_date"
          type="date"
          placeholder="Pick a day"
          format="MMM DD, YYYY"
          value-format="YYYY-MM-DD"
        />
        <div v-if="formData.errors.part_a_effective_date" class="error-message mt-1">{{ formData.errors.part_a_effective_date }}</div>
      </div>

      <div v-if="showMedicareFields" class="col-span-12 input-form" :class="{'has-error': formData.errors.part_b_effective_date}">
        <label for="part_b_effective_date-datepicker-dependant-form" class="block text-sm font-medium text-gray-700">Part B Effective Date</label>
        <el-date-picker
          id="part_b_effective_date-datepicker-dependant-form"
          style="width: 100% !important;"
          v-model="formData.part_b_effective_date"
          type="date"
          placeholder="Pick a day"
          format="MMM DD, YYYY"
          value-format="YYYY-MM-DD"
        />
        <div v-if="formData.errors.part_b_effective_date" class="error-message mt-1">{{ formData.errors.part_b_effective_date }}</div>
      </div>

      <div v-if="showMedicareFields" class="col-span-12 input-form" :class="{'has-error': formData.errors.part_d_effective_date}">
        <label for="part_d_effective_date-datepicker-dependant-form" class="block text-sm font-medium text-gray-700">Part D Effective Date</label>
        <el-date-picker
          id="part_d_effective_date-datepicker-dependant-form"
          style="width: 100% !important;"
          v-model="formData.part_d_effective_date"
          type="date"
          placeholder="Pick a day"
          format="MMM DD, YYYY"
          value-format="YYYY-MM-DD"
        />
        <div v-if="formData.errors.part_d_effective_date" class="error-message mt-1">{{ formData.errors.part_d_effective_date }}</div>
      </div>

      <div v-if="showMedicareFields" class="col-span-12 input-form" :class="{'has-error': formData.errors.veteran}">
        <label for="dependant-veteran" class="block text-sm font-medium text-gray-700">Veteran</label>
        <select id="dependant-veteran" v-model="formData.veteran" class="input-field w-full" name="veteran">
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
        <div v-if="formData.errors.veteran" class="error-message mt-1">{{ formData.errors.veteran }}</div>
      </div>

      <div v-if="showMedicareFields" class="col-span-12 input-form" :class="{'has-error': formData.errors.medicaid_id}">
        <label for="dependant-medicaid-id" class="block text-sm font-medium text-gray-700">Medicaid ID</label>
        <input id="dependant-medicaid-id" v-model="formData.medicaid_id" type="text" name="medicaid_id" class="input-field w-full">
        <div v-if="formData.errors.medicaid_id" class="error-message mt-1">{{ formData.errors.medicaid_id }}</div>
      </div>


      <div v-if="showMedicareFields" class="col-span-12 input-form" :class="{'has-error': formData.errors.social_security_benefits}">
        <label for="dependant-social_security_benefits" class="block text-sm font-medium text-gray-700">Social Security Benefits</label>
        <select id="dependant-social_security_benefits" v-model="formData.social_security_benefits" class="input-field w-full" name="social_security_benefits">
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
        <div v-if="formData.errors.social_security_benefits" class="error-message mt-1">{{ formData.errors.social_security_benefits }}</div>
      </div>

      <!-- END MEDICARE / MEDICAID FIELDS -->
    </div>

    <!-- BEGIN GROUP LEAD ONLY DEPENDANT FIELDS -->
    <div v-if="usesGroupLeadFields" class="grid grid-cols-12 gap-3 lg:gap-4 mt-3">

      <!-- BEGIN: Dependant Company Role -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.company_role}">
        <label for="dependant-company_role" class="block text-sm font-medium text-gray-700">Company Role</label>
        <select id="dependant-company_role" v-model="formData.company_role" class="input-field w-full" name="company_role">
          <option :value="null">- None -</option>
          <option v-for="(companyRole, index) in dependantCompanyRoles" :key="companyRole" :value="companyRole">{{ companyRole }}</option>
        </select>
        <div v-if="formData.errors.company_role" class="error-message mt-1">{{ formData.errors.company_role }}</div>
      </div>
      <!-- END: Dependant Company Role -->

      <!-- BEGIN: Dependant Title -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.title}">
        <label for="dependant-title" class="block text-sm font-medium text-gray-700">Title</label>
        <input id="dependant-title" v-model="formData.title" type="text" name="title" class="input-field w-full">
        <div v-if="formData.errors.title" class="error-message mt-1">{{ formData.errors.title }}</div>
      </div>
      <!-- END: Dependant Title -->

      <!-- BEGIN: Dependant Phone -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.phone}">
        <label for="dependant-phone" class="block text-sm font-medium text-gray-700">Phone</label>
        <MaskedPhoneInput element-id="phone" v-model="formData.phone" name="phone" class=" input-field w-full" />
        <div v-if="formData.errors.phone" class="error-message mt-1">{{ formData.errors.phone }}</div>
      </div>
      <!-- END: Dependant Phone -->

      <!-- BEGIN: Dependant Email -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.email}">
        <label for="dependant-email" class="block text-sm font-medium text-gray-700">Email</label>
        <input id="dependant-email" v-model="formData.email" type="text" name="email" class="input-field w-full">
        <div v-if="formData.errors.email" class="error-message mt-1">{{ formData.errors.email }}</div>
      </div>
      <!-- END: Dependant Email -->

      <!-- BEGIN: Dependant Notes -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.notes}">
        <label for="dependant-notes" class="block text-sm font-medium text-gray-700">Notes</label>
        <textarea id="dependant-notes" rows="3" v-model="formData.notes" type="text" name="notes" class="input-field w-full"></textarea>
        <div v-if="formData.errors.notes" class="error-message mt-1">{{ formData.errors.notes }}</div>
      </div>
      <!-- END: Dependant Notes -->

      <!-- BEGIN: Dependant Is Decision Maker? -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.is_decision_maker}">
        <label for="dependant-is_decision_maker" class="block text-sm font-medium text-gray-700">Is Decision Maker?</label>
        <ElCheckbox id="dependant-is_decision_maker" :true-label="1" :false-label="0" v-model="formData.is_decision_maker" name="is_decision_maker" class="custom-checkbox" />
        <div v-if="formData.errors.is_decision_maker" class="error-message mt-1">{{ formData.errors.is_decision_maker }}</div>
      </div>
      <!-- END: Dependant Is Decision Maker? -->

    </div>
    <!-- END GROUP LEAD ONLY DEPENDANT FIELDS -->

    <div class="flex items-center justify-end mt-5">
      <button type="button" @click.prevent="submitForm" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <span v-else>Save</span>
      </button>
      <button v-if="editMode" type="button" @click.prevent="deleteDependant" class="btn btn-danger w-24 ml-2" :class="{'pointer-events-none': deleting}">
        <LoadingSpinner v-if="deleting"></LoadingSpinner>
        <span v-else>Delete</span>
      </button>
    </div>
  </form>


  <Modal :show="ssnPasswordModalVisible" title="Enter Email and Password to show SSN" @onClose="ssnPasswordModalVisible = false">
    <form class="max-w-sm mx-auto" @submit.prevent="submitCredentials">
      <div>
        <input id="medication-check-email" v-model="credentialsForm.email" autocomplete="off" autofocus class="input-field  mt-1 block w-full" placeholder="Email Address" required type="email"/>
        <BreezeInputError :message="credentialsForm.errors.email" class="mt-2"/>
      </div>

      <div class="mt-2">
        <input id="medication-check-password" v-model="credentialsForm.password" autocomplete="off" class="input-field mt-1 block w-full" placeholder="Password" required style="text-security:disc; -webkit-text-security:disc;" type="text"/>
        <BreezeInputError :message="credentialsForm.errors.password" class="mt-2"/>
      </div>

      <div class="mt-4">
        <BreezeButton :class="{ 'opacity-25': fetchingSSN }" :disabled="fetchingSSN" class="text-white bg-tb-blue block w-full py-3">
          <p class="mx-auto font-bold">Show SSN</p>
        </BreezeButton>
      </div>
    </form>
  </Modal>
</template>
