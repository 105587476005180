<script setup>
import {useForm} from "@inertiajs/inertia-vue3";
import {ref} from "vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";

const props = defineProps({
  leadId: Number
})

const formData =  useForm({
  content: null,
})

const emit = defineEmits(['note-added']);

const loading = ref(false);

function addNewNote() {
  if (loading.value) return;
  loading.value = true;

  setTimeout(() => {
    formData.post(route('lead.notes.store', props.leadId), {
      preserveScroll: true,
      only: [''],
      onSuccess: (response) => {
        emit('note-added');
        formData.reset();
      },
      onError: (error) => {

      },
      onFinish: () => {
        loading.value = false;
      },
    })
  }, 500)
}
</script>

<template>
  <form @submit.prevent="addNewNote">
    <!-- BEGIN: Note Content -->
    <div class="col-span-12 input-form" :class="{'has-error': formData.errors.content}">
      <label for="note" class="form-label text-gray-600">Note</label>
      <textarea v-model="formData.content" name="note" id="" rows="4" class="input-field"></textarea>
      <div class="error-message mt-1" v-if="formData.errors.content">{{ formData.errors.content }}</div>
    </div>
    <!-- END: Note Content -->

    <button :disabled="loading" class="btn btn-primary mt-4 w-full" type="submit">
      <LoadingSpinner v-if="loading"></LoadingSpinner>
      <span v-else>Submit</span>
    </button>
  </form>
</template>

<style scoped></style>