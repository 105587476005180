<script setup>
import { Menu, MenuButton, MenuItem, MenuItems, Portal } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { usePopper } from "@/utils/use-popper";
import {Inertia} from "@inertiajs/inertia";
import {ref} from "vue";
import {Link} from "@inertiajs/inertia-vue3";

const props = defineProps({
  doctor: Object,
  lead_id: Number,
  hideOptions: {
      type: Boolean,
      default: false,
  },
})

const emit = defineEmits(['show-update-form'])

function confirmDelete() {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to undo this.',
    icon: 'warning',
    confirmButtonText: "Yes, I'm sure.",
    showCancelButton: true,
    confirmButtonColor: '#B91C1C',
    cancelButtonColor: '#899CC1'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteDoctor();
    }
  })
}


const deleting = ref(false);
function deleteDoctor() {
  if (deleting.value) return;
  deleting.value = true;
  Inertia.delete(route('leads.doctors.destroy', [props.lead_id, props.doctor.id]), {
    only: ['errors'],
    preserveScroll: true,
    onSuccess: () => {
      emit('doctor-deleted');
    },
    onFinish: () => {
      deleting.value = false;
    }
  });
}

let [trigger, container] = usePopper({
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    },
  ],
})
</script>

<template>
  <tr :class="['hover:bg-gray-50', 'relative']">
    <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ props.doctor.doctor ?? '—' }}</td>
    <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ props.doctor.pcp_id ?? '—' }}</td>
    <td class="whitespace-nowrap px-2 py-3.5  text-sm font-medium text-gray-900">{{ props.doctor.facility ?? '—' }}</td>
    <td class="whitespace-nowrap px-2 py-3.5  text-sm text-gray-900">{{ props.doctor.address }}</td>
    <td class="whitespace-nowrap px-2 py-3.5  text-sm text-gray-900">{{ props.doctor.phone }}</td>
    <td class="whitespace-nowrap px-2 py-3.5  text-sm text-gray-900">{{ props.doctor.for }}</td>
    <td v-if="!hideOptions" class="relative whitespace-nowrap px-2 py-3.5  text-sm text-gray-900">
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton ref="trigger" class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-tb-blue">
            Options
            <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <Portal>
          <MenuItems ref="container" class="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a href="javascript;:" @click.prevent="$emit('show-update-form', doctor)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Modify</a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a @click.prevent="confirmDelete" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Delete</a>
              </MenuItem>
            </div>
          </MenuItems>
        </Portal>
      </Menu>
    </td>
  </tr>
</template>

<style scoped></style>
