<template>
  <tr>
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{{ license.state_name }}</td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ license.formatted_expires_at }}</td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <a v-if="license.file_name && license.file_path" target="_blank" :href="license.file_path"
        class="font-semibold text-blue-700">
        {{ license.file_name }}
      </a>
      <span v-else>—</span>
    </td>

    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton ref="trigger"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-tb-blue">
            Options
            <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <Portal>
          <MenuItems ref="container"
            class="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
              <a @click.prevent="openEditLicenseModal" href="#"
                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Edit</a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
              <a href="javascript:;"
                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                @click.prevent="confirmDelete">Delete</a>
              </MenuItem>
              <MenuItem v-if="license.file_path !== null" v-slot="{ active }">
              <a href="#" @click.prevent="confirmRemoveLicenseFile"
                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Remove
                License File</a>
              </MenuItem>
            </div>
          </MenuItems>
        </Portal>
      </Menu>
    </td>
  </tr>

  <!-- BEGIN: Edit License Modal -->
  <TransitionRoot as="template" :show="showEditLicenseModal">
    <Dialog as="div" class="relative z-10" @close="showEditLicenseModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">Edit license
                  </DialogTitle>
                  <div>
                    <form class="intro-y box p-5" method="POST"
                      enctype="multipart/form-data">
                      <!-- BEGIN: State  -->
                      <div class="col-span-12 input-form mt-4" :class="{ 'has-error': isInvalid('state_id') }">
                          <label for="file_name" class="form-label text-slate-600">State</label>
                          <select v-model="formData.state_id" name="state_id" id="state_id" class="input-field ">
                              <option v-for="(state) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
                          </select>
                          <div v-if="isInvalid('state_id') || Object.keys(errors).length" class="error-message mt-1">
                              {{ errorMessage('state_id') ?? '&nbsp;' }}
                          </div>
                      </div>
                      <!-- END: State  -->

                      <!-- BEGIN: Expires At  -->
                      <div class="col-span-12 input-form mt-4" :class="{ 'has-error': isInvalid('expires_at') }">
                          <label for="file_name" class="form-label text-slate-600">Expires At</label>
                          <masked-date-picker :element-id="`agent-license-datepicker-edit-form`" v-model="formData.expires_at"></masked-date-picker>
                          <div v-if="isInvalid('expires_at') || Object.keys(errors).length" class="error-message mt-1">
                              {{ errorMessage('expires_at') ?? '&nbsp;' }}
                          </div>
                      </div>
                      <!-- END: Expires At  -->


                        <div class="col-span-12 mt-4 input-form" :class="{ 'has-error': isInvalid('file') }">
                        <label for="dz" class="form-label text-slate-600">File</label>
                        <!-- BEGIN: Dropzone -->
                        <div class="dropzone rounded-lg border-slate-200"
                          :id='"agent-update-license-dropzone" + license.id'>
                          <div class="fallback">
                            <input name="file" type="file" />
                          </div>
                          <div class="dz-message">
                            <div class="text-lg font-medium">Drop files here or click to upload.</div>
                            <div class="text-gray-600">
                              Files will not be automatically uploaded.
                            </div>
                          </div>
                        </div>
                        <!-- END: Dropzone -->
                        <div v-if="isInvalid('file')" class="error-message mt-1">{{ errorMessage('file') }}</div>
                      </div>

                      <!-- BEGIN: File name  -->
                      <div class="col-span-12 input-form mt-4" :class="{ 'has-error': isInvalid('file_name') }">
                        <label for="file_name" class="form-label text-slate-600">File name</label>
                        <input type="text" v-model="file_name" name="file_name" id="file_name" class="input-field ">
                        <div v-if="isInvalid('file_name')" class="error-message mt-1">{{ errorMessage('file_name') }}
                        </div>
                      </div>
                      <!-- END: File name  -->

                      <div class="text-right mt-5">
                        <button type="button" class="btn btn-default w-24" @click="showEditLicenseModal = false">
                          Cancel
                        </button>
                        <button type="button" @click.prevent="updateLicense" class="btn btn-primary w-24" :class="{ 'pointer-events-none': loading }">
                          <LoadingSpinner v-if="loading"></LoadingSpinner>
                          <span v-else>Submit</span>
                        </button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- END: Modal -->
</template>

<script setup>
import _ from "lodash";
import helpers from "../../helper";
import { Menu, MenuButton, MenuItem, MenuItems, Portal } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { usePopper } from "@/utils/use-popper";
import { ref, computed, nextTick } from "vue";
import Dropzone from "dropzone";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import MaskedDatePicker from "@/Components/MaskedDatePicker.vue";

const props = defineProps({
  modelType: {
    type: String,
    required: true,
    validator(value, props) {
      return ['agent', 'lead'].includes(value);
    },
  },
  modelId: {
    type: Number,
    required: true,
  },
  states: {
    type: Array,
    required: true,
  },
  files: {
    type: Array,
    default: () => [],
  },
  license: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  }
});


const isAgentModel = computed(() => { // Default
  return !props.modelType || props.modelType !== 'lead';
});
const isLeadModel = computed(() => { // Default
  return props.modelType === 'lead';
});

const emit = defineEmits(['license-updated', 'license-deleted', 'license-file-deleted', 'license-file-updated'])

let formData = ref(_.cloneDeep(props.license));
let updateLicenseUrl = ref(isAgentModel.value ? `/agents/${props.modelId}/licenses/${props.license.id}` : `/leads/${props.modelId}/licenses/${props.license.id}`);
let showEditLicenseModal = ref(false);
const errors = ref({});
const loading = ref(false);

let file_selected = ref(null)
let file_name = ref(null)
let dropzone = ref(null)
const dropzoneOptions = ({
  maxFiles: 1,
  maxFilesize: 20, // mb
  parallelUploads: 1, // 1 was set
  paramName: "file",
  addRemoveLinks: true,
  uploadMultiple: false,
  url: '/', // Just a placeholder, needed because DZ asks for it, but we process the upload manually.
  autoProcessQueue: false,
  createImageThumbnails: false,
  accept: function (file, done) {
    const allowedMimeTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
      "application/vnd.ms-excel",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
      "image/bmp",
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];

    if (!allowedMimeTypes.includes(file.type)) {
      done("Error! Files of this type are not accepted");
    } else {
      done();
    }
  }
})

const initDropzone = () => {
  Dropzone.autoDiscover = false;

  dropzone = new Dropzone('#agent-update-license-dropzone' + props.license.id, dropzoneOptions);

  if (props.license.file_id) {
    const fileFound = props.files.find(item => item.id == props.license.file_id)
    var mockFile = { name: props.license.file_name, size: fileFound?.bytes, mock: true };
    file_selected.value = null;
    dropzone.options.addedfile.call(dropzone, mockFile);
  }

  dropzone.on("error", (error) => {
    console.log(error);
  });

  dropzone.on("addedfile", file => {
    file_selected.value = file;
    formData.value.new_file = file;
  });

  dropzone.on("removedfile", file => {
    file_selected.value = null;
    formData.value.new_file = null;
    formData.value.file_id = null;
  });

  const dz = dropzone
  dropzone.on("maxfilesexceeded", function (file) {
    dz.removeAllFiles();
    dz.addFile(file);
  });
}

function updateLicense() {
  if (loading.value) return;

  loading.value = true;

  const form = new FormData();
  if (formData.value.state_id) {
    form.append('state_id', formData.value.state_id);
  }

  if (formData.value.expires_at) {
    form.append('expires_at', formData.value.expires_at);
  }

  if (formData.value.file_id) {
    form.append('file_id', formData.value.file_id);
  }

  if (file_name.value) {
      form.append('file_name', file_name.value);
  }

  if (file_selected.value) {
      form.append('new_file', file_selected.value);
  }

  form.append('_method', 'PATCH')

  setTimeout(() => {
    axios
      .post(updateLicenseUrl.value, form, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((response) => {
        console.log(response)
        if (response && response.status === 200) {
          emit('license-updated', { license: response.data, index: props.index });
          formData.value = _.cloneDeep(response.data);
          errors.value = {};
          showEditLicenseModal.value = false;
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.status === 422) {
          errors.value = error.response.data.errors;
          nextTick(() => {
            helpers.scrollToClass('has-error');
          })
        } else {
          Swal.fire({
            text: 'Unknown error occurred, please try again.',
            icon: 'error'
          })
        }
        console.log('AgentAndLeadLicensedStateListItem.vue @ updateLicense()', error);
      })
      .finally(() => {
        loading.value = false;
      })
  }, 500);
}

function confirmDelete() {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to undo this.',
    icon: 'warning',
    confirmButtonText: "Yes, I'm sure.",
    showCancelButton: true,
    confirmButtonColor: '#B91C1C',
    cancelButtonColor: '#899CC1'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteLicense();
    }
  })
}

function confirmRemoveLicenseFile() {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to undo this.',
    icon: 'warning',
    confirmButtonText: "Yes, I'm sure.",
    showCancelButton: true,
    confirmButtonColor: '#B91C1C',
    cancelButtonColor: '#899CC1'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteLicenseFile();
    }
  })
}

function deleteLicense() {
  loading.value = true;

  let target = isAgentModel.value ? `/agents/${props.modelId}/licenses/${formData.value.id}` : `/leads/${props.modelId}/licenses/${formData.value.id}`;
  axios
    .delete(target)
    .then((response) => {
      emit('license-deleted', { index: props.index });
    })
    .catch((error) => {
      Swal.fire({
        text: 'Unknown error occurred, please try again.',
        icon: 'error'
      });
    })
    .finally(() => {
      loading.value = false;
    })
}

function deleteLicenseFile() {
  loading.value = true;
  // if (!formData.value.file_name && !formData.value.file_path) {
  //   return;
  // }

  let target = isAgentModel.value ? `/agents/${props.modelId}/licenses/${formData.value.id}/file` : `/leads/${props.modelId}/licenses/${formData.value.id}/file`;
  axios
    .delete(target)
    .then((response) => {
      emit('license-file-deleted', { index: props.index });
    })
    .catch((error) => {
      Swal.fire({
        text: 'Unknown error occurred, please try again.',
        icon: 'error'
      });
    })
    .finally(() => {
      loading.value = false;
    })
}

function openEditLicenseModal() {
  showEditLicenseModal.value = true;
  file_name.value = props.license.file_name
  nextTick(() => {
    initDropzone()
  })
}

let [trigger, container] = usePopper({
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    },
  ],
})

function isInvalid(attribute) {
  return errors.value.hasOwnProperty(attribute);
}

function errorMessage(attribute) {
  if (errors.value.hasOwnProperty(attribute)) {
    return errors.value[attribute][0];
  }
}
</script>

<style scoped>

</style>