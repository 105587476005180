<template>
  <div v-if="currentStatus.id === dataSet.status_id">
    <!-- Header -->
    <div class="md:flex justify-between items-center mb-4 mt-4">
      <div class="flex items-center justify-between space-x-2">
        <h4 class="text-xl font-semibold">{{ currentStatus.name }}</h4>
      </div>
      <div v-if="this.canUpdate">
        <button @click.prevent="showCreateActionForm" v-if="viewingAssignedActions" class="btn-primary text-sm">Add Action</button>
        <button @click="viewingAssignedActions = true;" v-if="!viewingAssignedActions" class="btn-primary text-sm">View Actions for this status</button>
      </div>
    </div>
    <section v-if="viewingAssignedActions" class="overflow-hidden mt-8 flex flex-col">
      <div class="w-full px-1 pb-4">
        <div class="py-2 font-semibold flex items-center justify-start space-x-2 bg-amber-100 px-4 rounded-lg mb-4 border">
          <InfoIcon class="w-4 h-4"></InfoIcon>
          <span>Highlighted actions change the ownership of the lead.</span>
        </div>
        <div class="-my-2 overflow-x-auto sm:-mx-2">
          <div class="inline-block min-w-full py-2 align-middle md:px-2">
            <div class="overflow-hidden shadow border md:rounded-lg" v-if="tabFormData.actions.length">
              <table class="min-w-full divide-y divide-gray-300" >
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                  <th v-if="canUpdate" scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Reorder</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Step</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Action Name</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Hours After Last Action</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Hour Maximum</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Text Template</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Email Template</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Voicemail Script</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Ownership Change Type</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Ownership Change Model</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Grace Hours</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Grace Pull Type</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Grace Pull Name</th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Hide Previous History</th>
                  <th v-if="canUpdate" scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Options</th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr :class="{'bg-amber-100': data.changes_ownership}" v-for="(data, index) in tabFormData.actions" :key="`${data.action_id}_${index}`">
                  <td class="whitespace-nowrap font-medium text-gray-900 py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                    <el-tooltip placement="top" content="This action changes ownership of the lead." v-if="data.changes_ownership">
                      <button class="flex items-center p-0 btn-secondary justify-center">
                        <InfoIcon class="w-4 h-4"></InfoIcon>
                      </button></el-tooltip>
                  </td>
                  <td v-if="canUpdate" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                    <div class="flex space-x-1 items-center">
                      <button v-if="index !== 0" class="btn btn-secondary p-1 btn-sm" @click="moveUp(index, data.action_id)">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.0203 10.6869C12.8428 10.8644 12.565 10.8805 12.3693 10.7353L12.3132 10.6869L8.00008 6.37401L3.68697 10.6869C3.50946 10.8644 3.23168 10.8805 3.03594 10.7353L2.97986 10.6869C2.80235 10.5094 2.78621 10.2316 2.93145 10.0359L2.97986 9.97979L7.64653 5.31312C7.82404 5.13561 8.10181 5.11948 8.29756 5.26471L8.35363 5.31312L13.0203 9.97979C13.2156 10.1751 13.2156 10.4916 13.0203 10.6869Z" fill="black"/>
                        </svg>
                      </button>
                      <button v-if="(index + 1) < tabFormData.actions.length" class="btn btn-secondary p-1 btn-sm" @click="moveDown(index, data.action_id)">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.9797 5.3131C3.15721 5.13559 3.43499 5.11945 3.63073 5.26469L3.68681 5.3131L7.99992 9.62599L12.313 5.3131C12.4905 5.13559 12.7683 5.11945 12.9641 5.26469L13.0201 5.3131C13.1976 5.49061 13.2138 5.76839 13.0686 5.96413L13.0201 6.02021L8.35347 10.6869C8.17596 10.8644 7.89819 10.8805 7.70244 10.7353L7.64637 10.6869L2.9797 6.02021C2.78444 5.82495 2.78444 5.50837 2.9797 5.3131Z" fill="black"/>
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2  font-medium text-gray-900"><span>{{ index + 1 }}</span></td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.action.name }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.hours_after }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.hour_maximum }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900 first-letter:uppercase">{{ data.text_template?.name ?? '—' }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.email_template?.name ?? '—' }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.voicemail_script?.name ?? '—' }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.ownership_change_type ?? '—' }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.ownership_change_model_label ?? '—' }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.grace_hours ?? '0' }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.grace_pull_type ?? '—' }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.grace_pull_model_label ?? '—' }}</td>
                  <td class="whitespace-nowrap px-2 py-2  text-gray-900">{{ data.hide_previous_history == 1 ? 'Yes' : 'No' }}</td>
                  <td v-if="canUpdate" class="whitespace-nowrap px-2 py-2  text-gray-900 first-letter:uppercase">
                    <button @click="editAction(data, index)" class="btn-secondary text-xs p-2 mr-1">Edit</button>
                    <button class="btn-secondary text-xs p-2" @click="removeAction(data, index)">Remove</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="border border-2 rounded border-dashed flex items-center justify-center text-lg py-12 px-2">This status has no actions assigned</div>
          </div>
        </div>
      </div>
    </section>

    <!-- Edit Action Form -->
    <section v-else-if="editingAction" class="flex w-full items-center justify-center">
      <section class="w-[472px]">
        <div v-if="tabFormData.status_has_no_actions === false" class="pb-10">
          Editing Action
          <div>
            <label class="text-slate-500" for="action">Action</label>
            <div class="flex items-center justify-center space-x-4">
             <div class="w-full">
                 <ActionAutocompleteCreateField :lead-category="leadCategory" v-model="actionToUpdate.action" :active-model="actionToUpdate.action" :is-object="true"></ActionAutocompleteCreateField>
             </div>
              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden">+</span></button>
            </div>
            <div v-if="errors.action" class="error-message mt-1">{{errors.action}}</div>
          </div>

          <div class="mt-6">
            <label class="text-slate-500 flex items-center" for="hours_after">
                Hours After Last Action
            </label>
            <div class="flex items-center justify-center space-x-4">
              <div class="w-full">
                <el-select-v2
				                style="width: 100%;"
                  v-model="actionToUpdate.hours_after"
                  :options="hoursAfterOptions"
                  placeholder="Please select"
                />
                <div class="text-xs text-gray-500 mt-1">Any number of hours between 0 and {{ maximumAllowedHourInput }}</div>
              </div>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden">+</span></button>
            </div>
            <div v-if="errors.hours_after" class="error-message mt-1">{{errors.hours_after[0] ?? errors.hours_after }}</div>
          </div>

          <div class="mt-6">
            <label class="text-slate-500 flex items-center" for="hours_after">
		            Maximum Hours the Action can be Scheduled out
            </label>
            <div class="flex items-center justify-center space-x-4">
              <div class="w-full">
                <el-select-v2
				                style="width: 100%;"
                  v-model="actionToUpdate.hour_maximum"
                  :options="hoursAfterOptions"
                  placeholder="Please select"
                />
                <div class="text-xs text-gray-500 mt-1">Any number of hours between 0 and {{ maximumHourMaxInput }}</div>
                  <div v-if="user_is_admin" class=" w-full text-sm mt-2 text-left">
                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2.5 py-0.5 font-medium text-gray-800 inline-block w-full">
                        <InfoIcon class="w-3 h-3 mr-1 text-gray-500"></InfoIcon>
                        {{ selectedWorkingHoursText }}
                    </span>
                  </div>
              </div>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
            <div v-if="errors.hour_maximum" class="error-message mt-1">{{errors.hour_maximum}}</div>
          </div>

          <div class="mt-6">
            <label class="text-slate-500" for="text_template">Text Template (Optional)</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="actionToUpdate.text_template" class="input-field w-full rounded-md border px-4 py-2" name="text_template" id="">
                <option value="">—</option>
                <option v-for="template in availableTextTemplates" :key="template.id" :value="template">{{ template.name }}</option>
              </select>
              <button @click.prevent="$emit('show-modal', 'createTextTemplateModal')" class="btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden">+</span></button>
            </div>

            <button v-if="actionToUpdate.text_template" class="btn btn-primary text-sm mt-1" @click.prevent="showTextPreview(actionToUpdate.text_template)">
              <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 block md:hidden">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span class="hidden md:block">Preview text</span>
            </button>
          </div>

          <div class="mt-6">
            <label class="text-slate-500" for="email_template">Email Template (Optional)</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="actionToUpdate.email_template" class="input-field w-full rounded-md border px-4 py-2" name="email_template" id="">
                <option value="">—</option>
                <option v-for="template in availableEmailTemplates" :key="template.id" :value="template">{{ template.name }}</option>
              </select>
              <button @click.prevent="$emit('show-modal', 'createEmailTemplateModal')" class="btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden">+</span></button>
            </div>
            <button v-if="actionToUpdate.email_template" class="btn btn-primary text-sm mt-1" @click.prevent="showEmailPreview(actionToUpdate.email_template)">
              <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 block md:hidden">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span class="hidden md:block">Preview email</span>
            </button>
          </div>

          <div class="mt-6">
            <label class="text-slate-500" for="voicemail_script">Voicemail Script (Optional)</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="actionToUpdate.voicemail_script" class="input-field w-full rounded-md border px-4 py-2" name="voicemail_script" id="">
                <option value="">—</option>
                <option v-for="script in availableScripts" :key="script.id" :value="script">{{ script.name }}</option>
              </select>
              <button @click.prevent="$emit('show-modal', 'createVoicemailScriptModal')" class="btn btn-primary flex items-center justify-center whitespace-nowrap rounded-md text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden">+</span></button>
            </div>
            <button v-if="actionToUpdate.voicemail_script" class="btn btn-primary text-sm mt-1" @click.prevent="showVoicemailScriptPreview(actionToUpdate.voicemail_script)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 block md:hidden">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span class="hidden md:block">Preview Script</span>
            </button>
          </div>

          <!-- Lead Ownership Change -->
          <div v-if="isCommercialLead" class="mt-6">
            <label class="text-slate-500" for="email_template">Lead Ownership Change Type</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="actionToUpdate.ownership_change_type" class="input-field w-full rounded-md border px-4 py-2" name="ownership_change_type" id="">
                <option :value="null">Do not enforce</option>
                <option v-for="type in leadOwnershipChangeTypes" :value="type.value">{{ type.label }}</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>

          <div v-if="isCommercialLead && actionToUpdate.ownership_change_type" class="mt-6">
            <label class="text-slate-500" for="email_template">Ownership Change {{ ownership_change_type === 'User' ? 'Agent' : ownership_change_type }}</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="actionToUpdate.ownership_change_model_id" class="input-field w-full rounded-md border px-4 py-2" name="ownership_change_model_id" id="">
                <option :value="null">— None —</option>
                <option v-if="actionToUpdate.ownership_change_type === 'User'" v-for="model in ownershipChangeTypeModels" :value="model.id">{{ model.last_name }}, {{ model.first_name }}</option>
                <option v-if="actionToUpdate.ownership_change_type === 'Bucket'" v-for="model in ownershipChangeTypeModels" :value="model.id">{{ model.label }}</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>
          <!-- Lead Ownership Change -->

          <div v-if="isCommercialLead" class="mt-6">
            <label class="text-slate-500" for="email_template">Grace Hours</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="actionToUpdate.grace_hours" class="input-field w-full rounded-md border px-4 py-2" name="hours_after" id="">
                <option :value="null">Do not enforce</option>
                <option v-for="hour in [...Array(539).keys()]" :value="hour + 1">{{ hour + 1 }} hours</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>

          <div v-if="isCommercialLead && actionToUpdate.grace_hours" class="mt-6">
            <label class="text-slate-500" for="email_template">Grace Pull Type</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="actionToUpdate.grace_pull_type" class="input-field w-full rounded-md border px-4 py-2" name="hours_after" id="">
                <option v-for="type in gracePullTypes" :value="type.value">{{ type.label }}</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>

          <div v-if="isCommercialLead && actionToUpdate.grace_hours && actionToUpdate.grace_pull_type" class="mt-6">
            <label class="text-slate-500" for="email_template">Grace Pull {{ actionToUpdate.grace_pull_type }}</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="actionToUpdate.grace_pull_model_id" class="input-field w-full rounded-md border px-4 py-2" name="hours_after" id="">
                <option :value="null">— None —</option>
                <option v-for="model in gracePullTypeModels" :value="model.id">{{ model.label }}</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>

          <div v-if="isCommercialLead" class="mt-6">
            <label class="text-slate-500" for="email_template">Hide Previous History</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="actionToUpdate.hide_previous_history" class="input-field w-full rounded-md border px-4 py-2" name="hours_after" id="">
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>

          <div class="mt-6 md:flex items-center justify-center md:space-x-2 space-y-2 md:space-y-0">
            <button @click="closeUpdateForm" :disabled="loading" class="btn-secondary text-sm">Cancel</button>
            <button @click="updateAction" :disabled="loading" class="btn-primary flex w-full items-center justify-center whitespace-nowrap rounded-md text-sm">Update</button>
          </div>
        </div>
      </section>
    </section>
    <!-- End Editing Action Form -->

    <!-- Add Action Form -->
    <section v-else class="flex w-full items-center justify-center">
      <section class="w-[472px]">
        <div class="pb-10">
          <div>
            <label class="text-slate-500" for="action">Action</label>
            <div class="flex items-center justify-center space-x-4">
                <div class="w-full">
                  <ActionAutocompleteCreateField :lead-category="leadCategory" v-model="action" :active-model="action" :is-object="true"></ActionAutocompleteCreateField>
                </div>
                <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
            <div v-if="errors.action" class="error-message mt-1">{{errors.action}}</div>
          </div>

          <div class="mt-6">
            <label class="text-slate-500 flex items-center" for="hours_after">
                Hours After Last Action
            </label>
            <div class="flex items-center justify-center space-x-4">
              <div class="w-full">
                <el-select-v2
	                style="width: 100%;"
                  v-model="hours_after"
                  :options="hoursAfterOptions"
                  placeholder="Please select"
                />
                <div class="text-xs text-gray-500 mt-1">Any number of hours between 0 and {{ maximumAllowedHourInput }}</div>
              </div>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
            <div v-if="errors.hours_after" class="error-message mt-1">{{errors.hours_after}}</div>
          </div>

          <div class="mt-6">
            <label class="text-slate-500 flex items-center" for="hours_after">
		            Maximum Hours the Action can be Scheduled out
            </label>
            <div class="flex items-center justify-center space-x-4">
              <div class="w-full">
                <el-select-v2
				                style="width: 100%;"
                  v-model="hour_maximum"
                  :options="hoursAfterOptions"
                  placeholder="Please select"
                />
                <div class="text-xs text-gray-500 mt-1">Any number of hours between 0 and {{ maximumHourMaxInput }}</div>

                <div v-if="user_is_admin" class=" w-full text-sm mt-2 text-left">
                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2.5 py-0.5 font-medium text-gray-800 inline-block w-full">
                        <InfoIcon class="w-3 h-3 mr-1 text-gray-500"></InfoIcon>
                        {{ selectedWorkingHoursText }}
                    </span>
                </div>
              </div>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
            <div v-if="errors.hour_maximum" class="error-message mt-1">{{errors.hour_maximum}}</div>
          </div>

          <div class="mt-6">
            <label class="text-slate-500" for="text_template">Text Template (Optional)</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="text_template" class="input-field w-full rounded-md border px-4 py-2" name="text_template" id="">
                <option value="">—</option>
                <option v-for="template in availableTextTemplates" :key="template.id" :value="template">{{ template.name }}</option>
              </select>
              <button @click.prevent="$emit('show-modal', 'createTextTemplateModal')" class="btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden">+</span></button>
            </div>
            <button v-if="text_template" class="btn btn-primary text-sm mt-1" @click.prevent="showTextPreview(text_template)">
              <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 block md:hidden">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span class="hidden md:block">Preview text</span>
            </button>
          </div>

          <div class="mt-6">
            <label class="text-slate-500" for="email_template">Email Template (Optional)</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="email_template" class="input-field w-full rounded-md border px-4 py-2" name="email_template" id="">
                <option value="">—</option>
                <option v-for="template in availableEmailTemplates" :key="template.id" :value="template">{{ template.name }}</option>
              </select>

              <button @click.prevent="$emit('show-modal', 'createEmailTemplateModal')" class="btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden">+</span></button>
            </div>
            <button v-if="email_template" class="btn btn-primary text-sm mt-1" @click.prevent="showEmailPreview(email_template)">
              <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 block md:hidden">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span class="hidden md:block">Preview Email</span>
            </button>
          </div>

          <div class="mt-6">
            <label class="text-slate-500" for="voicemail_script">Voicemail Script (Optional)</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="voicemail_script" class="input-field w-full rounded-md border px-4 py-2" name="voicemail_script" id="">
                <option value="">—</option>
                <option v-for="script in availableScripts" :key="script.id" :value="script">{{ script.name }}</option>
              </select>
              <button @click.prevent="$emit('show-modal', 'createVoicemailScriptModal')" class="btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden">+</span></button>
            </div>
            <button v-if="voicemail_script" class="btn btn-primary text-sm mt-1" @click.prevent="showVoicemailScriptPreview(voicemail_script)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 block md:hidden">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span class="hidden md:block">Preview Script</span>
            </button>
          </div>
          <!-- Lead Ownership Change -->
          <div v-if="isCommercialLead" class="mt-6">
            <label class="text-slate-500" for="email_template">Lead Ownership Change Type</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="ownership_change_type" class="input-field w-full rounded-md border px-4 py-2" name="ownership_change_type" id="">
                <option :value="null">Do not enforce</option>
                <option v-for="type in leadOwnershipChangeTypes" :value="type.value">{{ type.label }}</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>

          <div v-if="ownership_change_type" class="mt-6">
            <label class="text-slate-500" for="email_template">Ownership Change {{ ownership_change_type === 'User' ? 'Agent' : ownership_change_type }}</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="ownership_change_model_id" class="input-field w-full rounded-md border px-4 py-2" name="ownership_change_model_id" id="">
                <option :value="null">— None —</option>
                <option v-if="ownership_change_type === 'User'" v-for="model in ownershipChangeTypeModels" :value="model.id">{{ model.last_name }}, {{ model.first_name }}</option>
                <option v-if="ownership_change_type === 'Bucket'" v-for="model in ownershipChangeTypeModels" :value="model.id">{{ model.label }}</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>
          <!-- Lead Ownership Change -->

          <div v-if="isCommercialLead" class="mt-6">
            <label class="text-slate-500" for="email_template">Grace Hours</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="grace_hours" class="input-field w-full rounded-md border px-4 py-2" name="hours_after" id="">
                <option :value="null">Do not enforce</option>
                <option v-for="hour in [...Array(539).keys()]" :value="hour + 1">{{ hour + 1 }} hours</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>

          <div v-if="grace_hours" class="mt-6">
            <label class="text-slate-500" for="email_template">Grace Pull Type</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="grace_pull_type" class="input-field w-full rounded-md border px-4 py-2" name="hours_after" id="">
                <option v-for="type in gracePullTypes" :value="type.value">{{ type.label }}</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>

          <div v-if="grace_hours && grace_pull_type" class="mt-6">
            <label class="text-slate-500" for="email_template">Grace Pull {{ grace_pull_type === 'User' ? 'Agent' : grace_pull_type }}</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="grace_pull_model_id" class="input-field w-full rounded-md border px-4 py-2" name="hours_after" id="">
                <option :value="null">— None —</option>
                <option v-for="model in gracePullTypeModels" :value="model.id">{{ model.label }}</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>

          <div v-if="isCommercialLead" class="mt-6">
            <label class="text-slate-500" for="email_template">Hide Previous History</label>
            <div class="flex items-center justify-center space-x-4">
              <select v-model="hide_previous_history" class="input-field w-full rounded-md border px-4 py-2" name="hours_after" id="">
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>

              <button class="opacity-0 pointer-events-none btn-primary whitespace-nowrap text-sm"><span class="hidden md:block">Create new</span><span class="block md:hidden"></span></button>
            </div>
          </div>

          <div class="mt-6 flex items-center justify-center space-x-4">
            <button @click="appendAction" class="btn-primary w-full whitespace-nowrap">Submit</button>
          </div>
        </div>
      </section>
    </section>

    <Modal :show="showTextPreviewModal" @onClose="closeTextPreviewModal" :unmount="true">
      <TextTemplatePreview :text-template-preview="textTemplatePreview" :loading="textPreviewLoading"></TextTemplatePreview>
    </Modal>

    <Modal :show="showEmailPreviewModal" @onClose="closeEmailPreviewModal" :unmount="true">
      <EmailTemplatePreview :emailTemplatePreview="emailTemplatePreview" :loading="emailPreviewLoading"></EmailTemplatePreview>
    </Modal>

    <Modal :show="showVoicemailScriptPreviewModal" @onClose="closeVoicemailScriptPreviewModal" :unmount="true">
      <VoicemailScriptPreview :voicemailScriptPreview="voicemailScriptPreview" :loading="voicemailScriptPreviewLoading"></VoicemailScriptPreview>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/Components/Modal.vue";
import TextTemplatePreview from "@/Components/partials/TextTemplatePreview.vue";
import EmailTemplatePreview from "@/Components/partials/EmailTemplatePreview.vue";
import VoicemailScriptPreview from "@/Components/partials/VoicemailScriptPreview.vue";
import axios from "axios";
import _ from "lodash";
import helpers from "../../helper";
import ActionScheduleEditActionModal from "./ActionScheduleEditActionModal.vue";
import {ElTooltip, ElSelectV2} from 'element-plus';
import ActionAutocompleteCreateField from "@/Components/ActionAutocompleteCreateField.vue";
import InfoIcon from "@/Components/InfoIcon.vue";

export default {
  name: "ActionScheduleStatusTab",

  components: {
    TextTemplatePreview,
    EmailTemplatePreview,
    VoicemailScriptPreview,
    Modal,
    ActionScheduleEditActionModal,
    ElTooltip,
    ActionAutocompleteCreateField,
    InfoIcon,
    ElSelectV2
  },

  props: {
    leadCategory: {required: true, type: String,},
    currentStatus: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true
    },

    dataSet: {
      type: Object,
      required: true,
    },

    availableActions: {
      type: Array,
      required: true,
    },

    availableTextTemplates: {
      type: Array,
      required: true,
    },

    availableEmailTemplates: {
      type: Array,
      required: true,
    },

    availableScripts: {
      type: Array,
      required: true,
    },

    createdTextTemplate: {
      type: Object,
      default: null,
    },

    createdEmailTemplate: {
      type: Object,
      default: null,
    },

    createdAction: {
      type: Object,
      default: null,
    },

    createdScript: {
      type: Object,
      default: null,
    },

    user_is_admin: {
      type: Boolean,
      default: false,
    },

    leadOwnershipChangeTypes: {
      type: Array,
      default: () => [
        {value: 'Bucket', 'label': 'Bucket'},
        // {value: 'Round Robin', 'label': 'Round Robin'},
        {value: 'User', 'label': 'Agent'},
      ]
    },

    gracePullTypes: {
      type: Array,
      default: () => [
        {value: 'Bucket', 'label': 'Bucket'},
        // {value: 'Round Robin', 'label': 'Round Robin'},
        {value: 'User', 'label': 'Agent'},
      ]
    },

    buckets: {
      type: Array,
      default: () => [],
    },

    agents: {
      type: Array,
      default: () => [],
    },

    selectedWorkingHoursText: {
      type: String,
      default: '',
    },
  },

  emits: ['updated-data', 'show-modal'],

  inject: {
    canUpdate: {
      default: true
    },
  },

  data() {
    return {
      tabFormData: _.cloneDeep(this.dataSet),

      action: null,
      hours_after: null,
      hour_maximum: null,
      text_template: null,
      text_template_id: null,
      email_template: null,
      voicemail_script: null,

      ownership_change_type: null,
      ownership_change_model_id: null,

      grace_hours: null,
      grace_pull_type: this.gracePullTypes[0]?.value,
      grace_pull_model_id: null,

      hide_previous_history: 0,

      viewingAssignedActions: true,

      errors: {
        action: null,
        hours_after: null,
      },

      actionToUpdate: null,
      editingAction: false,
      indexOfActionToUpdate: null,
      loading: false,

      showTextPreviewModal: false,
      textTemplatePreview: null,
      textPreviewLoading: false,

      showEmailPreviewModal: false,
      emailTemplatePreview: null,
      emailPreviewLoading: false,

      showVoicemailScriptPreviewModal: false,
      voicemailScriptPreview: null,
      voicemailScriptPreviewLoading: false,

      maximumAllowedHourInput: 8760,
      maximumHourMaxInput: 8760,
    }
  },

  computed: {
    ownershipChangeTypeModels() {
      if (this.editingAction) {
        if (this.actionToUpdate.ownership_change_type === 'Bucket') {
          return this.buckets;
        }

        if (this.actionToUpdate.ownership_change_type === 'User') {
          return this.agents;
        }
      } else {
        if (this.ownership_change_type === 'Bucket') {
          return this.buckets;
        }

        if (this.ownership_change_type === 'User') {
          return this.agents;
        }
      }

      return [];
    },

    gracePullTypeModels() {
      if (this.editingAction) {
        if (this.actionToUpdate.grace_pull_type === 'Bucket') {
          return this.buckets;
        }

        if (this.actionToUpdate.grace_pull_type === 'User') {
          return this.agents;
        }
      } else {
        if (this.grace_pull_type === 'Bucket') {
          return this.buckets;
        }

        if (this.grace_pull_type === 'User') {
          return this.agents;
        }
      }

      return [];
    },

	  hoursAfterOptions() {
      return Array.from({ length: 8761 }).map((_, idx) => ({
        value: idx,
        label: `${idx} Hours`,
      }))
	  },

    modelName() {
      switch (this.leadCategory) {
        case 'recruitment':
          return 'Recruitment Source';
        case 'commercial':
        default:
          return 'Lead Source';
      }
    },
    isCommercialLead() { // Commercial Lead: classic lead. The other type is a recruitment lead
      return !this.leadCategory || this.leadCategory !== 'recruitment';
    },
  },

  watch: {
    tabFormData: {
      handler(newValue, oldValue) {
        this.$emit('updated-data', {
          index: this.index,
          data: newValue
        });
      },
      deep: true
    },

    createdAction(newValue, oldValue) {
      // When action is created from modal, update current action in form, for current status tab
      if (newValue !== null && this.currentStatus.id === this.dataSet.status_id) {
        if (this.actionToUpdate) {
          this.actionToUpdate.action = newValue;
        }

        this.action = newValue;
      }
    },

    createdTextTemplate(newValue, oldValue) {
      // When action is created from modal, update current action in form, for current status tab
      if (newValue !== null && this.currentStatus.id === this.dataSet.status_id) {
        if (this.actionToUpdate) {
          this.actionToUpdate.text_template = newValue;
        }

        this.text_template = newValue;
      }
    },

    createdEmailTemplate(newValue, oldValue) {
      // When action is created from modal, update current action in form, for current status tab
      if (newValue !== null && this.currentStatus.id === this.dataSet.status_id) {
        if (this.actionToUpdate) {
          this.actionToUpdate.email_template = newValue;
        }

        this.email_template = newValue;
      }
    },

    createdScript(newValue, oldValue) {
      // When action is created from modal, update current action in form, for current status tab
      if (newValue !== null && this.currentStatus.id === this.dataSet.status_id) {
        if (this.actionToUpdate) {
          this.actionToUpdate.voicemail_script = newValue;
        }

        this.voicemail_script = newValue;
      }
    },

    ownership_change_type(newValue, oldValue) {
      this.ownership_change_model_id = null;
    },

    grace_pull_type(newValue, oldValue) {
      this.grace_pull_model_id = null;
    },

    grace_hours(newValue, oldValue) {
      if (newValue === null) {
        this.grace_pull_model_id = null;
        this.grace_pull_type = null;
      }
    },
  },

  methods: {
    appendAction() {
      if (this.action === null || this.action === '') {
        this.errors.action = 'Please select an action.';
        return this.$nextTick(() => {
          helpers.scrollToClass('error-message');
        })
      } else {
        this.errors.action = null;
      }

      if (this.hours_after === null || this.hours_after === '') {
        this.errors.hours_after = 'Please select hours after.';
        return this.$nextTick(() => {
          helpers.scrollToClass('error-message');
        })
      } else {
        this.errors.hours_after = null;
      }

      if (this.hours_after < 0 || this.hours_after > this.maximumAllowedHourInput) {
        this.errors.hours_after = 'Please input valid hours after number.';
        return this.$nextTick(() => {
          helpers.scrollToClass('error-message');
        })
      } else {
        this.errors.hours_after = null;
      }

      if (this.hour_maximum < 0 || this.hour_maximum > this.maximumHourMaxInput) {
        this.errors.hour_maximum = 'Please input valid hour maximum.';
        return this.$nextTick(() => {
          helpers.scrollToClass('error-message');
        })
      } else {
        this.errors.hour_maximum = null;
      }

      let ownershipChangeModelLabel = null;
      if (this.ownership_change_type === 'User' && this.ownership_change_model_id !== null) {
        let foundAgent = this.ownershipChangeTypeModels.find(i => i.id === this.ownership_change_model_id);
        ownershipChangeModelLabel = foundAgent.label;
      } else if (this.ownership_change_type === 'Bucket' && this.ownership_change_model_id !== null) {
        let foundBucket = this.ownershipChangeTypeModels.find(i => i.id === this.ownership_change_model_id);
        ownershipChangeModelLabel = foundBucket.label;
      }

      let gracePullModelLabel = null;
      if (this.grace_hours && this.grace_pull_type === 'User' && this.grace_pull_model_id !== null) {
        let foundAgent = this.gracePullTypeModels.find(i => i.id === this.grace_pull_model_id);
        gracePullModelLabel = foundAgent.label;
      } else if (this.grace_hours && this.grace_pull_type === 'Bucket' && this.grace_pull_model_id !== null) {
        let foundBucket = this.gracePullTypeModels.find(i => i.id === this.grace_pull_model_id);
        gracePullModelLabel = foundBucket.label;
      }

      this.tabFormData.actions.push({
        action: this.action,
        action_id: this.action.id,
        hours_after: this.hours_after,
        hour_maximum: this.hour_maximum,
        text_template: this.text_template,
        email_template: this.email_template,
        voicemail_script: this.voicemail_script,
        ownership_change_type: this.ownership_change_type,
        ownership_change_model_id: this.ownership_change_type !== null ? this.ownership_change_model_id : null,
        ownership_change_model_label: this.ownership_change_type !== null ? ownershipChangeModelLabel : null,
        grace_hours: this.grace_hours,
        grace_pull_type: this.grace_hours !== null ? this.grace_pull_type : null,
        grace_pull_model_id: this.grace_hours !== null ? this.grace_pull_model_id : null,
        grace_pull_model_label: this.grace_hours !== null ? gracePullModelLabel : null,
        hide_previous_history: this.hide_previous_history,
        changes_ownership: this.ownership_change_type !== null && this.ownership_change_model_id !== null,
      })

      this.resetForm();
      this.viewingAssignedActions = true;
    },

    resetForm() {
      this.action = null;
      this.hours_after = null;
      this.hour_maximum = null;
      this.text_template = null;
      this.email_template = null;
      this.voicemail_script = null;
      this.ownership_change_type = null;
      this.grace_hours = null;
      this.hide_previous_history = 0;

      // Clean up errors
      this.errors = {
        action: null,
        hours_after: null,
        hour_maximum: null,
      }
    },

    removeAction(data, index) {
      this.tabFormData.actions.splice(index, 1);
    },

    moveUp(index, action_id) {
      // let index = this.tabFormData.actions.findIndex(e => e.action_id === action_id);

      if (index > 0) {
        let el = this.tabFormData.actions[index];
        this.tabFormData.actions[index] = this.tabFormData.actions[index - 1];
        this.tabFormData.actions[index - 1] = el;
      }
    },

    moveDown(index, action_id) {
      // let index = this.tabFormData.actions.findIndex(e => e.action_id === action_id);

      if (index !== -1 && index < this.tabFormData.actions.length - 1) {
        let el = this.tabFormData.actions[index];
        this.tabFormData.actions[index] = this.tabFormData.actions[index + 1];
        this.tabFormData.actions[index + 1] = el;
      }
    },

    editAction(action, index) {
      this.viewingAssignedActions = false;
      this.editingAction = true;
      this.actionToUpdate = _.cloneDeep(action);
      this.indexOfActionToUpdate = index;
    },

    updateAction() {
      if (this.loading) return;
      this.loading = true;


        if (this.actionToUpdate.hasOwnProperty('action_schedule_map_id') && this.actionToUpdate.action_schedule_map_id !== null) {
          setTimeout(() => {
          // We want to hit the server, because we are updating existing action schedule map
          axios
            .put(route('action-schedule-maps.update', {
              action_schedule_map: this.actionToUpdate.action_schedule_map_id,
              lead_category: this.leadCategory
            }), this.actionToUpdate)
            .then((response) => {
              this.tabFormData.actions[this.indexOfActionToUpdate] = response.data;
              this.closeUpdateForm();
            })
            .catch((error) => {
              if (error.response && error.response.data && error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.$nextTick(() => {
                  helpers.scrollToClass('error-message');
                })
              } else {
                Swal.fire({
                  text: 'Unknown error occurred, please try again.',
                  icon: 'error'
                });
              }
              console.log("ActionScheduleStatusTab - updateAction()", error.response);
            })
            .finally(() => {
              this.loading = false;
            })
          }, 500)
        } else {
          this.loading = false;
          if (this.actionToUpdate.action === null || this.actionToUpdate.action === '') {
            this.errors.action = ['Please select an action.'];
            return this.$nextTick(() => {
              helpers.scrollToClass('error-message');
            })
          } else {
            this.errors.action = null;
          }

          if (this.actionToUpdate.hours_after === null || this.actionToUpdate.hours_after === '') {
            this.errors.hours_after = ['Please select hours after.'];
            return this.$nextTick(() => {
              helpers.scrollToClass('error-message');
            })
          } else {
            this.errors.hours_after = null;
          }

          if (this.actionToUpdate.hours_after < 0 || this.actionToUpdate.hours_after > this.maximumAllowedHourInput) {
            this.errors.hours_after = ['Please input valid hours after number.'];
            return this.$nextTick(() => {
              helpers.scrollToClass('error-message');
            })
          } else {
            this.errors.hours_after = null;
          }

          if (this.hour_maximum < 0 || this.hour_maximum > this.maximumHourMaxInput) {
            this.errors.hour_maximum = ['Please input valid hour maximum.'];
            return this.$nextTick(() => {
              helpers.scrollToClass('error-message');
            })
          } else {
            this.errors.hour_maximum = null;
          }

          this.actionToUpdate.changes_ownership = this.actionToUpdate.ownership_change_type !== null && this.actionToUpdate.ownership_change_model_id !== null;
          this.tabFormData.actions[this.indexOfActionToUpdate] = this.actionToUpdate;
          this.errors = {};
          this.closeUpdateForm();
        }
    },

    closeUpdateForm() {
      this.viewingAssignedActions = true;
      this.editingAction = false;
      this.actionToUpdate = null;
      this.indexOfActionToUpdate = null;
    },

    showCreateActionForm() {
      this.viewingAssignedActions = false;
      this.editingAction = false;
      this.actionToUpdate = null;
      this.indexOfActionToUpdate = null;
      // this.resetForm();
    },

    showTextPreview(template) {
      if (this.textPreviewLoading) return;
      this.textPreviewLoading = true;
      this.showTextPreviewModal = true;
      this.textTemplatePreview = null;

      setTimeout(() => {
        axios
        .get(route('text_template_preview.show', template.id))
        .then(response => {
          if (response.data) {
            this.textTemplatePreview = response.data;
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.textPreviewLoading = false;
        })
      }, 500)
    },

    closeTextPreviewModal() {
      this.showTextPreviewModal = false;
    },

    showEmailPreview(template) {
      if (this.emailPreviewLoading) return;
      this.emailPreviewLoading = true;
      this.showEmailPreviewModal = true;
      this.emailTemplatePreview = null;

      setTimeout(() => {
        axios
        .get(route('email_template_preview.show', template.id))
        .then(response => {
          if (response.data) {
            this.emailTemplatePreview = response.data;
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.emailPreviewLoading = false;
        })
      }, 500)
    },

    closeEmailPreviewModal() {
      this.showEmailPreviewModal = false;
    },

    showVoicemailScriptPreview(script) {
      if (this.voicemailScriptPreviewLoading) return;
      this.voicemailScriptPreviewLoading = true;
      this.showVoicemailScriptPreviewModal = true;
      this.voicemailScriptPreview = null;

      setTimeout(() => {
        axios
        .get(route('voicemail_script_preview.show', script.id))
        .then(response => {
          if (response.data) {
            this.voicemailScriptPreview = response.data;
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.voicemailScriptPreviewLoading = false;
        })
      }, 500)
    },

    closeVoicemailScriptPreviewModal() {
      this.showVoicemailScriptPreviewModal = false;
    },
  },
}
</script>

<style>

</style>
