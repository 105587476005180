<script setup>
import {computed, onMounted, ref} from "vue";

const props = defineProps({
  agentId: {
    type: Number,
    required: true,
  },
});

const loading = ref(0);
const hasCredentials = ref(false);
const previousMigration = ref(null);
const noticeMessage = ref('');
const buttonEnabled = computed(() => {
  return !loading.value && true && !previousMigration.value && hasCredentials.value;
});

onMounted(() => {
  fetchPreviousMigration();
});

const fetchPreviousMigration = function() {
  loading.value++;
  axios
    .get(route('agents.hst-migration.status', {agent: props.agentId}))
    .then(r => {
      hasCredentials.value = r.data.has_credentials;
      if (!hasCredentials.value) {
        noticeMessage.value = "Agent doesn't have HST credentials";
      }
      previousMigration.value = r.data.active;
      if (previousMigration.value) {
        noticeMessage.value = "Agent has already been migrated or a migration for this agent is in progress";
      }
    })
    .finally(() => {
      loading.value--;
    });
}

const startMigration = function() {
  if (previousMigration.value) return;
  loading.value++;
  axios.post(route('agents.hst-migration.migrate', {agent: props.agentId}))
    .then(r => {
      if (r.data?.success) {
        previousMigration.value = true;
        $notify.success({
          title: 'HST Migration Started',
          message: 'The migration is running on the background, you will be notified once it is completed',
        })
      } else {
        if (r.data?.error) {
          noticeMessage.value = r.data.error;
        }
      }
    })
    .catch(e => {
      $notify.error({message: 'An error has occurred'});
    })
    .finally(() => {
      loading.value--;
    })
}
</script>

<template>
  <div>
    <button class="btn btn-primary" :disabled="!buttonEnabled"
      :class="[{'opacity-30': !buttonEnabled}]"
      @click.prevent="startMigration"
    >Migrate Agent's HST Data</button>
    <div v-if="noticeMessage" class="mt-4">
      <span class="text-sm text-orange-700 px-1">{{ noticeMessage }}</span>
    </div>
  </div>
</template>

<style scoped>
</style>