<script setup>
import {useForm} from "@inertiajs/inertia-vue3";
import {ElOption, ElSelect} from 'element-plus';
import {computed, onMounted, ref} from "vue";
import {useErrorHandlerAxios} from "@/utils/useErrorHandlerAxios";
import InputError from "@/Components/InputError.vue";

const {handleErrors, isInvalid, errorMessage, clearErrors, errors} = useErrorHandlerAxios();

const searchCommissionsRef = ref(null);

const props = defineProps({
  modelValue: null,
  activeModel: null,
  allowCreate: {
    type: Boolean,
    default: true,
  },
  isObject: {
    type: Boolean,
    default: false,
  },
})

onMounted(async () => {
  if (props.activeModel !== null) {
    commissionList.value.push({commission: props.activeModel});
  }
})

const searchingCommission = ref(false);
const formData = useForm({
  commission: null
})
const commissionList = ref([]);
const emit = defineEmits(['update:modelValue'])

function searchActions(queryString) {
  if (!queryString) return;
  axios
    .get(route('api.internal.agent-carrier-commission.search', {search: queryString}))
    .then(response => {
      commissionList.value = response.data;
      updateModelValue(null);
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      searchingCommission.value = false;
    })
}

function updateModelValue(value) {
  if (!value) {
    return;
  }
  emit('update:modelValue', value);
}

const creatingCommission = ref(false)

function createCommission(value = null) {
  if (cannotCreateCommission.value) return;
  let val = searchCommissionsRef.value.query;
  creatingCommission.value = true;
  if (!commissionList.value.find(i => i.commission === val)) {
    commissionList.value.push({commission: val});
  }
  updateModelValue(val);
  creatingCommission.value = false;
  searchCommissionsRef.value.blur();
}

const cannotCreateCommission = computed(() => {
  return props.allowCreate
    && (searchingCommission.value
      || creatingCommission.value
      || ['', null].includes(searchCommissionsRef.value?.query)
      || commissionList.value.some(action => action.name === searchCommissionsRef.value?.query)
    );
})
</script>

<template>
  <el-select
    ref="searchCommissionsRef"
    v-model="modelValue"
    :loading="searchingCommission"
    :remote-method="searchActions"
    :reserve-keyword="true"
    :allow-create="true"
    class="w-full"
    filterable
    placeholder="Search commissions or create one if none found"
    remote
    @change="updateModelValue($event)"
    @keyup.enter="createCommission()"
  >
    <el-option
      v-if="commissionList.length"
      v-for="commission in commissionList"
      :key="`commission_${commission.commission}`"
      :label="`${commission.commission}`"
      :value="commission.commission"
    ></el-option>
  </el-select>
  <InputError :message="errorMessage('name')"></InputError>
</template>

<style scoped></style>
