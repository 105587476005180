<script setup>
import {ElSwitch} from "element-plus";
import {useForm} from "@inertiajs/inertia-vue3";
import {ref} from "vue";

const props = defineProps({
    agency_info: {type: Object, required: true},
});

const formData = useForm({
    commissions_enabled: props.agency_info.commissions_enabled,
});

const loading = ref(false);

const submitForm = function(){
  if (loading.value) return;
  loading.value = true;
  formData.post(route('agencies.commissions-toggle', props.agency_info.id), {
    preserveScroll: true,
    preserveState: true,
    only: ['agency_info', 'errors'],
    onSuccess: () => {
      $notify.success({
        message: 'Saved',
        showClose: true,
      });
    },
    onError: () => {
    },
    onFinish: () => {
      loading.value = false;
    }
  });
};
</script>

<template>
<div class="p-4">
  <form method="POST" @submit.prevent="submitForm">
    <div class="flex space-x-4 items-center my-2">
      <el-switch v-model="formData.commissions_enabled" id="commissions_enabled" />
      <label class="cursor-pointer text-md" for="commissions_enabled">TopBroker Commission Features Enabled</label>
    </div>
    <div class="mt-4">
      <button class="btn btn-primary" :class="{'opacity-30': loading}" :disabled="loading" type="submit">Submit</button>
    </div>
  </form>
</div>
</template>