<template>
  <tr v-show="editMode">
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
      <input type="text" class="input-field  whitespace-nowrap" v-model="formData.name">
      <div v-if="isInvalid('name') || isInvalid('url')" class="error-message mt-1">{{ errorMessage('name') }}</div>
    </td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
      <input type="text" ref="urlinput" class="input-field  whitespace-nowrap" v-model="formData.url">
      <div v-if="isInvalid('url') || isInvalid('name')" class="error-message mt-1">{{ errorMessage('url') }}</div>
    </td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
      <button class="btn-primary mr-2" @click="updateReason">Save</button>
      <button class="btn-secondary text-sm " @click="cancel">Cancel</button>
      <div v-if="isInvalid('name') || isInvalid('url')" class="error-message mt-1">&nbsp;</div>
    </td>
  </tr>
  <tr v-show="!editMode">
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"><a target="_blank" :href="bookmark.url">{{ bookmark.name }}</a></td>
    <td class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6"><a target="_blank" :href="bookmark.url">{{ bookmark.url }}</a></td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
      <button class="btn btn-sm btn-secondary text-sm  mr-2" @click="editMode = true">Edit</button>
      <button class="btn-primary bg-red-500 text-white hover:bg-red-500/90" @click="confirmDelete">Delete</button>
    </td>
  </tr>
</template>

<script>
import _ from "lodash";
import helpers from "../../helper";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";

export default {
  name: "AgencyBookmarkListItem",

  mixins: [serverValidationErrorsMixin],

  props: {
    agencyId: {
      type: Number,
      required: true,
    },
    bookmark: {
      type: Object,
      required: true,
    }
  },

  emits: ['bookmark-updated', 'bookmark-deleted'],

  data() {
    return {
      formData: _.cloneDeep(this.bookmark),
      editMode: false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      new Cleave(this.$refs.urlinput, {
        prefix: 'https://',
        noImmediatePrefix: true,
      });

      this.$refs.urlinput.addEventListener('paste', this.handlePaste);
    })
  },

  beforeUnmount() {
    this.$refs.urlinput.removeEventListener('paste', this.handlePaste);
  },

  methods: {
    updateReason() {
      this.loading = false;
      // AgencyDeadReasonController@update
      axios
        .put(`/agencies/${this.agencyId}/bookmarks/${this.bookmark.id}`, {
          name: this.formData.name,
          url: this.formData.url,
        })
        .then((response) => {
          this.$emit('bookmark-updated', { bookmark: response.data });
          this.formData = response.data;
          this.editMode = false;
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.status === 422) {
            this.errors = error.response.data.errors;
            this.$nextTick(() => {
              helpers.scrollToClass('has-error');
            })
          } else {
            Swal.fire({
              text: 'Unknown error occurred, please try again.',
              icon: 'error'
            })
          }
          console.log("AgencyBookmarksListItem - handleErroredSubmission", error);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    confirmDelete() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to undo this.',
        icon: 'warning',
        confirmButtonText: "Yes, I'm sure.",
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteBookmark();
        }
      })
    },

    deleteBookmark() {
      this.loading = true;
      // AgencyDeadReasonController@destroy
      axios
        .delete(`/agencies/${this.agencyId}/bookmarks/${this.formData.id}`)
        .then((response) => {
          this.$emit('bookmark-deleted');
        })
        .catch((error) => {
          Swal.fire({
            text: 'Unknown error occurred, please try again.',
            icon: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    cancel() {
      this.editMode = false;
    },

    handlePaste(event) {
    // Get the pasted text from the event
      const pastedText = event.clipboardData.getData('text/plain');

    // Remove "https://" and "http://" from the pasted text
      const modifiedText = pastedText.replace(/(https?:\/\/)/g, '');

    // Prevent the default paste action
      event.preventDefault();

    // Insert the modified text into the textarea
      document.execCommand('insertText', false, modifiedText);
    }
  },
}
</script>

<style scoped>

</style>
