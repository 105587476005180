<script setup>
import LeadLoginForm from "@/Components/partials/LeadLoginForm.vue";
import { Head, useForm } from '@inertiajs/inertia-vue3';
import Pagination from "@/Components/Pagination.vue";
import {ref, computed, nextTick} from "vue";
import helpers from "@/helper";
import Modal from "@/Components/Modal.vue";
import {Inertia} from "@inertiajs/inertia";
import {PlusCircleIcon} from "@heroicons/vue/outline";
import axios from 'axios';
import { LockClosedIcon } from '@heroicons/vue/solid';


const props = defineProps({
    lead: Object,
    logins: Object
});

function showCreateMessageModal() {
  messageModalVisible.value = true;
}

const messageModalVisible = ref(false);
const createMode = ref(true);

const loginFormName = computed(() => {
  let mode = formData.createMode ? 'Add Login' : 'Edit Login';
  return `${mode}`;
})

function submitForm() {
  if (formData.loading) return;
  formData.loading = false;
  setTimeout(() => {
    if (formData.createMode) {
      return createLogin();
    }
    updateLogin();
  }, 500)
}
function createLogin() {
  formData.post(route('leads.login.store', props.lead.id),
    {
      preserveScroll: true,
      onSuccess: () => {
        $notify.success({
        message: 'Login added',
        showClose: true,
      }),
        formData.reset()
        messageModalVisible.value = false
      },
      onError: (error) => {
        console.log(error)
        nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      }
    },
  );
}
function updateLogin() {
  formData.post(route('leads.login.update', [props.lead.id, formData.logins]),
    {
      preserveScroll: true,
      onSuccess: () => {
        $notify.success({
        message: 'Login updated',
        showClose: true,
      }),
        formData.reset()
        messageModalVisible.value = false
      },
      onError: (error) => {
        console.log(error)
        nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      }
    },
  );
}

const formData = useForm({
    logins: null,
    login_name: null,
    login_username: null,
    login_url: null,
    login_password: null,
    createMode: true,
    loading: false,
});

function closeMessageModal() {
  messageModalVisible.value = false;
  formData.clearErrors();
  formData.reset();
}

function openEditForm(login) {
  formData.logins = login.id;
  formData.login_name = login.login_name;
  formData.login_username = login.login_username;
  formData.login_url = login.login_url;
  formData.login_password = '';
  formData.createMode = false;
  messageModalVisible.value = true;
}

function confirmDelete(login) {
  console.log(login)
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to undo this.',
    icon: 'warning',
    confirmButtonText: "Yes, I'm sure.",
    showCancelButton: true,
    confirmButtonColor: '#B91C1C',
    cancelButtonColor: '#899CC1'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteMessage(login);
    }
  })
}
function deleteMessage(login) {
  Inertia.delete(route('leads.login.destroy', [props.lead.id, login.id]), {
    preserveScroll: true,
    onSuccess: () => {
        $notify.success({
        message: 'Login deleted.',
        showClose: true,
      })
    },
    onError: (error) => {
      console.log(error)
    }
  })
}

const showPassword = async (login) => {
    try {
        // Show a confirmation dialog
        const result = await Swal.fire({
            title: 'Show Password',
            text: 'Are you sure you want to reveal the password?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Show',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#899CC1',
            cancelButtonColor: '#d33',
        });

        if (result.isConfirmed) {
            // Proceed to show the password
            const response = await axios.get(`/api/lead-logins/${login.id}/show-password`);
            console.log('Decrypted password response:', response.data);

            // Set the decrypted password and showPassword property
            login.decryptedPassword = response.data.login_password;
            login.showPassword = true;

            console.log('Updated login object:', login);

            // Automatically hide password after 5 seconds (adjust as needed)
            login.hideTimeout = setTimeout(() => {
                login.showPassword = false;
                login.decryptedPassword = ''; // Optionally clear the decrypted password
                login.hideTimeout = null;
                console.log('Password hidden');
            }, 5000); // 5000 milliseconds = 5 seconds

            login.isShowingPassword = true; // Set the flag to true
        }
    } catch (error) {
        console.error(error);
    }
}

let logins_per_page_param_name = 'logins_per_page';
const loginsPerPage = ref(10);
const loginsPerPageChanged = function(){
  let data = {};
  data[logins_per_page_param_name] = loginsPerPage.value;
  Inertia.reload({
    data: data,
    only: ['logins']
  });
}
</script>

<template>
  <Head title="Logins" />

        <div class="px-4 sm:px-6 lg:px-8">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <div class="md:flex items-center justify-between px-5 py-4 bg-white">
                            <button @click.prevent="showCreateMessageModal" class="btn btn-primary text-sm">
                              <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                <span>Add Login</span>
                            </button>
                        </div>
                        <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
                            <thead class="bg-slate-200">
                            <tr>
                                <th scope="col"
                                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                    Login Name
                                </th>
                                <th scope="col"
                                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                    Username
                                </th>
                                <th scope="col"
                                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                    Password
                                </th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-right pr-5 text-sm font-semibold text-gray-900">
                                    Options
                                </th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                            <tr class="hover:bg-gray-50" v-for="login in logins.data" :key="login.id">
                              <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 text-tb-blue font-semibold no-underline hover:underline">
                                <a target="_blank" :href="login.login_url">{{ login.login_name }}</a>
                              </td>
                              <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900">
                                {{ login.login_username }}
                              </td>
                              <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900">
                                <button v-if="!login.showPassword" class="btn btn-primary py-1 px-2" @click="showPassword(login)"><LockClosedIcon class="h-4 w-4"></LockClosedIcon></button>
                                <span v-if="login.showPassword">{{ login.decryptedPassword }}</span>
                              </td>
                              <td class="whitespace-nowrap px-2 pr-5 py-3.5 text-sm text-gray-900 first-letter:uppercase text-right">
                                <a target="_blank" :href="login.login_url"><button class="btn-primary py-1 px-2 text-xs">Go To Wesbite</button></a>
                                <button class="btn-secondary text-xs py-1 px-2 ml-2" @click.prevent="openEditForm(login)">Edit</button>
                                <button class="btn-danger text-xs py-1 px-2 ml-2" @click.prevent="confirmDelete(login)">Delete</button>
                              </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    <Modal :show="messageModalVisible" @onClose="closeMessageModal()"
             :title="loginFormName">
        <LeadLoginForm :logins="logins" :form-data="formData" @submit="submitForm"></LeadLoginForm>
      </Modal>
</template>
