<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="hideModal">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">Create Script</DialogTitle>
                  <div>
                    <app-voicemail-script-create-form :create-mode="true" :modal-id="modalId" :should-redirect="false" @hide-modal="hideModal"
                                                      @script-created="notifyParent"></app-voicemail-script-create-form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import AppVoicemailScriptCreateForm from "../AppVoicemailScriptForm.vue";

export default {
  name: "ActionScheduleCreateVoicemailScriptModal",

  components: {
    AppVoicemailScriptCreateForm,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LoadingSpinner
  },

  props: {
    user_is_admin: {
      type: Boolean,
      required: true,
    },

    modalId: {
      type: String,
      default: 'action-schedule-create-voicemail-script-modal',
    },

    open: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['script-created', 'modal-hidden'],

  data() {
    return {
      modalName: 'createVoicemailScriptModal',
      loading: false,
      formData: {
        name: null,
        is_agency: false,
      },
    }
  },

  methods: {
    notifyParent(payload) {
      this.$emit('script-created', {script: payload.script});
      this.hideModal();
    },

    hideModal() {
      this.$emit('modal-hidden', this.modalName)
    },
  },
}
</script>

<style scoped>

</style>