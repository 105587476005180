<script setup>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {computed, ref} from 'vue';
import { QuestionMarkCircleIcon } from "@heroicons/vue/solid";
import { ElTooltip } from 'element-plus';
import helper from "@/helper";


const props = defineProps({
    leadCategory: {required: true, type: String,},
    formData: Object,
    leadSource: Object,
    leadTypes: Array,
    actionSchedules: Array,
    startingPoints: Array,
    agents: Array,
    buckets: Array,
    errors: {
        type: Object,
        default: null
    }
})



const emit = defineEmits(['submit']);

const modelName = computed(() => {
  switch (props.leadCategory) {
    case 'recruitment':
      return 'Recruitment Source';
    case 'commercial':
    default:
      return 'Lead Source';
  }
});
const isCommercialLead = computed(() => { // Commercial Lead: classic lead. The other type is a recruitment lead
  return !props.leadCategory || props.leadCategory !== 'recruitment';
});

const starting_point_label = ref('');
const show_starting_point_select = ref(false);
const startingPointValue = ref([]);

function isInvalid(attribute) {
    if (props.formData.errors && props.formData.errors[attribute]) {
        return true;
    } else {
        return props.errors && props.errors.hasOwnProperty(attribute);
    }
}

function errorMessage(attribute) {
    if (props.formData.errors && props.formData.errors[attribute]) {
        return props.formData.errors[attribute];
    } else if (props.errors && props.errors.hasOwnProperty(attribute)) {
        return props.errors[attribute][0];
    }
}

</script>

<template>
    <div>
        <form @submit.prevent="emit('submit')" class="intro-y box p-5 bg-white lead-source-form " method="POST">
            <div class="grid gap-6 grid-cols-1 mt-3">
                <!-- BEGIN: Vendor -->
                <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('vendor')}">
                    <label for="vendor" class="form-label text-sm  text-gray-800">Vendor</label>
                    <input v-model="formData.vendor" id="vendor" type="text" class="form-control input-field w-full" name="vendor">
                    <div v-if="isInvalid('vendor')" class="error-message mt-1">{{ errorMessage('vendor') }}</div>
                </div>
                <!-- END: Vendor -->

                <!-- BEGIN: Label -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('label')}">
                <div class="flex text-tb-blue">
                    <label for="label" class="form-label text-sm mt-2 text-gray-800">Label</label>
                    <el-tooltip placement="top" >
                            <template #content>
                            <p class="text-sm">Source name that sub agents would see.</p>
                            </template>
                            <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                        </el-tooltip>
                    </div>
                    <input v-model="formData.label" id="label" type="text" class="form-control input-field w-1/2" name="label">
                    <div v-if="isInvalid('label')" class="error-message mt-1">{{ errorMessage('label') }}</div>
                    <input v-model="formData.label_searchable_to_sub_agents" value="1" id="label_searchable_to_sub_agents" type="checkbox" class="input-checkbox form-check-input mr-2" name="label_searchable_to_sub_agents">
                    <label for="label_searchable_to_sub_agents" class="form-label text-sm  text-gray-800">Searchable to Sub Agents</label>
                    <div v-if="isInvalid('label_searchable_to_sub_agents')" class="error-message mt-1">{{ errorMessage('label_searchable_to_sub_agents') }}</div>
                </div>
                <!-- END: Label -->

                <!-- BEGIN: Lead Type -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('lead_type_id')}">
                    <label for="lead_type_id" class="form-label text-sm  text-gray-800">Lead Type</label>
                    <select v-model="formData.lead_type_id" id="lead_type_id" class="form-control input-field w-full" name="lead_type_id">
                                                <option>- Select -</option>
                                                <option v-for="leadType in leadTypes" :value="leadType.id">{{ leadType.name }}</option>
                                            </select>
                    <div v-if="isInvalid('lead_type_id')" class="error-message mt-1">{{ errorMessage('lead_type_id') }}</div>
                </div>
                <!-- END: Lead Type -->

                <!-- BEGIN: Cost Per Lead -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('cost_per_lead')}">
                    <div class="flex text-tb-blue">
                    <label for="cost_per_lead" class="form-label mt-2 text-sm text-gray-800">Cost Per Lead</label>
                    <el-tooltip placement="top" >
                            <template #content>
                            <p class="text-sm">How much you pay for each lead.</p>
                            </template>
                            <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                        </el-tooltip>
                    </div>
                    <input v-model="formData.cost_per_lead" id="cost_per_lead" type="text" class="form-control input-field w-full" name="cost_per_lead">
                    <div v-if="isInvalid('cost_per_lead')" class="error-message mt-1">{{ errorMessage('cost_per_lead') }}</div>
                </div>
                <!-- END: Cost Per Lead -->

                <!-- BEGIN: Notify When Dead -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('notify_agency_when_dead')}">
                    <div class="flex text-tb-blue">
                    <label for="notify_agency_when_dead" class="form-label mt-2 text-sm text-gray-800">Notify Admin When Lead Has a Dead Status</label>
                    <el-tooltip placement="top" >
                            <template #content>
                            <p class="text-sm">If selected, an email will be sent to the Agency Admin once<br> a lead from this source is changed to dead status.</p>
                            </template>
                            <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                        </el-tooltip>
                    </div>
                    <select v-model="formData.notify_agency_when_dead" id="notify_agency_when_dead" class="form-control input-field w-full" name="notify_agency_when_dead">
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    <div v-if="isInvalid('notify_agency_when_dead')" class="error-message mt-1">{{ errorMessage('notify_agency_when_dead') }}</div>
                </div>
                <!-- END: Notify When Dead -->

                <!-- BEGIN: Action Schedule -->
                <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('action_schedule_id')}">
                    <div class="flex text-tb-blue">
                    <label for="action_schedule_id" class="form-label mt-2 text-sm text-gray-800">Action Schedule</label>
                    <el-tooltip placement="top" >
                            <template #content>
                            <p class="text-sm">Choose the action schedule that this lead should follow.</p>
                            </template>
                            <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                        </el-tooltip>
                    </div>
                    <select v-model="formData.action_schedule_id" id="action_schedule_id" class="form-control input-field w-full" name="action_schedule_id">
                        <option :value="null">Select</option>
                        <option v-for="scheduleMap in actionSchedules" :value="scheduleMap.id">{{ scheduleMap.name }}</option>
                    </select>
                    <div v-if="isInvalid('action_schedule_id')" class="error-message mt-1">{{ errorMessage('action_schedule_id') }}</div>
                </div>
                <!-- END: Action Schedule -->

                <!-- BEGIN: Starting Point -->
                <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('starting_point')}">
                    <div class="flex text-tb-blue">
                    <label for="starting_point" class="form-label mt-2 text-sm text-gray-800">Starting Point</label>
                    <el-tooltip placement="top" >
                            <template #content>
                            <p class="text-sm">Choose how this lead should be<br> assigned once it is created in the system.</p>
                            </template>
                            <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                        </el-tooltip>
                    </div>
                    <select v-model="formData.starting_point" id="starting_point" class="form-control input-field w-full" name="starting_point">
                        <option v-for="startingPoint in startingPoints" :value="startingPoint.id">{{ startingPoint.name }}</option>
                    </select>
                    <div v-if="isInvalid('starting_point')" class="error-message mt-1">{{ errorMessage('starting_point') }}</div>
                </div>
                <!-- END: Starting Point -->

            <!-- BEGIN: Hard Time Limit -->
              <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('hard_time_limit')}">
                <div class="flex text-tb-blue">
                <label for="hard_time_limit" class="form-label mt-2 text-sm text-gray-800">Hard Time Limit</label>
                <el-tooltip placement="top" >
                    <template #content>
                        <p class="text-sm">You can optionally set a certain amount of hours<br> that the first owning agent can have the lead.<br> If the lead is still in uncontacted status after the<br> number of hours specified, it will be pulled<br> and assigned accordingly.</p>
                        </template>
                        <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                    </el-tooltip>
                </div>
                <input v-model="formData.hard_time_limit" id="hard_time_limit" type="text" class="form-control input-field w-full" name="hard_time_limit">
                <div v-if="isInvalid('hard_time_limit')" class="error-message mt-1">{{ errorMessage('hard_time_limit') }}</div>
            </div>
            <!-- END: Hard Time Limit -->

            <!-- BEGIN: Bad Phone Action -->
            <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('hard_time_limit_action')}">
                <div class="flex text-tb-blue">
                <label for="hard_time_limit_action" class="form-label text-sm mt-2 text-gray-800">Hard Time Limit Action</label>
                <el-tooltip placement="top" >
                        <template #content>
                            <p class="text-sm">What happens to the lead once the Hard Time Limit is Met</p>
                            </template>
                            <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                        </el-tooltip>
                </div>
                <select v-model="formData.hard_time_limit_action" id="hard_time_limit_action" class="form-control input-field w-full" name="hard_time_limit_action">
                    <option>Select</option>
                    <option :value="null">No action</option>
                    <option value="1">Transfer to agent</option>
                    <option value="2">Transfer to bucket</option>
                </select>
                <div v-if="isInvalid('hard_time_limit_action')" class="error-message mt-1">{{ errorMessage('hard_time_limit_action') }}</div>
            <!-- END: Bad Phone Action -->
                <!-- BEGIN: Bad Phone Action -->
                <div v-if="isCommercialLead && formData.hard_time_limit_action == 1" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('htl_user_id')}">
                    <label for="htl_user_id" class="form-label text-sm  text-gray-800">Agent</label>
                    <select v-model="formData.htl_user_id" id="htl_user_id" class="form-control input-field w-full" name="htl_user_id">
                        <option :value="null">Select Agent</option>
                        <option v-for="agent in agents" :key="agent.id" :value="agent.id">{{ agent.last_name }}, {{ agent.first_name }}</option>
                    </select>
                    <div v-if="isInvalid('htl_user_id')" class="error-message mt-1">{{ errorMessage('htl_user_id') }}</div>
                </div>
                <!-- END: Bad Phone Action -->
                <!-- BEGIN: Bad Phone Action -->
                <div v-if="isCommercialLead && formData.hard_time_limit_action == 2" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('htl_bucket_id')}">
                    <label for="htl_bucket_id" class="form-label text-sm  text-gray-800">Bucket</label>
                    <select v-model="formData.htl_bucket_id" id="htl_bucket_id" class="form-control input-field w-full" name="htl_bucket_id">
                        <option :value="null">Select Bucket</option>
                        <option v-for="bucket in buckets" :key="bucket.id" :value="bucket.id">{{ bucket.name }}</option>
                    </select>
                    <div v-if="isInvalid('htl_bucket_id')" class="error-message mt-1">{{ errorMessage('htl_bucket_id') }}</div>
                </div>
            </div>

            <!-- BEGIN: Weekly Dispo -->
            <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('weekly_dispo_email')}">
                <div class="flex text-tb-blue">
                <label for="weekly_dispo_email" class="form-label mt-2 text-sm text-gray-800">Weekly Dispo File Email</label>
                <el-tooltip placement="top" >
                    <template #content>
                        <p class="text-sm">You can send your lead vendor a disposition detail<br> file every Saturday morning by putting their email address here.</p>
                        </template>
                        <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                    </el-tooltip>
                </div>
                <input v-model="formData.weekly_dispo_email" id="weekly_dispo_email" type="text" class="form-control input-field w-full" name="weekly_dispo_email">
                <div v-if="isInvalid('weekly_dispo_email')" class="error-message mt-1">{{ errorMessage('weekly_dispo_email') }}</div>
            </div>
            <!-- END: Weekly Dispo -->

            <!-- BEGIN: Send Dispo -->
            <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('send_dispo_type')}">
                <div class="flex text-tb-blue">
                <label for="send_dispo_type" class="form-label mt-2 text-sm text-gray-800">Send Dispo Type</label>
                <el-tooltip placement="top" >
                    <template #content>
                        <p class="text-sm">Weekly will be sent on Saturday at 8am for the previous week Sat-Fri.<br>Daily will be sent each day for the previous day at 8am</p>
                        </template>
                        <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                    </el-tooltip>
                </div>       
                <select v-model="formData.send_dispo_type" id="send_dispo_type" class="form-control input-field w-full" name="send_dispo_type">
                    <option :value="null">Select</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                </select>                
                    <div v-if="isInvalid('send_dispo_type')" class="error-message mt-1">{{ errorMessage('send_dispo_type') }}</div>
            </div>
            <!-- END: Send Dispo -->

                <!-- BEGIN: Give Bad Phone Credit -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form w-full" :class="{'has-error': isInvalid('give_bad_phone_credit')}">
                    <div class="flex text-tb-blue">
                    <label for="give_bad_phone_credit" class="form-label mt-2 text-sm text-gray-800">Return Disconnected Number Credits</label>
                    <el-tooltip placement="top" >
                            <template #content>
                            <p class="text-sm">If selected, the agent will recieve a credit in agency bucks for what the agent paid.</p>
                            </template>
                            <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                        </el-tooltip>
                    </div>
                        <select v-model="formData.give_bad_phone_credit" id="give_bad_phone_credit" class="form-control input-field w-full" name="give_bad_phone_credit">
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    <div v-if="isInvalid('give_bad_phone_credit')" class="error-message mt-1">{{ errorMessage('give_bad_phone_credit') }}</div>
                </div>
              <!-- END: Give Bad Phone Credit -->

                <!-- BEGIN: Time To Allow Bad Phone Credit -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form w-full" :class="{'has-error': isInvalid('timeframe_bad_phone_credit')}">
                    <div class="flex text-tb-blue">
                    <label for="timeframe_bad_phone_credit" class="form-label mt-2 text-sm text-gray-800">Time To Allow Disconnected Number Credit</label>
                    <el-tooltip placement="top" >
                            <template #content>
                            <p class="text-sm">If selected, only give credit within this timeline.</p>
                            </template>
                            <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                        </el-tooltip>
                    </div>
                        <select v-model="formData.timeframe_bad_phone_credit" id="timeframe_bad_phone_credit" class="form-control input-field w-full" name="timeframe_bad_phone_credit">
                            <option value="1440">24 Hours</option>
                            <option value="2880">48 Hours</option>
                            <option value="4320">72 Hours</option>
                            <option value="5760">4 days</option>
                        </select>
                    <div v-if="isInvalid('timeframe_bad_phone_credit')" class="error-message mt-1">{{ errorMessage('timeframe_bad_phone_credit') }}</div>
                </div>
                <!-- END: Time To Allow Bad Phone Credit -->

                <!-- BEGIN: Bad Phone Action -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('bad_phone_action')}">
                    <label for="bad_phone_action" class="form-label text-sm  text-gray-800">Disconnected Number Action</label>
                    <select v-model="formData.bad_phone_action" id="bad_phone_action" class="form-control input-field w-full" name="bad_phone_action">
                        <option :value="null">No action</option>
                        <option value="1">Transfer to agent</option>
                        <option value="2">Transfer to bucket</option>
                    </select>
                    <div v-if="isInvalid('bad_phone_action')" class="error-message mt-1">{{ errorMessage('bad_phone_action') }}</div>
                    <!-- BEGIN: Bad Phone Action -->
                    <div v-if="formData.bad_phone_action == 1" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('bad_phone_action_user_id')}">
                        <label for="bad_phone_action_user_id" class="form-label text-sm  text-gray-800">Agent</label>
                        <select v-model="formData.bad_phone_action_user_id" id="bad_phone_action_user_id" class="form-control input-field w-full" name="bad_phone_action_user_id">
                            <option :value="null">Select Agent</option>
                            <option v-for="agent in agents" :key="agent.id" :value="agent.id">{{ agent.last_name }}, {{ agent.first_name }}</option>
                        </select>
                        <div v-if="isInvalid('bad_phone_action_user_id')" class="error-message mt-1">{{ errorMessage('bad_phone_action_user_id') }}</div>
                    </div>
                    <!-- END: Bad Phone Action -->
                    <!-- BEGIN: Bad Phone Action -->
                    <div v-if="formData.bad_phone_action == 2" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('bad_phone_action_bucket_id')}">
                        <label for="bad_phone_action_bucket_id" class="form-label text-sm  text-gray-800">Bucket</label>
                        <select v-model="formData.bad_phone_action_bucket_id" id="bad_phone_bucket_id" class="form-control input-field w-full" name="bad_phone_action_bucket_id">
                            <option :value="null">Select Bucket</option>
                            <option v-for="bucket in buckets" :key="bucket.id" :value="bucket.id">{{ bucket.name }}</option>
                        </select>
                        <div v-if="isInvalid('bad_phone_action_bucket_id')" class="error-message mt-1">{{ errorMessage('bad_phone_action_bucket_id') }}</div>
                    </div>
                </div>
                <!-- END: Bad Phone Action -->
                <!-- BEGIN: Dead Action -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('dead_action')}">
                    <label for="dead_action" class="form-label text-sm  text-gray-800">Dead Action</label>
                    <select v-model="formData.dead_action" id="dead_action" class="form-control input-field w-full" name="dead_action">
                        <option :value="null">No action</option>
                        <option value="1">Transfer to agent</option>
                        <option value="2">Transfer to bucket</option>
                    </select>
                    <div v-if="isInvalid('dead_action')" class="error-message mt-1">{{ errorMessage('dead_action') }}</div>
                    <!-- END: Dead Action -->
                    <div v-if="formData.dead_action == 1" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('dead_action_user_id')}">
                        <label for="dead_action_user_id" class="form-label text-sm  text-gray-800">Agent</label>
                        <select v-model="formData.dead_action_user_id" id="dead_action_user_id" class="form-control input-field w-full" name="dead_action_user_id">
                            <option :value="null">Select Agent</option>
                            <option v-for="agent in agents" :key="agent.id" :value="agent.id">{{ agent.last_name }}, {{ agent.first_name }}</option>
                        </select>
                        <div v-if="isInvalid('dead_action_user_id')" class="error-message mt-1">{{ errorMessage('dead_action_user_id') }}</div>
                    </div>
                    <!-- END: Bad Phone Action -->
                    <div v-if="formData.dead_action == 2" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('dead_action_bucket_id')}">
                        <label for="dead_action_bucket_id" class="form-label text-sm  text-gray-800">Bucket</label>
                        <select v-model="formData.dead_action_bucket_id" id="dead_action_bucket_id" class="form-control input-field w-full" name="dead_action_bucket_id">
                            <option :value="null">Select Bucket</option>
                            <option v-for="bucket in buckets" :key="bucket.id" :value="bucket.id">{{ bucket.name }}</option>
                        </select>
                        <div v-if="isInvalid('dead_action_bucket_id')" class="error-message mt-1">{{ errorMessage('dead_action_bucket_id') }}</div>
                    </div>
                </div>
                <!-- BEGIN: Give Already Purchased Credit -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('give_already_purchased_credit')}">
                    <div class="flex text-tb-blue">
                        <label for="give_already_purchased_credit" class="form-label text-sm mt-2 text-gray-800">Give Already Purchased Credit?</label>
                        <el-tooltip placement="top" >
                            <template #content>
                                <p class="text-sm">If selected, the agent will recieve a credit in agency bucks for what the agent paid.</p>
                            </template>
                            <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                        </el-tooltip>
                    </div>
                    <select v-model="formData.give_already_purchased_credit" id="give_already_purchased_credit" class="form-control input-field w-full" name="give_already_purchased_credit">
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    <div v-if="isInvalid('give_already_purchased_credit')" class="error-message mt-1">{{ errorMessage('give_already_purchased_credit') }}</div>
                </div>
                <!-- END: Give Already Purchased Credit -->
                <!-- BEGIN: Time To Allow Already Purchased Credit -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form w-full" :class="{'has-error': isInvalid('timeframe_already_purchased_credit')}">
                  <div class="flex text-tb-blue">
                    <label for="timeframe_already_purchased_credit" class="form-label mt-2 text-sm text-gray-800">Time To Allow Already Purchased Credit</label>
                    <el-tooltip placement="top" >
                      <template #content>
                        <p class="text-sm">If selected, only give credit within this timeline.</p>
                      </template>
                      <QuestionMarkCircleIcon @click.prevent="true" class="p-1 ml-1 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
                    </el-tooltip>
                  </div>
                  <select v-model="formData.timeframe_already_purchased_credit" id="timeframe_already_purchased_credit" class="form-control input-field w-full" name="timeframe_already_purchased_credit">
                    <option value="1440">24 Hours</option>
                    <option value="2880">48 Hours</option>
                    <option value="4320">72 Hours</option>
                    <option value="5760">4 days</option>
                  </select>
                  <div v-if="isInvalid('timeframe_already_purchased_credit')" class="error-message mt-1">{{ errorMessage('timeframe_already_purchased_credit') }}</div>
                </div>
                <!-- END: Time To Allow Already Purchased Credit -->
                <!-- BEGIN: Active -->
                <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('active')}">
                    <label for="active" class="form-label text-sm  text-gray-800">Active</label>
                    <select v-model="formData.active" id="active" class="form-control input-field w-full" name="active">
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    <div v-if="isInvalid('active')" class="error-message mt-1">{{ errorMessage('active') }}</div>
                </div>
                <!-- END: Active -->

                <!-- BEGIN: RTB Daily -->
                <div v-if="isCommercialLead" class="col-span-12 lg:col-span-6 input-form mt-2" :class="{'has-error': isInvalid('rtb_daily_overflow_cap')}">
                    <label for="rtb_daily_overflow_cap" class="form-label mt-2 text-sm text-gray-800 whitespace-nowrap">Turn on RTB?</label>
                    <input v-model="formData.rtb_active" id="rtb_daily_overflow_cap" type="checkbox" class="input-field h-4 cursor-pointer w-4 mt-1 rounded border-gray-300 text-tb-blue focus:ring-tb-blue" name="rtb_daily_overflow_cap">
                    <div v-if="isInvalid('rtb_daily_overflow_cap')" class="error-message mt-1">{{ errorMessage('rtb_daily_overflow_cap') }}</div>
                </div>
                <!-- END: RTB Daily -->

                <!-- BEGIN: RTB Daily -->
                <div v-if="isCommercialLead && formData.rtb_active == 1" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('rtb_daily_overflow_cap')}">
                    <label for="rtb_daily_overflow_cap" class="form-label mt-2 text-sm text-gray-800">RTB Daily Overflow Cap</label>
                    <input v-model="formData.rtb_daily_overflow_cap" id="rtb_daily_overflow_cap" type="text" class="form-control input-field w-full" name="rtb_daily_overflow_cap">
                    <div v-if="isInvalid('rtb_daily_overflow_cap')" class="error-message mt-1">{{ errorMessage('rtb_daily_overflow_cap') }}</div>
                </div>
                <!-- END: RTB Daily -->

                <!-- BEGIN: RTB Weekly -->
                <div v-if="isCommercialLead && formData.rtb_active == 1" class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('rtb_weekly_overflow_cap')}">
                    <label for="rtb_weekly_overflow_cap" class="form-label mt-2 text-sm text-gray-800">RTB Weekly Overflow Cap</label>
                    <input v-model="formData.rtb_weekly_overflow_cap" id="rtb_weekly_overflow_cap" type="text" class="form-control input-field w-full" name="rtb_weekly_overflow_cap">
                    <div v-if="isInvalid('rtb_weekly_overflow_cap')" class="error-message mt-1">{{ errorMessage('rtb_weekly_overflow_cap') }}</div>
                </div>
                <!-- RTB Weekly -->
            </div>

            <div class="text-right mt-5">
                <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
                    <LoadingSpinner v-if="loading"></LoadingSpinner>
                    <span v-else>Submit</span>
                </button>
            </div>
        </form>
    </div>
</template>
