<script setup>
import {computed, onBeforeMount, ref} from "vue";
import {useForm} from "@inertiajs/inertia-vue3";
import BreezeInputError from "@/Components/InputError.vue";
import BreezeButton from "@/Components/Button.vue";
import {PlusCircleIcon} from "@heroicons/vue/outline";
import LeadMedicationTableListItem from "@/Components/LeadMedicationTableListItem.vue";
import Modal from "@/Components/Modal.vue";
import MedicationForm from "@/Components/MedicationForm.vue";
import DoctorForm from "@/Components/DoctorForm.vue";
import LeadDoctorTableListItem from "@/Components/LeadDoctorTableListItem.vue";
import {ElLoading} from "element-plus";
import CardContainer from "@/Components/CardContainer.vue";

const props = defineProps({
  lead: Object,
  leadDependants: Array,
})

const globalLoading = ref(null);
const passwordChecked = ref(false);

onBeforeMount(() => {
  initModule();
})

async function passwordCheckedRecently() {
  return axios.get(route('medication-password-checked-recently', props.lead.id)).then(function(response) {
    return new Promise((resolve) => {
      if (response.data.checked) {
        resolve(true)
      } else {
        resolve(false)
      }
    });
  })
}


async function initModule() {
  globalLoading.value = ElLoading.service({fullscreen: true, text: 'Loading', lock: true})
  let checkedRecently = await passwordCheckedRecently();
  if (checkedRecently) {
    let result1 = await fetchMedications()
    let result2 = await fetchDoctors()
    if (result1 && result2) {
      passwordChecked.value = true
      setTimeout(() => {
        globalLoading.value.close()
        globalLoading.value = null;
      }, 500)
    } else {
      passwordChecked.value = false;
      globalLoading.value.close()
      globalLoading.value = null;
    }
  } else {
    passwordChecked.value = false;
    globalLoading.value.close()
    globalLoading.value = null;
  }
}
const medications = ref([]);

function fetchMedications() {
  return axios.get(route('leads.medication.index', props.lead.id))
      .then(response => {
        medications.value = response.data;
        return new Promise((resolve) => {
          resolve(true)
        })
      })
      .catch(error => {
        return new Promise((resolve) => {
          resolve(true)
        })
      })
      .finally(() => {
        return new Promise((resolve) => {
          resolve(true)
        })
      })
}

const doctors = ref([]);

function fetchDoctors() {
  return axios.get(route('leads.doctors.index', props.lead.id))
      .then(response => {
        doctors.value = response.data;
        return new Promise((resolve) => {
          resolve(true)
        })
      })
      .catch(error => {
        return new Promise((resolve) => {
          resolve(true)
        })
      })
      .finally(() => {
        return new Promise((resolve) => {
          resolve(true)
        })
      })
}


const passwordForm = useForm({
  email: '',
  password: '',
  lead_id: props.lead.id,
})

async function checkPassword() {
  passwordForm.post(route('verify-credentials'), {
    onSuccess: () => {
      fetchData();
    },
    onError: () => {
    }
  })
}

async function fetchData() {
  globalLoading.value = ElLoading.service({fullscreen: true, text: 'Loading', lock: true})
  let result1 =  await fetchMedications();
  let result2 = await fetchDoctors();
  if (result1 && result2) {
    passwordChecked.value = true
    setTimeout(() => {
      globalLoading.value.close()
      globalLoading.value = null;
    }, 500)
  }
}
const medicationForm = useForm({
  id: null,
  name: null,
  dosage: null,
  frequency: null,
  dependant_id: null,
})

const medicationModalVisible = ref(false);

const editMedication = (record) => {
  medicationForm.id = record.id;
  medicationForm.name = record.name;
  medicationForm.dosage = record.dosage;
  medicationForm.frequency = record.frequency;
  medicationForm.dependant_id = record.dependant_id;
  medicationModalVisible.value = true;
}

const showCreateMedicationForm = () => {
  resetMedicationForm();
  showMedicationFormModal()
}

const showMedicationFormModal = () => {
  medicationModalVisible.value = true;
}

const closeMedicationModal = () => {
  medicationModalVisible.value = false;
  setTimeout(() => {
    medicationForm.reset();
  }, 300)
}

const submitMedicationForm = () => {
  if (medicationForm.processing) return;
  if (medicationForm.id) {
    return updateMedication();
  }
  createMedication();
}

const createMedication = () => {
  if (medicationForm.processing) return;
  medicationForm.post(route('leads.medication.store', props.lead.id),
      {
        preserveScroll: true,
        onSuccess: () => {
          $notify.success({
            message: 'Medication added',
            showClose: true,
          })
          medicationModalVisible.value = false;

          setTimeout(() => {
            resetMedicationForm();
          }, 300)

          fetchMedications();
        },
        onError: (error) => {
        }
      },
  );
}

const updateMedication = () => {
  if (medicationForm.processing) return;
  medicationForm.post(route('leads.medication.update', [props.lead.id, medicationForm.id]),
      {
        preserveScroll: true,
        onSuccess: () => {
          $notify.success({
            message: 'Medication updated',
            showClose: true,
          })

          medicationModalVisible.value = false;

          setTimeout(() => {
            resetMedicationForm();
          }, 300)

          fetchMedications();
        },
        onError: (error) => {
        }
      },
  );
}

const resetMedicationForm = () => {
  medicationForm.id = null;
  medicationForm.name = null;
  medicationForm.dosage = null;
  medicationForm.frequency = null;
  medicationForm.dependant_id = null;
}

const medicationFormTitle = computed(() => {
  return medicationForm.id ? 'Edit Medication' : 'Add Medication';
})

const doctorForm = useForm({
  id: null,
  doctor: null,
  pcp_id: null,
  facility: null,
  address: null,
  phone: null,
  dependant_id: null,
})

const doctorModalVisible = ref(false);

const editDoctor = (record) => {
  doctorForm.id = record.id;
  doctorForm.doctor = record.doctor;
  doctorForm.pcp_id = record.pcp_id;
  doctorForm.facility = record.facility;
  doctorForm.address = record.address;
  doctorForm.phone = record.phone;
  doctorForm.dependant_id = record.dependant_id;
  doctorModalVisible.value = true;
}

const showDoctorFormModal = () => {
  doctorModalVisible.value = true;
}

const showDoctorCreateForm = () => {
  resetDoctorForm();
  showDoctorFormModal();
}

const closeDoctorModal = () => {
  doctorModalVisible.value = false;
  setTimeout(() => {
    doctorForm.reset();
  }, 300)
}

const submitDoctorForm = () => {
  if (doctorForm.processing) return;
  if (doctorForm.id) {
    return updateDoctor();
  }
  createDoctor();
}

const createDoctor = () => {
  if (doctorForm.processing) return;
  doctorForm.post(route('leads.doctors.store', props.lead.id),
      {
        preserveScroll: true,
        onSuccess: () => {
          $notify.success({
            message: 'Doctor/Facility added',
            showClose: true,
          })
          doctorModalVisible.value = false;
          fetchDoctors();

          setTimeout(() => {
            resetDoctorForm();
          }, 300)
        },
        onError: (error) => {
        }
      },
  );
}

const updateDoctor = () => {
  if (doctorForm.processing) return;
  doctorForm.post(route('leads.doctors.update', [props.lead.id, doctorForm.id]),
      {
        preserveScroll: true,
        onSuccess: () => {
          $notify.success({
            message: 'Doctor/Facility updated',
            showClose: true,
          })
          doctorModalVisible.value = false;
          fetchDoctors();

          setTimeout(() => {
            resetDoctorForm();
          }, 300)
        },
        onError: (error) => {
        }
      },
  );
}

const resetDoctorForm = () => {
  doctorForm.id = null;
  doctorForm.doctor = null;
  doctorForm.facility = null;
  doctorForm.address = null;
  doctorForm.phone = null;
  doctorForm.dependant_id = null;
}

const doctorFormTitle = computed(() => {
  return doctorForm.id ? 'Edit Doctor/Facility' : 'Add Doctor/Facility';
})
</script>

<template>
  <div v-if="globalLoading">

  </div>
  <div v-else>
    <CardContainer v-if="!passwordChecked" class="-mx-2 p-6">
      <p class="text-center mb-4">Please enter your credentials to view this information.</p>
      <form class="max-w-sm mx-auto" @submit.prevent="checkPassword">
        <div>
          <input id="medication-check-email" v-model="passwordForm.email" autocomplete="off" autofocus class="input-field  mt-1 block w-full" placeholder="Email Address" required type="email"/>
          <BreezeInputError :message="passwordForm.errors.email" class="mt-2"/>
        </div>

        <div class="mt-2">
          <input id="medication-check-password" v-model="passwordForm.password" autocomplete="off" class="input-field mt-1 block w-full" placeholder="Password" required style="text-security:disc; -webkit-text-security:disc;" type="text"/>
          <BreezeInputError :message="passwordForm.errors.password" class="mt-2"/>
        </div>

        <div class="mt-4">
          <BreezeButton :class="{ 'opacity-25': passwordForm.processing }" :disabled="passwordForm.processing" class="text-white bg-tb-blue block w-full py-3">
            <p class="mx-auto font-bold">Unlock</p>
          </BreezeButton>
        </div>
      </form>
    </CardContainer>
    <div v-else>
      <div class="flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div class="md:flex items-center justify-between px-5 py-2 bg-white">
                <h1 class="text-xl font-semibold bg-white text-gray-900">
                  Medications </h1>
                <button class="w-full md:w-auto btn-primary inline-flex items-center px-5 py-2 text-sm" type="button" @click.prevent="showCreateMedicationForm">
                  <PlusCircleIcon aria-hidden="true" class="-ml-1 mr-2 h-5 w-5"/>
                  <span>Add Medication</span>
                </button>
              </div>
              <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
                <thead class="bg-slate-200">
                <tr>
                  <th class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900" scope="col">
                    Name
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">
                    Dosage
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">
                    Frequency
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">Who
                    is it for
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">
                    Options
                  </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <LeadMedicationTableListItem v-for="(medication, i) in medications" :key="`medication_${medication.id}`" :lead_id="props.lead.id" :medication="medication" @show-update-form="editMedication" @medication-deleted="fetchMedications"></LeadMedicationTableListItem>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-4">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div class="md:flex items-center justify-between px-5 py-2 bg-white">
                <h1 class="text-xl font-semibold bg-white text-gray-900">
                  Doctors/Facilities </h1>
                <button class="w-full md:w-auto btn-primary inline-flex items-center px-5 py-2 text-sm" type="button" @click.prevent="showDoctorCreateForm">
                  <PlusCircleIcon aria-hidden="true" class="-ml-1 mr-2 h-5 w-5"/>
                  <span>Add Doctor/Facility</span>
                </button>
              </div>
              <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
                <thead class="bg-slate-200">
                <tr>
                  <th class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900" scope="col">
                    Doctor
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">
                    PCP ID
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">
                    Facility
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">
                    Address
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">
                    Phone
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">Who
                    is it for
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">
                    Options
                  </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <LeadDoctorTableListItem v-for="(doctor, i) in doctors" :key="`doctor_${doctor.id}`" :doctor="doctor" :lead_id="props.lead.id" @show-update-form="editDoctor" @doctor-deleted="fetchDoctors"></LeadDoctorTableListItem>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


  <Modal :show="medicationModalVisible" :title="medicationFormTitle" @onClose="closeMedicationModal()">
    <MedicationForm :form="medicationForm" :leadDependants="leadDependants" @onSubmit="submitMedicationForm"></MedicationForm>
  </Modal>

  <Modal :show="doctorModalVisible" :title="doctorFormTitle" @onClose="closeDoctorModal()">
    <DoctorForm :form="doctorForm" :leadDependants="leadDependants" @onSubmit="submitDoctorForm"></DoctorForm>
  </Modal>
</template>

<style scoped>

</style>