<script setup>
import { useForm } from "@inertiajs/inertia-vue3";
import { ref, computed } from "vue";
import AgentRoundRobinModal from '@/Components/partials/AgentRoundRobinModal.vue';
import Modal from "@/Components/Modal.vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";

const props = defineProps({
    roundRobinGroups: Array,
    agentId: Number,
    entries: Array,
    weekly_cap: Array,
    daily_cap: Array,
})

const formData = useForm({
  groups: props.entries.map(i => i.rr_group_id),
  global_cost_per_lead: null,
  global_weekly_cap: null,
  global_daily_cap: null,
  agent: props.agentId
})

const form = useForm({
  weekly_cap: null,
  daily_cap: null,
  lead_owner_price: null,
  rr_group: null,
  agent: null
})


function showCreateMessageModal(rr) {
  messageModalVisible.value = true;
  form.rr_group = rr.id;
  form.agent = props.agentId;
  form.weekly_cap = rr.entries[0].weekly_cap;
  form.daily_cap = rr.entries[0].daily_cap;
  form.lead_owner_price = rr.entries[0].lead_owner_price;
}


const messageModalVisible = ref(false);

function closeMessageModal() {
  messageModalVisible.value = false;
  formData.loading = false;
  form.reset();
}


function submitForm() {
  if (formData.groups){
  formData.post(route('rr_groups.agents.store', {
    rr_group: props.roundRobinGroups,
   }), {
    preserveScroll: true,
    onSuccess: () => {
      formData.global_cost_per_lead = null
      formData.global_daily_cap = null
      formData.global_weekly_cap = null
      messageModalVisible.value = false
        $notify.success({
        title: 'Success',
        message: 'Updated Successfully',
        showClose: true,
      })
      },
    onError: (error) => {
      console.log(error)
      nextTick(() => {
        helpers.scrollToClass('has-error');
      })
    }
  });
}
}


function updateCap(rr) {
  form.loading = true;
    form.put(route('rr_groups.agents.entries.agentEntries', form.rr_group), {
        preserveScroll: true,
        only: ['roundRobinGroups'],
        onSuccess: r => {
            console.log(r);
            formData.groups = r.props.roundRobinGroups.filter(i => i.entries.length).map(i => i.id);
            formData.defaults({
            groups: formData.groups
            })
            form.reset();
            messageModalVisible.value = false;
            $notify.success({
            message: 'Successfully updated.',
            showClose: true,
      })
        },
        onError: (error) => {
            console.log(error)
            nextTick(() => {
                helpers.scrollToClass('has-error');
            })
        },
        onFinish: () => {
          form.loading = false;
        }
    });
}
</script>

<template>
    <form @submit.prevent="submitForm" class="p-5">

      <div class="mt-8 flex flex-col col-span-12 rounded-lg xl:col-span-9">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <div class="bg-tb-blue p-3">
             <h2 class="font-bold text-sm text-white">Round Robin Groups</h2>
            </div>
            <table class="min-w-full divide-y divide-gray-300 border-t border-gray-300">
              <thead class="bg-slate-100">
              <tr>
                <th scope="col" class="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">Access</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">RR Group Name</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Weekly Cap</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Daily Cap</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Cost Per Lead</th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="rr in roundRobinGroups" :key="rr.id">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <input :id="`select_group${rr.rr_group_name}`" name="select_group[]" type="checkbox"
                     class="h-4 w-4 rounded border-gray-300 text-tb-blue focus:ring-tb-blue cursor-pointer"
                     :value="rr.id"
                      v-model="formData.groups"/>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ rr.rr_group_name }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><button @click.prevent="showCreateMessageModal(rr)" class="btn-primary shadow-md font-bold w-5 h-8">{{ rr.entries.length ? rr.entries[0]?.weekly_cap : 0 }}</button></td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><button @click.prevent="showCreateMessageModal(rr)" class="btn-primary shadow-md font-bold w-5 h-8">{{ rr.entries.length ? rr.entries[0]?.daily_cap : 0 }}</button></td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><button @click.prevent="showCreateMessageModal(rr)" class="btn-primary shadow-md font-bold w-5 h-8">{{ rr.entries.length ? rr.entries[0]?.lead_owner_price : 0 }}</button></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>

      <div class="flex space-x-2 mt-5 text-sm">
          <label class="form-label text-gray-600 mt-1">Bulk update cost per lead</label>
          <div>
              <input type="text" class="input-field ml-1" placeholder="Leave empty to skip" v-model="formData.global_cost_per_lead" />
          </div>
      </div>
      <div class="flex space-x-4 mt-4 text-sm">
          <label class="form-label text-gray-600 mt-1">Bulk update weekly cap</label>
          <div>
              <input type="text" class="input-field ml-1" placeholder="Leave empty to skip" v-model="formData.global_weekly_cap" />
          </div>
      </div>
      <div class="flex space-x-6 mt-4 text-sm">
          <label class="form-label text-gray-600 mt-1">Bulk update daily cap</label>
          <div>
              <input type="text" class="input-field ml-3" placeholder="Leave empty to skip" v-model="formData.global_daily_cap" />
          </div>
      </div>

      <div class="text-left mt-5">
        <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': formData.loading}">
          <LoadingSpinner v-if="formData.loading"></LoadingSpinner>
          <span v-else>Update</span>
        </button>
      </div>
    </form>


  <Modal :show="messageModalVisible" @onClose="closeMessageModal()"
             title="Update Round Robin Entry">
        <AgentRoundRobinModal :weekly_cap="weekly_cap" :daily_cap="daily_cap" :entries="entries" :form="form" @submit="updateCap"></AgentRoundRobinModal>
  </Modal>
</template>
