export function useMergeFieldDragAndDropHandler(e) {
    e.preventDefault();
    const targetField = e.target;
    const start = targetField.selectionStart;
    const end = targetField.selectionEnd;
    const value = targetField.value ?? '';
    const draggedText = e.dataTransfer.getData("text");

    // Insert the dragged text content at the current caret position
    targetField.value = value.substring(0, start) + draggedText + value.substring(end);
    targetField.selectionStart = start + draggedText.length;
    targetField.selectionEnd = start + draggedText.length;
    return targetField.value;
}

export function useInitDragAndDropFields() {
    const draggableElements = document.querySelectorAll('.draggable');

    draggableElements.forEach(el => {
        // Set the dataTransfer data for each el with .draggable class;
        el.addEventListener('dragstart', function(event) {
            event.dataTransfer.setData('text', el.textContent);
        });
    });
}
