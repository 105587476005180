<script setup>
import {ref, onMounted, computed} from 'vue';
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import {useForm} from "@inertiajs/inertia-vue3";
import axios from "axios";
import {CheckCircleIcon, XIcon, BanIcon} from "@heroicons/vue/solid";

const props = defineProps({
    agencyInfo : {
        type     : Object,
        required : true
    }
});

const formData = useForm({
    email_blasts_auto_approve: props.agencyInfo.email_blasts_auto_approve
});
const data = ref({
    email_blast_auto_approve_changed: false
});
const notifyObject = ref({
    show: false,
    classes: '',
    message: '',
    ok: null
});
const button_email_blast_auto_approval_save_enabled = computed(function(){
    return data.value.email_blast_auto_approve_changed;
});

function emailBlastsAutoApproveChanged() {
    data.value.email_blast_auto_approve_changed = true;
}

function submitEmailBlastAutoApprovalSave() {
    data.value.email_blast_auto_approve_changed = false;
    axios.put(route('email-blats.agency-auto-approve'), {
        email_blasts_auto_approve: formData.email_blasts_auto_approve
    }).then(r => {
        notifySuccess('Saved');
    })
    .catch(e => {
        data.value.email_blast_auto_approve_changed = true;
        notifyError(e.message);
        throw e;
    });
}

function notifySuccess(message) {
    notifyObject.value.show = true;
    notifyObject.value.classes = 'bg-green-50';
    notifyObject.value.message = message;
    notifyObject.value.ok = 1;
}
function notifyError(message) {
    notifyObject.value.show = true;
    notifyObject.value.classes = 'bg-red-50';
    notifyObject.value.message = message;
    notifyObject.value.ok = 0;
}

</script>

<template>
    <div v-show="notifyObject.show" :class="notifyObject.classes" class="rounded-lg p-4 mt-2 mb-5">
        <div class="flex">
            <div class="flex-shrink-0">
                <CheckCircleIcon v-if="notifyObject.ok" class="h-5 w-5 text-black" aria-hidden="true" />
                <BanIcon v-if="!notifyObject.ok" class="h-5 w-5 text-black" aria-hidden="true" />
            </div>
            <div class="ml-3">
                <p class="text-sm font-medium text-green-800">{{ notifyObject.message }}</p>
            </div>
            <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <button @click="notifyObject.show = false;" type="button" class="inline-flex rounded-md p-1.5 text-black focus:outline-none focus:ring-2 focus:ring-offset-2">
                        <span class="sr-only">Dismiss</span>
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    </div>
    <header
        class="md:flex md:flex-none items-center justify-between py-4 px-1">
        <h1 class="text-lg font-semibold text-gray-900">Agency emails and texts</h1>
    </header>
    <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

            </div>
        </div>
    </div>
    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
        <!-- BEGIN: Auto Approve Email Blast Templates -->
        <form @submit.prevent="submitEmailBlastAutoApprovalSave" class="col-span-12 grid grid-cols-12 gap-3 lg:gap-6" style="align-items: end">
            <div class="col-span-12 lg:col-span-3 input-form" :class="{'has-error': formData.errors.email_blasts_auto_approve}">
                <label for="email_blasts_auto_approve" class="form-label  text-gray-600">Auto Approve Email Blast Templates</label>
                <select v-model="formData.email_blasts_auto_approve" @change="emailBlastsAutoApproveChanged" data-placeholder="Auto approve email blasts" class="input-field w-full" id="email_blasts_auto_approve" name="email_blasts_auto_approve">
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select>
                <div class="error-message mt-1" v-if="formData.errors.email_blasts_auto_approve">{{ formData.errors.email_blasts_auto_approve }}</div>
            </div>
            <div class="col-span-12 lg:col-span-3 input-form" :class="{'has-error': formData.errors.email_blasts_auto_approve}">
                <button :disabled="!button_email_blast_auto_approval_save_enabled" type="submit" :class="{
                    'btn-primary': button_email_blast_auto_approval_save_enabled,
                    'bg-gray-200': !button_email_blast_auto_approval_save_enabled
                }" class="btn rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2">Save</button>
            </div>
        </form>
        <!-- END: Auto Approve Email Blast Templates -->
    </div>
</template>

<style scoped>
</style>
