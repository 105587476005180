<template>
  <tr>
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{{ access.accessible_by_name }}</td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
      <button class="btn-danger text-sm" @click="confirmDelete">Revoke access</button>
    </td>
  </tr>


</template>

<script>
import _ from "lodash";
import helpers from "../../helper";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";

export default {
  name: "AgentAccessListItem",

  mixins: [serverValidationErrorsMixin],

  props: {
    agentId: {
      type: Number,
      required: true,
    },
    access: {
      type: Object,
      required: true,
    }
  },

  emits: ['access-deleted'],

  data() {
    return {
      data: _.cloneDeep(this.access),
    }
  },

  methods: {
    confirmDelete() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to undo this.',
        icon: 'warning',
        confirmButtonText: "Yes, I'm sure.",
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAccess();
        }
      })
    },

    deleteAccess() {
      this.loading = true;
      axios
        .delete(`/agents/${this.agentId}/account-access/${this.data.id}`)
        .then((response) => {
          this.$emit('access-deleted');
        })
        .catch((error) => {
          Swal.fire({
            text: 'Unknown error occurred, please try again.',
            icon: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    cancel() {
      this.editMode = false;
    },
  },
}
</script>

<style scoped>

</style>