<script setup>
import Cleave from 'cleave.js'
import {computed, onBeforeUnmount, onMounted, ref, watch} from 'vue';

const props = defineProps({
  elementId: {
    type: String,
    default: () => `input-${Math.random().toString(36).substring(7)}`
  },

  modelValue: {
    default: null,
    required: true,
    validator(value) {
      return value === null || typeof value === 'string' || value instanceof String || typeof value === 'number'
    }
  },
  defaultOptions: {
    type: Object,
    default: () => ({
      numericOnly: true,
      blocks: [0, 3, 0, 3, 4],
      delimiters: ["(", ")", " ", "-"]
    })
  },
  options: {
    type: Object,
    default: () => ({})
  },
  // Set this prop as `false` to emit masked value
  raw: {
    type: Boolean,
    default: true
  },
})

const model = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});

const emit = defineEmits(['onBlur', 'update:modelValue', 'onEnter'])
const input = ref(null);
const cleave = ref(null);

// callback backup
let onValueChangedFn = ref(null); // maybe set it as function

onMounted(() => {
  if (cleave.value) return;

  cleave.value = new Cleave(input.value, getOptions({...props.defaultOptions, ...props.options}));
})

/**
 * Inject our method in config options
 */
function getOptions(options) {
  // Preserve original callback
  onValueChangedFn.value = options.onValueChanged;

  return Object.assign({}, options, {
    onValueChanged: onValueChanged,
  });
}

/**
 * Watch for value changed by cleave and notify parent component
 */
function onValueChanged(event) {
  let value = props.raw ? event.target.rawValue : event.target.value;
  emit('update:modelValue', value);

  // // Call original callback method
  if (typeof onValueChangedFn.value === 'function') {
    onValueChangedFn.value.call(this, event)
  }
}

function onBlur(event) {
  emit('blur', event)
}

watch(
  () => props.defaultOptions,
  (newOptions) => {
    cleave.value.destroy();
    cleave.value = new Cleave(input.value, getOptions(newOptions));
    cleave.value.setRawValue(model)
  },
  {deep: true}
)

watch(model, (newValue, oldValue) => {
  if (!cleave.value) return;
  // when v-model is not masked (raw)
  if (props.raw && newValue === cleave.value.getRawValue()) return;
  //  when v-model is masked (NOT raw)
  if (!props.raw && newValue === input.value) return;
  // Lastly set newValue
  cleave.value.setRawValue(newValue);
})

onBeforeUnmount(() => {
  if (!cleave.value) return;
  cleave.value.destroy();
  cleave.value = null;
  onValueChangedFn.value = null;
})
</script>

<template>
  <input :id="elementId" ref="input" :class="[props.classes, 'form-input']" :value="cleave ? cleave.properties.result : model"
         type="text" @keyup.enter="emit('onEnter', $event)" @blur="emit('onBlur', $event)">
</template>
