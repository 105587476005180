<script setup>
import moment from "moment";
import { CheckIcon, EyeIcon } from "@heroicons/vue/solid";
import {ref, computed, nextTick, watch} from "vue";
import { useForm, usePage} from '@inertiajs/inertia-vue3';
import Modal from "@/Components/Modal.vue";
import AppModifyPolicies from "./AppModifyPolicies.vue";
import { Menu, MenuButton, MenuItem, MenuItems, Portal } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { usePopper } from "@/utils/use-popper";
import BeneficiariesList from "@/Components/BeneficiariesList.vue";

const roles = usePage().props.value.auth?.user?.roles;
const isSuperAdmin = roles?.some(role => role.name == "Super Admin")
const props = defineProps({
  policy: Object,
  policies: Object,
  filtersExpanded: false,
  leadId: Number,
  lead: Object,
  products: Object,
  enrollees: Array,
  agents: Array,
  splitPercentages: Array,
  carriersData: Object,
  agencyCarriers: Object,
  applicants: Array,
  hst_available: Boolean,
  activeTab: String,
  leadSchedules: Array,
  user: Object,
})

const emit = defineEmits([
  'cancelledStatus', 
  'renewPolicy', 
  'delete', 
  'resyncHst', 
  'postHstSale',
  'sameTab',
])

const medicareProductCategoryIds = [16, 17];

function showCreateMessageModal() {
  messageModalVisible.value = true;
}

const messageModalVisible = ref(false);
const beneficiariesModalVisible = ref(false);
const createMode = ref(true);

const policyFormName = computed(() => {
  let mode = formData.createMode ? 'Create Quotesheet' : 'Modify Policy';
  return `${mode}`;
})

const renewPolicy = ref(false)
const isCancelled = ref(false)

let [trigger, container] = usePopper({
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    },
  ],
})

const formData = useForm({
    selectedCarrier: null,
    products: null,
    applicants: null,
    premium: null,
    policy_number: null,
    effective_date: null,
    sub_policies: [],
    end_date: null,
    business_type: null,
    assist_user_id: null,
    assist_rate: null,
    taxcreditid: null,
    client_portion: null,
    enrollees: null,
    draft_date: null,
    face_amount: null,
    beneficiaries: [],
    notes: null,
    renew: renewPolicy,
    isCancelled: isCancelled,
    loading: false,
    selectedScheduleToComplete: null,
    selectedApptsAndTasksToComplete: [],
    current_plan_info: null,
})

function openEditForm(policy) {
  editingPolicy.value = policy.id;
  formData.selectedCarrier = policy.carrier;
  formData.products = policy.product.id;
  formData.applicants = policy.applicants.map(i => i.id);
  formData.premium = policy.premium;
  formData.policy_number = policy.policy_number;
  formData.effective_date = policy.effective_date;
  formData.end_date = policy.end_date;
  formData.business_type = policy.business_type;
  formData.assist_user_id = policy.assist_user_id;
  formData.client_portion = policy.client_portion;
  formData.assist_rate = policy.assist_rate;
  formData.taxcreditid = policy.taxcreditid;
  formData.enrollees = policy.enrollees;
  formData.draft_date = policy.draft_date;
  formData.face_amount = policy.face_amount;
  formData.beneficiaries = policy.beneficiaries;
  formData.notes = policy.note;
  formData.createMode = false;
  formData.sub_policies = [];
  for (let i = 0; i <= 5; i++) {
    let subtransaction = {policy_number: null, effective_date: null, end_date: null};
    if (policy.subTransactions[i] && policy.subTransactions[i].policy_number) {
      subtransaction.policy_number = policy.subTransactions[i].policy_number;
      subtransaction.effective_date = policy.subTransactions[i].effective_date;
      subtransaction.end_date = policy.subTransactions[i].end_date;
    }
    formData.sub_policies.push(subtransaction);
  }
  formData.current_plan_info = policy.current_plan_info;
  messageModalVisible.value = true;
}


function closeMessageModal() {
  messageModalVisible.value = false;
  setTimeout(() => {
    formData.reset();
    formData.clearErrors();
  }, 300)
}

const editingPolicy = ref()
const sameTab = ref(props.activeTab)

function submitForm() {
  formData.put(route('leads.new-policy.update', {
        new_policy: editingPolicy.value,
        lead: props.leadId,
   }), {
    preserveScroll: true,
    onSuccess: () => {
      messageModalVisible.value = false;
      formData.defaults({
          renew: false
        })
        formData.reset()
        $notify.success({
        title: 'Success',
        message: 'Policy updated',
        showClose: true,
      }),
      handlePolicySubmission();
      },
    onError: (error) => {
      console.log(error)
      nextTick(() => {
        helpers.scrollToClass('has-error');
      })
    }
  });
}

function handlePolicySubmission() {
  sameTab.value = 'policies';
}

const formattedPremium = computed(() => {
  if (!props.policy || isNaN(props.policy.premium)) return '';

  return props.policy.premium.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
})
const mostRecentDates = computed(() => {
  if (!props.policy) return { effective: '', end: '' };

  let allDates = [];

  // Push policy date
  allDates.push({ 
    effective: new Date(props.policy.effective_date), 
    end: props.policy.end_date ? new Date(props.policy.end_date) : null
  });

  // Push subTransactions dates
  if (props.policy.subTransactions && props.policy.subTransactions.length) {
    props.policy.subTransactions.forEach(sub => {
      allDates.push({ 
        effective: new Date(sub.effective_date), 
        end: sub.end_date ? new Date(sub.end_date) : null
      });
    });
  }

  // Sort by end dates in ascending order, considering entries with null end dates as the latest
  allDates.sort((a, b) => {
    if (a.end === null) return 1;
    if (b.end === null) return -1;
    return a.end - b.end;
  });

  // Filter out expired end dates, but always keep entries with null end dates
  const validDates = allDates.filter(date => !date.end || date.end >= new Date());

  // Get the earliest valid end date and its corresponding effective date
  const currentDateObj = validDates.length ? validDates[0] : null;

  return {
    effective: currentDateObj ? moment(currentDateObj.effective).tz('America/New_York').format('MM/DD/YYYY') : '',
    end: currentDateObj && currentDateObj.end ? moment(currentDateObj.end).tz('America/New_York').format('MM/DD/YYYY') : '',
  };
});

function showBeneficiariesReadOnly(policy) {
  beneficiariesModalVisible.value = true;
}
function hideBeneficiariesReadOnly() {
  beneficiariesModalVisible.value = false;
}

</script>

<template>
  <tr v-show="props.policy.status == 'A'">
    <td class="whitespace-nowrap px-2 py-3.5 text-sm font-medium text-gray-900">{{ moment(props.policy.created_at).tz('America/New_York').format('MM/DD/YYYY') }}</td>
    <td class="whitespace-nowrap px-2 py-3.5 text-sm text-gray-900">{{ mostRecentDates.effective }}<br> {{ mostRecentDates.end }}</td>
    <td @click.prevent="openEditForm(props.policy)" class="whitespace-nowrap px-2 py-3.5 cursor-pointer no-underline hover:underline text-sm hover:text-tb-blue">{{ props.policy.product?.name }} {{ props.policy.policy_number ? '/ ' + props.policy.policy_number : '' }} {{ props.policy.taxcreditid ?  '/ ' + props.policy.taxcreditid : '' }}</td>
    <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ formattedPremium }}</td>
    <td v-if="filtersExpanded && policies.data?.some(policy => policy.client_portion)" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ props.policy.client_portion ? '$' + props.policy.client_portion.toFixed(2) : '' }}</td>
    <td v-if="filtersExpanded && policies.data?.some(policy => policy.assist_user_id)" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ props.policy.assist_user_id ? props.policy?.assisted_agent : '' }}</td>
    <td v-if="filtersExpanded && policies.data?.some(policy => policy.assist_rate)" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ props.policy.assist_rate ? props.policy.assist_rate.toString() + '%' : '' }}</td>
    <td v-if="filtersExpanded && policies.data?.some(policy => policy.draft_date)" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ props.policy.draft_date ? moment(props.policy.draft_date).tz('America/New_York').format('MM/DD/YYYY') : '' }}</td>
    <td v-if="filtersExpanded && policies.data?.some(policy => policy.face_amount)" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ props.policy?.face_amount || '' }}</td>
    <td v-if="filtersExpanded && policies.data?.some(policy => policy.beneficiaries_count)" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-blue-900 first-letter:uppercase"><span class="cursor-pointer" @click="showBeneficiariesReadOnly(props.policy)">{{ props.policy?.beneficiaries_count || '' }}</span></td>
    <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton ref="trigger" class="inline-flex justify-left w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-tb-blue">
            Options
            <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <Portal>
          <MenuItems ref="container" class="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a @click.prevent="openEditForm(props.policy)" class="cursor-pointer" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Edit Policy</a>
              </MenuItem>
              <MenuItem  v-if="policy.status == 'A'" v-slot="{ active }">
                <a class="cursor-pointer" @click="emit('cancelledStatus', policy)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Cancel Policy</a>
              </MenuItem>
              <MenuItem  v-if="policy.status == 'A' && policy.hst_trans_id && isSuperAdmin" v-slot="{ active }">
                <a class="cursor-pointer" @click="emit('resyncHst', policy)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Resync With HST</a>
              </MenuItem>
              <MenuItem  v-if="policy.status == 'A' && !policy.hst_trans_id && isSuperAdmin" v-slot="{ active }">
                <a class="cursor-pointer" @click="emit('postHstSale', policy)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Post To HST</a>
              </MenuItem>
              <MenuItem v-if="policy.status == 'A'" v-slot="{ active }">
                <a class="cursor-pointer" @click.prevent="emit('delete', policy)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Delete Policy</a>
              </MenuItem>
              <MenuItem v-if="props.policy.product?.show_renew_button == 1" v-slot="{ active }">
                <a @click="emit('renewPolicy', props.policy)" class="cursor-pointer" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Renew Policy</a>
              </MenuItem>
            </div>
          </MenuItems>
        </Portal>
      </Menu>
    </td>
  </tr>
  <tr v-show="props.policy.status == 'A' && medicareProductCategoryIds.indexOf(props.policy.product?.category_id) > -1">
    <td colspan="8" class="bg-gray-100 px-2 py-3.5 text-sm text-gray-900 break-words">
      <span class="font-bold">Current Plan Info:</span>
      {{ props.policy.current_plan_info ?? '-' }}
    </td>
  </tr>
  <tr v-show="props.policy.status == 'A' && props.policy?.applicants?.length">
    <td colspan="8" class="bg-gray-100 px-2 py-3.5 text-sm text-gray-900 break-words">
      <span class="font-bold">Applicants:</span>
      {{ props.policy.applicants.map(applicant => `${applicant.first_name}${applicant.last_name ? ` ${applicant.last_name}` : ''}`).join(', ') }}
    </td>
  </tr>
  <tr v-show="props.policy.status == 'A' && props.policy.note">
    <td colspan="8" class="bg-gray-100 px-2 py-3.5 text-sm text-gray-900 break-words">
      <span class="font-bold">Note:</span> {{ props.policy.note }}
    </td>
  </tr>

    <Modal :show="messageModalVisible" @onClose="closeMessageModal()"
           :title="policyFormName">
           <AppModifyPolicies
           @onSubmit="submitForm"
           :policies="policies"
           :lead-id="leadId"
           :lead="lead"
           :products="products"
           :enrollees="enrollees"
           :split-percentages="splitPercentages"
           :agents="agents"
           :carriers-data="carriersData"
           :applicants="applicants"
           :hst_available="hst_available"
           :hst_trans_id="props.policy.hst_trans_id"
           :active-tab="activeTab"
           :policy="policy"
           :form-data="formData"
           :lead-schedules="leadSchedules"
           :user="user"
           ></AppModifyPolicies>
    </Modal>

    <Modal :show="beneficiariesModalVisible" @onClose="hideBeneficiariesReadOnly()"
           title="Policy Beneficiaries" max-width="max-w-[1200px]">
      <div class="text-sm text-center italic">Use the Edit Policy option to edit beneficiaries</div>
      <BeneficiariesList :beneficiaries="props.policy.beneficiaries" readonly
         />
    </Modal>
</template>

<style scoped></style>
