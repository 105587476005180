<template>
    <tr class="hover:bg-gray-50">
      <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">
        <div class="flex items-center">
        <div class="h-10 w-10 flex-shrink-0">
          <img class="h-10 w-10 rounded-full" src="../../images/avatar_placeholder.png" alt=""/>
        </div>
        <div class="ml-4">
          <div class="font-medium text-base text-gray-900">{{ agent.name }}</div>
          <div class="text-gray-500 text-xs">
          <input v-maska="'(###) ###-####'" type="text" class="border-none px-0 py-0 text-gray-500 text-xs" readonly v-model="agent.phone">
          </div>
          <div class="text-gray-500" text-xs>{{ agent.email }}</div>
        </div>
      </div>      </td>
      <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">
        <button @click.prevent="showBalanceDetails" class="font-bold text-blue-600 underline">
          Total: {{ agent.total }}
        </button>
        <Link :href="route('users.credits.adjustment.show', agent.id)" class="mt-2 underline text-blue-600 font-semibold block">Make Adjustments</Link>
        <Link :href="route('users.credits.ledger.index', [agent.id, {'show': 'all'}])" class="underline text-blue-600 font-semibold block">View Ledger</Link>
      </td>
      <td class="py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase min-w-[200px] max-w-[200px]">
        {{ agent.leader_names ?? '—' }}
      </td>
      <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">
        <button @click.prevent="confirmStatusChange" class="text-sm py-0 ml-2 px-2" :class="agent.is_active ? 'btn-primary' : 'btn-secondary'">
        {{ agent.is_active ? 'Active' : 'Inactive'}}
      </button>
      </td>
      <td class="relative whitespace-nowrap pl-3 pr-4 text-right text-sm sm:pr-6 first-letter:uppercase">
        <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton ref="trigger" class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-tb-blue">
            Options
            <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

          <Portal>
          <MenuItems ref="container" class="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <Link :href="route('agents.show', agent.id)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Edit Agent</Link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a :href="route('impersonate.start', agent)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Login as agent</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Export agent data</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Report card</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Edit Agent (Test Only)</a>
                </MenuItem>
              </div>
          </MenuItems>
          </Portal>
      </Menu>
      </td>
  </tr>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems, Portal } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { usePopper } from "@/utils/use-popper";
import {ref} from "vue";
import { Link } from '@inertiajs/inertia-vue3';

const props = defineProps({
  agent: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  }
})

const emit = defineEmits(['agent-status-updated', 'show-balance-modal']);

const loading = ref(false);
const errors = ref({});

function isInvalid(attribute) {
  return errors.value.hasOwnProperty(attribute);
}

function errorMessage(attribute) {
  if (errors.value.hasOwnProperty(attribute)) {
    return errors.value[attribute][0];
  }
}

function confirmStatusChange() {
    let deactivateText = 'Are you sure you want to deactivate this agent?';
    let activateText = 'Are you sure you want to reactivate this agent?  Reactivation is subject to pro-rated billing using the billing profile attached to this agent.';

    let text = props.agent.is_active ? deactivateText : activateText;

    Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'info',
      confirmButtonText: "Yes, I'm sure.",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        toggleActiveStatus();
      }
    })
}

function toggleActiveStatus() {
  if (loading.value) return;
  loading.value = true;
  // App\Http\Controllers\AgentActiveStatusController::class, 'store'
  setTimeout(() => {
    axios
      .post(`/agents/${props.agent.id}/toggle-active-status`)
      .then((response) => {
        handleSuccessfulSubmission(response);
      })
      .catch((error) => {
        console.log(error, 'AppAgentsTableListItem - toggleActiveStatus()')
        handleErroredSubmission(error);
      })
      .finally(() => {
        loading.value = false;
      })
  }, 500)
}

function handleSuccessfulSubmission(response) {
  emit('agent-status-updated', {
    index: props.index,
    is_active: response.data.is_active
  });

  Swal.fire({
    text: 'Agent status updated.',
    icon: 'success'
  });

  errors.value = {};
}

function handleErroredSubmission(error) {
  if (error.response && error.response.data && error.response.status === 422) {
    errors.value = error.response.data.errors;
  } else {
    Swal.fire({
      text: 'Unknown error occurred, please try again.',
      icon: 'error'
    })
  }
  console.log("AppAgentsTableListItem - handleErroredSubmission", error.response);
}

let [trigger, container] = usePopper({
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    },
  ],
})

function showBalanceDetails() {
    emit('show-balance-modal', {
        agent: props.agent
    })
}
</script>
