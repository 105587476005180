<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">

      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button @click="updateStatesModalVisible = true" type="button" class="btn-primary inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm sm:w-auto">Edit licensed states</button>
      </div>
    </div>
    <div class="mt-8 flex flex-col" v-if="licenses.length">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Licensed In</th>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Expiration Date</th>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">License File</th>
                <th class="py-3.5 pl-4 text-left text-sm font-semibold text-right text-gray-900 sm:pr-6">
                  Actions
                  <span class="sr-only">Actions</span>
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <agent-and-lead-licensed-state-list-item
                v-for="(license, i) in licenses"
                :model-type="modelType"
                :states="states"
                :files="files"
                :license="license"
                :key="license.id"
                :index="i"
                :modelId="modelId"
                @license-updated="syncData"
                @licenseDeleted="handleLicenseDeleted"
                @licenseFileDeleted="handleLicenseFileDeleted"
                @license-file-updated="handleLicenseFileUpdated"
              ></agent-and-lead-licensed-state-list-item>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full text-center my-4">
      This {{ modelType }} has no licenses.
    </div>
  </div>

  <!-- BEGIN: Update States Modal Modal -->
  <Modal :show="updateStatesModalVisible" @onClose="updateStatesModalVisible = false" :title="`Select states that this ${modelType} is licensed in`">
      <form @submit.prevent="bulkEditLicensedStates" class="p-5">
          <div class="grid grid-cols-12 gap-3 lg:gap-6">
              <!-- BEGIN: States -->
              <div class="col-span-12 input-form" :class="{'has-error': licensedStatesForm.errors.states}">
                  <label for="default_mga_id" class="form-label text-gray-600"></label>

                  <div class="relative flex items-start">
                      <div  class="flex my-4 h-4 items-center">
                          <input id="toggle_select_all" name="toggle_select_all" type="checkbox"
                                 :checked="allSelected"
                                 @change="toggleSelectAllStates"
                                 class="h-4 w-4 rounded border-gray-300 text-tb-blue focus:ring-tb-blue cursor-pointer"/>
                          <div class="ml-3 text-sm">
                              <label for="toggle_select_all" class="text-gray-600 cursor-pointer">Select All</label>
                          </div>
                      </div>
                  </div>

                  <div v-for="state in states" :key="state.id" class="relative flex items-start">
                      <div class="flex h-5 items-center">
                          <input :id="`selected_state_${state.id}`" name="selected_states[]" type="checkbox"
                                 class="h-4 w-4 rounded border-gray-300 text-tb-blue focus:ring-tb-blue cursor-pointer"
                                 :value="state.id"
                                 v-model="licensedStatesForm.states" />
                      </div>
                      <div class="ml-3 text-sm">
                          <label :for="`selected_state_${state.id}`" class="text-gray-600 cursor-pointer">{{ state.name }}</label>
                      </div>
                  </div>
                  <div class="error-message mt-1" v-if="licensedStatesForm.errors.states">{{ licensedStatesForm.errors.states }}</div>
              </div>
              <!-- END: States -->
          </div>

          <div class="text-right mt-5">
              <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
                  <LoadingSpinner v-if="loading"></LoadingSpinner>
                  <span v-else>Submit</span>
              </button>
          </div>
      </form>
  </Modal>
  <!-- END: Modal -->
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import helpers from "../../helper";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import AgentAndLeadLicensedStateListItem from "./AgentAndLeadLicensedStateListItem.vue";
import {ElDatePicker} from "element-plus";
import Dropzone from "dropzone";
import Modal from "@/Components/Modal.vue";
import {useForm} from "@inertiajs/inertia-vue3";
import {nextTick} from "vue";
import {Inertia} from "@inertiajs/inertia";

export default {
  name: "AgentStates",

  mixins: [serverValidationErrorsMixin],

  components: {
    AgentAndLeadLicensedStateListItem,
    LoadingSpinner,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ElDatePicker,
    Modal
  },

  props: {
    modelType: {
      type: String,
      required: true,
      validator(value, props) {
        return ['agent', 'lead'].includes(value);
      },
    },
    modelId: {
      type: Number,
      required: true,
    },
    states: {
      type: Array,
      required: true,
    },
  },

  watch: {
    showCreateModal(newValue, oldValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.initDropzone();
        })
      }
    }
  },

  data() {
    return {
      addLicenseUrl: this.isAgentModel ? `/agents/${this.modelId}/licenses` : `/leads/${this.modelId}/licenses`,
      licenses: [],
      files: [],
      selectedState: null,
      expires_at: null,
      file: null,
      file_name: null,
      license_file: null,
      loading: false,
      showCreateModal: false,
      updateStatesModalVisible: false,
      licensedStatesForm: null,

    }
  },

  mounted() {
    this.fetchLicensedStates();
  },

  computed: {
    licensedInStateIds() {
      return this.licenses.map(i => i.state_id);
    },

    statesNotLicensedIn() {
      return this.states.filter(i => {
        return !this.licensedInStateIds.includes(i.id);
      });
    },

    allSelected() {
        return this.states.length === this.licensedStatesForm.states?.length;
    },

    isAgentModel() { // Default
        return !this.modelType || this.modelType !== 'lead';
    },
    isLeadModel() {
        return this.modelType === 'lead';
    },
  },

  methods: {
    fetchLicensedStates() {
      this.loading = true;
      let target = this.isAgentModel ? `/agents/${this.modelId}/licenses` : `/leads/${this.modelId}/licenses`;
      axios
        .get(target)
        .then((response) => {
          this.files = response.data.files;
          this.licenses = response.data.licenses;

          this.licensedStatesForm = useForm({
              states: response.data.licenses.map(i => i.state_id),
          })
        })
        .catch((error) => {
          console.log("Error while fetching licensed states for the agent - AgentAndLeadStates.vue @ fetchLicensedStates", error)
        })
        .finally(() => {
          setTimeout(() => {
              this.loading = false;
          }, 150)
        })
    },

    syncData(payload) {
      return this.licenses[payload.index] = payload.license;
    },

    handleLicenseDeleted(payload) {
      this.licenses.splice(payload.index, 1);
    },

    handleLicenseFileDeleted(payload) {
      this.licenses[payload.index].file_id = null;
      this.licenses[payload.index].file_name = null;
      this.licenses[payload.index].file_path = null;
    },

    handleLicenseFileUpdated() {
      console.log('handleLicenseFileUpdated')
      this.fetchLicensedStates()
    },

    toggleSelectAllStates(e) {
        if (e.target.checked) {
            return this.licensedStatesForm.states = this.states.map(i => i.id);
        } else {
            return this.licensedStatesForm.states = [];
        }
    },

    bulkEditLicensedStates() {
        if (this.loading) return;

        this.loading = true;

        let target = this.isAgentModel ? route('agents.licenses.bulk-update', this.modelId) : route('leads.licenses.bulk-update', this.modelId);
        this.licensedStatesForm.patch(target, {
            preserveScroll: true,
            onSuccess: () => {
                this.updateStatesModalVisible = false;
                $notify.success({
                    message: "Licensed states updated",
                    showClose: true
                })
                this.fetchLicensedStates();
            },
            onError: (error) => {
                console.log(error)
                nextTick(() => {
                    helpers.scrollToClass('has-error');
                })
            },
            onFinish: () => {
                setTimeout(() => {
                    this.loading = false;
                }, 1000);
            }
        })
    }

  },
}
</script>

<style scoped>
.el-input__wrapper {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
</style>