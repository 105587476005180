<script setup>
import {useForm} from "@inertiajs/inertia-vue3";
import {SaveIcon, MinusCircleIcon} from "@heroicons/vue/outline";
import {onMounted} from "vue";

const props = defineProps({
  lead: {type: Object, required: true},
  leadForm: {type: Object, required: true},
  leadFormSubmission: {type: [Object, null]},
});

onMounted(() => {
  fillFormData();
});

function fillFormData() {
  if (!props.leadFormSubmission) return;
  let answers = props.leadFormSubmission.relations.answers;
  for (let i = 0; i < answers.length; i++) {
    let iAnswer = answers[i];
    formData.answers[iAnswer.lead_form_question_id] = iAnswer.answer;
  }
}

const emit = defineEmits(
  'onSubmit',
  'onClose'
);

const formData = useForm({
  answers: [],
});

function submitForm() {
  let cleaned = cleanFormData(formData);
  emit('onSubmit', cleaned);
}
function cleanFormData() {
  let result = [];
  for (let i = 0; i < formData.answers.length; i++) {
    if (typeof formData.answers[i] === "undefined") continue;
    result.push({
      question_id: i,
      answer: formData.answers[i],
    });
  }
  return result;
}
function discard() {
  emit('onClose');
}

</script>
<template>
  <div>
    <form @submit.prevent="submitForm">
    <div v-for="(question, index) in props.leadForm.questions_active" :key="question.id" class="mb-8">
      <h2>{{ question.question }}</h2>
      <textarea type="text" class="form-input w-full" v-model="formData.answers[question.id]" />
    </div>
    <div class="mt-4">
      <button type="submit" class="btn btn-primary"><SaveIcon class="w-4 h-4 mr-2" /> Save</button>
      <button type="button" @click.prevent="discard" class="btn btn-primary ml-4"><MinusCircleIcon class="w-4 h-4 mr-2" /> Discard Changes</button>
    </div>
    </form>
  </div>
</template>