<template>
  <div id="action-schedule-edit-action-modal" class="modal" tabindex="-1" aria-hidden="true" data-tw-backdrop="static">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header flex justify-between">
          <span>Edit Status Action</span>
          <a ref="dismiss-btn" data-tw-dismiss="modal" href="javascript:;">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="x" data-lucide="x" class="lucide lucide-x w-8 h-8 text-slate-400"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </a>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="updateAction" class="intro-y box p-5" method="POST">
            <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
              <!-- BEGIN: Action -->
              <div class="col-span-12 input-form" :class="{'has-error': isInvalid('action_id')}">
                <label for="action_id" class="form-label text-slate-500 flex justify-between">
                  <span class="mr-1">Action</span>
                  <button class="btn btn-link btn-sm mr-1 text-black" data-tw-toggle="modal"
                          data-tw-target="#action-schedule-create-action" type="button">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.99992 2.33331C4.87525 2.33331 2.33325 4.87531 2.33325 7.99998C2.33325 11.1246 4.87525 13.6666 7.99992 13.6666C11.1246 13.6666 13.6666 11.1246 13.6666 7.99998C13.6666 4.87531 11.1246 2.33331 7.99992 2.33331M7.99992 14.6666C4.32392 14.6666 1.33325 11.676 1.33325 7.99998C1.33325 4.32398 4.32392 1.33331 7.99992 1.33331C11.6759 1.33331 14.6666 4.32398 14.6666 7.99998C14.6666 11.676 11.6759 14.6666 7.99992 14.6666"
                            fill="black"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8 11.2239C7.724 11.2239 7.5 10.9999 7.5 10.7239V5.27594C7.5 4.99994 7.724 4.77594 8 4.77594C8.276 4.77594 8.5 4.99994 8.5 5.27594V10.7239C8.5 10.9999 8.276 11.2239 8 11.2239"
                            fill="black"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.2239 7.99994C11.2239 8.27594 10.9999 8.49994 10.7239 8.49994H5.27588C4.99988 8.49994 4.77588 8.27594 4.77588 7.99994C4.77588 7.72394 4.99988 7.49994 5.27588 7.49994H10.7239C10.9999 7.49994 11.2239 7.72394 11.2239 7.99994"
                            fill="black"/>
                    </svg>
                  </button>
                </label>
                <select v-model="formData.action_id" class="form-control" name="" id="action_id">
                  <option v-for="action in availableActions" :value="action.id">{{ action.name }}</option>
                </select>
                <div v-if="isInvalid('action_id')" class="pristine-error text-danger mt-1">{{ errorMessage('action_id') }}</div>
              </div>
              <!-- END: Action -->

              <!-- BEGIN: Hours After -->
              <div class="col-span-12 input-form" :class="{'has-error': isInvalid('hours_after')}">
                <label for="hours_after" class="form-label text-slate-500">Hours After Last Action</label>
                <select v-model="formData.hours_after" class="form-control" name="hours_after" id="hours_after">
                  <option v-for="hour in [...Array(1000).keys()]" :value="hour">{{ hour }} hours</option>
                </select>
                <div v-if="isInvalid('hours_after')" class="pristine-error text-danger mt-1">
                  {{ errorMessage('hours_after') }}
                </div>
              </div>
              <!-- END: Hours After -->

              <!-- BEGIN: Text Template -->
              <div class="col-span-12 input-form" :class="{'has-error': isInvalid('text_template_id')}">
                <label for="text_template_id" class="form-label text-slate-500 flex justify-between">
                  <span class="mr-1">Text Template</span>
                  <button class="btn btn-link btn-sm mr-1 text-black" data-tw-toggle="modal"
                          data-tw-target="#action-schedule-create-action" type="button">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.99992 2.33331C4.87525 2.33331 2.33325 4.87531 2.33325 7.99998C2.33325 11.1246 4.87525 13.6666 7.99992 13.6666C11.1246 13.6666 13.6666 11.1246 13.6666 7.99998C13.6666 4.87531 11.1246 2.33331 7.99992 2.33331M7.99992 14.6666C4.32392 14.6666 1.33325 11.676 1.33325 7.99998C1.33325 4.32398 4.32392 1.33331 7.99992 1.33331C11.6759 1.33331 14.6666 4.32398 14.6666 7.99998C14.6666 11.676 11.6759 14.6666 7.99992 14.6666"
                            fill="black"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8 11.2239C7.724 11.2239 7.5 10.9999 7.5 10.7239V5.27594C7.5 4.99994 7.724 4.77594 8 4.77594C8.276 4.77594 8.5 4.99994 8.5 5.27594V10.7239C8.5 10.9999 8.276 11.2239 8 11.2239"
                            fill="black"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.2239 7.99994C11.2239 8.27594 10.9999 8.49994 10.7239 8.49994H5.27588C4.99988 8.49994 4.77588 8.27594 4.77588 7.99994C4.77588 7.72394 4.99988 7.49994 5.27588 7.49994H10.7239C10.9999 7.49994 11.2239 7.72394 11.2239 7.99994"
                            fill="black"/>
                    </svg>
                  </button>
                </label>
                <select v-model="formData.text_template_id" class="form-control" name="" id="text_template_id">
                  <option value="">Implement</option>
                </select>
                <div v-if="isInvalid('text_template_id')" class="pristine-error text-danger mt-1">
                  {{ errorMessage('text_template_id') }}
                </div>
              </div>
              <!-- END: Text Template -->

              <!-- BEGIN: Email Template -->
              <div class="col-span-12 input-form" :class="{'has-error': isInvalid('email_template_id')}">
                <label for="email_template_id" class="form-label text-slate-500 flex justify-between">
                  <span class="mr-1">Email Template</span>
                  <button class="btn btn-link btn-sm mr-1 text-black" data-tw-toggle="modal"
                          data-tw-target="#action-schedule-create-action" type="button">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.99992 2.33331C4.87525 2.33331 2.33325 4.87531 2.33325 7.99998C2.33325 11.1246 4.87525 13.6666 7.99992 13.6666C11.1246 13.6666 13.6666 11.1246 13.6666 7.99998C13.6666 4.87531 11.1246 2.33331 7.99992 2.33331M7.99992 14.6666C4.32392 14.6666 1.33325 11.676 1.33325 7.99998C1.33325 4.32398 4.32392 1.33331 7.99992 1.33331C11.6759 1.33331 14.6666 4.32398 14.6666 7.99998C14.6666 11.676 11.6759 14.6666 7.99992 14.6666"
                            fill="black"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8 11.2239C7.724 11.2239 7.5 10.9999 7.5 10.7239V5.27594C7.5 4.99994 7.724 4.77594 8 4.77594C8.276 4.77594 8.5 4.99994 8.5 5.27594V10.7239C8.5 10.9999 8.276 11.2239 8 11.2239"
                            fill="black"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.2239 7.99994C11.2239 8.27594 10.9999 8.49994 10.7239 8.49994H5.27588C4.99988 8.49994 4.77588 8.27594 4.77588 7.99994C4.77588 7.72394 4.99988 7.49994 5.27588 7.49994H10.7239C10.9999 7.49994 11.2239 7.72394 11.2239 7.99994"
                            fill="black"/>
                    </svg>
                  </button>
                </label>
                <select v-model="formData.email_template_id" class="form-control" name="" id="email_template_id">
                  <option value="">Implement</option>
                </select>
                <div v-if="isInvalid('email_template_id')" class="pristine-error text-danger mt-1">
                  {{ errorMessage('name') }}
                </div>
              </div>
              <!-- END: Email Template -->

              <!-- BEGIN: Voicemail Script -->
              <div class="col-span-12 input-form" :class="{'has-error': isInvalid('voicemail_script_id')}">
                <label for="voicemail_script_id" class="form-label text-slate-500 flex justify-between">
                  <span class="mr-1">Voicemail Script</span>
                  <button class="btn btn-link btn-sm mr-1 text-black" data-tw-toggle="modal"
                          data-tw-target="#action-schedule-create-action" type="button">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.99992 2.33331C4.87525 2.33331 2.33325 4.87531 2.33325 7.99998C2.33325 11.1246 4.87525 13.6666 7.99992 13.6666C11.1246 13.6666 13.6666 11.1246 13.6666 7.99998C13.6666 4.87531 11.1246 2.33331 7.99992 2.33331M7.99992 14.6666C4.32392 14.6666 1.33325 11.676 1.33325 7.99998C1.33325 4.32398 4.32392 1.33331 7.99992 1.33331C11.6759 1.33331 14.6666 4.32398 14.6666 7.99998C14.6666 11.676 11.6759 14.6666 7.99992 14.6666"
                            fill="black"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8 11.2239C7.724 11.2239 7.5 10.9999 7.5 10.7239V5.27594C7.5 4.99994 7.724 4.77594 8 4.77594C8.276 4.77594 8.5 4.99994 8.5 5.27594V10.7239C8.5 10.9999 8.276 11.2239 8 11.2239"
                            fill="black"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.2239 7.99994C11.2239 8.27594 10.9999 8.49994 10.7239 8.49994H5.27588C4.99988 8.49994 4.77588 8.27594 4.77588 7.99994C4.77588 7.72394 4.99988 7.49994 5.27588 7.49994H10.7239C10.9999 7.49994 11.2239 7.72394 11.2239 7.99994"
                            fill="black"/>
                    </svg>
                  </button>
                </label>
                <select v-model="formData.voicemail_script_id" class="form-control" name="" id="voicemail_script_id">
                  <option value="">Implement</option>
                </select>
                <div v-if="isInvalid('name')" class="pristine-error text-danger mt-1">
                  {{ errorMessage('voicemail_script_id') }}
                </div>
              </div>
              <!-- END: Voicemail Script -->
            </div>

            <div class="text-right mt-5">
              <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
                <span v-show="loading">
                  <svg width="25" viewBox="-2 -2 42 42" xmlns="http://www.w3.org/2000/svg" stroke="rgb(30, 41, 59)" class="w-5 h-5 text-white">
                      <g fill="none" fill-rule="evenodd" stroke="currentColor">
                          <g transform="translate(1 1)" stroke-width="4">
                              <circle stroke-opacity=".5" cx="18" cy="18" r="18"></circle>
                              <path d="M36 18c0-9.94-8.06-18-18-18">
                                  <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"></animateTransform>
                              </path>
                          </g>
                      </g>
                  </svg>
                </span>
                <span v-show="!loading">Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import helpers from "../../helper";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";

export default {
  name: "ActionScheduleUpdateActionModal",

  mixins: [serverValidationErrorsMixin],


  props: {
    user_is_admin: {
      type: Boolean,
      required: true,
    },

    actionData: {
      type: Object,
      required: true,
    },

    availableActions: {
      type: Array,
      required: true,
    }
  },

  emits: ['action-updated'],

  data() {
    return {
      loading: false,
      formData: _.cloneDeep(this.actionData),
    }
  },

  methods: {
    updateAction() {
      if (this.loading) return;
      this.loading = true;



      setTimeout(() => {
        if (this.formData.hasOwnProperty('action_schedule_map_id') && this.formData.action_schedule_map_id !== null) {
          // We want to hit the server, because we are updating existing action schedule map
          axios
            .put(`/action-schedule-maps/${this.formData.action_schedule_map_id}`, this.formData)
            .then((response) => {
              this.handleSuccessfulSubmission(response)
            })
            .catch((error) => {
              this.handleErroredSubmission(error);
            })
            .finally(() => {
              this.loading = false;
            })
        } else {
          // This action schedule map is not yet saved / created, so we update it only on frontend
        }
      }, 500)
    },

    handleSuccessfulSubmission(response) {
      this.hideModal();

      this.$emit('action-updated', {action: response.data});
      this.formData = {
        action_name: null,
        hours_after: null,
        text_template_id: null,
        email_template_id: null,
        voicemail_script_id: null,
      };

      this.errors = {};
    },

    handleErroredSubmission(error) {
      console.log(error);
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        });
      }
      console.log("ActionScheduleEditActionModal - handleErroredSubmission", error.response);
    },

    hideModal() {
      const editActionModal = tailwind.Modal.getInstance(document.getElementById("action-schedule-edit-action-modal"));
      editActionModal.hide();
    },
  },
}
</script>

<style scoped>

</style>