<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="py-2 font-semibold flex items-center justify-start space-x-2 bg-amber-100 px-4 rounded-lg mb-4 border">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
          </svg>
          <span>Toggling access for an agent gives that agent access to login into {{ agentName }}'s account.</span>
        </div>
    </div>
    <div class="mt-8 flex flex-col" v-if="possible_users.length">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th class="py-4 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Toggle Access</th>
                <th class="py-4 pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Agent Name
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(ag) in possible_users">
                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <label class="block relative items-center cursor-pointer">
                      <input type="checkbox" v-model="ag.accessible" @change="toggleAccess(ag)" id="toggle_notification" class="sr-only peer">
                      <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-tb-blue"></div>
                    </label>
                    <!-- <button class="btn-danger text-sm" @click="confirmDelete">Revoke access</button> -->
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{{ ag.last_name + ", " + ag.first_name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full text-center my-4">
      No access given to any user..
    </div>
  </div>

  <!-- BEGIN: Create Access Modal -->
  <TransitionRoot as="template" :show="showCreateModal">
    <Dialog as="div" class="relative z-10" @close="showCreateModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">Assign new carrier</DialogTitle>
                  <div>
                    <form @submit.prevent="grantAccess" class="intro-y box p-5" method="POST">
                      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
                        <!-- BEGIN: accessible_by -->
                        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('user_id')}">
                          <label for="user_id" class="form-label text-gray-600">User</label>
                          <select class="input-field" name="user_id" id="user_id" v-model="user_id">
                            <option value="">—</option>
                            <option v-for="user in possible_users" :key="user.id" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                          </select>
                          <div v-if="isInvalid('user_id')" class="pristine-error  text-danger mt-1">{{ errorMessage('user_id') }}</div>
                        </div>
                        <!-- END: accessible_by -->
                      </div>

                      <div class="text-right mt-5">
                        <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
                          <LoadingSpinner v-if="loading"></LoadingSpinner>
                          <span v-else>Submit</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- END: Create Access Modal -->
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import { truncate } from "lodash";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import AgentAccessListItem from "./AgentAccessListItem.vue";

export default {
  name: "AgentAccess",

  mixins: [serverValidationErrorsMixin],

  components: {
    LoadingSpinner,
    AgentAccessListItem,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },

  props: {
    agentId: {
      type: Number,
      required: true,
    },

    agentName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      account_accessible_by: [],
      user_id: null,
      possible_users: [],
      loading: false,
      showCreateModal: false,
    }
  },

  mounted() {
    this.fetchAccountAccess();
  },

  methods: {
    toggleAccess(agent){
      console.log('toggleAccess', agent)
      console.log('agent.accessible', agent.accessible)
      if (agent.accessible) {
        this.grantAccess(agent.id)
      } else {
        this.deleteAccess(agent.id)
      }
    },
    fetchAccountAccess() {
      this.loading = true;
      axios
        .get(`/agents/${this.agentId}/account-access`)
        .then((response) => {
          this.account_accessible_by = response.data.account_accessible_by;
          const agents = response.data.agents.map(item => {
            const found = this.account_accessible_by.find(ac => ac.accessible_by == item.id)
            if(found) {
              item.accessible = true;
            } else {
              item.accessible = false;
            }
            return item;
          })
          this.possible_users = response.data.agents;
        })
        .catch((error) => {
          // Handle error
        })
        .finally(() => {
          this.loading = false;
        })
    },

    grantAccess(user_id) {
      this.loading = true;
      setTimeout(() => {
        axios
          .post(`/agents/${this.agentId}/account-access`, {
            user_id: user_id
          })
          .then((response) => {
            this.fetchAccountAccess();
          })
          .catch((error) => {
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    deleteAccess(accessible_by) {
      this.loading = true;
      axios
        .delete(`/agents/${this.agentId}/account-access/${accessible_by}/revoke`)
        .then((response) => {
          this.fetchAccountAccess();
        })
        .catch((error) => {
          Swal.fire({
            text: 'Unknown error occurred, please try again.',
            icon: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    syncData(event) {
      let elementPos = this.notes.map(function(x) {return x.id; }).indexOf(event.note.id);
      this.notes[elementPos] = event.note;
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        })
      }
      console.log("AgentNotes - handleErroredSubmission", error.response);
    },
  },
}
</script>

<style scoped>

</style>
