<script setup>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {ref, nextTick, computed, onMounted, onBeforeMount, watch, onBeforeUnmount} from "vue";
import Cleave from "cleave.js";


const props = defineProps({
  formData: Object,
  loading: Boolean,
})

const input = computed(() => {
    return props.formData.url
});

const agencybookmarkurl = ref(null);

function isInvalid(attribute) {
    return props.formData.errors?.hasOwnProperty(attribute);
}
function errorMessage(attribute) {
    if (props.formData.errors?.hasOwnProperty(attribute)) {
        return props.formData?.errors[attribute][0];
    }
}

onMounted(() => {
    nextTick(() => {
        new Cleave(`#agencybookmarkurl`, {
            prefix: 'https://',
            noImmediatePrefix: true,
        });

        agencybookmarkurl.value.addEventListener('paste', handlePaste);
    })
});

onBeforeUnmount(() => {
    agencybookmarkurl.value.removeEventListener('paste', handlePaste);
})

function handlePaste(event) {
    // Get the pasted text from the event
    const pastedText = event.clipboardData.getData('text/plain');

    // Remove "https://" and "http://" from the pasted text
    const modifiedText = pastedText.replace(/(https?:\/\/)/g, '');

    // Prevent the default paste action
    event.preventDefault();

    // Insert the modified text into the textarea
    document.execCommand('insertText', false, modifiedText);
}

const emit = defineEmits(['submit']);
</script>

<template>
    <form @submit.prevent="emit('submit')" class="intro-y box p-5">

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
        <!-- BEGIN: Name -->
        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('name')}">
            <label for="first_name" class="form-label  text-gray-600">Name</label>
            <input v-model="formData.name" id="name" type="text" class="input-field w-full" name="name">
            <div v-if="isInvalid('name')" class="error-message mt-1">{{ errorMessage('name') }}</div>
        </div>
    <!-- END: Name -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
    <!-- BEGIN: URL -->
        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('url')}">
            <label for="first_name" class="form-label  text-gray-600">URL</label>
            <input v-model="formData.url" ref="agencybookmarkurl" id="agencybookmarkurl" type="text" class="input-field w-full" url="url">
            <div v-if="isInvalid('url')" class="error-message mt-1">{{ errorMessage('url') }}</div>
        </div>
    <!-- END: URL -->
    </div>


    <div class="text-right mt-5">
      <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <span v-else>Submit</span>
      </button>
    </div>
  </form>
</template>
