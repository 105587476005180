<template>
  <form @submit.prevent="submit" class="p-5" method="POST">
    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Lead Date -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('lead_date')}">
        <label for="lead_date_modal_form" class="block text-left form-label text-sm text-gray-600">Lead Date</label>
        <el-date-picker
          style="width: 100% !important;"
          v-model="formData.lead_date"
          type="date"
          placeholder="Pick a day"
          format="MMM DD, YYYY"
          disabled
        />
        <div v-if="isInvalid('lead_date')" class="error-message">{{ errorMessage('lead_date') }}</div>
      </div>
      <!-- END: Lead Date -->

      <!-- BEGIN: Company -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('company')}">
        <label for="company_modal_form" class="block text-left form-label text-sm text-gray-600">Company</label>
        <input @keydown.enter="$event.preventDefault()" ref="company" v-model="formData.company" id="company_modal_form" type="text" class="form-control form-control-sm input-field w-full" name="company">
        <div v-if="isInvalid('company')" class="error-message">{{ errorMessage('company') }}</div>
      </div>
      <!-- END: Company -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: First name -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('first_name')}">
        <label for="first_name_modal_form" class="block text-left form-label text-sm text-gray-600">First Name</label>
        <input @keydown.enter="$event.preventDefault()" v-model="formData.first_name" id="first_name_modal_form" type="text" class="form-control form-control-sm input-field w-full" name="first_name">
        <div v-if="isInvalid('first_name')" class="error-message">{{ errorMessage('first_name') }}</div>
      </div>
      <!-- END: First name -->

      <!-- BEGIN: Last name -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('last_name')}">
        <label for="last_name_modal_form" class="block text-left form-label text-sm text-gray-600">Last Name</label>
        <input @keydown.enter="$event.preventDefault()" v-model="formData.last_name" id="last_name_modal_form" type="text" class="form-control form-control-sm input-field w-full" name="last_name">
        <div v-if="isInvalid('last_name')" class="error-message">{{ errorMessage('last_name') }}</div>
      </div>
      <!-- END: Last name -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Address 1 -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('address_1')}">
        <label for="address_1_modal_form" class="block text-left form-label text-sm text-gray-600">Address</label>
        <input @keydown.enter="$event.preventDefault()" v-model="formData.address_1" id="address_1_modal_form" type="text" class="form-control form-control-sm input-field w-full" name="address_1">
        <div v-if="isInvalid('address_1')" class="error-message">{{ errorMessage('address_1') }}</div>
      </div>
      <!-- END: Address 1 -->

      <!-- BEGIN: City -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('city')}">
        <label for="city_modal_form" class="block text-left form-label text-sm text-gray-600">City</label>
        <input @keydown.enter="$event.preventDefault()" v-model="formData.city" id="city_modal_form" type="text" class="form-control form-control-sm input-field w-full" name="city">
        <div v-if="isInvalid('city')" class="error-message">{{ errorMessage('city') }}</div>
      </div>
      <!-- END: City -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: State Id -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('state_id')}">
        <label for="state_id_modal_form" class="block text-left form-label text-sm text-gray-600">State</label>
        <select @keydown.enter="$event.preventDefault()" v-model="formData.state_id" data-placeholder="Select state" class="tom-select input-field w-full" id="state_id_modal_form" name="state_id">
          <option v-for="(state, i) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
        </select>
        <div v-if="isInvalid('state_id')" class="error-message">{{ errorMessage('state_id') }}</div>
      </div>
      <!-- END: State Id -->

      <!-- BEGIN: Zip -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('zip')}">
        <label for="zip_modal_form" class="block text-left form-label text-sm text-gray-600">Zip</label>
        <input @keydown.enter="$event.preventDefault()" v-model="formData.zip" id="zip_modal_form" type="text" name="zip" class="form-control form-control-sm input-field w-full">
        <div v-if="isInvalid('zip')" class="error-message">{{ errorMessage('zip') }}</div>
      </div>
      <!-- END: Zip -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Email -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('email')}">
        <label for="email_modal_form" class="block text-left form-label text-sm text-gray-600">Email</label>
        <input @keydown.enter="$event.preventDefault()" @change="checkForDuplicateEmail" v-model="formData.email" id="email_modal_form" type="email" name="email" class="form-control form-control-sm input-field w-full">
        <div v-if="isInvalid('email')" class="error-message">{{ errorMessage('email') }}</div>
        <div v-if="duplicateEmailCheckMessage" class="text-left text-sm mt-1" v-html="duplicateEmailCheckMessage"></div>
      </div>
      <!-- END: Email -->

      <!-- BEGIN: Phone -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('phone')}">
        <label for="phone_modal_form" class="block text-left form-label text-sm text-gray-600">Phone</label>
        <MaskedPhoneInput @onEnter="$event.preventDefault()" @change="checkForDuplicatePhone" v-model="formData.phone" element-id="phone_modal_form" type="text" name="phone" class="form-control form-control-sm input-field w-full" />
        <div v-if="isInvalid('phone')" class="error-message">{{ errorMessage('phone') }}</div>
        <div v-if="duplicatePhoneCheckMessage" class="text-left text-sm mt-1" v-html="duplicatePhoneCheckMessage"></div>
      </div>
      <!-- END: Phone -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Source ID -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('source_id')}">
        <label for="source_id_modal_form" class="block text-left form-label text-sm text-gray-600">Source</label>
        <select v-model="formData.source_id" data-placeholder="Select source" class="tom-select input-field w-full" id="source_id_modal_form" name="source_id">
          <option v-for="(leadSource, index) in sources" :key="`ls_${leadSource.id}`" :value="leadSource.id" :label="`${leadSource.vendor} - ${leadSource.label}`"></option>
        </select>
        <div v-if="isInvalid('source_id')" class="error-message">{{ errorMessage('source_id') }}</div>
      </div>
      <!-- END: Source ID -->

      <!-- BEGIN: Lead Status ID -->
      <div class="col-span-12 lg:col-span-6" :class="{'has-error': isInvalid('lead_status_id')}">
        <label for="lead_status_modal_form" class="block text-left form-label text-sm text-gray-600">Lead Status</label>
        <select v-model="formData.lead_status_id" data-placeholder="Select status" class="tom-select input-field w-full" id="lead_status_modal_form" name="lead_status_id">
          <option v-for="status in new_lead_statuses" :key="status.id" :value="status.id">{{ status.name }}</option>
        </select>
        <div v-if="isInvalid('lead_status_id')" class="error-message">{{ errorMessage('lead_status_id') }}</div>
      </div>
      <!-- END: Lead Status ID -->
    </div>

    <div class="text-right mt-10">
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense" :class="{'pointer-events-none': loading}">
        <button :disabled="loading" type="submit" class="btn-primary sm:col-start-2">
          <span v-if="loading">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
          <span v-else>Submit</span>
        </button>
        <button :disabled="loading" type="button" class="mt-3 w-full btn-secondary sm:mt-0 sm:col-start-1" @click="$emit('close-lead-form-modal')">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
import serverValidationErrorsMixin from "./mixins/serverValidationErrorsMixin";
import helpers from "../helper";
import {ElDatePicker} from 'element-plus';
import MaskedPhoneInput from "@/Components/MaskedPhoneInput.vue";

export default {
  name: "AppLeadCreateForm",

  mixins: [serverValidationErrorsMixin],

  components: {
    MaskedPhoneInput,
    ElDatePicker,
  },

  props: {
    states: {
      type: Array,
      default: () => [],
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    new_lead_statuses: {
      type: Array,
      default: () => [],
    },
    sources: {
        type: Array,
        default: () => []
    }
  },

  mounted() {
    this.$refs.company.focus();
  },

  emits: ['close-lead-form-modal'],

  data() {
    return {
      formData: {
        lead_date: new Date(),
        company: null,
        first_name: null,
        last_name: null,
        address_1: null,
        city: null,
        state_id: null,
        zip: null,
        email: null,
        phone: null,
        source_id: null,
        lead_status_id: 1,
      },
      loading: false,
      checkingDuplicates: null,
      duplicatePhoneCheckMessage: null,
      duplicateEmailCheckMessage: null,
    }
  },

  methods: {
    submit(e) {
      if (this.loading) return;
      this.loading = true;

      setTimeout(() => {
        axios
          .post('/leads', this.formData)
          .then((response) => {
            this.handleSuccessfulSubmission(response);
          })
          .catch((error) => {
            console.log(error, 'AppLeadCreateForm - submit()')
            this.loading = false;
            this.handleErroredSubmission(error);
          });
      }, 500)
    },

    handleSuccessfulSubmission(response) {
      this.hideModal();
      if (response.data && response.data.redirect_url) {
        // Redirect to lead show page
        this.$inertia.get(response.data.redirect_url);
      } else {
        console.log('lead created');
      }
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        });
      }
      console.log("AppLeadCreateForm - handleErroredSubmission", error.response);
    },

    updateLeadDate(dateObj) {
      this.formData.lead_date = dateObj.dateInstance.toDateString();
    },

    hideModal() {
      // const createLeadModal = tailwind.Modal.getInstance(document.querySelector("#create-lead-modal"));
      // createLeadModal.hide();
      this.cleanUp();
      this.$emit('close-lead-form-modal');
    },

    cleanUp() {
      this.errors = {};
      this.formData = {
        lead_date: new Date().toDateString(),
        company: null,
        first_name: null,
        last_name: null,
        address_1: null,
        city: null,
        state_id: null,
        zip: null,
        phone: null,
        email: null,
        source_id: null,
        lead_status_id: null,
      };
    },

    checkForDuplicatePhone() {
      if(this.checkingDuplicates) return;
      if (!this.formData.phone) {
        this.duplicatePhoneCheckMessage = null;
        return;
      }
      this.checkingDuplicates = true;
      let cleanPhone = this.formData.phone.replace(/\D/g, '');
      axios
        .get(route('api.check-duplicate-leads'), {params: {
          field: 'phone',
          value: cleanPhone,
        }})
        .then(response => {
          if (response.data.message) {
            this.duplicatePhoneCheckMessage = response.data.message;
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.checkingDuplicates = false;
        })
    },
    checkForDuplicateEmail() {
      if(this.checkingDuplicates) return;
      if (!this.formData.email) {
        this.duplicateEmailCheckMessage = null;
        return;
      }
      this.checkingDuplicates = true;
      axios
        .get(route('api.check-duplicate-leads'), {params: {
            field: 'email',
            value: this.formData.email
          }})
        .then(response => {
          if (response.data.message) {
            this.duplicateEmailCheckMessage = response.data.message;
          }
        })
        .catch((error) => {

        })
        .finally(() => {
          this.checkingDuplicates = false;
        })
		},
  },
}
</script>

<style scoped></style>
