<script setup>
import {onMounted, ref} from "vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {useForm} from "@inertiajs/inertia-vue3";

const props = defineProps({
  agentId: {
    type: Number,
    required: true,
  },
  carrierId: {
    type: Number,
    required: true,
  },
  availableAgents: {
    type: Object,
    required: false,
  },
  placeholderText: {
    type: String,
    default: 'Select agent to transfer downline to',
  }
});

const loading = ref(false);
const emits = defineEmits(['done']);
const formData = useForm({
  to_agent_id: null,
});

onMounted(() => {
  loadData();
});
const dataAvailableAgents = ref(null);
const loadData = function(){
  if (props.availableAgents) {
    // No need for async load
    dataAvailableAgents.value = props.availableAgents;
    return;
  }
  loading.value = true;
  axios.get(route('agent-carriers.agents-with-access', {agent: props.agentId, carrier: props.carrierId}))
    .then(r => {
      dataAvailableAgents.value = r.data.agents;
    })
    .catch(e => {
      console.error(e);
    })
    .finally(() => {
      loading.value = false;
    })
}

const handleSubmit = function() {
  if (!formData.to_agent_id) return;
  loading.value = true;
  formData.put(route('agent-carriers.transfer-downline', {agent: props.agentId, carrier: props.carrierId}), {
    onSuccess: function(){
      $notify.success({message: 'Downline transferred'});
      emits('done');
    },
    onError: function(e) {
      console.error(e);
      $notify.warning({message: 'Something went wrong'});
    },
    onFinish: function() {
      loading.value = false;
    },
  });
}
</script>
<template>
<div>
  <div v-if="loading" class="flex justify-center items-center">
    <LoadingSpinner color-class="tb-blue" v-if="loading" class="w-4 h-4"></LoadingSpinner>
  </div>
  <div v-if="dataAvailableAgents">
    <form @submit.prevent="handleSubmit">
      <div class="flex gap-2">
        <div class="w-3/4">
          <select v-model="formData.to_agent_id" data-placeholder="Select an agent" class="input-field">
            <option :value="null">{{ placeholderText }}</option>
            <option v-for="(agent, index) in dataAvailableAgents" :value="agent.id">{{ agent.last_name }}, {{ agent.first_name }}</option>
          </select>
        </div>
        <div>
          <button type="submit" class="btn btn-primary text-sm">Transfer</button>
        </div>
      </div>
    </form>
  </div>
</div>
</template>
<style scoped>
</style>