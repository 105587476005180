<template>
  <tr>
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{{ access.name }}</td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
      <select class="input-field" name="daily_limit" id="daily_limit" v-model="data.daily_limit" @change="createBucketAccess">
        <option key="no-access" value="no-access">No access</option>
        <option v-for="(limit, i) in limits" :key="i" :value="limit.value">{{ limit.label }}</option>
      </select>
      <div v-if="isInvalid('daily_limit')" class="pristine-error  text-danger mt-1">{{ errorMessage('daily_limit') }}</div>
    </td>
    <!-- <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
      <button class="btn-primary text-sm mr-2" @click="createBucketAccess">Save</button>
      <button class="btn-secondary text-sm" @click="cancel">Cancel</button>
      <div v-if="isInvalid('daily_limit')" class="pristine-error text-sm text-danger mt-1">&nbsp;</div>
    </td> -->
  </tr>
</template>

<script>
import _ from "lodash";
import helpers from "../../helper";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";

export default {
  name: "AgentBucketNoAccessListItem",

  mixins: [serverValidationErrorsMixin],

  props: {
    agentId: {
      type: Number,
      required: true,
    },
    access: {
      type: Object,
      required: true,
    },
    limits: {
      required: true,
      default: () => [],
    }
  },

  emits: ['bucket-limit-updated', 'refetch-data'],

  data() {
    return {
      data: _.cloneDeep(this.access),
      editMode: false,
    }
  },

  methods: {
    createBucketAccess() {
      this.loading = true;
      setTimeout(() => {
        axios
          .post(`/agents/${this.agentId}/bucket-access`, {
            bucket_id: this.access.id,
            daily_limit: this.data.daily_limit
          })
          .then((response) => {
            this.$emit('refetch-data');
          })
          .catch((error) => {
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    confirmDelete() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to undo this.',
        icon: 'warning',
        confirmButtonText: "Yes, I'm sure.",
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAccess();
        }
      })
    },

    updateAccess() {
      console.log('this.access', this.access)
      this.loading = false;
      axios
        .put(`/agents/${this.agentId}/bucket-access/${this.access.id}`, {
          daily_limit: this.data.daily_limit,
        })
        .then((response) => {
          this.$emit('bucket-limit-updated', { access: response.data });
          this.data = _.cloneDeep(response.data);
          this.editMode = false;
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.status === 422) {
            this.errors = error.response.data.errors;
            this.$nextTick(() => {
              helpers.scrollToClass('has-error');
            })
          } else {
            Swal.fire({
              text: 'Unknown error occurred, please try again.',
              icon: 'error'
            })
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },

    deleteAccess() {
      this.loading = true;
      axios
        .delete(`/agents/${this.agentId}/bucket-access/${this.data.id}`)
        .then((response) => {
          this.$emit('refetch-data');
        })
        .catch((error) => {
          Swal.fire({
            text: 'Unknown error occurred, please try again.',
            icon: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    cancel() {
      this.editMode = false;
    },
  },
}
</script>

<style scoped>

</style>