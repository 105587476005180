<script setup>
import {useForm} from "@inertiajs/inertia-vue3";
import {computed, ref} from "vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {debounce} from "lodash";

const props = defineProps({
  lead: Object,
  leadBillingInfo: Object,
  states: Object,
});

const loading = ref(false);
const hasAuthenticatedAccess = ref(false);
const authenticatedAccessTimeout = 30 * 60 * 1000; // 30 Minutes
const authenticationPassword = ref(null);
let voidAuthenticationInterval = null;

const formData = useForm({
    ssn: null,
    birth_state_id: null,
    billing_address: null,
    billing_state_id: null,
    billing_city: null,
    billing_zip: null,
    billing_cc: null,
    exp_month: null,
    exp_year: null,
    primary_occupation: null,
    primary_employer: null,
    primary_employer_address: null,
    primary_employer_city: null,
    primary_employer_state_id: null,
    primary_employer_zip: null,
    primary_employer_phone: null,
    primary_income: null,
    spouse_employer: null,
    spouse_employer_address: null,
    spouse_employer_city: null,
    spouse_employer_state_id: null,
    spouse_employer_zip: null,
    spouse_employer_phone: null,
    spouse_income: null,
    household_members: null,
    doctor: null,
    doctor_address: null,
    doctor_city: null,
    doctor_state_id: null,
    doctor_zip: null,
    medications: null,
  });

const handleSubmit = function() {
  loading.value = true;
  formData.put(route('leads.billing-info.update', { lead: props.lead.id }), {
    preserveScroll: true,
    only: ['leadBillingInfo'],
    onSuccess() {
      $notify.success("Billing info updated");
    },
    onFinish() {
      loading.value = false;
    }
  })
}


const canSubmitAuthentication = computed(() => Boolean(authenticationPassword));
let debouncedResetVoidAuthenticationTimeout = debounce(function(){
  clearTimeout(voidAuthenticationInterval);
  voidAuthenticationInterval = setTimeout(() => {
    formData.reset();
    hasAuthenticatedAccess.value = false;
  }, authenticatedAccessTimeout);
}, 600);
const resetVoidAuthenticationTimeout = function() {
  debouncedResetVoidAuthenticationTimeout();
}
const authenticateBilling = function() {
  loading.value = true;
  axios
    .post(route('leads.billing-info.get-data', {lead: props.lead.id}), {
      password: authenticationPassword.value,
    })
    .then(r => {
      console.log('response', r);
      if (!r.data.billing_info) {
        return $notify.error("Authentication succeeded but got a bad server response");
      }
      let fields = Object.keys(formData.data());
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        formData[field] = r.data.billing_info[field];
      }
      hasAuthenticatedAccess.value = true;
      resetVoidAuthenticationTimeout();
    })
    .catch(e => {
      if (e.response?.data?.message) {
        $notify.warning(e.response.data.message);
      }
      if (e.response?.data?.bad_password) {
        $notify.warning("Wrong password");
      }
    })
    .finally(() => {
      authenticationPassword.value = '';
      loading.value = false;
    });

}
</script>

<template>
  <div @mouseover="resetVoidAuthenticationTimeout" class="p-4">
    <div v-if="loading"  class="absolute inset-0 z-[99999!important;] bg-white/80 flex justify-center items-center rounded-lg transition transition-opacity duration-300 opacity-100">
      <LoadingSpinner color-class="text-tb-blue h-8 w-8"></LoadingSpinner>
    </div>
    <div v-if="hasAuthenticatedAccess">
      <form @submit.prevent="handleSubmit">
        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.ssn}">
            <label class="block text-gray-600">SSN</label>
            <input v-model="formData.ssn" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.ssn" class="error-message">{{ formData.errors.ssn }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.birth_state_id}">
            <label class="block text-gray-600">Birth State</label>
            <select v-model="formData.birth_state_id" class="input-field form-control w-full">
              <option :value="null"></option>
              <option v-for="(state, index) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
            </select>
            <div v-if="formData.errors.birth_state_id" class="error-message">{{ formData.errors.birth_state_id }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.billing_address}">
            <label class="block text-gray-600">Billing Address</label>
            <input v-model="formData.billing_address" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.billing_address" class="error-message">{{ formData.errors.billing_address }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.billing_state_id}">
            <label class="block text-gray-600">Billing State</label>
            <select v-model="formData.billing_state_id" class="input-field form-control w-full">
              <option :value="null"></option>
              <option v-for="(state, index) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
            </select>
            <div v-if="formData.errors.billing_state_id" class="error-message">{{ formData.errors.billing_state_id }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.billing_city}">
            <label class="block text-gray-600">Billing City</label>
            <input v-model="formData.billing_city" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.billing_city" class="error-message">{{ formData.errors.billing_city }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.billing_zip}">
            <label class="block text-gray-600">Billing Zip</label>
            <input v-model="formData.billing_zip" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.billing_zip" class="error-message">{{ formData.errors.billing_zip }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.billing_cc}">
            <label class="block text-gray-600">Billing Card</label>
            <input v-model="formData.billing_cc" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.billing_cc" class="error-message">{{ formData.errors.billing_cc }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.exp_month}">
            <label class="block text-gray-600">Exp. Month</label>
            <input v-model="formData.exp_month" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.exp_month" class="error-message">{{ formData.errors.exp_month }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.exp_year}">
            <label class="block text-gray-600">Exp. Year</label>
            <input v-model="formData.exp_year" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.exp_year" class="error-message">{{ formData.errors.exp_year }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.primary_occupation}">
            <label class="block text-gray-600">Primary Occupation</label>
            <input v-model="formData.primary_occupation" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.primary_occupation" class="error-message">{{ formData.errors.primary_occupation }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.primary_income}">
            <label class="block text-gray-600">Primary Income</label>
            <input v-model="formData.primary_income" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.primary_income" class="error-message">{{ formData.errors.primary_income }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.primary_employer}">
            <label class="block text-gray-600">Primary Employer</label>
            <input v-model="formData.primary_employer" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.primary_employer" class="error-message">{{ formData.errors.primary_employer }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.primary_employer_phone}">
            <label class="block text-gray-600">Primary Employer Phone</label>
            <input v-model="formData.primary_employer_phone" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.primary_employer_phone" class="error-message">{{ formData.errors.primary_employer_phone }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.primary_employer_address}">
            <label class="block text-gray-600">Primary Employer Address</label>
            <input v-model="formData.primary_employer_address" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.primary_employer_address" class="error-message">{{ formData.errors.primary_employer_address }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.primary_employer_state_id}">
            <label class="block text-gray-600">Primary Employer State</label>
            <select v-model="formData.primary_employer_state_id" class="input-field form-control w-full">
              <option :value="null"></option>
              <option v-for="(state, index) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
            </select>
            <div v-if="formData.errors.primary_employer_state_id" class="error-message">{{ formData.errors.primary_employer_state_id }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.primary_employer_city}">
            <label class="block text-gray-600">Primary Employer City</label>
            <input v-model="formData.primary_employer_city" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.primary_employer_city" class="error-message">{{ formData.errors.primary_employer_city }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.primary_employer_zip}">
            <label class="block text-gray-600">Primary Employer Zip</label>
            <input v-model="formData.primary_employer_zip" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.primary_employer_zip" class="error-message">{{ formData.errors.primary_employer_zip }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.spouse_employer}">
            <label class="block text-gray-600">Spouse Employer</label>
            <input v-model="formData.spouse_employer" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.spouse_employer" class="error-message">{{ formData.errors.spouse_employer }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.spouse_income}">
            <label class="block text-gray-600">Spouse Income</label>
            <input v-model="formData.spouse_income" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.spouse_income" class="error-message">{{ formData.errors.spouse_income }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.spouse_employer_address}">
            <label class="block text-gray-600">Spouse Employer Address</label>
            <input v-model="formData.spouse_employer_address" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.spouse_employer_address" class="error-message">{{ formData.errors.spouse_employer_address }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.spouse_employer_state_id}">
            <label class="block text-gray-600">Spouse Employer State</label>
            <select v-model="formData.spouse_employer_state_id" class="input-field form-control w-full">
              <option :value="null"></option>
              <option v-for="(state, index) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
            </select>
            <div v-if="formData.errors.spouse_employer_state_id" class="error-message">{{ formData.errors.spouse_employer_state_id }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.spouse_employer_city}">
            <label class="block text-gray-600">Spouse Employer City</label>
            <input v-model="formData.spouse_employer_city" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.spouse_employer_city" class="error-message">{{ formData.errors.spouse_employer_city }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.spouse_employer_zip}">
            <label class="block text-gray-600">Spouse Employer Zip</label>
            <input v-model="formData.spouse_employer_zip" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.spouse_employer_zip" class="error-message">{{ formData.errors.spouse_employer_zip }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.spouse_employer_phone}">
            <label class="block text-gray-600">Spouse Employer Phone</label>
            <input v-model="formData.spouse_employer_phone" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.spouse_employer_phone" class="error-message">{{ formData.errors.spouse_employer_phone }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.household_members}">
            <label class="block text-gray-600">Household Members</label>
            <input v-model="formData.household_members" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.household_members" class="error-message">{{ formData.errors.household_members }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.doctor}">
            <label class="block text-gray-600">Doctor</label>
            <input v-model="formData.doctor" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.doctor" class="error-message">{{ formData.errors.doctor }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.doctor_address}">
            <label class="block text-gray-600">Doctor Address</label>
            <input v-model="formData.doctor_address" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.doctor_address" class="error-message">{{ formData.errors.doctor_address }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.doctor_state_id}">
            <label class="block text-gray-600">Doctor State</label>
            <select v-model="formData.doctor_state_id" class="input-field form-control w-full">
              <option :value="null"></option>
              <option v-for="(state, index) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
            </select>
            <div v-if="formData.errors.doctor_state_id" class="error-message">{{ formData.errors.doctor_state_id }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.doctor_city}">
            <label class="block text-gray-600">Doctor City</label>
            <input v-model="formData.doctor_city" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.doctor_city" class="error-message">{{ formData.errors.doctor_city }}</div>
          </div>
          <div class="w-full" :class="{'has-error': formData.errors.doctor_zip}">
            <label class="block text-gray-600">Doctor Zip</label>
            <input v-model="formData.doctor_zip" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.doctor_zip" class="error-message">{{ formData.errors.doctor_zip }}</div>
          </div>
        </div>

        <div class="md:flex md:items-end md:space-x-4 mt-3">
          <div class="w-full" :class="{'has-error': formData.errors.medications}">
            <label class="block text-gray-600">Medications</label>
            <textarea v-model="formData.medications" type="text" class="input-field form-control w-full" />
            <div v-if="formData.errors.medications" class="error-message">{{ formData.errors.medications }}</div>
          </div>
        </div>

        <div class="mt-3">
          <button type="submit" class="btn btn-primary" :disabled="loading" :class="{'opacity-30': loading}">Save</button>
        </div>
      </form>
    </div>
    <div v-else>
      <h2 class="text-xl text-center">Secured Tab</h2>
      <div class="text-center text-sm">Please enter your TopBroker password to access this information</div>
      <form method="POST" @submit.prevent="authenticateBilling">
        <div class="flex flex-1 justify-content-center justify-center space-x-3">
          <input type="password" class="input-field max-w-[300px]" v-model="authenticationPassword" />
          <button :disabled="!canSubmitAuthentication" type="submit" class="btn btn-primary" :class="{'opacity-30': !canSubmitAuthentication}">Authenticate</button>
        </div>
      </form>
    </div>

  </div>
</template>