<script setup>
import helpers from "@/helper";
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import { Head, Link, useForm } from '@inertiajs/inertia-vue3';
import Pagination from "@/Components/Pagination.vue";
import {ref, computed, nextTick} from "vue";
import Modal from "@/Components/Modal.vue";
import {Inertia} from "@inertiajs/inertia";
import AgencyManageDepartments from "./AgencyManageDepartments.vue";


const props = defineProps({
    departments: Object,
    agencyId: Number
})

const departmentSkills = ref([]);

function showCreateMessageModal() {
  messageModalVisible.value = true;
}

const messageModalVisible = ref(false);
const createMode = ref(true);

const departmentFormName = computed(() => {
  let mode = formData.createMode ? 'Create Department' : 'Edit Department';
  return `${mode}`;
})

function submitForm() {
  if (formData.loading) return;
  formData.loading = false;
  setTimeout(() => {
    if (formData.createMode) {
      return createDepartment();
    }
    updateDepartment();
  }, 500)
}
function createDepartment() {
  formData.post(route('agencies.department_skills.store', props.agencyId),
    {
      preserveScroll: true,
      onSuccess: () => {
        formData.reset()
        messageModalVisible.value = false
        $notify.success({
        title: 'Success',
        message: 'Department successfully created.',
        showClose: true,
      })
      },
      onError: (error) => {
        console.log(error)
        nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      }
    },
  );
}
function updateDepartment() {
  formData.put(route('agencies.department_skills.update', [props.agencyId, formData.departments]),
    {
      preserveScroll: true,
      onSuccess: () => {
        setTimeout(() => {
        formData.defaults({
          name: null,
          send_text: null,
          createMode: true,
        })
        formData.reset()
        }, 300)

        messageModalVisible.value = false
        $notify.success({
        title: 'Success',
        message: 'Department successfully updated.',
        showClose: true,
      })
      },
      onError: (error) => {
        console.log(error)
        nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      }
    },
  );
}

const formData = useForm({
  departments: null,
  name: null,
  send_text: 0,
  createMode: true,
  loading: false,
});

function openEditForm(skill) {
  formData.departments = skill.id;
  formData.name = skill.name;
  formData.send_text = skill.send_text;
  formData.createMode = false;
  messageModalVisible.value = true;
}

function closeMessageModal() {
  messageModalVisible.value = false;
  setTimeout(() => {
    formData.reset();
    formData.clearErrors();
  }, 300)
}

function confirmDelete(skill) {
  console.log(skill)
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to undo this.',
    icon: 'warning',
    confirmButtonText: "Yes, I'm sure.",
    showCancelButton: true,
    confirmButtonColor: '#B91C1C',
    cancelButtonColor: '#899CC1'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteMessage(skill);
    }
  })
}
function deleteMessage(skill) {
  Inertia.delete(route('agencies.department_skills.destroy', [props.agencyId, skill.id]), {
    preserveScroll: true,
    onError: (error) => {
      console.log(error)
    }
  })
}

</script>

<template>
  <Head title="Departments" />

    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 flex flex-col md:flex-row w-full items-center justify-end space-y-2 space-x-0 md:space-x-2 md:space-y-0">
          <button @click.prevent="showCreateMessageModal" class="btn btn-primary">Create Department</button>
        </div>
      </div>

      <!-- Email Templates table -->
      <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full table-fixed divide-y divide-gray-200">
                <thead class="bg-slate-200">
                <tr>
                  <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Department</th>
                  <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Send Text</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6 font-semibold">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="skill in departments.data" :key="skill.id">
                  <td class="whitespace-nowrap px-3 py-4 text-gray-900">
                    {{ skill.name }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-gray-900">
                    {{ skill.send_text == 1 ? 'Yes' : 'No' }}
                  </td>
                  <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right  font-medium sm:pr-6">
                    <button class="btn btn-primary text-sm mr-2" @click.prevent="openEditForm(skill)">Edit
                    </button>
                    <button class="btn btn-danger text-sm ml-2" @click.prevent="confirmDelete(skill)">Delete</button>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal :show="messageModalVisible" @onClose="closeMessageModal()"
             :title="departmentFormName">
        <AgencyManageDepartments :form-data="formData" @onSubmit="submitForm"></AgencyManageDepartments>
      </Modal>
</template>