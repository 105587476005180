<script setup>
import {useForm} from "@inertiajs/inertia-vue3";
import {ElSelect, ElOption} from 'element-plus';
import {debounce} from "lodash";
import {nextTick, onMounted, ref} from "vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";

const props = defineProps({
  leadId: Number,
})

const searchingAgents = ref(false);
const submitting = ref(false);
const formData = useForm({
  agent_id: null
})
const agentList = ref([]);

const emit = defineEmits(['lead-shared-successfully'])

function submit() {
  if (submitting.value) return;
  submitting.value = true;

    formData.post(route('agent-lead-share.store', props.leadId), {
      preserveScroll: true,
      only: ['statusAndAssignmentData'],
      onSuccess: () => {
        emit('lead-shared-successfully')
        formData.reset();
        agentList.value = [];
      },
      onError: () => {
        console.log('error')
      },
      onFinish: () => {
        setTimeout(() => {
            submitting.value = false;
        }, 300)
      },
    })
}

const searchAgents = debounce(search => {
  axios
    .get(route('agent-lead-share.search-agents', props.leadId), { params: {
        search: search,
    }})
    .then(response => {
      nextTick(() => {
          agentList.value = response.data;
      })
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      searchingAgents.value = false;
    })
}, 200)

onMounted(() => {
    searchingAgents.value = true;
    searchAgents();
})

</script>

<template>
  <form action="" @submit.prevent="submit">
    <div class="input-form p-4">
      <label for="agents_to_share_with" class="form-label">Select An Agent</label>
      <el-select
        v-model="formData.agent_id"
        class="w-full"
        filterable
        remote
        placeholder=""
        :remote-method="searchAgents"
        :loading="searchingAgents"
        remote-show-suffix
      >
        <el-option
          v-for="agent in agentList"
          :key="agent.id"
          :label="`${agent.last_name}, ${agent.first_name}`"
          :value="agent.id"
        />
      </el-select>
      <button :disabled="formData.agent_id === null" type="submit" :class="[{'pointer-events-none opacity-70':submitting},'btn-primary mt-4 text-right w-24']">
          <LoadingSpinner v-if="submitting"></LoadingSpinner>
          <span v-else>Submit</span>
      </button>
    </div>
  </form>
</template>

<style scoped></style>
