<script setup>
import {Inertia} from "@inertiajs/inertia";
import {Head, useForm} from '@inertiajs/inertia-vue3';
import {computed, ref} from "vue";
import Modal from "@/Components/Modal.vue";
import {EyeIcon, TrashIcon} from "@heroicons/vue/outline";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import LeadQuestionnaireForm from "@/Components/partials/LeadQuestionnaireForm.vue";


const props = defineProps({
    lead: Object,
    questionnaires: Object
});
const loading = ref(false);
let modalDataDefault = {
  model: {
    id: null,
    relations: {
      answers: null,
      leadForm: null
    },
  },
};
const modalData = ref(modalDataDefault);
function loadLeadForm(questionnaire) {
  loading.value = true;
  leadFormModalVisible.value = true;
  axios.get(route('leads.form-submissions.edit', {
    lead: props.lead.id,
    form_submission: questionnaire.id
  }))
    .then(r => {
      modalData.value.model.id = r.data.lead_form_submission.id;
      modalData.value.model.relations.leadForm = r.data.lead_form_submission.lead_form;
      modalData.value.model.relations.answers = r.data.lead_form_submission.answers;
    })
    .catch(e => {
      throw e;
    })
    .finally(() => {
      loading.value = false;
    });
}
function closeLeadFormModal() {
  loading.value = false;
  leadFormModalVisible.value = false;
}

const leadFormModalVisible = ref(false);

function confirmDelete(questionnaire) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to undo this.',
    icon: 'warning',
    confirmButtonText: "Yes, I'm sure.",
    showCancelButton: true,
    confirmButtonColor: '#B91C1C',
    cancelButtonColor: '#899CC1'
  }).then((result) => {
    if (result.isConfirmed) {
      Inertia.delete(route('leads.form-submissions.destroy', {
        lead: props.lead.id,
        form_submission: questionnaire.id
      }), {
        preserveScroll: true,
        onSuccess: () => {
          $notify.success({
            title: 'Deleted',
            showClose: true,
          });
        },
        onError: (e) => {
          $notify.error({
            title: 'Error',
            message: e.message,
            showClose: true,
          })
          throw e;
        }
      })
    }
  })
}

function updateQuestionnaire(data) {
  loading.value = true;
  axios.put(route('leads.form-submissions.update', {
    lead: props.lead.id,
    form_submission: modalData.value.model.id
  }), {
    answers: data
  })
    .then(r => {
      $notify.success({
        title: 'Updated',
        showClose: true,
      });
      closeLeadFormModal();
    })
    .catch(e => {
      $notify.error({
        title: 'Error',
        message: e.message,
        showClose: true,
      })
      throw e;
    })
    .finally(() => {
      loading.value = false;
      modalData.value = modalDataDefault;
    })
}

</script>

<template>
  <Head title="Lead Questionnaires" />
          <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6">
                  <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
                          <thead class="bg-slate-200">
                          <tr>
                              <th scope="col"
                                  class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                  Lead Type
                              </th>
                              <th scope="col"
                                  class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                  Submitted On
                              </th>
                              <th scope="col"
                                  class="whitespace-nowrap px-2 py-3.5 text-right pr-5 text-sm font-semibold text-gray-900">
                                  Options
                              </th>
                          </tr>
                          </thead>
                          <tbody class="divide-y divide-gray-200 bg-white">
                          <tr class="hover:bg-gray-50" v-for="questionnaire in questionnaires.data" :key="questionnaire.id">
                            <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900">
                              {{ questionnaire.lead_type }}
                            </td>
                            <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">
                              {{ questionnaire.formatted_created_at }}
                            </td>
                            <td class="whitespace-nowrap px-2 pr-5 py-3.5 text-sm text-gray-900 first-letter:uppercase text-right">
                              <button @click.prevent="loadLeadForm(questionnaire)" class="btn btn-sm btn-primary"><EyeIcon class="w-4 h-4 mr-2" /> View</button>
<!--                              <button @click.prevent="confirmDelete(questionnaire)" class="btn btn-sm btn-primary bg-amber-600 hover:bg-amber-900 ml-2"><TrashIcon class="w-4 h-4 mr-2" /> Delete</button>-->
                            </td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>

    <Modal :show="leadFormModalVisible" @onClose="closeLeadFormModal" max-width="max-w-[900px]"
             title="Questionnaire">
      <div class="relative mt-8">
        <div v-show="loading"
             class="absolute inset-0 z-[9999] bg-white/80 flex justify-center items-center transition transition-opacity duration-300 opacity-100">
          <LoadingSpinner color-class="text-tb-blue h-8 w-8"></LoadingSpinner>
        </div>
        <LeadQuestionnaireForm v-if="!loading" :lead="lead" :lead-form="modalData.model.relations.leadForm" :lead-form-submission="modalData.model"
          @on-close="closeLeadFormModal"
          @on-submit="updateQuestionnaire"
        />
      </div>
      </Modal>
</template>
