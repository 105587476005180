<script setup>
import Label from "@/Components/Label.vue";
import Button from "@/Components/Button.vue";
import InputError from "@/Components/InputError.vue";
import {useForm} from "@inertiajs/inertia-vue3";
import {Inertia} from "@inertiajs/inertia";
import {ElPopconfirm} from "element-plus";

const props = defineProps({
  agencyId: {
    type: Number,
    required: true
  },
  twilioSids: {
    type: Object,
    default: null
  },
  messagingServices: {
    type: Object,
    default: null,
  },
})

const sidsForm = useForm({
  customer_profile_sid: props.twilioSids?.customer_profile_sid ?? null, // TODO: Add screenshot of where to find this - BU...
  brand_registration_sid: props.twilioSids?.brand_registration_sid ?? null, // TODO: Add screenshot of where to find this - BN...
  messaging_service_sid: props.twilioSids?.messaging_service_sid ?? null, // TODO: Add screenshot of where to find this - MG...
  trust_hub_a2p_bundle_sid: props.twilioSids?.trust_hub_a2p_bundle_sid ?? null, // TODO: Add screenshot of where to find this - BN...
});

function submitSids() {
  if (sidsForm.processing) return;
  sidsForm.post(route('twilio-settings.sids.store', props.agencyId), {
    preserveScroll: true,
    preserveState: true,
    only: ['twilioSids', 'errors'],
    onSuccess: () => {
      $notify.success({
        message: 'Submitted successfully',
        showClose: true,
      })
    },
    onError: () => {
    },
    onFinish: () => {
    }
  })
}

function deleteSids() {
  Inertia.delete(route('twilio-settings.sids.delete', [props.agencyId, props.twilioSids.id]), {
    preserveScroll: true,
    preserveState: false,
    only: ['twilioSids', 'messagingServices', 'errors'],
    onSuccess: () => {
      $notify.success({
        message: 'Twilio SIDs Deleted successfully',
        showClose: true,
      })
    },
    onError: (e) => {
    },
    onFinish: () => {
    }
  });
}

const messagingServiceForm = useForm({
  messaging_service_sid: null,
});

function addMessagingService() {
  if (messagingServiceForm.processing) return;
  messagingServiceForm.post(route('twilio-settings.add-messaging-service', props.agencyId), {
    preserveScroll: true,
    preserveState: true,
    only: ['messagingServices', 'errors'],
    onSuccess: () => {
      $notify.success({
        message: 'Submitted successfully',
        showClose: true,
      })

      messagingServiceForm.reset();
    },
    onError: () => {
    },
    onFinish: () => {
    }
  })
}
</script>

<template>
    <!-- SIDs Form -->
    <div class="p-4">
        <h1 class="mb-4 font-semibold">
            SID Information - The following information needs to be an exact match to this agencies SIDs from Twilio.
            Please ensure you have the correct combination of SIDs before submitting.
            Check out the screenshot below, with explanations.

            <a class="text-blue-500 underline"
               href="../../images/twilio_sids_screenshot.png"
               target="_blank">Screenshot</a>
        </h1>
    </div>

    <el-popconfirm
      v-if="props.twilioSids?.id && props.twilioSids?.processed_at !== null"
      width="220"
      title="Are you sure?"
      confirm-button-text="Yes"
      cancel-button-text="No"
      @confirm="deleteSids">
      <template #reference>
        <button class="btn-danger mb-4" type="button">Delete Twilio SID's Information</button>
      </template>
    </el-popconfirm>

    <div :class="[props.twilioSids && props.twilioSids?.submitted_at !== null ? 'pointer-events-none opacity-50' : '', 'p-4 border rounded-lg']">
        <form class="space-y-2" @submit.prevent="submitSids">

            <h1 class="font-semibold" v-if="props.twilioSids">SIDS ID: {{ props.twilioSids.id }}</h1>
            <p v-if="props.twilioSids?.submitted_at">Submitted at - {{ props.twilioSids?.submitted_at }}</p>
            <p v-if="props.twilioSids?.processed_at">Processed at - {{ props.twilioSids?.processed_at }}</p>
            <p v-if="props.twilioSids?.submitted_at && props.twilioSids?.processed_at === null">Processing, please check back soon.</p>

            <div>
                <Label>Customer Profile SID</Label>
                <input v-model="sidsForm.customer_profile_sid"
                       :class="[{'has-error': sidsForm.errors.customer_profile_sid}, 'input-field']"
                       maxlength="255"
                       type="text">
                <InputError :message="sidsForm.errors.customer_profile_sid"></InputError>
                <span
                    class="text-xs text-gray-500">Enter the exact customer profile SID (Starts with BU...)</span>
            </div>

            <div>
                <Label>Brand registration SID</Label>
                <input v-model="sidsForm.brand_registration_sid"
                       :class="[{'has-error': sidsForm.errors.brand_registration_sid}, 'input-field']"
                       maxlength="255"
                       type="text">
                <InputError :message="sidsForm.errors.brand_registration_sid"></InputError>
                <span class="text-xs text-gray-500">Enter the exact brand registration SID (Starts with BN...)</span>
            </div>

            <div>
                <Label>Messaging Service SID</Label>
                <input v-model="sidsForm.messaging_service_sid"
                       :class="[{'has-error': sidsForm.errors.messaging_service_sid}, 'input-field']"
                       maxlength="255"
                       type="text">
                <InputError :message="sidsForm.errors.messaging_service_sid"></InputError>
                <span class="text-xs text-gray-500">Enter the exact messaging service SID (Starts with MG...)</span>
            </div>

            <div>
                <Label>Trust Hub A2P bundle SID</Label>
                <input v-model="sidsForm.trust_hub_a2p_bundle_sid"
                       :class="[{'has-error': sidsForm.errors.trust_hub_a2p_bundle_sid}, 'input-field']"
                       maxlength="255"
                       type="text">
                <InputError :message="sidsForm.errors.trust_hub_a2p_bundle_sid"></InputError>
                <span
                    class="text-xs text-gray-500">Enter the exact trust product SID (Starts with BU...)</span>
            </div>

            <button v-if="props.twilioSids === null || props.twilioSids?.submitted_at === null"
                    class="btn-primary mt-4"
                    type="submit">Submit
            </button>
        </form>
    </div>
    <!-- End SIDs Form -->

    <!-- Messaging Services -->

    <h1 class="font-semibold text-xl mt-4" v-if="messagingServices?.length">Connected Messaging Services</h1>

    <div v-for="(messagingService, index) in messagingServices" :key="index" class="space-y-2" @submit.prevent="">
        <div class="mt-4 border rounded-lg p-4 space-y-2">
            <div class="lg:flex lg:justify-between items-center">
                <div class="mb-4">
                    <p>Numbers count: {{messagingService.phone_numbers_count}}</p>
                </div>
<!--                <button class="btn-danger text-xs p-1 ml-auto"-->
<!--                        type="button"-->
<!--                        @click.prevent="null">Unlink Messaging Service</button>-->
            </div>
            <div>
                <Label>Connected Messaging Service ID</Label>
                <input v-model="messagingService.id"
                       :class="['input-field']"
                       disabled
                       maxlength="255"
                       readonly
                       type="text">
            </div>

            <div>
                <Label>Connected Messaging Service SID</Label>
                <input v-model="messagingService.sid"
                       :class="['input-field']"
                       disabled
                       maxlength="255"
                       readonly
                       type="text">
            </div>

        </div>
    </div>


    <form v-if="props.twilioSids?.submitted_at && props.twilioSids?.processed_at" class="space-y-2 p-4 border-dashed border-2 rounded-lg mt-4" @submit.prevent="addMessagingService">
        <h1 class="text-lg mb-4">Add new messaging service?</h1>
        <div>
            <Label>Messaging Service SID</Label>
            <input v-model="messagingServiceForm.messaging_service_sid"
                   :class="[{'has-error': messagingServiceForm.errors.messaging_service_sid}, 'input-field']"
                   maxlength="255"
                   type="text">
            <InputError :message="messagingServiceForm.errors.messaging_service_sid"></InputError>
            <span class="text-xs text-gray-500">Enter the exact messaging service SID (Starts with MG...)</span>
        </div>

        <button class="btn-primary mt-4" type="submit">Submit</button>
    </form>
</template>

<style scoped>

</style>
