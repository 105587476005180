<script setup>
import {useForm} from "@inertiajs/inertia-vue3";
import {onMounted, ref} from "vue";

const props = defineProps({
  agent_info: {},
  agentId: {
    type: Number,
    required: true,
  }
});

const loading = ref(false);

const formData = useForm({
  ssn: null,
  company: null,
  tax_id: null,
})

onMounted(() => {
  formData.ssn = props.agent_info.ssn_decrypted;
  formData.company = props.agent_info.company;
  formData.tax_id  = props.agent_info.tax_id;
});

const handleSubmit = function() {
  loading.value = true;
  formData
    .put(route('agents.contract-info.update', {agent: props.agentId}), {
      onSuccess: (r) => {
        $notify.success({message: 'Contract Info updated'});
      },
      onFinish: () => {
        loading.value = false;
      },
    });
}
</script>

<template>
  <form method="POST" @submit.prevent="handleSubmit">
    <div class="grid grid-cols-12 space-x-2">
      <!-- BEGIN: SSN -->
      <div class="md:col-span-4">
        <div class="input-form mt-4" :class="{ 'has-error': formData.errors.ssn }">
          <label for="ssn" class="text-gray-600 font-bold">SSN</label>
          <input type="text" name="ssn" class="input-field w-full" id="ssn" v-model="formData.ssn" autocomplete="off" />
          <div class="error-message mt-1" v-if="formData.errors.ssn">{{ formData.errors.ssn }}</div>
        </div>
      </div>
      <!-- END: Company Name -->

      <!-- BEGIN: Company Name -->
      <div class="md:col-span-4">
        <div class="input-form mt-4" :class="{ 'has-error': formData.errors.company }">
          <label for="company" class="text-gray-600 font-bold">Company Name</label>
          <input v-model="formData.company" id="company" type="text" class="input-field  w-full" name="company">
          <div v-if="false" class="error-message mt-1">{{ formData.errors.company }}</div>
        </div>
      </div>
      <!-- END: Company Name -->

      <!-- BEGIN: Tax ID -->
      <div class="md:col-span-4">
        <div class="input-form mt-4" :class="{ 'has-error': formData.errors.tax_id }">
          <label for="tax_id" class="text-gray-600 font-bold">Tax ID</label>
          <input v-model="formData.tax_id" id="tax_id" type="text" class="input-field  w-full" name="tax_id">
          <div v-if="false" class="error-message mt-1">{{ formData.errors.tax_id }}</div>
        </div>
      </div>
      <!-- END: Tax ID -->
    </div>
    <div class="mt-3">
      <button type="submit" class="btn btn-primary" :disabled="loading" :class="[{'opacity-30': loading}]">Update</button>
    </div>
  </form>
</template>