<script setup>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {useForm} from "@inertiajs/inertia-vue3";
import {ref} from "vue";
import helpers from "@/helper";

const props = defineProps({
  stripe_keys: {
    type: Object,
    default: null
  },
  agencyId: {
    type: Number,
    required: true
  }
})

const formData = useForm({
  stripe_public_key: null,
  stripe_secret_key: null,
})

const loading = ref(false);

function submitStripeKeysForm() {
  if (loading.value) return;
  loading.value = true;
  setTimeout(() => {
    formData.post(route('agencies.stripe-keys.store', props.agencyId), {
      preserveScroll: true,
      only: ['stripe_keys', 'errors'],
      onSuccess: () => {
        $notify.success({
          title: 'Success',
          message: 'Stripe keys successfully updated.',
          showClose: true,
        })
      },
      onError: (error) => {
        console.log(error, 'AgecyStripeKey]s.vue@submitStripeKeysForm')
        $notify.error({
          title: 'Error',
          message: error.message ?? 'Unexpected Error Occurred.',
          showClose: true,
        })
      },
      onFinish: () => {
        loading.value = false;
      }
    })
  }, 300)
}
</script>

<template>
  <div class="bg-yellow-100 text-yellow-900 p-4 rounded-lg">
    <span class="font-bold">IMPORTANT:</span> Changing stripe keys will remove all existing payment methods from your agents, and they will need to re-add new ones.
  </div>

  <form @submit.prevent="submitStripeKeysForm" action="">
    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
      <!-- BEGIN: Stripe Public Key -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': formData.errors.stripe_public_key}">
        <label for="stripe_public_key" class="form-label text-gray-600">Stripe Public Key</label>
        <input v-if="props.stripe_keys.data?.stripe_public_key_last_5 !== null" :value="props.stripe_keys.data?.stripe_public_key_last_5" readonly disabled id="stripe_public_key" type="text" class="input-field my-2 w-full italic bg-gray-100" name="stripe_public_key">
        <input v-model="formData.stripe_public_key" id="stripe_public_key" placeholder="Add new public key" type="text" class="input-field  w-full" name="stripe_public_key">
        <div v-if="formData.errors.stripe_public_key" class="error-message mt-1">{{ formData.errors.stripe_public_key }}</div>
      </div>
      <!-- END: Stripe Public Key -->
    </div>

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6">
      <!-- BEGIN: Stripe Secret Key -->
      <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': formData.errors.stripe_secret_key}">
        <label for="stripe_secret_key" class="form-label text-gray-600">Stripe Secret Key</label>
        <input v-if="props.stripe_keys.data?.stripe_secret_key_last_5 !== null" :value="props.stripe_keys.data?.stripe_secret_key_last_5" readonly disabled type="text" class="input-field my-2 w-full italic bg-gray-100" name="stripe_secret_key">
        <input v-model="formData.stripe_secret_key" placeholder="Add new secret key" id="stripe_secret_key" type="text" class="input-field  w-full" name="stripe_secret_key">
        <div v-if="formData.errors.stripe_secret_key" class="error-message mt-1">{{ formData.errors.stripe_secret_key }}</div>
      </div>
      <!-- END: Stripe Secret Key -->
    </div>

    <div class="mt-5">
      <button type="submit" class="btn btn-primary" :class="{'pointer-events-none': loading}">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <span v-else>Save for production</span>
      </button>
    </div>
  </form>
</template>

<style scoped>

</style>