<script setup>
import {computed, onBeforeMount, ref} from "vue";
import {ElOption, ElSelect} from "element-plus";

const props = defineProps({
  agentId: {
    type: Number,
    required: true,
  },
});

const loading = ref(false);
const agencies = ref([]);
const selectedAgency = ref(null);
const transfers = ref([]);

const buttonEnabled = computed(() => {
  return !loading.value && selectedAgency.value;
});

onBeforeMount(() => {
  fetchAgencies();
  fetchPreviousTransfers();
});

const fetchAgencies = function () {
  loading.value = true;
  axios
    .get(route('agent-transfer.agencies.index', props.agentId))
    .then(r => {
      agencies.value = r.data;
    })
    .finally(() => {
      loading.value = false;
    });
}

const fetchPreviousTransfers = function () {
  loading.value = true;
  axios
    .get(route('agent-transfer.index', {user: props.agentId}))
    .then(r => {
      transfers.value = r.data;
    })
    .finally(() => {
      loading.value = false;
    });
}

const startTransfer = function () {
  loading.value = true;
  axios.post(route('agent-transfer.store', {
    user: props.agentId,
    agency_id: selectedAgency.value,
  }))
    .then(r => {
      $notify.success({
        title: 'Transfer Started',
        message: 'The transfer is running on the background, please check back later.',
      })

      fetchPreviousTransfers();
    })
    .catch(e => {
      let message = e.response?.data?.message ?? 'An error has occurred';
      $notify.error({message: message});
    })
    .finally(() => {
      loading.value = false;
    })
}
</script>

<template>
  <div>
    <div class="bg-orange-100 p-4 border border-orange-400 text-black rounded-md mb-4">
      <h4 class="text-lg font-bold mb-2">IMPORTANT</h4>
      <div class="space-y-1">
        <p>&nbsp;- The "Losing" agency owner must have approved this request in writing.</p>
        <p>&nbsp;- This transfer CANNOT be un-done.</p>
        <p>&nbsp;- All leads owned by the user will be moved.</p>
        <p>&nbsp;- All sources owned by the agent will have their actions (action schedules, dead / disconnected phone actions) removed if not owned by the user. They must select a new actions under the new agency.</p>
        <p>&nbsp;- The lead marketplace card attached to the agent will be removed. They will need to create a new lead marketplace card after the transition.</p>
        <p>&nbsp;- Billing settings such as leader pay, force agent pay and fee overrides will be removed.</p>
        <p>&nbsp;- All Campaigns owned by the user will be deleted.</p>
        <p>&nbsp;- All Email Blasts owned by the user will be deleted.</p>
        <p>&nbsp;- All Quotesheet Plan Templates owned by the user will be deleted.</p>
      </div>
    </div>

    <div class="text-center w-full col-1">

      <el-select v-model="selectedAgency" placeholder="Select" style="max-width: 400px" class="block max-w-[400px] w-full" clearable>
        <el-option
            v-for="item in agencies"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        />
      </el-select>

      <button class="btn btn-primary block mx-auto max-w-[400px] w-full mt-2" :disabled="!buttonEnabled" :class="[{'opacity-30': !buttonEnabled}]" @click.prevent="startTransfer">
        Transfer Agent To Selected Agency
      </button>
    </div>

    <div class="mt-8 flex flex-col" v-if="transfers.length">
      Transfers
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Old Agency</th>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">New Agency</th>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Initiated At</th>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Finished At</th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="transfer in transfers">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{{ transfer.old_agency_name }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ transfer.new_agency_name }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ transfer.initiated_at }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ transfer.transferred_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>