export default {
  data() {
    return {
      errors: {}
    }
  },

  methods: {
    isInvalid(attribute) {
      return this.errors.hasOwnProperty(attribute);
    },
    errorMessage(attribute) {
      if (this.errors.hasOwnProperty(attribute)) {
        return this.errors[attribute][0];
      }
    },
    clearErrors() {
      this.errors = {};
    },
  }
}