<script setup>
import {ElDatePicker} from "element-plus";
import {ref} from "vue";
import Modal from "@/Components/Modal.vue";
import AgentCarrierTransferDownline from "@/Components/AgentCarrierTransferDownline.vue";
import MaskedDatePicker from "@/Components/MaskedDatePicker.vue";
import AgentCarrierCommissionAutocompleteCreateField
  from "@/Components/AgentCarrierCommissionAutocompleteCreateField.vue";

const props = defineProps({
  agentId: {
    type: Number,
    required: true,
  },
  carrierId: {
    type: Number,
    required: true,
  },
  formData: {
    type: Object,
    required: true,
  },
  loading: {
    type: Object,
    required: true,
  },
  uplineOptions: {
    type: Array,
    required: true,
  }
});

const emits = defineEmits(['cancel', 'submit']);

const handleCancel = function(){
  emits('cancel');
};

const transferDownlineModalVisible = ref(false);
const showTransferDownlineModal = function(){
  transferDownlineModalVisible.value = true;
}
const hideTransferDownlineModal = function(){
  transferDownlineModalVisible.value = false;
}
</script>

<template>
  <form @submit.prevent="emits('submit')" class="intro-y box p-5">

    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Status -->
      <div class="col-span-6 input-form" :class="{'has-error': formData.errors.status}">
        <label for="status" class="form-label text-gray-600">Status</label>
        <select v-model="formData.status" class="input-field">
          <option value="">None</option>
          <option value="active">Active</option>
          <option value="inactive_vested">Inactive - Vested</option>
          <option value="inactive_not_vested">Inactive - Not Vested</option>
          <option value="sent">Sent</option>
          <option value="pending">Pending</option>
          <option value="terminated_or_declined">Terminated/Declined *</option>
        </select>
        <div class="error-message mt-1" v-if="formData.errors.status">{{ formData.errors.status }}</div>
      </div>
      <!-- END: Status -->

      <!-- BEGIN: Effective Date -->
      <div class="col-span-6 input-form" :class="{'has-error': formData.errors.effective_date}">
        <label for="effective_date" class="form-label text-gray-600 text-sm ">Effective Date</label>
        <!--<el-date-picker
          style="width: 100% !important;"
          class="col-span-12 md:col-span-6"
          v-model="formData.effective_date"
          type="date"
          placeholder="From"
          format="MMM DD, YYYY"
          value-format="YYYY-MM-DD"
          :editable="true"
        />-->
        <masked-date-picker
          v-model="formData.effective_date"
          element-id="prefix-agent-carrier-effective-date"
          class="col-span-12 md:col-span-6"
        ></masked-date-picker>
        <div class="error-message mt-1" v-if="formData.errors.effective_date">{{ formData.errors.effective_date }}</div>
      </div>
      <!-- END: Effective Date -->

      <!-- BEGIN: Writing Number -->
      <div class="col-span-6 input-form" :class="{'has-error': formData.errors.writing_number}">
        <label for="writing_number" class="form-label text-gray-600 text-sm ">Writing Number</label>
        <input type="text" id="writing-number" v-model="formData.writing_number" class="input-field" />
        <div class="error-message mt-1" v-if="formData.errors.writing_number">{{ formData.errors.writing_number }}</div>
      </div>
      <!-- END: Writing Number -->

      <!-- BEGIN: Recruiting Number -->
      <div class="col-span-6 input-form" :class="{'has-error': formData.errors.recruiting_number}">
        <label for="recruiting_number" class="form-label text-gray-600 text-sm ">Recruiting Number</label>
        <input type="text" id="writing-number" v-model="formData.recruiting_number" class="input-field" />
        <div class="error-message mt-1" v-if="formData.errors.recruiting_number">{{ formData.errors.recruiting_number }}</div>
      </div>
      <!-- END: Recruiting Number -->

      <!-- BEGIN: Recruiting Number -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.contracting_agency_carrier_agent_leader_id}">
        <label for="contracting_agency_carrier_agent_leader_id" class="form-label text-gray-600 text-sm ">Direct Upline</label>
        <select class="input-field" v-model="formData.contracting_agency_carrier_agent_leader_id">
          <option :value="null">No leader</option>
          <option v-for="(agencyCarrierAgent, index) in uplineOptions" :value="agencyCarrierAgent.id">{{ agencyCarrierAgent.label }}</option>
        </select>
        <div class="error-message mt-1" v-if="formData.errors.contracting_agency_carrier_agent_leader_id">{{ formData.errors.contracting_agency_carrier_agent_leader_id }}</div>
      </div>
      <!-- END: Recruiting Number -->

      <!-- BEGIN: Commission -->
      <div class="col-span-6 input-form" :class="{'has-error': formData.errors.commission}">
        <label for="commission" class="form-label text-gray-600 text-sm ">Commission %</label>
        <!--<input type="number" id="writing-number" v-model="formData.commission" class="input-field" />-->
        <AgentCarrierCommissionAutocompleteCreateField
          v-model="formData.commission"
          :active-model="formData.commission"
        ></AgentCarrierCommissionAutocompleteCreateField>
        <div class="error-message mt-1" v-if="formData.errors.commission">{{ formData.errors.commission }}</div>
      </div>
      <!-- END: Commission -->

      <!-- BEGIN: Commission -->
      <div class="col-span-6 input-form" :class="{'has-error': formData.errors.dnc}">
        <label for="dnc" class="form-label text-gray-600 text-sm ">DNC</label>
        <select id="dnc" class="input-field" v-model="formData.dnc">
          <option :value="1">Yes</option>
          <option :value="0">No</option>
        </select>
        <div class="error-message mt-1" v-if="formData.errors.dnc">{{ formData.errors.dnc }}</div>
      </div>
      <!-- END: Commission -->

      <!-- BEGIN: Notes -->
      <div class="col-span-12 input-form" :class="{'has-error': formData.errors.notes}">
        <label for="notes" class="form-label text-gray-600 text-sm ">Notes</label>
        <textarea v-model="formData.notes" id="notes" type="text" class="input-field" rows="4"></textarea>
        <div class="error-message mt-1" v-if="formData.errors.notes">{{ formData.errors.notes }}</div>
      </div>
      <!-- END: Notes -->
    </div>
    <div class="mt-4">
      <button class="btn btn-primary mr-2" type="submit" :disabled="loading">Save</button>
      <button class="btn btn-secondary mr-2" type="button" @click.prevent="handleCancel" :disabled="loading">Cancel</button>
      <button class="btn btn-secondary" type="button" :disabled="loading" @click.prevent="showTransferDownlineModal">Transfer downline</button>
    </div>
  </form>

  <Modal :show="transferDownlineModalVisible" @onClose="hideTransferDownlineModal">
    <AgentCarrierTransferDownline
      :carrier-id="props.carrierId"
      :agent-id="props.agentId"
      @done="hideTransferDownlineModal"
    ></AgentCarrierTransferDownline>
  </Modal>
</template>

<style scoped>
</style>