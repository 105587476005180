<script setup>

import {ref, computed, onMounted} from "vue";

const props = defineProps({
  editingBeneficiaryIndex: Number,
  editingBeneficiaryData: Object,
});

const emits = defineEmits(
  'submitBeneficiary',
  'closePane'
);

onMounted(() => {
  if (editMode) {
    modelBeneficiary.value.name = props.editingBeneficiaryData.name;
    modelBeneficiary.value.email = props.editingBeneficiaryData.email;
    modelBeneficiary.value.phone = props.editingBeneficiaryData.phone;
    modelBeneficiary.value.address = props.editingBeneficiaryData.address;
    modelBeneficiary.value.split_percentage = props.editingBeneficiaryData.split_percentage;
  }
});

const modelBeneficiary = ref({
  name: null,
  email: null,
  phone: null,
  address: null,
  split_percentage: null,
});

function submitForm(event) {
  if (!validateBeneficiaryInput(modelBeneficiary)) return;
  emits('submitBeneficiary', event, modelBeneficiary);
  resetModelBeneficiary();
}

const modelBeneficiaryErrors = ref([]);
function validateBeneficiaryInput() {
  modelBeneficiaryErrors.value = [];
  let name = modelBeneficiary.value.name?.trim();
  let email = modelBeneficiary.value.email?.trim();
  let phone = modelBeneficiary.value.phone?.trim();
  let address = modelBeneficiary.value.address?.trim();
  let split_percentage = parseInt(modelBeneficiary.value.split_percentage);

  if (!name) {
    modelBeneficiaryErrors.value.push({field: 'name', display: 'Name is required'});
  }
  // if (!email) {
  //   modelBeneficiaryErrors.value.push({field: 'email', display: 'Email is required'});
  // }
  // if (!phone) {
  //   modelBeneficiaryErrors.value.push({field: 'phone', display: 'Phone is required'});
  // }
  // if (!address) {
  //   modelBeneficiaryErrors.value.push({field: 'address', display: 'Address is required'});
  // }
  if (!split_percentage || (split_percentage < 1 || split_percentage > 100)) {
    modelBeneficiaryErrors.value.push({field: 'split_percentage', display: "Percentage can\'t be lower than 1% or greater than 100%"});
  }
  return modelBeneficiaryErrors.value.length === 0;
}

function resetModelBeneficiary() {
  modelBeneficiary.value.name = null;
  modelBeneficiary.value.email = null;
  modelBeneficiary.value.phone = null;
  modelBeneficiary.value.address = null;
  modelBeneficiary.value.split_percentage = null;
}

const editMode = computed(() => {
  return typeof props.editingBeneficiaryIndex === 'number' && props.editingBeneficiaryIndex >= 0 && props.editingBeneficiaryData && Object.keys(props.editingBeneficiaryData).length > 0;
});
</script>

<template>
  <div class="p-8">
    <form @submit.prevent="submitForm">
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4 mb-2">
        <dt class="col-span-1">Name*</dt>
        <dd class="col-span-2">
          <input type="text" class=" input-field ml-2 w-full" v-model="modelBeneficiary.name" />
        </dd>
      </div>
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4 mb-2">
        <dt class="col-span-1">Email</dt>
        <dd class="col-span-2">
          <input type="text" class=" input-field ml-2 w-full" v-model="modelBeneficiary.email" />
        </dd>
      </div>
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4 mb-2">
        <dt class="col-span-1">Phone</dt>
        <dd class="col-span-2">
          <input type="text" class=" input-field ml-2 w-full" v-model="modelBeneficiary.phone" />
        </dd>
      </div>
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4 mb-2">
        <dt class="col-span-1">Address</dt>
        <dd class="col-span-2">
          <input type="text" class=" input-field ml-2 w-full" v-model="modelBeneficiary.address" />
        </dd>
      </div>
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4 mb-2">
        <dt class="col-span-1">Percentage*</dt>
        <dd class="col-span-2">
          <input type="number" class=" input-field ml-2 w-full" v-model="modelBeneficiary.split_percentage" />
        </dd>
      </div>
      <div v-if="modelBeneficiaryErrors.length" class="py-3 px-8 text-amber-700">
        <ul class="list-disc">
          <li v-for="error in modelBeneficiaryErrors">{{ error.display }}</li>
        </ul>
      </div>
      <button type="submit" class="btn btn-primary">{{ editMode ? 'Modify' : 'Add'}}</button>
      <button type="button" class="btn btn-primary ml-6" @click="emits('closePane')">Cancel</button>
    </form>
  </div>
</template>