<template>
  <div v-show="editMode">
    <button @click.prevent="submit" type="submit" class="ml-auto mb-3 btn btn-primary flex items-center justify-center text-sm" :class="{'pointer-events-none': loading}">
      <LoadingSpinner v-if="loading"></LoadingSpinner>
      <span v-else class="text-lg font-black">Update Lead Info</span>
    </button>
    <form ref="myForm">
    <div class="lg:grid grid-flow-col grid-cols-2 grid-rows-1 mb-5 lg:gap-x-6">
      <div class="bg-white overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <div class="p-6 flex space-x-4 items-center justify-between">
          <div class="flex space-x-4 items-center">
            <h4 class="text-xl font-black">Lead Information</h4>
          </div>
        </div>
        <div class="px-4 sm:px-6 -mt-1">
          <dl class="w-full">
            <div v-if="usesGroupLeadFields" class="flex space-x-4 items-start mb-2">
              <div class="w-full">
                <label class="block text-gray-600">Company</label>
                <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('company')}">
                  <input @keyup.enter.prevent="submitWithoutClosingEditForm" @blur="checkForChange" v-model="company" id="company" type="text" class="input-field w-full font-bold" name="company">
                  <div v-if="isInvalid('company')" class="error-message">{{ errorMessage('company') }}</div>
                </dd>
              </div>
            </div>
            <div class="flex space-x-4 items-start">
              <div class="w-full">
                <label class="block text-gray-600">
                  <span v-if="usesGroupLeadFields">Benefits Contact First</span>
                  <span v-else>Primary First</span>
                </label>
                <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('first_name')}">
                  <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" @focus="storeOriginalValue" v-model="first_name" id="first_name" type="text" class="input-field w-full" name="first_name">
                  <div v-if="isInvalid('first_name')" class="error-message">{{ errorMessage('first_name') }}</div>
                </dd>
              </div>
            </div>
            <div class="flex space-x-4 items-start">
              <div class="w-full">
                <label class="block text-gray-600">
                  <span v-if="usesGroupLeadFields">Benefits Contact Last</span>
                  <span v-else>Primary Last</span>
                </label>
                <dd class="mt-1 text-sm text-gray-900">
                  <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="last_name" id="last_name" type="text" class=" input-field w-full" name="last_name" :class="{'has-error': isInvalid('last_name')}">
                  <div v-if="isInvalid('last_name')" class="error-message">{{ errorMessage('last_name') }}</div>
                </dd>
              </div>
            </div>
            <div class="flex space-x-4 items-start mt-3">
              <div class="w-full">
                <div class="">
                  <label class="block text-gray-600 mr-1">Nickname</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('nickname')}">
                    <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="nickname" id="nickname" type="text" class="input-field w-full" name="nickname">
                    <div v-if="isInvalid('nickname')" class="error-message">{{ errorMessage('nickname') }}</div>
                  </dd>
                </div>
                <div class="mt-2">
                  <div class="flex items-center">
                    <input @change="submitWithoutClosingEditForm" id="use_nickname" type="checkbox" v-model="use_nickname_on_templates" class="input-checkbox mr-2">
                    <label for="use_nickname" class="block text-gray-600 mr-1 text-sm">Use Nickname on Templates</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- request from leb to hide
              <div class="flex space-x-4 items-start mt-3">
              <div class="w-full">
                <label for="dob-datepicker-edit-form" class="block text-gray-600">Primary Date Of Birth</label>
                <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('dob')}">
                  <masked-date-picker :element-id="`dob-datepicker-edit-form`" v-model="dob" @changed="submitWithoutClosingEditForm"></masked-date-picker>
                  <div v-if="isInvalid('dob')" class="error-message">{{ errorMessage('dob') }}</div>
                </dd>
              </div>
            </div>-->
          </dl>
        </div>

          <div class="px-4 sm:px-6 -mt-1 pb-6">
              <dl class="w-full">

                  <div class="space-y-3">
                    <div class="flex space-x-4 items-start my-3">
                      <div class="w-full">
                        <label class="block text-gray-600 mr-1">Title/Notes (First Contact)</label>
                        <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('title_notes_first_contact')}">
                          <textarea @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="title_notes_first_contact" id="title_notes_first_contact" type="text" class="input-field w-full" name="title_notes_first_contact"></textarea>
                          <div v-if="isInvalid('title_notes_first_contact')" class="error-message">{{ errorMessage('title_notes_first_contact') }}</div>
                        </dd>
                      </div>
                    </div>
                  </div>

                  <div class="space-y-3">
                    <div>
                      <div>
                        <div class="flex items-center justify-start text-gray-600">
                          <label class="mr-2">
                            <span v-if="usesGroupLeadFields">Phone (First Contact)</span>
                            <span v-else>Phone</span>
                          </label>
                          <div class="flex items-center">
                            <button v-if="phone && !isDnc && !hst_age_locked && (!lead.phone_1_checked_at || lead.phone_1_valid)" class="btn-secondary p-1 inline-block" type="button" @click.prevent="showComposeTextForm({leadId: lead.id, phone: 1})">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                              </svg>
                            </button>
                            <a v-if="phone && clicktocall && !isDnc && !hst_age_locked" :href="clicktocallPhone1Route" class="btn-secondary px-1 py-0.5 ml-2 inline-block" type="button">
                              <div class="flex items-center">
                                <img v-if="clicktocall === 'TBComm'" class="h-5 w-5" src="../../../images/tbcomm.png" >
                                <span class="text-sm">{{ clicktocall }}</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-2">
                        <div class="w-1/5">
                          <dd class="mt-1.5 text-sm text-gray-900">
                            <select @change="submitWithoutClosingEditForm" id="phone_type" name="phone_type" v-model="phone_type" class="input-field">
                              <option :value="null"></option>
                              <option value="Home">Home</option>
                              <option value="Work">Work</option>
                              <option value="Mobile">Mobile</option>
                            </select>
                          </dd>
                        </div>
                        <div class="">
                          <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('phone')}">
                            <MaskedPhoneInput element-id="phone" v-model="phone" @onEnter="submitWithoutClosingEditForm" @onBlur="checkForChange" name="phone" class=" input-field w-full" />
                            <div v-if="isInvalid('phone')" class="error-message">{{ errorMessage('phone') }}</div>
                          </dd>
                        </div>
                        <div class="w-1/3">
                          <dd class="mt-1.5 text-sm text-gray-900">
                            <select @change="submitWithoutClosingEditForm" id="phone1_owner_id" name="phone1_owner_id" v-model="phone1_owner_id" class="input-field">
                              <option v-if="leadDependants.length === 0" :value="null" selected>{{ first_name }} - Primary</option>
                              <option v-else v-for="dependant in leadDependants" :value="dependant.id">{{ dependant.name }} - {{ dependant.formatted_role }}</option>
                            </select>
                          </dd>
                        </div>
                      </div>
                      <div v-if="lead.phone && lead.phone_1_checked_at && !lead.phone_1_valid" class="text-sm text-orange-500 mt-1">According to third party verification systems, the number {{ this.formatPhone(lead.phone) }} is unable to make or receive text messages.</div>
                    </div>

                    <div>
                      <div>
                        <div class="flex items-center justify-start text-gray-600">
                          <label class="mr-2">
                            <span v-if="usesGroupLeadFields">Phone 2 (First Contact)</span>
                            <span v-else>Phone 2</span>
                          </label>
                          <div class="flex items-center">
                            <button v-if="phone_2 && !isDnc && !hst_age_locked && (!lead.phone_2_checked_at || lead.phone_2_valid)" class="btn-secondary p-1 inline-block" type="button" @click.prevent="showComposeTextForm({leadId: lead.id, phone: 2})">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                              </svg>
                            </button>
                            <a v-if="phone_2 && clicktocall && !isDnc && !hst_age_locked" :href="clicktocallPhone2Route" class="btn-secondary px-1 py-0.5 ml-2 inline-block" type="button">
                              <div class="flex items-center">
                                <img v-if="clicktocall === 'TBComm'" class="h-5 w-5" src="../../../images/tbcomm.png" >
                                <span class="text-sm">{{ clicktocall }}</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-2">
                        <div class="w-1/5">
                          <dd class="mt-1.5 text-sm text-gray-900">
                            <select @change="submitWithoutClosingEditForm" id="phone_2_type" name="phone_2_type" v-model="phone_2_type" class="input-field">
                              <option :value="null"></option>
                              <option value="Home">Home</option>
                              <option value="Work">Work</option>
                              <option value="Mobile">Mobile</option>
                            </select>
                          </dd>
                        </div>
                        <div class="">
                          <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('phone_2')}">
                            <MaskedPhoneInput element-id="phone_2" v-model="phone_2" @onEnter="submitWithoutClosingEditForm" @onBlur="checkForChange" name="phone_2" class=" input-field w-full" />
                            <div v-if="isInvalid('phone_2')" class="error-message">{{ errorMessage('phone_2') }}</div>
                          </dd>
                        </div>
                        <div class="w-1/3">
                          <dd class="mt-1.5 text-sm text-gray-900">
                            <select @change="submitWithoutClosingEditForm" id="phone2_owner_id" name="phone2_owner_id" v-model="phone2_owner_id" class="input-field">
                              <option v-if="leadDependants.length === 0" :value="null" selected>{{ first_name }} - Primary</option>
                              <option v-else v-for="dependant in leadDependants" :value="dependant.id">{{ dependant.name }} - {{ dependant.formatted_role }}</option>
                            </select>
                          </dd>
                        </div>
                      </div>
                      <div v-if="lead.phone_2 && lead.phone_2_checked_at && !lead.phone_2_valid" class="text-sm text-orange-500 mt-1">According to third party verification systems, the number {{ this.formatPhone(lead.phone_2) }} is unable to make or receive text messages.</div>
                    </div>

                  </div>

                  <div class="space-y-3 mt-3">
                    <div>
                      <div>
                        <div class="flex items-center space-x-2">
                          <div class="w-full">
                            <label class="flex-0 text-gray-600">
                                  <span class="mr-2">
                                    <span v-if="usesGroupLeadFields">Email 1 (First Contact)</span>
                                    <span v-else>Email 1</span>
                                  </span>
                              <button v-if="!isDnc && !hst_age_locked" class="btn-secondary p-1 inline-block" type="button" @click.prevent="showComposeEmailForm({leadId: lead.id, email: 1})">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                              </button>
                              <a v-if="lead.email && !isDnc && !hst_age_locked" class="btn btn-primary p-1 text-xs ml-2" type="button" @click.stop :href="`mailto:${lead.email}`">Email via Your App</a>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-2">
                        <div class="w-2/3">
                          <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('email')}">
                            <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="email" id="email" type="email" name="email" class=" input-field w-full">
                            <div v-if="isInvalid('email')" class="error-message">{{ errorMessage('email') }}</div>
                          </dd>
                        </div>
                        <div class="w-1/3">
                          <dd class="mt-1.5 text-sm text-gray-900">
                            <select @change="submitWithoutClosingEditForm" id="email1_owner" name="email1_owner" v-model="email1_owner_id" class="input-field">
                              <option v-if="leadDependants.length === 0" :value="null" selected>{{ first_name }} - Primary</option>
                              <option v-else v-for="dependant in leadDependants" :value="dependant.id">{{ dependant.name }} - {{ dependant.formatted_role }}</option>
                            </select>
                          </dd>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="flex space-x-2 items-center">
                          <div class="w-full">
                            <label class="flex-0 text-gray-600">
                                  <span class="mr-2">
                                    <span v-if="usesGroupLeadFields">Email 2 (First Contact)</span>
                                    <span v-else>Email 2</span>
                                  </span>
                              <button v-if="!isDnc && !hst_age_locked" class="btn-secondary p-1 inline-block" type="button" @click.prevent="showComposeEmailForm({leadId: lead.id, email: 2})">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                              </button>
                              <a v-if="lead.email_2 && !isDnc && !hst_age_locked" class="btn btn-primary p-1 text-xs ml-2" type="button" @click.stop :href="`mailto:${lead.email_2}`">Email via Your App</a>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="flex space-x-2 items-center">
                        <div class="w-2/3">
                          <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('email_2')}">
                            <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="email_2" id="email_2" type="email" name="email_2" class=" input-field w-full">
                            <div v-if="isInvalid('email_2')" class="error-message">{{ errorMessage('email_2') }}</div>
                          </dd>
                        </div>
                        <div class="w-1/3">
                          <dd class="mt-1.5 text-sm text-gray-900">
                            <select @change="submitWithoutClosingEditForm" id="email2_owner_id"  name="email2_owner_id" v-model="email2_owner_id" class="input-field">
                              <option v-if="leadDependants.length === 0" :value="null" selected>{{ first_name }} - Primary</option>
                              <option v-else v-for="dependant in leadDependants" :value="dependant.id">{{ dependant.name }} - {{ dependant.formatted_role }}</option>
                            </select>
                          </dd>
                        </div>
                      </div>
                    </div>
                      <div v-if="usesGroupLeadFields">
                        <div class="flex space-x-4 items-start mt-8">
                          <dl class="w-full">
                            <div class="flex space-x-4 items-start">
                              <div class="w-full">
                                <label class="block text-gray-600">Second Contact First</label>
                                <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('first_name_second_contact')}">
                                  <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" @focus="storeOriginalValue" v-model="first_name_second_contact" id="first_name_second_contact" type="text" class="input-field w-full" name="first_name_second_contact">
                                  <div v-if="isInvalid('first_name_second_contact')" class="error-message">{{ errorMessage('first_name_second_contact') }}</div>
                                </dd>
                              </div>
                              <div class="w-full">
                                <label class="block text-gray-600">Second Contact Last</label>
                                <dd class="mt-1 text-sm text-gray-900">
                                  <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="last_name_second_contact" id="last_name_second_contact" type="text" class=" input-field w-full" name="last_name_second_contact" :class="{'has-error': isInvalid('last_name_second_contact')}">
                                  <div v-if="isInvalid('last_name_second_contact')" class="error-message">{{ errorMessage('last_name_second_contact') }}</div>
                                </dd>
                              </div>
                            </div>
                          </dl>
                        </div>
                        <div class="flex space-x-4 items-start mt-3">
                          <div class="w-full">
                            <label class="block text-gray-600 mr-1">Title/Notes (Second Contact)</label>
                            <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('title_notes_second_contact')}">
                              <textarea @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="title_notes_second_contact" id="title_notes_second_contact" type="text" class="input-field w-full" name="title_notes_second_contact"></textarea>
                              <div v-if="isInvalid('title_notes_second_contact')" class="error-message">{{ errorMessage('title_notes_second_contact') }}</div>
                            </dd>
                          </div>
                        </div>
                        <div class="flex space-x-2 items-center my-2">
                          <div class="w-full">
                            <label class="flex-0 text-gray-600">
                              <span class="mr-2">Phone (Second Contact)</span>
                            </label>
                            <MaskedPhoneInput element-id="phone_second_contact" v-model="phone_second_contact" @onEnter="submitWithoutClosingEditForm" @onBlur="checkForChange" name="phone_second_contact" class="mt-1 input-field w-full" />
                            <div v-if="isInvalid('phone_second_contact')" class="error-message">{{ errorMessage('phone_second_contact') }}</div>
                          </div>
                          <div class="w-full">
                            <label class="flex-0 text-gray-600">
                              <span class="mr-2">Phone 2 (Second Contact)</span>
                            </label>
                            <MaskedPhoneInput element-id="phone_2_second_contact" v-model="phone_2_second_contact" @onEnter="submitWithoutClosingEditForm" @onBlur="checkForChange" name="phone_2_second_contact" class="mt-1 input-field w-full" />
                            <div v-if="isInvalid('phone_2_second_contact')" class="error-message">{{ errorMessage('phone_2_second_contact') }}</div>
                          </div>
                        </div>
                        <div class="flex space-x-2 items-center">
                          <div class="w-full">
                            <label class="flex-0 text-gray-600">
                              <span class="mr-2">Email (Second Contact)</span>
                            </label>
                            <button v-if="!isDnc && !hst_age_locked" class="btn-secondary p-1 inline-block" type="button" @click.stop="showComposeEmailForm({leadId: lead.id, email: 3})">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                              </svg>
                            </button>
                            <a v-if="lead.email_second_contact && !isDnc && !hst_age_locked" class="btn btn-primary ml-2 p-1 text-xs" type="button" @click.stop :href="`mailto:${lead.email_second_contact}`">Email via Your App</a>
                            <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="email_second_contact" id="email_second_contact" type="email" name="email_second_contact" class="mt-1 input-field w-full">
                            <div v-if="isInvalid('email_second_contact')" class="error-message">{{ errorMessage('email_second_contact') }}</div>
                          </div>
                        </div>

                        <div class="mt-8">
                          <h4 class="text-xl font-black">Social Media</h4>
                          <div class="mt-2">
                            <div class="flex space-x-2 items-center">
                              <div class="w-full">
                                <label class="flex-0 text-gray-600">
                                  <span class="mr-2">Facebook</span>
                                </label>
                                <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="social_media_facebook_id" id="social_media_facebook_id" type="email" name="social_media_facebook_id" class="mt-1 input-field w-full">
                                <div v-if="isInvalid('social_media_facebook_id')" class="error-message">{{ errorMessage('social_media_facebook_id') }}</div>
                              </div>
                            </div>
                            <div class="flex space-x-2 items-center">
                              <div class="w-full">
                                <label class="flex-0 text-gray-600">
                                  <span class="mr-2">Instagram</span>
                                </label>
                                <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="social_media_instagram_id" id="social_media_instagram_id" type="email" name="social_media_instagram_id" class="mt-1 input-field w-full">
                                <div v-if="isInvalid('social_media_instagram_id')" class="error-message">{{ errorMessage('social_media_instagram_id') }}</div>
                              </div>
                            </div>
                            <div class="flex space-x-2 items-center">
                              <div class="w-full">
                                <label class="flex-0 text-gray-600">
                                  <span class="mr-2">LinkedIn</span>
                                </label>
                                <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="social_media_linkedin_id" id="social_media_linkedin_id" type="email" name="social_media_linkedin_id" class="mt-1 input-field w-full">
                                <div v-if="isInvalid('social_media_linkedin_id')" class="error-message">{{ errorMessage('social_media_linkedin_id') }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </dl>
          </div>

      </div>

      <div class="bg-white shadow-sm overflow-hidden sm:rounded-lg border pb-6">
          <div class="px-6 pt-6 flex space-x-4 items-center justify-between">
              <div class="flex space-x-4 items-center">
                  <h4 class="text-xl font-black">Address Details</h4>
              </div>
          </div>
          <div class="px-6 pb-4 flex space-x-4 items-center justify-between">
            <div class="flex space-x-4 items-center">
              <h4 class="text-lg font-black">Current Leads Time: <span class="text-tb-blue">({{ leadCurrentTime }}, {{ lead.timezone_region_name }})</span></h4>
            </div>
          </div>
          <div class="px-4 sm:px-6 -mt-1">
              <dl class="">
                  <div class="w-full">
                      <label class="block text-gray-600">Address 1</label>
                      <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('address_1')}">
                          <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="address_1" id="address_1" type="text" class=" input-field w-full" name="address_1">
                          <div v-if="isInvalid('address_1')" class="error-message">{{ errorMessage('address_1') }}</div>
                      </dd>
                  </div>
                  <div class="w-full mt-3">
                      <label class="block text-gray-600">Address 2</label>
                      <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('address_2')}">
                          <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="address_2" id="address_2" type="text" class="input-field w-full" name="address_2">
                          <div v-if="isInvalid('address_2')" class="error-message">{{ errorMessage('address_2') }}</div>
                      </dd>
                  </div>
                  <div class="flex space-x-4 items-start mt-3">
                      <div class="w-full">
                          <label class="block text-gray-600">City</label>
                          <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('city')}">
                              <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="city" id="city" type="text" class="input-field w-full" name="city">
                              <div v-if="isInvalid('city')" class="error-message">{{ errorMessage('city') }}</div>
                          </dd>
                      </div>
                      <div class="w-full">
                          <label class="block text-gray-600">State</label>
                          <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('state_id')}">
                              <select @change="submitWithoutClosingEditForm" v-model="state_id" data-placeholder="Select state" class="tom-select input-field w-full" id="state_id" name="state_id">
                                  <option v-for="(state, i) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
                              </select>
                              <div v-if="isInvalid('state_id')" class="error-message">{{ errorMessage('state_id') }}</div>
                          </dd>
                      </div>
                  </div>
                  <div class="flex space-x-4 items-start mt-3 pb-6">
                      <div class="w-full">
                          <label class="block text-gray-600">Zip</label>
                          <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('zip')}">
                              <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="zip" id="zip" type="text" name="zip" class=" input-field w-full">
                              <div v-if="isInvalid('zip')" class="error-message">{{ errorMessage('zip') }}</div>
                          </dd>
                      </div>
                      <div class="w-full">
                          <label class="block text-gray-600">County</label>
                          <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('county')}">
                              <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="county" id="county" type="text" class=" input-field w-full">
                              <div v-if="isInvalid('county')" class="error-message">{{ errorMessage('county') }}</div>
                          </dd>
                      </div>
                  </div>
              </dl>
          </div>

        <div v-if="isRecruitingLead">
          <div class="p-6 flex space-x-4 items-center justify-between">
            <div class="flex space-x-4 items-center">
              <h4 class="text-xl font-black">Recruiting Details</h4>
            </div>
          </div>
          <div class="px-4 sm:px-6 -mt-1">
            <dl class="w-full">
              <div>
                <div class="flex items-end space-x-4 items-start">
                  <div class="w-full">
                    <label class="block text-gray-600">NPN Number</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('recruiting_npn_number')}">
                      <input @keyup.enter.prevent="submitWithoutClosingEditForm" @blur="checkForChange" v-model="recruiting_npn_number" id="recruiting_npn_number" type="text" class=" input-field w-full" name="recruiting_npn_number">
                      <div v-if="isInvalid('recruiting_npn_number')" class="error-message">{{ errorMessage('recruiting_npn_number') }}</div>
                    </dd>
                  </div>
                </div>
                <div class="flex space-x-4 items-start mt-3">
                  <div class="w-full">
                    <label class="block text-gray-600">Health & Life License</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('recruiting_health_and_life_license')}">
                      <select @blur="checkForChange" v-model="recruiting_health_and_life_license" id="recruiting_health_and_life_license" type="text" class=" input-field w-full" name="recruiting_health_and_life_license">
                        <option :value="1">Yes</option>
                        <option :value="0">No</option>
                      </select>
                      <!--<input @keyup.enter.prevent="submitWithoutClosingEditForm" @blur="checkForChange" v-model="recruiting_health_and_life_license" id="recruiting_health_and_life_license" type="text" class=" input-field w-full" name="recruiting_health_and_life_license">-->
                      <div v-if="isInvalid('recruiting_health_and_life_license')" class="error-message">{{ errorMessage('recruiting_health_and_life_license') }}</div>
                    </dd>
                  </div>
                  <div class="w-full">
                    <label class="block text-gray-600">E&O</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('recruiting_errors_and_omissions')}">
                      <select @blur="checkForChange" v-model="recruiting_errors_and_omissions" id="recruiting_errors_and_omissions" type="text" class=" input-field w-full" name="recruiting_errors_and_omissions">
                        <option :value="1">Yes</option>
                        <option :value="0">No</option>
                      </select>
                      <div v-if="isInvalid('recruiting_errors_and_omissions')" class="error-message">{{ errorMessage('recruiting_errors_and_omissions') }}</div>
                    </dd>
                  </div>
                </div>
              </div>
            </dl>
          </div>
        </div>

        <div v-if="!isRecruitingLead">
          <div class="p-6 flex space-x-4 items-center justify-between">
            <div class="flex space-x-4 items-center">
              <h4 class="text-xl font-black">Additional Details</h4>
            </div>
          </div>
          <div class="px-4 sm:px-6 -mt-1">
            <dl class="w-full">
              <div>
                <div v-if="!usesGroupLeadFields" class="flex items-end space-x-4 items-start">
                  <div class="w-full">
                    <label class="block text-gray-600">Company</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('company')}">
                      <input @keyup.enter.prevent="submitWithoutClosingEditForm" @blur="checkForChange" v-model="company" id="company" type="text" class=" input-field w-full" name="company">
                      <div v-if="isInvalid('company')" class="error-message">{{ errorMessage('company') }}</div>
                    </dd>
                  </div>
                </div>

                <div v-if="lead.has_type_marked_as_group" class="flex space-x-4 items-start mt-3">
                  <div class="w-full">
                    <label class="block text-gray-600">Renewal Date</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('renewal_date')}">
                      <masked-date-picker @change="submitWithoutClosingEditForm" @blur="checkForChange('renewal_date')" element-id="renewal_date" v-model="renewal_date"></masked-date-picker>
                      <div v-if="isInvalid('renewal_date')" class="error-message">{{ errorMessage('renewal_date') }}</div>
                    </dd>
                  </div>
                  <div class="w-full">
                    <label class="block text-gray-600">Number Of Employees</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('number_of_employees')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="number_of_employees" id="number_of_employees" type="text" name="number_of_employees" class=" input-field w-full">
                      <div v-if="isInvalid('number_of_employees')" class="error-message">{{ errorMessage('number_of_employees') }}</div>
                    </dd>
                  </div>
                </div>
                <div v-if="lead.has_type_marked_as_group" class="flex space-x-4 items-start mt-3">
                  <div class="w-full">
                    <label class="block text-gray-600">Fax</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('fax')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="fax" id="fax" type="text" name="fax" class=" input-field w-full">
                      <div v-if="isInvalid('fax')" class="error-message">{{ errorMessage('fax') }}</div>
                    </dd>
                  </div>
                  <div class="w-full">
                    <label class="block text-gray-600">Website</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('website')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="website" id="website" type="text" name="website" class=" input-field w-full">
                      <div v-if="isInvalid('website')" class="error-message">{{ errorMessage('website') }}</div>
                    </dd>
                  </div>
                </div>
              </div>

              <div class="flex items-end space-x-4 mt-3">
                <div class="w-full">
                  <label class="block text-gray-600">Annual Household Income</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('annual_household_income')}">
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"> $ </span>
                      <masked-currency-input v-model="annual_household_income" @change="checkForChange" id="annual_household_income" @onEnter="submitWithoutClosingEditForm" class="flex-1 min-w-0 block w-full px-3 py-1.5 rounded-none rounded-r-md input-field"></masked-currency-input>
                    </div>
                    <div v-if="isInvalid('annual_household_income')" class="error-message">{{ errorMessage('annual_household_income') }}</div>
                  </dd>
                </div>

                <div v-if="!usesGroupLeadFields" class="w-full">
                  <label class="block text-gray-600">Wedding Anniversary</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('wedding_anniversary')}">
                    <masked-date-picker @change="submitWithoutClosingEditForm" @blur="checkForChange" element-id="wedding_anniversary" v-model="wedding_anniversary"></masked-date-picker>
                    <div v-if="isInvalid('wedding_anniversary')" class="error-message">{{ errorMessage('wedding_anniversary') }}</div>
                  </dd>
                </div>
              </div>

              <div class="w-full mt-3">
                <label class="block text-gray-600">Main Contact</label>
                <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('main_contact')}">
                  <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" id="main_contact" v-model="main_contact" type="text" name="main_contact" class=" input-field w-full">
                  <div v-if="isInvalid('main_contact')" class="error-message">{{ errorMessage('main_contact') }}</div>
                </dd>
              </div>
              <div class="flex items-end space-x-4 mt-3">
                <div class="w-full">
                  <label class="block text-gray-600">Currently Insured</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('currently_insured')}">
                    <select v-model="formData.currently_insured" id="currently_insured" class="form-control input-field w-full" name="currently_insured">
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    <div v-if="isInvalid('currently_insured')" class="error-message">{{ errorMessage('currently_insured') }}</div>
                  </dd>
                </div>
                <div class="w-full">
                  <label class="block text-gray-600">Desired Start</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('desired_start')}">
                    <masked-date-picker @change="submitWithoutClosingEditForm"  @blur="checkForChange" element-id="desired-start-form" v-model="desired_start"></masked-date-picker>
                    <div v-if="isInvalid('desired_start')" class="error-message">{{ errorMessage('desired_start') }}</div>
                  </dd>
                </div>
              </div>
              <div class="flex space-x-4 items-start mt-3">
                <div class="w-full">
                  <label class="block text-gray-600">Social Security Number</label>
                  <dd v-if="ssnVisible" class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('ssn')}">
                    <input @keyup.enter="submitSsnWithoutClosingEditForm" @blur.prevent="checkIfSsnChanged" v-model="ssn" id="ssn" type="text" name="medicare_id" class=" input-field w-full">
                    <div v-if="isInvalid('ssn')" class="error-message">{{ errorMessage('ssn') }}</div>
                  </dd>
                  <dd v-else>
                    <button type="button" @click.prevent="showSSNPasswordForm" class="btn btn-primary py-1 px-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-4 w-4"><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd"></path></svg></button>
                  </dd>
                </div>
              </div>
              <div v-if="lead.has_type_marked_as_medicare" class="flex space-x-4 items-start mt-3">
                <div class="w-full">
                  <label class="block text-gray-600">Medicare ID</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('medicare_id')}">
                    <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="medicare_id" id="medicare_id" type="text" name="medicare_id" class=" input-field w-full">
                    <div v-if="isInvalid('medicare_id')" class="error-message">{{ errorMessage('medicare_id') }}</div>
                  </dd>
                </div>
              </div>
              <div v-if="lead.has_type_marked_as_medicare" class="flex space-x-4 items-start mt-3">
                <div class="w-full">
                  <label class="block text-gray-600">Part A Effective Date</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('part_a_effective_date')}">
                    <el-date-picker
                      id="part_a_effective_date-datepicker-edit-form"
                      style="width: 100% !important;"
                      v-model="part_a_effective_date"
                      type="date"
                      placeholder="Pick a day"
                      format="MMM DD, YYYY"
                      value-format="YYYY-MM-DD"
                      @change="submitWithoutClosingEditForm"
                    />
                    <div v-if="isInvalid('part_a_effective_date')" class="error-message">{{ errorMessage('part_a_effective_date') }}</div>
                  </dd>
                </div>
              </div>
              <div v-if="lead.has_type_marked_as_medicare" class="flex space-x-4 items-start mt-3">
                <div class="w-full">
                  <label class="block text-gray-600">Part B Effective Date</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('part_b_effective_date')}">
                    <el-date-picker
                      id="part_b_effective_date-datepicker-edit-form"
                      style="width: 100% !important;"
                      v-model="part_b_effective_date"
                      type="date"
                      placeholder="Pick a day"
                      format="MMM DD, YYYY"
                      value-format="YYYY-MM-DD"
                      @change="submitWithoutClosingEditForm"
                    />
                    <div v-if="isInvalid('part_b_effective_date')" class="error-message">{{ errorMessage('part_b_effective_date') }}</div>
                  </dd>
                </div>
              </div>
              <div v-if="lead.has_type_marked_as_medicare" class="flex space-x-4 items-start mt-3">
                <div class="w-full">
                  <label class="block text-gray-600">Part D Effective Date</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('part_d_effective_date')}">
                    <el-date-picker
                      id="part_d_effective_date-datepicker-edit-form"
                      style="width: 100% !important;"
                      v-model="part_d_effective_date"
                      type="date"
                      placeholder="Pick a day"
                      format="MMM DD, YYYY"
                      value-format="YYYY-MM-DD"
                      @change="submitWithoutClosingEditForm"
                    />
                    <div v-if="isInvalid('part_d_effective_date')" class="error-message">{{ errorMessage('part_d_effective_date') }}</div>
                  </dd>
                </div>
              </div>

              <div v-if="lead.has_type_marked_as_medicare" class="flex space-x-4 items-start mt-3">
                <div class="w-full">
                  <label class="block text-gray-600">Veteran</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('veteran')}">
                    <select v-model="formData.veteran" id="veteran" class="form-control input-field w-full" name="veteran">
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    <div v-if="isInvalid('veteran')" class="error-message">{{ errorMessage('veteran') }}</div>
                  </dd>
                </div>
              </div>

              <div v-if="lead.has_type_marked_as_medicare" class="flex space-x-4 items-start mt-3">
                <div class="w-full">
                  <label class="block text-gray-600">Medicaid ID</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('medicaid_id')}">
                    <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="medicaid_id" id="medicaid_id" type="text" name="medicaid_id" class=" input-field w-full">
                    <div v-if="isInvalid('medicaid_id')" class="error-message">{{ errorMessage('medicaid_id') }}</div>
                  </dd>
                </div>
              </div>

              <div v-if="lead.has_type_marked_as_medicare" class="flex space-x-4 items-start mt-3">
                <div class="w-full">
                  <label class="block text-gray-600">Social Security Benefits</label>
                  <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('social_security_benefits')}">
                    <select @change="submitWithoutClosingEditForm" v-model="formData.social_security_benefits" id="social_security_benefits" class="form-control input-field w-full" name="social_security_benefits">
                      <option :value="null"></option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    <div v-if="isInvalid('social_security_benefits')" class="error-message">{{ errorMessage('social_security_benefits') }}</div>
                  </dd>
                </div>
              </div>

              <!-- BEGIN: Group Type Lead Fields -->
              <div v-if="usesGroupLeadFields">
                <div class="flex items-end space-x-4 mt-3">
                  <div class="w-full">
                    <label class="block text-gray-600">Is This A Parent Lead?</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('is_parent_lead')}">
                      <select v-model="formData.is_parent_lead" id="is_parent_lead" class="form-control input-field w-full" name="is_parent_lead" @blur="checkForChange">
                        <option :value="0">No</option>
                        <option :value="1">Yes</option>
                      </select>
                      <div v-if="isInvalid('is_parent_lead')" class="error-message">{{ errorMessage('is_parent_lead') }}</div>
                    </dd>
                  </div>
                  <div v-if="!formData.is_parent_lead" class="w-full">
                    <label class="block text-gray-600">Parent Lead</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('parent_lead_id')}">
                      <select v-model="formData.parent_lead_id" id="parent_lead_id" class="form-control input-field w-full" name="parent_lead_id" @blur="checkForChange">
                        <option :value="null">- No -</option>
                        <option v-for="(parentLeadOption, index) in leadParentOptions.data" :key="parentLeadOption.id" :value="parentLeadOption.id">{{ `${parentLeadOption.company}: ${parentLeadOption.last_name}, ${parentLeadOption.first_name}` }}</option>
                      </select>
                      <div v-if="isInvalid('parent_lead_id')" class="error-message">{{ errorMessage('parent_lead_id') }}</div>
                    </dd>
                  </div>
                </div>

                <div class="flex items-end space-x-4 mt-3">
                  <dl class="w-full">
                    <div class="flex space-x-4 items-start">
                      <div class="w-full">
                        <label class="block text-gray-600">Notes</label>
                        <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('notes')}">
                          <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" @focus="storeOriginalValue" v-model="notes" id="notes" type="text" class="input-field w-full" name="notes">
                          <div v-if="isInvalid('notes')" class="error-message">{{ errorMessage('notes') }}</div>
                        </dd>
                      </div>
                    </div>
                  </dl>
                </div>

                <div class="flex items-end space-x-4 mt-3">
                  <div class="w-full">
                    <label class="block text-gray-600">SIC Code</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('sic_code')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="sic_code" id="sic_code" type="text" class=" input-field w-full">
                      <div v-if="isInvalid('sic_code')" class="error-message">{{ errorMessage('sic_code') }}</div>
                    </dd>
                  </div>
                  <div class="w-full">
                    <label class="block text-gray-600">FEIN</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('fein')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="fein" id="fein" type="text" class=" input-field w-full">
                      <div v-if="isInvalid('fein')" class="error-message">{{ errorMessage('fein') }}</div>
                    </dd>
                  </div>
                </div>
                <div class="flex items-end space-x-4 mt-3">
                  <div class="w-full">
                    <label class="block text-gray-600">Company Type</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('company_type')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="company_type" id="company_type" type="text" class=" input-field w-full">
                      <div v-if="isInvalid('company_type')" class="error-message">{{ errorMessage('company_type') }}</div>
                    </dd>
                  </div>
                  <div class="w-full">
                    <label class="block text-gray-600">Tax ID</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('tax_id')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="tax_id" id="tax_id" type="text" class=" input-field w-full">
                      <div v-if="isInvalid('tax_id')" class="error-message">{{ errorMessage('tax_id') }}</div>
                    </dd>
                  </div>
                </div>
                <div class="flex items-end space-x-4 mt-3">
                  <div class="w-full">
                    <label class="block text-gray-600">Waiting Period</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('waiting_period')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="waiting_period" id="waiting_period" type="text" class=" input-field w-full">
                      <div v-if="isInvalid('waiting_period')" class="error-message">{{ errorMessage('waiting_period') }}</div>
                    </dd>
                  </div>
                  <div class="w-full">
                    <label class="block text-gray-600">Hours of Operation</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('hours_of_operation')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="hours_of_operation" id="hours_of_operation" type="text" class=" input-field w-full">
                      <div v-if="isInvalid('hours_of_operation')" class="error-message">{{ errorMessage('hours_of_operation') }}</div>
                    </dd>
                  </div>
                </div>
                <div class="flex items-end space-x-4 mt-3">
                  <div class="w-full">
                    <label class="block text-gray-600">Employer Contribution Rate (Employee)</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('employer_contribution_rate_employee')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="employer_contribution_rate_employee" id="employer_contribution_rate_employee" type="text" class=" input-field w-full">
                      <div v-if="isInvalid('employer_contribution_rate_employee')" class="error-message">{{ errorMessage('employer_contribution_rate_employee') }}</div>
                    </dd>
                  </div>
                  <div class="w-full">
                    <label class="block text-gray-600">Employer Contribution Rate (Employee + Spouse)</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('employer_contribution_rate_employee_and_spouse')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="employer_contribution_rate_employee_and_spouse" id="employer_contribution_rate_employee_and_spouse" type="text" class=" input-field w-full">
                      <div v-if="isInvalid('employer_contribution_rate_employee_and_spouse')" class="error-message">{{ errorMessage('employer_contribution_rate_employee_and_spouse') }}</div>
                    </dd>
                  </div>
                </div>
                <div class="flex items-end space-x-4 mt-3">
                  <div class="w-full">
                    <label class="block text-gray-600">Employer Contribution Rate (Employee + Child)</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('employer_contribution_rate_employee_and_child')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="employer_contribution_rate_employee_and_child" id="employer_contribution_rate_employee_and_child" type="text" class=" input-field w-full">
                      <div v-if="isInvalid('employer_contribution_rate_employee_and_child')" class="error-message">{{ errorMessage('employer_contribution_rate_employee_and_child') }}</div>
                    </dd>
                  </div>
                  <div class="w-full">
                    <label class="block text-gray-600">Employer Contribution Rate (Employee + Family)</label>
                    <dd class="mt-1 text-sm text-gray-900" :class="{'has-error': isInvalid('employer_contribution_rate_employee_and_family')}">
                      <input @keyup.enter="submitWithoutClosingEditForm" @blur="checkForChange" v-model="employer_contribution_rate_employee_and_family" id="employer_contribution_rate_employee_and_family" type="text" class=" input-field w-full">
                      <div v-if="isInvalid('employer_contribution_rate_employee_and_family')" class="error-message">{{ errorMessage('employer_contribution_rate_employee_and_spouse') }}</div>
                    </dd>
                  </div>
                </div>
              </div>
              <!-- END: Group Type Lead Fields -->
            </dl>
          </div>
        </div>
      </div>
    </div>
    </form>
  </div>
  <lead-view-info-read-only
    v-if="!editMode"
    :lead="lead"
    :clicktocall="clicktocall"
    @show-edit-mode="handleShowEditMode"
    @show-compose-sms-form="showComposeTextForm"
    @show-compose-email-form="showComposeEmailForm"
    :lead-current-time="leadCurrentTime"
    :hst_age_locked="hst_age_locked"
    @show-ssn-password-form="showSSNPasswordForm"
  ></lead-view-info-read-only>


  <Modal :show="ssnPasswordModalVisible" title="Enter Email and Password to show SSN" @onClose="ssnPasswordModalVisible = false">
    <form class="max-w-sm mx-auto" @submit.prevent="submitCredentials">
      <div>
        <input id="medication-check-email" v-model="credentialsForm.email" autocomplete="off" autofocus class="input-field  mt-1 block w-full" placeholder="Email Address" required type="email"/>
        <BreezeInputError :message="credentialsForm.errors.email" class="mt-2"/>
      </div>

      <div class="mt-2">
        <input id="medication-check-password" v-model="credentialsForm.password" autocomplete="off" class="input-field mt-1 block w-full" placeholder="Password" required style="text-security:disc; -webkit-text-security:disc;" type="text"/>
        <BreezeInputError :message="credentialsForm.errors.password" class="mt-2"/>
      </div>

      <div class="mt-4">
        <BreezeButton :class="{ 'opacity-25': fetchingSSN }" :disabled="fetchingSSN" class="text-white bg-tb-blue block w-full py-3">
          <p class="mx-auto font-bold">Show SSN</p>
        </BreezeButton>
      </div>
    </form>
  </Modal>
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import helpers from "../../helper";
import LeadViewInfoReadOnly from "./LeadViewInfoReadOnly.vue";
import _ from "lodash";
import { CheckCircleIcon, XIcon } from '@heroicons/vue/solid';
import { DateTime } from "luxon";
import { ElDatePicker } from "element-plus";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import { PlusCircleIcon } from '@heroicons/vue/outline';
import MaskedDatePicker from "@/Components/MaskedDatePicker.vue";
import MaskedCurrencyInput from "@/Components/MaskedCurrencyInput.vue";
import MaskedPhoneInput from "@/Components/MaskedPhoneInput.vue";
import Modal from "@/Components/Modal.vue";
import BreezeInputError from "@/Components/InputError.vue";
import BreezeButton from "@/Components/Button.vue";
import {useForm} from "@inertiajs/inertia-vue3";

export default {
  name: "LeadViewInfo",

  mixins: [serverValidationErrorsMixin],

  components: {
    BreezeButton, BreezeInputError,
    Modal,
    MaskedPhoneInput,
    LoadingSpinner,
    LeadViewInfoReadOnly,
    CheckCircleIcon,
    XIcon,
    ElDatePicker,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    PlusCircleIcon,
    MaskedDatePicker,
    MaskedCurrencyInput,
  },

  props: {
    lead: {
      type: Object,
      required: true,
    },
    states: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    leadDependants: {
        type: Array,
        default: () => [],
    },
    clicktocall: {
        type: String,
        default: null,
    },
    hst_age_locked: Boolean,
    leadParentOptions: Object,
  },

  inject: ['isDnc'],

  emits: [
      'update:lead',
      'show-edit-mode',
      'close-edit-mode',
      'show-compose-sms-form',
      'show-compose-email-form',
      'refresh-dependants'
  ],

  data() {
    return {
      loading: false,
      notificationMessage: 'Lead updated',
      formData: _.cloneDeep(this.lead),
      originalFormData: _.cloneDeep(this.lead),
      hasChanges: false,
      leadCurrentTime: null,
      dayjsMask: null,
      moneyMask: {
        preProcess: val => val.replace(/[$,]/g, ''),
        postProcess: val => {
        if (!val) return ''

        const sub = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0)

        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(val)
            .slice(0, sub ? -sub : undefined)
          }
      },
      decryptedSsn: null,
      ssn: null, // we wont show ssn until they enter their password. Then we will fetch it from the server, decrypt it and show it.
      hasSsnChanges: false,
      ssnPasswordModalVisible: false,
      credentialsForm: useForm({
        email: '',
        password: '',
      }),
      fetchingSSN: false,
      ssnVisible: false,
    }
  },

  mounted() {
    let time = DateTime.now().setZone(this.lead.timezone);
    this.leadCurrentTime = time.toFormat('hh:mm a')

    setInterval(() => {
      let time = DateTime.now().setZone(this.lead.timezone);
      this.leadCurrentTime = time.toFormat('hh:mm a')
    }, 1000 * 60)
  },

  computed: {
    usesGroupLeadFields() {
      return this.lead.has_type_marked_as_group;
    },
    isRecruitingLead() {
        return this.lead.lead_category === 'recruitment';
    },
    isCommercialLead() {
        return ! this.isRecruitingLead();
    },
    first_name: {
      get() {
        return this.formData.first_name;
      },
      set(value) {
        this.formData.first_name = value;
      }
    },
    last_name: {
      get() {
        return this.formData.last_name;
      },
      set(value) {
        this.formData.last_name = value;
      }
    },
    dob: {
      get() {
          return this.formData.dob;
      },
      set(value) {
          this.formData.dob = value;
      },
    },
    lead_status_id: {
      get() {
        return this.formData.lead_status_id;
      },
      set(value) {
        this.formData.lead_status_id = value;
      }
    },
    email: {
      get() {
        return this.formData.email;
      },
      set(value) {
        this.formData.email = value;
      }
    },
    email_2: {
      get() {
        return this.formData.email_2;
      },
      set(value) {
        this.formData.email_2 = value;
      }
    },
    phone: {
      get() {
        return this.formData.phone;
      },
      set(value) {
        this.formData.phone = value;
      }
    },
    phone_2: {
      get() {
        return this.formData.phone_2;
      },
      set(value) {
        this.formData.phone_2 = value;
      }
    },
    website: {
      get() {
        return this.formData.website;
      },
      set(value) {
        this.formData.website = value;
      }
    },
    fax: {
      get() {
        return this.formData.fax;
      },
      set(value) {
        this.formData.fax = value;
      }
    },
    address_1: {
      get() {
        return this.formData.address_1;
      },
      set(value) {
        this.formData.address_1 = value;
      }
    },
    address_2: {
      get() {
        return this.formData.address_2;
      },
      set(value) {
        this.formData.address_2 = value;
      }
    },
    city: {
      get() {
        return this.formData.city;
      },
      set(value) {
        this.formData.city = value;
      }
    },
    state_id: {
      get() {
        return this.formData.state_id;
      },
      set(value) {
        this.formData.state_id = value;
      }
    },
    zip: {
      get() {
        return this.formData.zip;
      },
      set(value) {
        this.formData.zip = value;
      }
    },
    county: {
      get() {
        return this.formData.county;
      },
      set(value) {
        this.formData.county = value;
      }
    },
    company: {
      get() {
        return this.formData.company;
      },
      set(value) {
        this.formData.company = value;
      }
    },
    recruiting_npn_number: {
      get() {
        return this.formData.recruiting_npn_number;
      },
      set(value) {
        this.formData.recruiting_npn_number = value;
      }
    },
    recruiting_health_and_life_license: {
      get() {
        return this.formData.recruiting_health_and_life_license;
      },
      set(value) {
        this.formData.recruiting_health_and_life_license = value;
      }
    },
    recruiting_errors_and_omissions: {
      get() {
        return this.formData.recruiting_errors_and_omissions;
      },
      set(value) {
        this.formData.recruiting_errors_and_omissions = value;
      }
    },
    nickname: {
      get() {
        return this.formData.nickname;
      },
      set(value) {
        this.formData.nickname = value;
      }
    },
    use_nickname_on_templates: {
      get() {
        return this.formData.use_nickname_on_templates;
      },
      set(value) {
        this.formData.use_nickname_on_templates = value;
      }
    },
    number_of_employees: {
      get() {
        return this.formData.number_of_employees;
      },
      set(value) {
        this.formData.number_of_employees = value;
      }
    },
    annual_household_income: {
      get() {
        return this.formData.annual_household_income;
      },
      set(value) {
        this.formData.annual_household_income = value;
      }
    },
    renewal_date: {
      get() {
        return this.formData.renewal_date;
      },
      set(value) {
        this.formData.renewal_date = value;
      },
    },
    wedding_anniversary: {
      get() {
        return this.formData.wedding_anniversary;
      },
      set(value) {
        this.formData.wedding_anniversary = value;
      }
    },
    part_a_effective_date: {
      get() {
        return this.formData.part_a_effective_date;
      },
      set(value) {
        this.formData.part_a_effective_date = value;
      }
    },
    part_b_effective_date: {
      get() {
        return this.formData.part_b_effective_date;
      },
      set(value) {
        this.formData.part_b_effective_date = value;
      }
    },
    part_d_effective_date: {
      get() {
        return this.formData.part_d_effective_date;
      },
      set(value) {
        this.formData.part_d_effective_date = value;
      }
    },
    veteran: {
      get() {
        return this.formData.veteran;
      },
      set(value) {
        this.formData.veteran = value;
      }
    },
    medicare_id: {
      get() {
        return this.formData.medicare_id;
      },
      set(value) {
        this.formData.medicare_id = value;
      }
    },
    medicaid_id: {
      get() {
        return this.formData.medicaid_id;
      },
      set(value) {
        this.formData.medicaid_id = value;
      }
    },
    social_security_benefits: {
      get() {
        return this.formData.social_security_benefits;
      },
      set(value) {
        this.formData.social_security_benefits = value;
      }
    },
    source_id: {
      get() {
        return this.formData.source_id;
      },
      set(value) {
        this.formData.source_id = value;
      }
    },
    main_contact: {
      get() {
        return this.formData.main_contact;
      },
      set(value) {
        this.formData.main_contact = value;
      }
    },
    is_parent_lead: {
      get() {
        return this.formData.is_parent_lead;
      },
      set(value) {
        this.formData.is_parent_lead = value;
      }
    },
    parent_lead_id: {
      get() {
        return this.formData.parent_lead_id;
      },
      set(value) {
        this.formData.parent_lead_id = value;
      }
    },
    notes: {
      get() {
        return this.formData.notes;
      },
      set(value) {
        this.formData.notes = value;
      }
    },
    sic_code: {
      get() {
        return this.formData.sic_code;
      },
      set(value) {
        this.formData.sic_code = value;
      }
    },
    fein: {
      get() {
        return this.formData.fein;
      },
      set(value) {
        this.formData.fein = value;
      }
    },
    company_type: {
      get() {
        return this.formData.company_type;
      },
      set(value) {
        this.formData.company_type = value;
      }
    },
    tax_id: {
      get() {
        return this.formData.tax_id;
      },
      set(value) {
        this.formData.tax_id = value;
      }
    },
    waiting_period: {
      get() {
        return this.formData.waiting_period;
      },
      set(value) {
        this.formData.waiting_period = value;
      }
    },
    hours_of_operation: {
      get() {
        return this.formData.hours_of_operation;
      },
      set(value) {
        this.formData.hours_of_operation = value;
      }
    },
    employer_contribution_rate_employee: {
      get() {
        return this.formData.employer_contribution_rate_employee;
      },
      set(value) {
        this.formData.employer_contribution_rate_employee = value;
      }
    },
    employer_contribution_rate_employee_and_spouse: {
      get() {
        return this.formData.employer_contribution_rate_employee_and_spouse;
      },
      set(value) {
        this.formData.employer_contribution_rate_employee_and_spouse = value;
      }
    },
    employer_contribution_rate_employee_and_child: {
      get() {
        return this.formData.employer_contribution_rate_employee_and_child;
      },
      set(value) {
        this.formData.employer_contribution_rate_employee_and_child = value;
      }
    },
    employer_contribution_rate_employee_and_family: {
      get() {
        return this.formData.employer_contribution_rate_employee_and_family;
      },
      set(value) {
        this.formData.employer_contribution_rate_employee_and_family = value;
      }
    },
    first_name_second_contact: {
      get() {
        return this.formData.first_name_second_contact;
      },
      set(value) {
        this.formData.first_name_second_contact = value;
      }
    },
    last_name_second_contact: {
      get() {
        return this.formData.last_name_second_contact;
      },
      set(value) {
        this.formData.last_name_second_contact = value;
      }
    },
    title_notes_first_contact: {
      get() {
        return this.formData.title_notes_first_contact;
      },
      set(value) {
        this.formData.title_notes_first_contact = value;
      }
    },
    title_notes_second_contact: {
      get() {
        return this.formData.title_notes_second_contact;
      },
      set(value) {
        this.formData.title_notes_second_contact = value;
      }
    },
    phone_second_contact: {
      get() {
        return this.formData.phone_second_contact;
      },
      set(value) {
        this.formData.phone_second_contact = value;
      }
    },
    phone_2_second_contact: {
      get() {
        return this.formData.phone_2_second_contact;
      },
      set(value) {
        this.formData.phone_2_second_contact = value;
      }
    },
    email_second_contact: {
      get() {
        return this.formData.email_second_contact;
      },
      set(value) {
        this.formData.email_second_contact = value;
      }
    },
    social_media_facebook_id: {
      get() {
        return this.formData.social_media_facebook_id;
      },
      set(value) {
        this.formData.social_media_facebook_id = value;
      }
    },
    social_media_instagram_id: {
      get() {
        return this.formData.social_media_instagram_id;
      },
      set(value) {
        this.formData.social_media_instagram_id = value;
      }
    },
    social_media_linkedin_id: {
      get() {
        return this.formData.social_media_linkedin_id;
      },
      set(value) {
        this.formData.social_media_linkedin_id = value;
      }
    },
    phone_type: {
      get() {
        return this.formData.phone_type;
      },
      set(value) {
        this.formData.phone_type = value;
      }
    },
    phone_2_type: {
      get() {
        return this.formData.phone_2_type;
      },
      set(value) {
        this.formData.phone_2_type = value;
      }
    },
    phone1_owner_id: {
      get() {
        return this.formData.phone1_owner_id;
      },
      set(value) {
        this.formData.phone1_owner_id = value;
      }
    },
    phone2_owner_id: {
      get() {
        return this.formData.phone2_owner_id;
      },
      set(value) {
        this.formData.phone2_owner_id = value;
      }
    },
    email1_owner_id: {
      get() {
        return this.formData.email1_owner_id;
      },
      set(value) {
        this.formData.email1_owner_id = value;
      }
    },
    email2_owner_id: {
      get() {
        return this.formData.email2_owner_id;
      },
      set(value) {
        this.formData.email2_owner_id = value;
      }
    },
    currently_insured: {
      get() {
        return this.formData.currently_insured;
      },
      set(value) {
        this.formData.currently_insured = value;
      }
    },
    desired_start: {
      get() {
        return this.formData.desired_start;
      },
      set(value) {
        this.formData.desired_start = value;
      }
    },
    clicktocallPhone1Route() {
        switch(this.clicktocall) {
            case 'TBComm':
                return `callto://${this.lead.phone}`;
                break;
            default:
                return `callto:${this.lead.phone_2}`
        }
    },
      clicktocallPhone2Route() {
          switch(this.clicktocall) {
              case 'TBComm':
                  return `callto://${this.lead.phone_2}`;
                  break;
              default:
              return `callto:${this.lead.phone_2}`
          }
      }
  },

  methods: {
    submitWithoutClosingEditForm(e) {
      this.submit(e, false);
    },
    submit(e, closeEditForm = true) {
      if (this.loading) return;
      this.loading = true;

      setTimeout(() => {
        axios
          .put(`/leads/${this.lead.id}`, this.formData)
          .then((response) => {
            this.handleSuccessfulSubmission(response);
            this.originalFormData = _.cloneDeep(this.formData);
            this.hasChanges = false;
            if (closeEditForm) {
              this.$emit('close-edit-mode');
            }
          })
          .catch((error) => {
            console.log(error, 'AppLeadEditForm - submit()')
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    handleSuccessfulSubmission(response) {
      this.$emit('update:lead', response.data.lead);
      this.$emit('refresh-dependants', response.data.dependants);
      window.mitt.emit('notify-toolbelt-widget', {
          phone1: response.data.lead.phone,
          email1: response.data.lead.email,
          first_name: response.data.lead.first_name,
          last_name: response.data.lead.last_name,
      })
      if (response.data && response.data.message) {
        this.showNotification(response.data.message);
      } else {
        this.showNotification();
      }

      this.errors = {};
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else if (error.response && error.response.status === 403)  {
        $notify.error({
          title: 'Error',
          message: 'You are not allow to do this.',
          showClose: true,
        })
      } else {
        $notify.error({
          title: 'Error',
          message: error.message ?? 'Unexpected error occurred.',
          showClose: true,
        })
      }
      console.log("AppLeadEditForm - handleErroredSubmission", error.response);
    },
    checkForChange(event) {
      // Check if user clicked outside of any form field
      if (!this.$refs.myForm.contains(event.relatedTarget)) {
          if (this.hasChanges) {
              this.promptSaveChanges();
          }
      }
    },

    filterNonNumeric(event, fieldName) {
      // Replace any non-numeric characters with an empty string
      this[fieldName] = this[fieldName].replace(/[^\d]/g, '');
    },

    promptSaveChanges() {
      Swal.fire({
        title: "Save changes?",
        text: `Do you want to save changes before you leave`,
        icon: "warning",
        confirmButtonColor: '#EF4444',
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonColor: '#899CC1',
      })
      .then((result) => {
        if (result.value) {  // Check result.value instead of just result
          this.submitWithoutClosingEditForm();
        } else {
          this.formData = _.cloneDeep(this.originalFormData);
        }
      });
    },
    updateWeddingAnniversary(dateObj) {
      this.formData.wedding_anniversary = dateObj.dateInstance.toDateString();
    },

    showNotification(message = null) {
        let messageContent = message ? message : 'Lead successfully updated';
        $notify.success({
          title: 'Success',
          message: messageContent,
          showClose: true,
        })
    },

    handleShowSSNForm() {
      console.log('here')
    },

    handleShowEditMode(payload) {
      this.$emit('show-edit-mode', payload);
    },

    showComposeTextForm(payload) {
      this.$emit('show-compose-sms-form', {
        leadId: payload.leadId,
        phone: payload.phone,
      });
    },
    showComposeEmailForm(payload) {
        this.$emit('show-compose-email-form', {
            leadId: payload.leadId,
            email: payload.email,
        });
    },
    showSSNPasswordForm() {
      this.ssnPasswordModalVisible = true;
    },
    submitCredentials() {
      this.fetchingSSN = true;
      this.credentialsForm.post(route('verify-credentials'), {
        preserveScroll: true,
        onSuccess: () => {
          this.fetchSSN();
        },
        onError: () => {
          this.fetchingSSN = false;
        }
      })
    },
    fetchSSN() {
      axios.get(`/leads/${this.lead.id}/ssn`)
        .then((response) => {
          this.decryptedSsn = response.data.ssn;
          this.ssn = response.data.ssn;
          this.ssnPasswordModalVisible = false;
          this.ssnVisible = true;
        })
        .catch((error) => {
          console.log(error, 'AppLeadEditForm - fetchSSN()')
          this.handleErroredSubmission(error);
        })
        .finally(() => {
          this.fetchingSSN = false;
        })
    },
    submitSsnWithoutClosingEditForm(e) {
      this.submitSsn(e, false);
    },
    submitSsn(e, closeEditForm = true) {
      if (this.loading) return;
      this.loading = true;

      setTimeout(() => {
        axios
            .post(route('leads.ssn.update', this.lead.id), { ssn: this.ssn })
            .then((response) => {
              if (response.data && response.data.message) {
                this.showNotification(response.data.message);
              } else {
                this.showNotification();
              }

              this.hasSsnChanges = false;
              this.decryptedSsn = this.ssn;
              if (closeEditForm) {
                this.$emit('close-edit-mode');
              }
            })
            .catch((error) => {
              console.log(error, 'AppLeadEditForm - submit()')
              this.handleErroredSubmission(error);
            })
            .finally(() => {
              this.loading = false;
            })
      }, 500)
    },
    checkIfSsnChanged() {
      if (this.ssn !== this.decryptedSsn) {
        this.hasSsnChanges = true;
        this.promptSaveSsnChanges();
      }
    },

    promptSaveSsnChanges() {
      Swal.fire({
        title: "Save changes?",
        text: `Do you want to save changes to SSN before you leave`,
        icon: "warning",
        confirmButtonColor: '#EF4444',
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonColor: '#899CC1',
      })
          .then((result) => {
            if (result.value) {  // Check result.value instead of just result
              this.submitSsnWithoutClosingEditForm();
            } else {
              this.ssn = this.decryptedSsn;
            }
          });
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(newVal) {
        this.hasChanges = !_.isEqual(newVal, this.originalFormData);
      }
    }
  },
}
</script>

<style scoped>
.input-group-sm {
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.has-error .input-group-text {
  --tw-border-opacity: 1;
  border: 1px solid rgb(var(--color-danger) / var(--tw-border-opacity));
}
</style>
