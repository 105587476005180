<template>
  <froala
    v-if="editable"
    v-model:value="content"
    :config="config"
    :tag="'textarea'"
  />
  <froalaView v-else v-model:value="content" class="froalalaview"/>
</template>

<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  allowImageUpload: {
    type: Boolean,
    default: true,
  },
  imageUploadUrl: {
    type: String,
    default: route('froala.images.store'),
  },
  allowFileUpload: {
    type: Boolean,
    default: true,
  },
  fileUploadUrl: {
    type: String,
    default: route('froala.files.store'),
  },
  zIndex: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['update:modelValue'])

const content = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});
const editorRef = ref(null);
const config = ref({
  toolbarButtons: {
    'moreText': {
      'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
    },
    'moreParagraph': {
      'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
    },
    'moreRich': {
      'buttons': ['insertLink', 'insertImage', 'insertTable', 'emoticons', 'specialCharacters', 'insertFile', 'insertHR']
    },
    'moreMisc': {
      'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
      'align': 'right',
      'buttonsVisible': 2
    }
  },
  quickInsertEnabled: false,
  charCounterCount: true,

  imageUploadMethod: 'POST',
  imageUploadURL: props.imageUploadUrl,
  imageMaxSize: 1024 * 1024 * 5, // 5mb
  imageMultipleStyles: false,
  imagePaste: false,
  imageUpload: props.allowImageUpload,

  fileUploadMethod: 'POST',
  fileUploadURL: props.fileUploadUrl,
  fileMaxSize: 1024 * 1024 * 10, // 10mb
  fileUpload: props.allowFileUpload,

  imageManagerLoadMethod: 'GET',
  imageManagerLoadURL: route('froala.images.index'),

  imageUploadParams: {
    _token: usePage().props.value.csrf_token,
  },
  fileUploadParams: {
    _token: usePage().props.value.csrf_token,
  },

  heightMax: 500,
  zIndex: props.zIndex,

  key: import.meta.env.VITE_FROALA_KEY,
  events: {
    initialized: function () {
      editorRef.value = this;
    }
  }
});

function insertContent(event) {
  let string = event && event.string ? event.string : '';
  editorRef.value.html.insert(string, false)
}

onMounted(() => {
  window.mitt.on('insert-given-string-in-editor', insertContent);
})

onUnmounted(() => {
  window.mitt.off('insert-given-string-in-editor', insertContent)
})
</script>

<style>
.froalalaview {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  min-height: 80px;
  background-color: white;
}

#fr-logo {
  display: none !important;
}
</style>
