<template>
  <div v-if="dependants.length" class="grid grid-cols-12 gap-3 px-6 py-4" v-for="(dependant, i) in dependants" :key="dependant.key" :id="`dependant_${dependant.id}`">
    <!-- BEGIN: Roles -->
    <div class="col-span-12 input-form" :class="{'has-error': isInvalidDependentField(i, 'role')}">
      <label :for="`${dependant.id}_dependant-role`" class="block text-sm font-medium text-gray-700">Role</label>
      <select :id="`${dependant.id}_dependant-role`" v-model="dependant.role" class="input-field w-full truncate capitalize" name="roles">
        <option v-for="role in dependantRoles" :value="role">{{ role }}</option>
      </select>
      <div v-if="isInvalidDependentField(i, 'role')" class="error-message mt-1">{{ dependantErrorMessage(i, 'role') }}</div>
    </div>
    <!-- END: Roles -->

    <!-- BEGIN: Dependant name -->
    <div class="col-span-6 input-form" :class="{'has-error': isInvalidDependentField(i, 'name')}">
      <label :for="`${dependant.id}_dependant-name`" class="block text-sm font-medium text-gray-700">Name</label>
      <input :id="`${dependant.id}_dependant-name`" v-model="dependant.name" type="text" name="name" class="input-field w-full">
      <div v-if="isInvalidDependentField(i, 'name')" class="error-message mt-1">{{ dependantErrorMessage(i, 'name') }}</div>
    </div>
    <!-- END: Dependant name -->

    <!-- BEGIN: Dependant dob -->
    <div class="col-span-6 input-form" :class="{'has-error': isInvalidDependentField(i, 'dob')}">
      <label :for="`${dependant.id}_dob-datepicker-edit-form`" class="block text-sm font-medium text-gray-700">DOB</label>
      <el-date-picker
        style="width: 100% !important;"
        v-model="dependant.dob"
        type="date"
        placeholder="Pick a day"
        format="MMM DD, YYYY"
        value-format="YYYY-MM-DD"
        @change="autoUpdateAge(dependant)"
      />
      <div v-if="isInvalidDependentField(i, 'dob')" class="error-message mt-1">{{ dependantErrorMessage(i, 'dob') }}</div>
    </div>
    <!-- END: Dependant dob -->

    <!-- BEGIN: Dependant age -->
    <div class="col-span-6 input-form" :class="{'has-error': isInvalidDependentField(i, 'age')}">
      <label :for="`${dependant.id}_dependant-age`" class="block text-sm font-medium text-gray-700">Age</label>
      <input :id="`${dependant.id}_dependant-age`" v-model="dependant.age" type="number" name="main_contact" class="input-field w-full">
      <div v-if="isInvalidDependentField(i, 'age')" class="error-message mt-1">{{ dependantErrorMessage(i, 'age') }}</div>
    </div>
    <!-- END: Dependant age -->

    <!-- BEGIN: Dependant Gender -->
    <div class="col-span-6 input-form" :class="{'has-error': isInvalidDependentField(i, 'gender')}">
      <label :for="`${dependant.id}_dependant-gender`" class="block text-sm font-medium text-gray-700">Gender</label>
      <select :id="`${dependant.id}_dependant-gender`" v-model="dependant.gender" class="input-field w-full" name="gender">
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>
      <div v-if="isInvalidDependentField(i, 'gender')" class="error-message mt-1">{{ dependantErrorMessage(i, 'gender') }}</div>
    </div>
    <!-- END: Dependant Gender -->

    <div class="col-span-6 input-form" :class="{'has-error': isInvalidDependentField(i, 'height_feet')}">
      <label :for="`dependant${i}_feet`" class="block text-sm font-medium text-gray-700">Height (ft / in)</label>
      <input :id="`dependant${i}_feet`" v-model="dependant.height_feet" type="number" name="main_contact" class="input-field w-full" placeholder="Feet">
      <div v-if="isInvalidDependentField(i, 'height_feet')" class="error-message mt-1">{{ dependantErrorMessage(i, 'height_feet') }}</div>
    </div>
    <div class="col-span-6 input-form" :class="{'has-error': isInvalidDependentField(i, 'height_inches')}">
      <label :for="`dependant${i}_in`" class="block text-sm font-medium text-gray-700">&nbsp;</label>
      <input :id="`dependant${i}_inches`" v-model="dependant.height_inches" type="number" name="main_contact" class="input-field w-full" placeholder="Inches">
      <div v-if="isInvalidDependentField(i, 'height_inches')" class="error-message mt-1">{{ dependantErrorMessage(i, 'height_inches') }}</div>
    </div>

    <!-- BEGIN: Dependant Weight -->
    <div class="col-span-6 input-form" :class="{'has-error': isInvalidDependentField(i, 'weight')}">
      <label :for="`${dependant.id}_dependant-weight`" class="block text-sm font-medium text-gray-700">Weight (lbs)</label>
      <input :id="`${dependant.id}_dependant-weight`" v-model="dependant.weight" type="text" name="main_contact" class="input-field w-full">
      <div v-if="isInvalidDependentField(i, 'weight')" class="error-message mt-1">{{ dependantErrorMessage(i, 'weight') }}</div>
    </div>
    <!-- END: Dependant Weight -->

    <!-- BEGIN: Dependant Tobacco -->
    <div class="col-span-6 input-form" :class="{'has-error': isInvalidDependentField(i, 'smoker')}">
      <label :for="`${dependant.id}_dependant-smoker`" class="block text-sm font-medium text-gray-700">Tobacco</label>
      <select :id="`${dependant.id}_dependant-smoker`" v-model="dependant.smoker" class="input-field w-full" name="smoker">
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </select>
      <div v-if="isInvalidDependentField(i, 'smoker')" class="error-message mt-1">{{ dependantErrorMessage(i, 'smoker') }}</div>
    </div>
    <!-- END: Dependant Tobacco -->

    <div class="col-span-12">
      <!-- BEGIN: Remove row  -->
      <button class="mt-3 p-3 btn-primary bg-transparent text-xs py-2 text-black border border-gray-300 font-medium ml-auto hover:bg-gray-50" @click.prevent="removeRow(i)">
        Remove
      </button>
      <!-- END: Remove row -->
    </div>
  </div>
  <div v-else class="my-2 text-slate-700 font-medium text-center">No Spouse & Dependants</div>
</template>

<script>
import { PlusIcon, XIcon } from '@heroicons/vue/solid';
import {ElDatePicker} from "element-plus";
import moment from "moment";

export default {
  name: "LeadDependants",

  components: {
    PlusIcon,
    XIcon,
    ElDatePicker,
  },

  props: {
    dependants: {
      type: Array,
      default: () => [],
    },

    dependantRoles: {
      type: Array,
      default: () => [],
    },

    errors: {
      type: Object,
      default: {},
    }
  },

  data() {
    return {
      createDependantModalVisible: false,
    }
  },

  emits: ['show-create-dependant-modal', 'removeDependant', 'updateDob'],

  methods: {
    showCreateDependantModal() {
      this.$emit('show-create-dependant-modal')
    },

    handleShowDependantModal() {
      this.createDependantModalVisible = true;
    },

    removeRow(index) {
      this.$emit('removeDependant', {
        index: index
      });
    },

    changeDependantsDob(payload) {
      payload.dependant.dob = payload.dob;
    },

    isInvalidDependentField(index, field) {
      return this.errors.hasOwnProperty(`dependants.${index}.${field}`);
    },

    dependantErrorMessage(index, field) {
      // Example - this.errors["dependants.0.name"]
      return this.errors[`dependants.${index}.${field}`][0];
    },

    autoUpdateAge(dependant) {
      dependant.age = moment().diff(dependant.dob, 'years')
    }
  },
}
</script>

<style scoped>

</style>