<script setup>
import { XIcon } from "@heroicons/vue/solid";
import moment from "moment";
import { ref, computed, nextTick} from "vue";
import { useForm } from '@inertiajs/inertia-vue3';
import Modal from "@/Components/Modal.vue";
import AppModifyPolicies from "./AppModifyPolicies.vue";
import { Menu, MenuButton, MenuItem, MenuItems, Portal } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { usePopper } from "@/utils/use-popper";
import BeneficiariesList from "@/Components/BeneficiariesList.vue";

const props = defineProps({
  policies: Object,
  filtersExpanded: false,
  leadId: Number,
  lead: Object,
  products: Object,
  enrollees: Array,
  agents: Array,
  splitPercentages: Array,
  carriersData: Object,
  policy: Object,
  applicants: Array,
  hst_available: Boolean,
})

const emit = defineEmits(['activeStatus', 'renewPolicy', 'delete'])

function showCreateMessageModal() {
  messageModalVisible.value = true;
}

const beneficiariesModalVisible = ref(false);
const messageModalVisible = ref(false);
const createMode = ref(true);

const policyFormName = computed(() => {
  let mode = formData.createMode ? 'Create Quotesheet' : 'Modify Policy';
  return `${mode}`;
})

const formData = useForm({
    selectedCarrier: null,
    applicants: null,
    products: null,
    enrollees: null,
    premium: null,
    policy_number: null,
    effective_date: null,
    end_date: null,
    business_type: null,
    assist_user_id: null,
    client_portion: null,
    assist_rate: null,
    taxcreditid: null,
    draft_date: null,
    face_amount: null,
    beneficiaries: [],
    sub_policies: [],
    notes: null,
    loading: false,
    current_plan_info: null,
})

function openEditForm(policy) {
  editingPolicy.value = policy.id;
  formData.selectedCarrier = policy.carrier;
  formData.applicants = policy.applicants.map(i => i.id);
  formData.products = policy.product.id;
  formData.enrollees = policy.enrollees;
  formData.premium = policy.premium;
  formData.policy_number = policy.policy_number;
  formData.effective_date = policy.effective_date;
  formData.end_date = policy.end_date;
  formData.business_type = policy.business_type;
  formData.assist_user_id = policy.assist_user_id;
  formData.assist_rate = policy.assist_rate;
  formData.taxcreditid = policy.taxcreditid;
  formData.notes = policy.note;
  formData.client_portion = policy.client_portion;
  formData.draft_date = policy.draft_date;
  formData.face_amount = policy.face_amount;
  formData.beneficiaries = policy.beneficiaries;
  formData.createMode = false;
  formData.sub_policies = [];
  for (let i = 0; i <= 5; i++) {
    let subtransaction = {policy_number: null, effective_date: null, end_date: null};
    if (policy.subTransactions[i] && policy.subTransactions[i].policy_number) {
      subtransaction.policy_number = policy.subTransactions[i].policy_number;
      subtransaction.effective_date = policy.subTransactions[i].effective_date;
      subtransaction.end_date = policy.subTransactions[i].end_date;
    }
    formData.sub_policies.push(subtransaction);
  }
  formData.current_plan_info = policy.current_plan_info;
  messageModalVisible.value = true;
}

function closeMessageModal() {
  messageModalVisible.value = false;
  setTimeout(() => {
    formData.reset();
    formData.clearErrors();
  }, 300)
}

const editingPolicy = ref()

let [trigger, container] = usePopper({
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    },
  ],
})

function submitForm() {
  formData.put(route('leads.new-policy.update', {
        new_policy: editingPolicy.value,
        lead: props.leadId
   }), {
    preserveScroll: true,
    onSuccess: () => {
      messageModalVisible.value = false;
        $notify.success({
        title: 'Success',
        message: 'Policy updated',
        showClose: true,
      })
      },
    onError: (error) => {
      console.log(error)
      nextTick(() => {
        helpers.scrollToClass('has-error');
      })
    }
  });
}

function showBeneficiariesReadOnly(policy) {
  beneficiariesModalVisible.value = true;
}
function hideBeneficiariesReadOnly() {
  beneficiariesModalVisible.value = false;
}

</script>

<template>
  <tr v-show="props.policy.status !== 'A'">
    <!--<td class="whitespace-nowrap cursor-pointer py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">
      <XIcon @click="emit('activeStatus', props.policy)" class="h-8 w-8" style="color: red;"></XIcon>
    </td>-->    
    <td class="whitespace-nowrap px-2 py-3.5  text-sm font-medium text-gray-900">{{ moment(props.policy.created_at).format('MM/DD/YYYY') }}</td>
    <td class="whitespace-nowrap px-2 py-3.5  text-sm text-gray-900">{{ moment(props.policy.effective_date).format('MM/DD/YYYY') }}<br> {{ props.policy.end_date ? moment(props.policy.end_date).format('MM/DD/YYYY') : '' }}</td>
    <td @click.prevent="openEditForm(props.policy)" class="whitespace-nowrap px-2 py-3.5 cursor-pointer no-underline hover:underline text-sm hover:text-tb-blue">{{ props.policy.product?.name }} {{ props.policy.policy_number ? '/ ' + props.policy.policy_number : '' }} {{ props.policy.taxcreditid ?  '/ ' + props.policy.taxcreditid : '' }}</td>
    <td v-if="filtersExpanded" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ props.policy.client_portion ? '$' + props.policy.client_portion.toFixed(2) : '' }}</td>
    <td v-if="filtersExpanded" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">${{ props.policy.premium.toFixed(2) }}</td>
    <td v-if="filtersExpanded" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ props.policy.draft_date ? moment(props.policy.draft_date).tz('America/New_York').format('MM/DD/YYYY') : '' }}</td>
    <td v-if="filtersExpanded" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">{{ props.policy.face_amount }}</td>
    <td v-if="filtersExpanded && policies.data?.some(policy => policy.beneficiaries_count)" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-blue-900 first-letter:uppercase"><span class="cursor-pointer" @click="showBeneficiariesReadOnly(props.policy)">{{ props.policy?.beneficiaries_count || '' }}</span></td>
    <td class="whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-900 first-letter:uppercase">
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton ref="trigger" class="inline-flex justify-left w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-tb-blue">
            Options
            <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <Portal>
          <MenuItems ref="container" class="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a @click.prevent="openEditForm(props.policy)" class="cursor-pointer" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Modify</a>
              </MenuItem>
              <MenuItem v-if="props.policy.status == 'C'||'E'" v-slot="{ active }">
                <a class="cursor-pointer" @click="emit('activeStatus', props.policy)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Activate Policy</a>
              </MenuItem>
              <MenuItem v-if="props.policy.status == 'C'||'E'" v-slot="{ active }">
                <a  class="cursor-pointer" @click.prevent="emit('delete', policy)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Delete Policy</a>
              </MenuItem>
            </div>
          </MenuItems>
        </Portal>
      </Menu>
    </td>
  </tr>
  <tr v-show="props.policy.status !== 'A' && props.policy.note">
    <td colspan="8" class="bg-gray-100 px-2 py-3.5 text-sm text-gray-900 break-words">
      <span class="font-bold">Note:</span> {{ props.policy.note }}
    </td>
  </tr>

    <Modal :show="messageModalVisible" @onClose="closeMessageModal()"
             :title="policyFormName">
             <AppModifyPolicies 
             @onSubmit="submitForm" 
             :policies="policies" 
             :lead-id="leadId"
             :lead="lead"
             :products="products" 
             :enrollees="enrollees" 
             :split-percentages="splitPercentages" 
             :agents="agents" 
             :form-data="formData"
             :carriers-data="carriersData"
             :applicants="applicants"
             :hst_available="hst_available"
             :policy="policy"
             ></AppModifyPolicies>
    </Modal>



    <Modal :show="beneficiariesModalVisible" @onClose="hideBeneficiariesReadOnly()"
           title="Policy Beneficiaries" max-width="max-w-[1200px]">
      <div class="text-sm text-center italic">Use the Edit Policy option to edit beneficiaries</div>
      <BeneficiariesList :beneficiaries="props.policy.beneficiaries" readonly
      />
    </Modal>
</template>

<style scoped></style>