<template>
  <div class="text-right">
    <button @click="openFileModal" type="button" class="btn-primary text-sm">Add file</button>
  </div>

  <!-- BEGIN: Files -->
  <div class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-8">
    <template v-if="files.length">
      <div v-for="file in files" :key="file.id" class="col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
        <div class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-gray-100">
          <div class="absolute left-0 top-0 mt-3 ml-3">
            <input v-if="false" class="form-check-input border border-slate-500" type="checkbox" :checked="false">
          </div>

          <a href="javascript:;" class="w-3/5 file__icon file__icon--file mx-auto">
            <div class="file__icon__file-name">{{ file.extension }}</div>
          </a>

          <a href="" class="block font-medium mt-4 text-center truncate">{{ file.name }}</a>
          <div class="text-gray-700 text-center mt-0.5">{{ file.size }}</div>
          <div class="absolute top-0 right-0 mr-2 mt-3 dropdown ml-auto">
            <Menu as="div" class="absolute top-0 right-0">
              <div>
                <MenuButton class="bg-gray-100 mr-2 mt-3 ml-auto rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-tb-blue">
                  <span class="sr-only">Open options</span>
                  <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                </MenuButton>
              </div>

              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <a :href="`/agents/${agentId}/files/${file.id}/download`" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        Download
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a @click.prevent="editFile(file)" href="javascript:;" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        Edit
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a @click.prevent="deleteFile(file)" href="javascript:;" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        Delete
                      </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
    </template>

    <div v-else class="text-sm font-medium col-span-12 text-center">No files</div>

  </div>
  <!-- END: Files -->

  <!-- BEGIN: Pagination -->
  <app-paginator :paginationData="paginationDataSet" :maxPages="5" @changePage="fetchFiles"
                 @updatePerPage="changePaginationLength"></app-paginator>
  <!-- END: Pagination -->

  <!-- BEGIN: Create File Modal -->
  <TransitionRoot as="template" :show="showFileModal">
    <Dialog as="div" class="relative z-10" @close="showFileModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">{{editMode ? 'Update' : 'Create'}} New Note</DialogTitle>
                  <div>
                    <form @submit.prevent="submitFile" class="intro-y box p-5" method="POST" enctype="multipart/form-data">
                      <div class="col-span-12 mt-4 input-form" :class="{'has-error': isInvalid('file')}">
                        <label for="dz" class="form-label text-slate-600">File</label>
                        <!-- BEGIN: Dropzone -->
                        <div class="dropzone rounded-lg border-slate-200" id="agent-view-files-dropzone">
                          <div class="fallback">
                            <input name="file" type="file"/>
                          </div>
                          <div class="dz-message">
                            <div class="text-lg font-medium">Drop files here or click to upload.</div>
                            <div class="text-gray-600">
                              Files will not be automatically uploaded.
                            </div>
                          </div>
                        </div>
                        <!-- END: Dropzone -->
                        <div v-if="isInvalid('file')" class="error-message mt-1">{{ errorMessage('file') }}</div>
                      </div>

                      <!-- BEGIN: File name  -->
                      <div class="col-span-12 input-form mt-4" :class="{'has-error': isInvalid('file_name')}">
                        <label for="file_name" class="form-label text-slate-600">File name</label>
                        <input type="text" v-model="formData.file_name" name="file_name" id="file_name" class="input-field ">
                        <div v-if="isInvalid('file_name')" class="error-message mt-1">{{ errorMessage('file_name') }}</div>
                      </div>
                      <!-- END: File name  -->

                      <div class="text-right mt-5">
                        <button type="button" class="btn btn-default w-24" @click="showFileModal = false">
                          Cancel
                        </button>
                        <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
                          <LoadingSpinner v-if="loading"></LoadingSpinner>
                          <span v-else>Submit</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- END: Create File Modal -->
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild, TransitionRoot
} from "@headlessui/vue";
import {DotsVerticalIcon} from "@heroicons/vue/solid";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import Dropzone from "dropzone";
import helpers from "../../helper";
import AppPaginator from "../AppPaginator.vue";

export default {
  name: "AgentFiles",

  mixins: [serverValidationErrorsMixin],

  components: {
    AppPaginator,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
    LoadingSpinner,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },

  props: {
    agentId: {
      type: Number,
      required: true,
    },
    states: {
      type: Array,
      required: true,
    }
  },

  emits: ['state-license-file-added'],

  data() {
    return {
      formData: {
        id: null,
        file_name: null,
        file_size: null,
        file: null,
        is_state_license: false,
        state_id: null,
      },
      showFileModal: false,
      files: [],
      paginationDataSet: [],
      dropzoneId: 'agent-view-files-dropzone',
      dropzoneOptions: {
        maxFiles: 1,
        maxFilesize: 20, // mb
        parallelUploads: 1, // 1 was set
        paramName: "file",
        addRemoveLinks: true,
        uploadMultiple: false,
        url: '/', // Just a placeholder, needed because DZ asks for it, but we process the upload manually.
        autoProcessQueue: false,
        createImageThumbnails: false,
        accept: function (file, done) {
          const allowedMimeTypes = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
            "application/vnd.ms-excel",
            "application/vnd.ms-excel.sheet.macroEnabled.12",
            "text/csv",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "image/jpeg",
            "image/png",
            "image/bmp",
            "application/pdf",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          ];

          if (!allowedMimeTypes.includes(file.type)) {
            done("Error! Files of this type are not accepted");
          } else {
            done();
          }
        }
      },
      currentPage: 1,
      paginationLength: 12,
      loading: false,
      dropzone: null,
      editMode: false,
    }
  },

  mounted() {
    this.initializeComponent()
  },

  watch: {
    showFileModal(newValue, oldValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.initDropzone();
        })
      }
    }
  },

  methods: {
    initializeComponent() {
      this.fetchFiles();
    },

    fetchFiles(page = null) {
      this.currentPage = page ? page : 1;

      // AgentFileController@index
      axios
        .get(`/agents/${this.agentId}/files?page=${this.currentPage}&per_page=${this.paginationLength}`)
        .then(this.refresh)
        .catch((error) => {
          console.log('AgentFiles - fetchFiles() error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    initDropzone() {
      // The constructor of Dropzone accepts two arguments:
      // 1. The selector for the HTML element that you want to add Dropzone to
      // 2. An (optional) object with the configuration
      Dropzone.autoDiscover = false;

      this.dropzone = new Dropzone('#agent-view-files-dropzone', this.dropzoneOptions);
      if (this.editMode && this.formData.id) {
        var mockFile = { name: this.formData.file_name, size: this.formData.file_size, mock: true };
        this.dropzone.options.addedfile.call(this.dropzone, mockFile);
      }
      const vm = this;

      this.dropzone.on("error", (error) => {
        console.log(error);
      });

      this.dropzone.on("addedfile", file => {
        vm.formData.file = file;
      });

      this.dropzone.on("removedfile", file => {
        vm.formData.file = null;
      });

      this.dropzone.on("maxfilesexceeded", function (file) {
        vm.dropzone.removeAllFiles();
        vm.dropzone.addFile(file);
      });
    },

    openFileModal() {
      this.editMode = false; 
      this.formData = {
        id: null,
        file: null,
        file_name: null,
        is_state_license: 0,
        state_id: null,
      };

      this.showFileModal = true;
    },

    changePaginationLength(payload) {
      this.paginationLength = payload;
      this.fetchFiles();
    },

    editFile(file) {
      this.editMode = true;

      this.formData = {
        id: file.id,
        file_size: file.bytes,
        file: null,
        file_name: file.name,
      };
      this.errors = {};

      this.showFileModal = true;
    },

    submitFile() {
      if (this.loading) return;

      this.loading = true;

      // AgentFileController@store
      const data = new FormData();

      if (this.formData.file_name) {
        data.append('file_name', this.formData.file_name);
      }

      if (this.formData.file) {
        data.append('file', this.formData.file);
      }

      let url = `/agents/${this.agentId}/files`
      if (this.editMode) {
        url = `/agents/${this.agentId}/files/${this.formData.id}`
        data.append('_method', 'PATCH')
      }

      setTimeout(() => {
        axios
          .post(url, data)
          .then((response) => {
            console.log(response)
            if (response && (response.status === 201 || response.status === 200)) {
              this.fetchFiles();
              this.formData = {
                file: null,
                file_name: null,
                state_id: null,
              };
              this.errors = {};
              this.dropzone.removeAllFiles();

              this.showFileModal = false;
            }
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.$nextTick(() => {
                helpers.scrollToClass('has-error');
              })
            } else {
              Swal.fire({
                text: 'Unknown error occurred, please try again.',
                icon: 'error'
              })
            }
            console.log('AgentFiles.vue @ submitFile()', error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500);

    },

    deleteFile(file) {
      // LeadFilesController@destroy
      axios
        .delete(`/agents/${this.agentId}/files/${file.id}`)
        .then((response) => {
          this.fetchFiles();
        })
        .catch((error) => {
          console.log('AgentFiles - deleteFile() error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    refresh({data}) {
      this.$nextTick(() => {
        this.paginationDataSet = data;
        this.files = data.data;
      })
    },
  },
}
</script>

<style scoped>
.dropzone {
  min-height: 100px;
}

.dropzone .dz-message {
  margin: 1em 0;
}
</style>