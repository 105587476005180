<script setup>
import {onMounted, ref} from "vue";
import {createPopup} from "@picmo/popup-picker";

const props = defineProps({
    inputElementId: {
        type: String,
        required: true
    },
    text: {
        type: String,
        default: null,
    },
    position: {
        type: String,
        default: "auto",
    }
});

const emit = defineEmits(['emoji-selected']);

const caretPosition = ref(null);

function initializeEmojiPicker() {
    const rootEl = document.querySelector(".emoji-trigger-container");
    const trigger = document.querySelector(".emoji-trigger");

    const picker = createPopup(
        {
            // picker options go here
            animate: false,
            className: 'customEmojiPickerClass'
        },
        {
            referenceElement: rootEl,
            triggerElement: trigger,
            position: props.position,
        }
    );

    trigger.addEventListener("click", (event) => {
        picker.toggle({ triggerElement: trigger, referenceElement: trigger });
        let messageField = document.getElementById(props.inputElementId);
        caretPosition.value = messageField.selectionStart;
    });

    picker.addEventListener("emoji:select", (event) => {
        let inputFieldValue = props.text ?? "";
        let updatedText =
            inputFieldValue.substring(0, caretPosition.value) +
            event.emoji +
            inputFieldValue.substring(caretPosition.value);

        emit('emoji-selected', {
            updatedText: updatedText
        })
    });
}

onMounted(() => {
    initializeEmojiPicker();
});
</script>

<template>
    <div class="emoji-trigger-container">
        <button
            id="emojiTrigger"
            class="emoji-trigger inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
            type="button"
        >
            <svg
                class="h-6 w-6 text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                ></path>
            </svg>
        </button>
    </div>
</template>

<style>
.customEmojiPickerClass {
    z-index: 9999999 !important;
}
.picmo__light {
    z-index: 9999998 !important;
    --accent-color: #899CC1 !important;
}
.picmo__closeButton {
    z-index: 9999999 !important;
}
</style>
