<script setup>
import { ref, computed } from "vue";
import { PlusCircleIcon } from "@heroicons/vue/outline";
import AppLeadSalesTableListItem from "./AppLeadSalesTableListItem.vue";
import AppLeadSalesTableCancelledItem from "./AppLeadSalesTableCancelledItem.vue";
import {Inertia} from "@inertiajs/inertia";
import Modal from "@/Components/Modal.vue";
import {TrashIcon} from "@heroicons/vue/solid";


const props = defineProps({
   leadId: {
     type: Number,
     required: true,
   },
   lead: Object,
   policies: Object,
   filtersExpanded: false,
   products: Object,
   enrollees: Array,
   agents: Array,
   splitPercentages: Array,
   carriersData: Object,
   applicants: Array,
   totalPremium: Number,
   hst_available: Boolean,
   activeTab: String,
   nextLeadSchedulesList: Array,
   user: Object,
})

const tabs = [
{ key: 'active', label: 'Active Policies' },
{ key: 'expired-cancelled', label: 'Expired/Cancelled Policies' },
]
const currentTab = ref('active')

function setCurrentTab(tabKey){
    currentTab.value = tabKey;
}

function showAdvancedFilters() {
     filtersExpanded.value = !filtersExpanded.value;
}

const filtersExpanded = ref(false);

function cancelledStatus(policy) {
  let policy_id = policy.id;
    Swal.fire({
        title: 'Cancel Policy?',
        text: 'Are you sure you want to cancel this policy?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.put(route('leads.cancelled.cancelledStatus', [props.leadId, policy.id]))
                .then(() => {
                  handleCancelledPolicySuccess(policy_id)
                })
                .catch(e => { throw e; })
                .finally(() => {})
        }
    })
}

function activeStatus(policy) {
    let policy_id = policy.id;
    Swal.fire({
        title: 'Activate Policy?',
        text: 'Are you sure you want to activate this policy?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.put(route('leads.active.activeStatus', [props.leadId, policy.id]))
                .then(() => {
                  handleActivePolicySuccess(policy_id);
                })
                .catch(e => { throw e; })
                .finally(() => {})
        }
    })
}

function renewPolicyUpdate(policy) {
    Swal.fire({
        title: 'Renew Policy?',
        text: 'Are you sure you want to renew this policy?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
    }).then((result) => {
        if (result.isConfirmed) {
            Inertia.put(route('leads.renew-policy.renewPolicy', [props.leadId, policy.id]), {}, {
              preserveScroll: true,
              onSuccess() {
                handleRenewPolicySuccess();
              },
              onError(e) {
                throw e;
              },
            })
        }
    })
}

function resyncHst(policy) {
    Swal.fire({
        title: 'Resync With HST?',
        text: 'Are you sure you want to resync with HST?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.post(route('transaction.hstSync', [policy]))
                .then(handlePolicyHstSyncSuccess)
                .catch(e => { throw e; })
                .finally(() => {})
        }
    })
}

function postHstSale(policy) {
    Swal.fire({
        title: 'Post Sale To HST?',
        text: 'Are you sure you want to post sale to HST?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.post(route('transaction.hstPostSale', [policy]))
                .then(handlePolicyHstPostSuccess)
                .catch(e => { throw e; })
                .finally(() => {})
        }
    })
}

function handlePolicyHstSyncSuccess() {
    $notify.success({
        title: 'Success',
        message: 'Policy has been synced with HST',
        showClose: true,
    });
}

function handlePolicyHstPostSuccess() {
    $notify.success({
        title: 'Success',
        message: 'Policy has been posted to HST',
        showClose: true,
    });
}

function handleActivePolicySuccess(policy_id) {
    let policy = props.policies.data.find(i => i.id === policy_id);
    if (policy) {
      policy.status = 'A';
    }
    $notify.success({
        title: 'Success',
        message: 'Policy has been activated',
        showClose: true,
    });
}
function handleCancelledPolicySuccess(policy_id) {
    let policy = props.policies.data.find(i => i.id === policy_id);
    if (policy) {
      policy.status = 'C';
    }
    $notify.success({
        title: 'Success',
        message: 'Policy has been cancelled',
        showClose: true,
    });
}

function handleRenewPolicySuccess() {
    $notify.success({
        title: 'Success',
        message: 'Policy has been renewed',
        showClose: true,
    });
}

function confirmDelete(policy) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to undo this.',
    icon: 'warning',
    confirmButtonText: "Yes, I'm sure.",
    showCancelButton: true,
    confirmButtonColor: '#B91C1C',
    cancelButtonColor: '#899CC1'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteMessage(policy);
    }
  })
}
function deleteMessage(policy) {
  Inertia.delete(route('leads.new-policy.destroy', {
        new_policy: policy.id,
        lead: props.leadId
   }), {
    preserveScroll: true,
    onSuccess: () => {
        $notify.success({
        title: 'Success',
        message: 'Policy deleted',
        showClose: true,
      })
      },
    onError: (error) => {
      console.log(error)
    }
  })
}

const formattedTotalPremium = computed(() => {
  if (isNaN(props.totalPremium)) return '';
  
  return props.totalPremium.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
})

const shouldShowExpandButton = computed(() => {
  return (
    props.policies.data?.some(policy => policy.client_portion) ||
    props.policies.data?.some(policy => policy.assist_user_id) ||
    props.policies.data?.some(policy => policy.assist_rate) ||
    props.policies.data?.some(policy => policy.draft_date) ||
    props.policies.data?.some(policy => policy.face_amount) ||
    props.policies.data?.some(policy => policy.beneficiaries_count)
  );
});

</script>

<template>
    <div class="mt-4 sm:mt-0 sm:ml-16 flex flex-col md:flex-row w-90 items-center justify-end space-y-2 space-x-0 md:space-x-2 md:space-y-0">
    </div>
<div class="flex shrink-0 px-4">
    <div class="overflow-hidden">
      <!-- Tabs -->
      <div class="col-span-12 flex items-center justify-between">
        <div class="lg:hidden w-full">
          <label for="tabs" class="sr-only">Select a tab</label>
          <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
          <select @change="setCurrentTab($event.target.value)" id="tabs" name="tabs"
                  class="block w-full rounded-md border-gray-300 focus:border-tb-blue focus:ring-tb-blue">
            <option v-for="tab in tabs" :key="tab.key" :value="tab.key" :selected="currentTab === tab.key">{{
                tab.label
              }}
            </option>
          </select>
        </div>
        <div class="hidden lg:block w-full">
          <nav class="flex space-x-1 rounded dh md:rounded-lg p-2 bg-white" aria-label="Tabs">
            <a @click.prevent="setCurrentTab(tab.key)" v-for="tab in tabs" :key="tab.key" href="javascript:;"
               :class="[currentTab === tab.key ? 'bg-tb-blue text-white shadow' : 'text-gray-500 hover:text-gray-700', 'px-6 py-2 font-semibold rounded-md']"
               :aria-current="currentTab === tab.key ? 'page' : undefined">{{ tab.label }}</a>
          </nav>
        </div>
      </div>
    </div>
</div>
    <div class="tab-content min-h-[300px]">
        <div v-if="currentTab === tabs[0].key" :id="tabs[0].key" class="tab-pane leading-relaxed p-5 active" role="tabpanel" :aria-labelledby="tabs[0].key">
            <div class="flex flex-col">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <div v-if="shouldShowExpandButton && policies.data?.some(policy => policy.status == 'A')" class="md:flex items-center justify-between px-5 py-2 bg-white">
                        <button type="button" class="btn-primary md:text-tb-blue md:hover:bg-white md:border-0 md:bg-white md:rounded-full inline-flex items-center px-5 py-2 md:p-0 text-sm" @click="showAdvancedFilters">
                        <PlusCircleIcon class="text-tb-blue w-6 h-6" :class="filtersExpanded === true ? 'rotate-45' : 'rotate-0'"></PlusCircleIcon>
                            <span>Show {{ filtersExpanded ? 'Less Info' : 'More Info' }}</span>
                        </button>
                    </div>
                        <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
                            <thead class="bg-slate-200">
                            <tr>
                                <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Created at</th>
                                <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Eff Date/End Date</th>
                                <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Product/PolicyNumber/Tax CreditID</th>
                                <th scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Premium</th>
                                <th v-if="filtersExpanded && policies.data?.some(policy => policy.client_portion)" scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Client Portion</th>
                                <th v-if="filtersExpanded && policies.data?.some(policy => policy.assist_user_id)" scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Assisted</th>
                                <th v-if="filtersExpanded && policies.data?.some(policy => policy.assist_rate)" scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Assist%</th>
                                <th v-if="filtersExpanded && policies.data?.some(policy => policy.draft_date)" scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Draft Date</th>
                                <th v-if="filtersExpanded && policies.data?.some(policy => policy.face_amount)" scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Face Amount</th>
                                <th v-if="filtersExpanded && policies.data?.some(policy => policy.beneficiaries_count)" scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Beneficiaries</th>
                                <th scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Options</th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <AppLeadSalesTableListItem v-for="(policy, index) in policies.data" :key="policy.id"
                                @renew-policy="renewPolicyUpdate"
                                @cancelled-status="cancelledStatus"
                                @delete="confirmDelete"
                                @resyncHst="resyncHst"
                                @postHstSale="postHstSale"
                                :policy="policy"
                                :products="products"
                                :enrollees="enrollees"
                                :split-percentages="splitPercentages"
                                :agents="agents"
                                :lead-id="leadId"
                                :lead="lead"
                                :policies="policies"
                                :carriers-data="carriersData"
                                :filters-expanded="filtersExpanded"
                                :hst_available="hst_available"
                                :applicants="applicants"
                                :active-tab="activeTab"
                                :lead-schedules="nextLeadSchedulesList"
                                :user="user"
                                ></AppLeadSalesTableListItem>
                            </tbody>
                        </table>
                            <div colspan="8" class="whitespace-nowrap px-2 py-3.5 text-sm border-t border-t-gray-300 text-gray-900">
                                <span class="font-bold">Total Active Premium:</span> {{ formattedTotalPremium }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="currentTab === tabs[1].key" :id="tabs[1].key" class="tab-pane leading-relaxed p-5" role="tabpanel" :aria-labelledby="tabs[1].key">
            <div class="flex flex-col">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <div v-if="shouldShowExpandButton && policies.data?.some(policy => policy.status == 'C')" class="md:flex items-center justify-between px-5 py-2 bg-white">
                        <button type="button" class="btn-primary md:text-tb-blue md:hover:bg-white md:border-0 md:bg-white md:rounded-full inline-flex items-center px-5 py-2 md:p-0 text-sm" @click="showAdvancedFilters">
                        <PlusCircleIcon class="text-tb-blue w-6 h-6" :class="filtersExpanded === true ? 'rotate-45' : 'rotate-0'"></PlusCircleIcon>
                            <span>Show {{ filtersExpanded ? 'Less Info' : 'More Info' }}</span>
                        </button>
                    </div>
                        <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
                            <thead class="bg-slate-200">
                            <tr>
                                <!--<th scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Status</th>-->
                                <th scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Created at</th>
                                <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Eff Date/End Date</th>
                                <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Product/PolicyNumber/Tax CreditID</th>
                                <th v-if="filtersExpanded" scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Client Portion</th>
                                <th v-if="filtersExpanded" scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Premium</th>
                                <th v-if="filtersExpanded" scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Draft Date</th>
                                <th v-if="filtersExpanded" scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Face Amount</th>
                                <th v-if="filtersExpanded && policies.data?.some(policy => policy.beneficiaries_count)" scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Beneficiaries</th>
                                <th scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Options</th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <AppLeadSalesTableCancelledItem v-for="policy in policies.data" :key="policy.id"
                                @renew-policy="renewPolicyUpdate"
                                @active-status="activeStatus"
                                @delete="confirmDelete"
                                :products="products"
                                :policy="policy"
                                :enrollees="enrollees"
                                :split-percentages="splitPercentages"
                                :agents="agents"
                                :lead-id="leadId"
                                :lead="lead"
                                :policies="policies"
                                :filters-expanded="filtersExpanded"
                                :carriers-data="carriersData"
                                :applicants="applicants"
                                :hst_available="hst_available"
                                ></AppLeadSalesTableCancelledItem>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
