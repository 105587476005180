<script setup>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {useForm} from "@inertiajs/inertia-vue3";
import {ElOption, ElSelect} from 'element-plus';
import {computed, onMounted, ref} from "vue";
import {useErrorHandlerAxios} from "@/utils/useErrorHandlerAxios";
import {PlusCircleIcon} from "@heroicons/vue/outline";
import InputError from "@/Components/InputError.vue";

const {handleErrors, isInvalid, errorMessage, clearErrors, errors} = useErrorHandlerAxios();

const searchActionsRef = ref(null);

const props = defineProps({
  leadCategory: {required: true, type: String,},
  modelValue: null,
  activeModel: null,
  allowCreate: {
    type: Boolean,
    default: true,
  },
  isObject: {
    type: Boolean,
    default: false,
  },
});

const modelName = computed(() => {
  switch (props.leadCategory) {
    case 'recruitment':
      return 'Recruitment Source';
    case 'commercial':
    default:
      return 'Lead Source';
  }
});
const isCommercialLead = computed(() => { // Commercial Lead: classic lead. The other type is a recruitment lead
  return !props.leadCategory || props.leadCategory !== 'recruitment';
});

onMounted(async () => {
  if (props.activeModel !== null) {
    actionList.value.push(props.activeModel);
  }
})

const searchingAction = ref(false);
const formData = useForm({
  action_id: null
})
const actionList = ref([]);
const emit = defineEmits(['update:modelValue'])

function searchActions(queryString) {
  if (!queryString) return;
  axios
    .get(route('api.internal.actions.search', {search: queryString, lead_category: props.leadCategory}))
    .then(response => {
      actionList.value = response.data;
      updateModelValue(null);
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      searchingAction.value = false;
    })
}

function updateModelValue(value) {
  if (!value) {
    return;
  }
  emit('update:modelValue', value);
}

const creatingAction = ref(false)

function createAction(value = null) {
  if (cannotCreateAction.value) return;

  let val = searchActionsRef.value.query;
  creatingAction.value = true;
  setTimeout(() => {
    axios
      .post(route('actions.store'), {
        name: val,
        lead_category: props.leadCategory,
      })
      .then((response) => {
        searchActionsRef.value.blur();

        actionList.value.push(response.data.action);

        if (response.data && response.data.action && response.data.action.id) {
          updateModelValue(props.isObject ? response.data.action : response.data.action.id);
        }

        clearErrors();

        $notify.success({
          title: 'Success',
          message: 'Action created.',
          showClose: true,
        })
      })
      .catch(error => {
        handleErrors(error)
      })
      .finally(() => {
        creatingAction.value = false;
      })
  }, 500);
}

const cannotCreateAction = computed(() => {
  return props.allowCreate
    && (searchingAction.value
      || creatingAction.value
      || ['', null].includes(searchActionsRef.value?.query)
      || actionList.value.some(action => action.name === searchActionsRef.value?.query)
    );
})
</script>

<template>
  <el-select
    ref="searchActionsRef"
    v-model="modelValue"
    :loading="searchingAction"
    :remote-method="searchActions"
    :reserve-keyword="true"
    class="w-full"
    clearable
    filterable
    placeholder="Search actions or create one if none found"
    remote
    @change="updateModelValue($event)"
    @keyup.enter="createAction()"
  >
    <template v-if="allowCreate" #prefix>
      <div class="flex items-center">
        <button :class="[{'pointer-events-none opacity-50': cannotCreateAction }, 'btn-primary text-sm px-2 py-1 text-xs']" :disabled="cannotCreateAction"
                @click.prevent="createAction">
          <LoadingSpinner v-show="creatingAction" height-class="w-4" width-class="h-4"/>
          <PlusCircleIcon v-show="!creatingAction" class="w-5 h-5"/>
        </button>
      </div>
    </template>
    <el-option
      v-for="action in actionList"
      :key="`action_${action.id}`"
      :label="`${action.name}`"
      :value="isObject ? action : action.id"
    ></el-option>
  </el-select>
  <InputError :message="errorMessage('name')"></InputError>
</template>

<style scoped></style>
