<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="hideModal" :unmount="false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">Create Email Template</DialogTitle>
                  <div>
                    <form @submit.prevent="createEmailTemplate" class="intro-y box p-5" method="POST">

                      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
                        <!-- BEGIN: Type -->
                        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('type')}">
                          <label for="type" class="form-label text-slate-500">Type</label>
                          <select v-model="formData.type" class="input-field" name="" id="">
                            <option v-if="user_is_admin" value="Agency">Agency</option>
                            <option value="Agent">Agent</option>
                          </select>
                          <div v-if="isInvalid('type')" class="error-message mt-1">{{ errorMessage('type') }}</div>
                        </div>
                        <!-- END: Type -->

                        <!-- BEGIN: Name -->
                        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('name')}">
                          <label for="name" class="form-label text-slate-500">Template name</label>
                          <input v-model="formData.name" id="name" type="text" class="input-field"
                                 name="name">
                          <div v-if="isInvalid('name')" class="error-message mt-1">{{ errorMessage('name') }}</div>
                        </div>
                        <!-- END: Name -->

                        <!-- BEGIN: Subject -->
                        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('subject')}">
                          <label for="subject" class="form-label text-slate-500">Template subject</label>
                          <input v-model="formData.subject" id="subject" type="text" class="input-field"
                                 name="subject">
                          <div v-if="isInvalid('subject')" class="error-message mt-1">{{ errorMessage('subject') }}</div>
                        </div>
                        <!-- END: Subject -->

                        <!-- BEGIN: Body -->
                        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('body')}">
                          <label for="body" class="form-label text-slate-500">Template body</label>
                          <AppFroala v-model:value="formData.body"></AppFroala>
                          <span class="text-xs text-slate-500">* Your email signature will be appended at the bottom of this content</span>
                          <div v-if="isInvalid('body')" class="error-message mt-1">{{ errorMessage('body') }}</div>
                        </div>
                        <!-- END: Body -->

                        <!-- BEGIN: Location -->
                        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('location')}">
                          <label for="location" class="form-label text-slate-500">Location</label>
                          <select v-model="formData.location" class="input-field" name="" id="">
                            <option value="B">Both</option>
                            <option value="A">Action Wizard</option>
                            <option value="T">Toolbelt</option>
                          </select>
                          <div v-if="isInvalid('location')" class="error-message mt-1">{{ errorMessage('location') }}</div>
                        </div>
                        <!-- END: Location -->

                        <!-- BEGIN: Merge fields -->
                        <div class="col-span-12 input-form">
                          <label for="tooltip" class="form-label text-s text-slate-500 mb-2">Available merge fields</label>
                          <p class="text-sm text-gray-600 box-border rounded-lg h-35 w-45 p-2 shadow border border-gray-300">
                            <a href="#" target="_self" @click.prevent="">[firstname]</a> - The Lead's First Name<br>
                            <a href="#" target="_self" @click.prevent="">[lastname]</a> - The Lead's Last Name<br>
                            <a href="#" target="_self" @click.prevent="">[state]</a> - The Lead's State<br>
                            <a href="#" target="_self" @click.prevent="">[zip]</a> - The Lead's Zip Code<br>
                            <a href="#" target="_self" @click.prevent="">[agentfirst]</a> - The Agent's First Name<br>
                            <a href="#" target="_self" @click.prevent="">[agentlast]</a> - The Agent's Last Name<br>
                            <a href="#" target="_self" @click.prevent="">[agentphone]</a> - The Agent's Phone Number<br>
                            <a href="#" target="_self" @click.prevent="">[leaddob]</a> - The Lead's Date of Birth<br>
                            <a href="#" target="_self" @click.prevent="">[spousefirstname]</a> - The Lead's Spouse First Name<br>
                            <a href="#" target="_self" @click.prevent="">[spouselastname]</a> - The Lead's Spouse Last Name<br>
                            <a href="#" target="_self" @click.prevent="">[spousedob]</a> - The Lead's Spouse Date of Birth<br>
                            <a href="#" target="_self" @click.prevent="">[dependentdob1]</a> - The Lead's First Dependent Date of Birth<br>
                            <a href="#" target="_self" @click.prevent="">[dependentdob2]</a> - The Lead's Second Dependent Date of Birth<br>
                            <a href="#" target="_self" @click.prevent="">[dependentdob3]</a> - The Lead's Third Dependent Date of Birth<br>
                            <a href="#" target="_self" @click.prevent="">[dependentdob4]</a> - The Lead's Fourth Dependent Date of Birth<br>
                            <a href="#" target="_self" @click.prevent="">[dependentdob5]</a> - The Lead's Fifth Dependent Date of Birth<br>
                            <a href="#" target="_self" @click.prevent="">[nextactionstamp]</a> - Date/Time of Next Action<br>
                            <a href="#" target="_self" @click.prevent="">[nextactiondate]</a> - Date of Next Action<br>
                            <a href="#" target="_self" @click.prevent="">[nextactiontime]</a> - Time of Next Action<br>
                          </p>
                        </div>
                        <!-- END: Merge fields -->
                      </div>

                      <div class="text-right mt-5">
                        <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
                          <LoadingSpinner v-if="loading"></LoadingSpinner>
                          <span v-else>Submit</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import axios from "axios";
import helpers from "../../helper";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import AppFroala from "@/Components/AppFroala.vue";

export default {
  name: "ActionScheduleCreateEmailTemplateModal",

  mixins: [serverValidationErrorsMixin],

  components: {
    AppFroala,
    LoadingSpinner,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },


  props: {
    leadCategory: {required: true, type: String,},
    user_is_admin: {
      type: Boolean,
      required: true,
    },
    open: {
      type: Boolean,
      default: false
    }
  },

  emits: ['email-template-created', 'modal-hidden'],

  data() {
    return {
      modalName: 'createEmailTemplateModal',
      loading: false,
      formData: {
        name: null,
        subject: null,
        body: null,
        type: 'Agent',
        location: 'B',
      }
    }
  },

  mounted() {
  },

  methods: {
    createEmailTemplate() {
      if (this.loading) return;

      this.loading = true;

      setTimeout(() => {
        let target = this.isCommercialLead ? route('email-templates.store') : route('recruiting-email-templates.store');
        axios
          .post(target, this.formData)
          .then((response) => {
            this.handleSuccessfulSubmission(response)
          })
          .catch((error) => {
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    handleSuccessfulSubmission(response) {
      this.$emit('email-template-created', { template: response.data.template });
      this.formData = {
        name: null,
        subject: null,
        body: null,
        type: 'Agent',
        location: 'B',
      };
      this.errors = {};
      this.hideModal();
    },

    handleErroredSubmission(error) {
      console.log(error);
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        });
      }
      console.log("ActionScheduleCreateEmailTemplateModal - handleErroredSubmission", error.response);
    },

    hideModal() {
      this.$emit('modal-hidden', this.modalName)
    },
  },
  computed: {
    modelName() {
      switch (this.leadCategory) {
        case 'recruitment':
          return 'Recruitment Source';
        case 'commercial':
        default:
          return 'Lead Source';
      }
    },
    isCommercialLead() { // Commercial Lead: classic lead. The other type is a recruitment lead
      return !this.leadCategory || this.leadCategory !== 'recruitment';
    },
  },
}
</script>

<style scoped>

</style>
