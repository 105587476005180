<template>
  <tr v-if="editMode">
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6" :class="{'has-error': isInvalid('content')}">
      <input type="text" class="input-field" v-model="data.content">
      <div v-if="isInvalid('content')" class="error-message mt-1">{{ errorMessage('content') }}</div>
    </td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-6">
      <button class="btn btn-primary text-sm mr-2" @click="updateNote">Save</button>
      <button class="btn btn-secondary text-sm" @click="cancel">Cancel</button>
      <div v-if="isInvalid('content')" class="error-message mt-1">&nbsp;</div>
    </td>
  </tr>
  <tr v-else>
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{{ note.content }}</td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-6">
      <button class="btn btn-secondary text-sm mr-2" @click="editMode = true">Edit</button>
      <button class="btn btn-danger text-sm" @click="confirmDelete">Delete</button>
    </td>
  </tr>


</template>

<script>
import _ from "lodash";
import helpers from "../../helper";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";

export default {
  name: "AgentNoteListItem",

  mixins: [serverValidationErrorsMixin],

  props: {
    agentId: {
      type: Number,
      required: true,
    },
    note: {
      type: Object,
      required: true,
    }
  },

  emits: ['note-updated', 'note-deleted'],

  data() {
    return {
      data: _.cloneDeep(this.note),
      editMode: false,
    }
  },

  methods: {
    updateNote() {
      this.loading = false;
      // AgentNoteController@update
      axios
        .put(`/agents/${this.agentId}/notes/${this.data.id}`, {
          content: this.data.content
        })
        .then((response) => {
          this.$emit('note-updated', {note: response.data});
          this.data = response.data;
          this.editMode = false;
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.status === 422) {
            this.errors = error.response.data.errors;
            this.$nextTick(() => {
              helpers.scrollToClass('has-error');
            })
          } else {
            Swal.fire({
              text: 'Unknown error occurred, please try again.',
              icon: 'error'
            })
          }
          console.log("AgentNoteListItem - handleErroredSubmission", error);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    confirmDelete() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to undo this.',
        icon: 'warning',
        confirmButtonText: "Yes, I'm sure.",
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteNote();
        }
      })
    },

    deleteNote() {
      this.loading = true;
      axios
        .delete(`/agents/${this.agentId}/notes/${this.data.id}`)
        .then((response) => {
          this.$emit('note-deleted');
        })
        .catch((error) => {
          Swal.fire({
            text: 'Unknown error occurred, please try again.',
            icon: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    cancel() {
      this.editMode = false;
    },
  },
}
</script>

<style scoped>

</style>