<template>
  <!-- BEGIN: Agency View -->
  <div class="col-span-12">
    <!--  Begin: Header -->
    <div class="border-b p-4 flex space-x-4 items-center justify-between">
      <div class="flex space-x-4 items-center">
        <button type="button" class="btn btn-sm btn-primary p-0" @click.prevent="toggleEditMode">
          <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" rx="7" fill="var(--color-primary)"/>
            <path v-if="editMode" d="m15.416 14.004 4.3-4.29a1.004 1.004 0 1 0-1.42-1.42l-4.29 4.3-4.29-4.3a1.004 1.004 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 .325 1.639 1 1 0 0 0 1.095-.219l4.29-4.3 4.29 4.3a1.002 1.002 0 0 0 1.639-.325 1 1 0 0 0-.22-1.095l-4.3-4.29Z" fill="#fff"/>
            <path v-else d="m16.505 10.249-7.97 7.97-.522 1.778 1.742-.503 7.997-7.997-1.247-1.248Zm1.36-1.36 1.247 1.247 1.162-1.162a.44.44 0 0 0 0-.624l-.624-.623a.44.44 0 0 0-.624 0l-1.16 1.162h-.001Zm3.033-2.41.623.624a2.205 2.205 0 0 1 0 3.118l-10.84 10.84-3.721 1.076a.88.88 0 0 1-1.091-1.094l1.1-3.752L17.781 6.479a2.205 2.205 0 0 1 3.118 0h-.001Z" fill="#fff"/>
          </svg>
        </button>
        <h4>Viewing Agent: <span class="font-medium">{{ agent_info.first_name }} {{ agent_info.last_name }}</span></h4>
      </div>

      <button @click.prevent="confirmDelete" type="button" class="btn btn-sm btn-danger">Delete</button>
    </div>
    <!-- End: Header -->
    <div class="col-span-12 flex items-center justify-between">
        <div class="lg:hidden w-full p-2">
          <label for="tabs" class="sr-only">Select a tab</label>
          <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
          <select @change="setCurrentTab($event.target.value)" id="tabs" name="tabs"
            class="block w-full rounded-md border-gray-300 focus:border-tb-blue focus:ring-tb-blue">
            <option v-for="tab in availableTabs" :key="tab.key" :value="tab.key" :selected="currentTab === tab.key">{{
              tab.label
            }}
            </option>
          </select>
        </div>
        <div class="hidden lg:block w-full">
          <nav class="flex space-x-1 shadow ring-1 ring-black ring-opacity-5 md:rounded p-2 bg-white"
            aria-label="Tabs">
            <a @click.prevent="setCurrentTab(tab.key)" v-for="tab in availableTabs" :key="tab.key" href="javascript:;"
              :class="[currentTab === tab.key ? 'bg-tb-blue text-white shadow' : 'text-gray-500 hover:text-gray-700', 'px-6 py-2 font-semibold rounded-md']"
              :aria-current="currentTab === tab.key ? 'page' : undefined">{{ tab.label }}</a>
          </nav>
        </div>
      </div>
    <div class="tab-content mt-5 min-h-[300px]">
      <!-- BEGIN: Agent Info  -->
      <div v-if="currentTab === tabs[0].key" :id="tabs[0].key" class="tab-pane leading-relaxed p-2 md:p-5 active" role="tabpanel" :aria-labelledby="tabs[0].key">
        <agent-info
          v-model:agent_info="agent_info"
          :assignable-roles="assignableRoles"
          :states="states"
          :timezones="timezones"
          :edit-mode="editMode"
          :possibleLeaders="possibleLeaders"
          :billing-profiles="billingProfiles"
          :available-twilio-phone-numbers="availableTwilioPhoneNumbers"
          :agentHasOwnTwilioAccountNumber="agentHasOwnTwilioAccountNumber"
          @show-edit-mode="handleShowEditMode">
        </agent-info>

      </div>
      <!-- END: Agent Info  -->
      <!-- BEGIN: Manage States  -->
      <div v-else-if="currentTab === tabs[1].key" :id="tabs[1].key" class="tab-pane leading-relaxed p-5" role="tabpanel" :aria-labelledby="tabs[1].key">
        <agent-and-lead-states ref="agent-states" model-type="agent" :states="states" :model-id="agent_info.id"></agent-and-lead-states>
      </div>
      <!-- END: Manage States  -->
      <!-- BEGIN: Manage Buckets  -->
      <div v-else-if="currentTab === tabs[2].key" :id="tabs[2].key" class="tab-pane leading-relaxed p-5" role="tabpanel" :aria-labelledby="tabs[2].key">
        <agent-buckets :agent-id="agent_info.id"></agent-buckets>
      </div>
      <!-- END: Manage Buckets  -->
      <!-- BEGIN: Manage Carriers Tags  -->
      <div v-else-if="currentTab === tabs[3].key" :id="tabs[3].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[3].key">
        <agent-and-lead-carriers model-type="agent" :model-id="agent_info.id" :agency-carriers="agencyCarriers"></agent-and-lead-carriers>
      </div>
      <!-- END: Manage Carriers Tags  -->
      <!-- BEGIN: Manage Files  -->
      <div v-else-if="currentTab === tabs[4].key" :id="tabs[4].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[4].key">
        <agent-files :agent-id="agent_info.id" :states="states" @state-license-file-added="handleFileAdded"></agent-files>
      </div>
      <!-- END: Manage Files  -->
      <!-- BEGIN: Manage access  -->
      <div v-else-if="currentTab === tabs[5].key" :id="tabs[5].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[5].key">
        <agent-access :agent-name="agent_info.first_name + ' ' + agent_info.last_name" :agent-id="agent_info.id"></agent-access>
      </div>
      <!-- END: Manage access  -->
      <!-- BEGIN: Manage round robin  -->
      <div v-else-if="currentTab === tabs[6].key" :id="tabs[6].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[6].key">
         <agent-round-robin :entries="entries" :agent-id="agent_info.id" :round-robin-groups="roundRobinGroups" :weekly_cap="weekly_cap" :daily_cap="daily_cap"></agent-round-robin>
      </div>
      <!-- END: Manage round robin  -->
      <div v-else-if="currentTab === tabs[7].key" :id="tabs[7].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[7].key">
        <agent-contract-info :agent_info="agent_info" :agent-id="agent_info.id"></agent-contract-info>
      </div>
      <!-- BEGIN: HST Migration  -->
      <div v-else-if="currentTab === tabs[8].key" :id="tabs[8].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[8].key">
         <agent-hst-migration :agent-id="agent_info.id"></agent-hst-migration>
      </div>
      <!-- END: HST Migration  -->

      <!-- BEGIN: Agency Transfer  -->
      <div v-else-if="currentTab === tabs[9].key" :id="tabs[9].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[9].key">
        <AgentAgencyTransfer :agent-id="agent_info.id"></AgentAgencyTransfer>
      </div>
      <!-- END: Agency Transfer  -->


      <!-- <div class="p-2 md:p-5 mb-10 border-t-2 mt-5" v-show="showNotes">
        <agent-notes :agent-id="agent_info.id"></agent-notes>
      </div> -->
    </div>


  </div>
  <!-- END: Agency View -->
</template>

<script>
import AgentFiles from "./partials/AgentFiles.vue";
import AgentInfo from "./partials/AgentInfo.vue";
import AgentNotes from "./partials/AgentNotes.vue";
import AgentAndLeadStates from "./partials/AgentAndLeadStates.vue";
import AgentAndLeadCarriers from "./partials/AgentAndLeadCarriers.vue";
import AgentAccess from "./partials/AgentAccess.vue";
import AgentBuckets from "./partials/AgentBuckets.vue";
import AgentRoundRobin from "./partials/AgentRoundRobin.vue";
import AgentContractInfo from "@/Components/partials/AgentContractInfo.vue";
import AgentHstMigration from "@/Components/AgentHstMigration.vue";
import AgentAgencyTransfer from "@/Components/AgentAgencyTransfer.vue";

export default {
  name: "AppAgentView",

  components: {
    AgentContractInfo,
    AgentAgencyTransfer,
    AgentHstMigration,
    AgentNotes,
    AgentFiles,
    AgentAndLeadCarriers,
    AgentAndLeadStates,
    AgentInfo,
    AgentAccess,
    AgentBuckets,
    AgentRoundRobin
  },

  props: {
    agent_info: {
      type: Object,
      required: true,
    },
    assignableRoles: Object,
    states: {
      type: Array,
      default: () => [],
    },
    timezones: {
      type: Array,
      default: () => [],
    },
    possibleLeaders: {
      type: Array,
      default: () => [],
    },
    billingProfiles: {
      type: Array,
      default: () => [],
    },
    agencyCarriers: {
      type: Array,
      default: () => []
    },
    roundRobinGroups: {
      type: Array,
      default: () => []
    },
    entries: {
      type: Array
    },
    availableTwilioPhoneNumbers: {
      type: Array,
      default: () => []
    },
    weekly_cap: {
      type: Array,
      required: true,
    },
    daily_cap: {
      type: Array,
      required: true,
    },
    agentHasOwnTwilioAccountNumber: {
      type: Boolean,
      required: true,
    }
  },

  data() {
    return {
      tabs: [
        { key: 'agent', label: 'Agent'},
        { key: 'states', label: 'States'},
        { key: 'buckets', label: 'Buckets'},
        { key: 'carriers', label: 'Carriers'},
        { key: 'files', label: 'Files'},
        { key: 'access', label: 'Access'},
        { key: 'round-robin', label: 'Round Robin'},
        { key: 'contract-info', label: 'Contract Info', hide: false}, // TODO: Define role
        { key: 'hst-migration', label: 'HST Migration', hide: () => this.$page.props.auth.user.id !== 306}, // Only for MLeb
        { key: 'agency-transfer', label: 'Agency Transfer', hide: () => this.$page.props.auth.user.id !== 306}, // Only for MLeb
      ],
      loading: false,
      editMode: false,
      currentTab: 'agent'
    }
  },

  computed: {
    availableTabs() {
      return this.tabs.filter(tab => {
        if (typeof tab.hide === 'function') {
          return ! tab.hide();
        }
        return ! tab.hide;
      });
    },
    showNotes() {
      return ! ['buckets', 'agent', 'states'].includes(this.currentTab) ;
    },
  },

  methods: {
    confirmDelete() {
      this.loading = true;
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to undo this.',
        icon: 'warning',
        confirmButtonText: "Yes, I'm sure.",
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAgent();
        }
      })
    },

    deleteAgent() {
      axios.delete(`/agents/${this.agent_info.id}`)
        .then((response) => {
          Swal.fire({
            title: 'Agent deleted',
          }).then(() => {
            if (response && response.data && response.data.redirect_url) {
              window.location = response.data.redirect_url;
            }
          })
        })
        .catch((error) => {
          Swal.fire({
            text: 'Unknown error occurred, please try again.',
            icon: 'error'
          });

          console.log("AppAgentView - handleErroredSubmission", error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleFileAdded() {
      this.$refs['agent-states'].fetchLicensedStates();
    },

    handleShowEditMode(payload) {
      if (payload.fieldId) {
        this.toggleEditMode(payload.fieldId)
      } else {
        this.toggleEditMode();
      }
    },

    toggleEditMode() {
      this.editMode = ! this.editMode;

      if (this.editMode) {
        this.$nextTick(() => {

        });
      }
    },

    setCurrentTab(tabKey) {
      this.currentTab = tabKey;
    },
  },
}
</script>

<style scoped>
li.active {
  width: unset !important;
}
</style>
