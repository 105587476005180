<script setup>
import {useForm} from "@inertiajs/inertia-vue3";
import {ElSelect, ElOption} from 'element-plus';
import {debounce} from "lodash";
import {ref} from "vue";

const props = defineProps({
  leadId: Number,
  customTags: Array
})

const searchingFolders = ref(false);
const formData = useForm({
  custom_tag_id: null,
  lead_id: props.leadId,
})
const leadFolderList = ref([]);

const emit = defineEmits(['lead-folder-assigned-successfully'])

function submit() {
  if (searchingFolders.value) return;
  searchingFolders.value = true;
  setTimeout(() => {
    formData.post(route('lead-custom-tags.lead.store', {lead_custom_tag: formData.custom_tag_id}), {
      preserveScroll: true,
      only: ['statusAndAssignmentData'],
      onSuccess: () => {
        emit('lead-folder-assigned-successfully')
        formData.reset();
        leadFolderList.value = [];
      },
      onError: () => {
        console.log('error')
      },
      onFinish: () => {
        searchingFolders.value = false;
      },

    })
  }, 500)
}

const searchLeadFolders = debounce(search => {
  axios
    .get(route('custom-tags.search', {search}))
    .then(response => {
      leadFolderList.value = response.data;
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      searchingFolders.value = false;
    })
}, 200)
</script>

<template>
  <form action="" @submit.prevent="submit">
    <div class="p-4">
      <label for="agents_to_share_with" class="form-label">Search for custom tag categories</label>
      <el-select 
        v-model="formData.custom_tag_id"
        class="w-full"
      >
      <el-option
          v-for="customTag in customTags"
          :key="customTag.id"
          :label="`${customTag.name}`"
          :value="customTag.id"
        />
      </el-select> 
      <button :disabled="formData.custom_tag_id === null" type="submit" class="btn-primary mt-4 text-right">Submit</button>
    </div>
  </form>
</template>

<style scoped></style>
